import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import googlePlayImg from './images/google-play-badge.png';
import { useTranslation } from "react-i18next";
import { GOOGLE_PLAY_URL } from "src/app/config";
import './GooglePlayBadge.scss';
const GooglePlayBadge = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: "google-play-badge", children: [_jsx("h5", { children: t('Meet.the.Banafo.App.for.Android_') }), _jsx("a", { href: GOOGLE_PLAY_URL, target: "_blank", rel: "noreferrer", children: _jsx("img", { src: googlePlayImg, className: "img-fluid", alt: 'google-play' }) })] }));
};
export default GooglePlayBadge;
