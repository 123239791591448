import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Col, Container, Row, Button, Form, SpinnerBackdrop, Stack, GoogleSignInButton, MicrosoftSignInButton } from "banafo-design-system";
import { ErrorMessageModal } from "src/features/auth/component-old/common/MessageModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { clearError, startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { useDispatch, useSelector } from "react-redux";
import { selectAppLoading, selectErrorText, selectHasError } from "src/features/auth/store/selectors/common";
import { selectAuth, selectAuthInteractionName } from "src/features/auth/store/selectors/auth";
import { interactionLogin, interactionLoginDetails, loginInputChanged } from "src/features/auth/store/actions/auth/login";
import "./LoginInteractionPage.scss";
import LineSeparator from "src/common/components/ui-old/LineSeparator";
import ForgottenPasswordModal from "src/features/auth/login/ForgottenPasswordModal";
import ForgottenPasswordSuccessModal from "src/features/auth/login/ForgottenPasswordSuccessModal";
import useModal from "src/common/hooks/useModal";
import { googleUrl, microsoftUrl } from "src/features/auth/config";
import { AUTH_REGISTER_ROUTE } from "src/app/router/config";
import GeneralError from "src/features/auth/GeneralError";
function LoginInteractionPage() {
    const dispatch = useDispatch();
    const loadingApp = useSelector((state) => selectAppLoading(state));
    const showWrongCredentials = useSelector((state) => selectAuth(state).showWrongCredentials);
    const hasError = useSelector((state) => selectHasError(state));
    const errorText = useSelector((state) => selectErrorText(state));
    const interactionName = useSelector((state) => selectAuthInteractionName(state));
    const { uid } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isOpen, open, close } = useModal();
    const [forgottenPassSuccessModalShow, setForgottenPassSuccessModalShow] = useState(false);
    useEffect(() => {
        dispatch(startAppLoader());
        dispatch(interactionLoginDetails(uid))
            .catch(() => {
            navigate("/");
        })
            .finally(() => {
            dispatch(stopAppLoader());
        });
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(startAppLoader());
        dispatch(interactionLogin(uid, { username, password }))
            .then((res) => {
            window.location = res.redirectTo;
        })
            .catch((err) => null)
            .finally(() => {
            dispatch(stopAppLoader());
        });
    };
    const renderInteractionType = () => {
        if (interactionName === 'consent') {
            return (_jsxs("div", { children: [t("Allow.user.access.to_"), ":"] }));
        }
        if (interactionName === 'login') {
            return (_jsx(_Fragment, { children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsx("h2", { className: "text-center mb-200", children: t("Login_") }), _jsx(GeneralError, { as: "h6" }), _jsx(Form.Group, { controlId: "email", className: "mb-50", children: _jsx(Form.Control, { type: "text", placeholder: t("Email_"), value: username, onChange: (e) => setUsername(e.target.value) }) }), _jsxs(Form.Group, { controlId: "password", className: "mb-0", children: [_jsx(Form.Control, { type: "password", placeholder: t("Password_"), value: password, onChange: (e) => setPassword(e.target.value), isInvalid: showWrongCredentials }), _jsxs(Form.Control.Feedback, { type: "invalid", children: [t("Wrong.username.or.password_"), "."] })] }), _jsx("p", { className: "text-end my-0", children: _jsxs(Link, { className: 'text-dark text-decoration-none', onClick: open, to: '#', children: [t("Forgot.your.password_"), "?"] }) }), _jsx(Button, { variant: "dark", type: "submit", className: "px-300 mt-250 mx-auto d-block text-uppercase", children: t("Login_") }), _jsx(Row, { children: _jsxs(Col, { xs: 9, className: "mx-auto my-200", children: [_jsxs("p", { className: "text-center mt-2 mb-0", children: [t("New.to.banafo?_"), " ", _jsx(Link, { className: 'text-dark text-decoration-none fw-bold text-uppercase', to: AUTH_REGISTER_ROUTE, children: t("Register_") })] }), _jsx(LineSeparator, { text: 'or', borderColor: "secondary", className: "my-25" }), _jsxs(Stack, { gap: 100, className: "col mx-auto", children: [_jsx(GoogleSignInButton, { url: googleUrl, children: t('Sign.in.with.Google_') }), _jsx(MicrosoftSignInButton, { url: microsoftUrl })] })] }) })] }) }));
        }
    };
    return (_jsxs("section", { className: "login-interaction", children: [_jsx(Container, { fluid: 'xl', className: 'pt-300 pb-800', children: _jsx(Row, { children: _jsx(Col, { xs: 12, md: { span: 6, offset: 3 }, lg: { span: 6, offset: 6 }, xl: { span: 4, offset: 6 }, xxl: { span: 3, offset: 6 }, children: _jsx(Card, { className: "px-lg-300 py-lg-400 border-0", children: _jsxs(Card.Body, { children: [loadingApp ? _jsx(SpinnerBackdrop, { position: 'absolute' }) : null, renderInteractionType()] }) }) }) }) }), _jsx(ErrorMessageModal, { message: errorText, show: hasError, onHide: () => dispatch(clearError()) }), _jsx(ForgottenPasswordModal, { show: isOpen, onHide: close, showSuccess: () => setForgottenPassSuccessModalShow(true) }), _jsx(ForgottenPasswordSuccessModal, { show: forgottenPassSuccessModalShow, onHide: () => setForgottenPassSuccessModalShow(false) })] }));
}
export default LoginInteractionPage;
