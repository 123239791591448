/**
 *
 * @param ajv
 * @param schema
 * @param inputValues
 * @param t
 * @returns {{hasErrors: boolean, errors: *[]}}
 */
const validateSchema = (ajv, schema, inputValues, t) => {
    let errors;
    let hasErrors = false;
    if (!ajv.validate(schema, Object.assign({}, inputValues))) {
        errors = ajv.errors.map(error => ({ name: error.dataPath.substr(1), message: error.message, params: error.params }))
            .reduce((acc, error) => {
            var _a, _b;
            const params = ((_b = (_a = error.params) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[0].params) || null;
            acc[error.name] = t(error.message, Object.assign({}, params));
            return acc;
        }, {});
        hasErrors = true;
    }
    return {
        hasErrors,
        errors
    };
};
export default validateSchema;
