import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip, BifIconPencil } from "banafo-design-system";
const EditNoteButton = ({ handleEditNote }) => {
    const { t } = useTranslation();
    return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "open-note", children: t('Edit_') }), children: _jsx(Button, { variant: "link", size: 'sm', className: "text-dark", onClick: handleEditNote, children: _jsx(BifIconPencil, {}) }) }));
};
EditNoteButton.propTypes = {
    handleEditNote: PropTypes.func
};
export default EditNoteButton;
