var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import api from "../../../../api/api";
import { setErrorMessage } from "./app";
export const FETCH_RECORDING_TRANSCRIPTS_BEGIN = 'FETCH_RECORDING_TRANSCRIPTS_BEGIN';
export const FETCH_RECORDING_TRANSCRIPTS_SUCCESS = 'FETCH_RECORDING_TRANSCRIPTS_SUCCESS';
export const FETCH_RECORDING_TRANSCRIPTS_FAILURE = 'FETCH_RECORDING_TRANSCRIPTS_FAILURE';
export const SEARCH_RECORDING_TRANSCRIPTS_BEGIN = 'SEARCH_RECORDING_TRANSCRIPTS_BEGIN';
export const SEARCH_RECORDING_TRANSCRIPTS_SUCCESS = 'SEARCH_RECORDING_TRANSCRIPTS_SUCCESS';
export const SEARCH_RECORDING_TRANSCRIPTS_FAILURE = 'SEARCH_RECORDING_TRANSCRIPTS_FAILURE';
export const SEARCH_RECORDING_TRANSCRIPTS_RESET = 'SEARCH_RECORDING_TRANSCRIPTS_RESET';
export const DOWNLOAD_TRANSCRIPTS_BEGIN = 'DOWNLOAD_TRANSCRIPTS_BEGIN';
export const DOWNLOAD_TRANSCRIPTS_SUCCESS = 'DOWNLOAD_TRANSCRIPTS_SUCCESS';
export const DOWNLOAD_TRANSCRIPTS_FAILURE = 'DOWNLOAD_TRANSCRIPTS_FAILURE';
export const searchRecordingTranscriptsBegin = () => ({
    type: SEARCH_RECORDING_TRANSCRIPTS_BEGIN
});
export const searchRecordingTranscriptsSuccess = (items) => ({
    type: SEARCH_RECORDING_TRANSCRIPTS_SUCCESS,
    payload: { items }
});
export const searchRecordingTranscriptsFailure = (error) => ({
    type: SEARCH_RECORDING_TRANSCRIPTS_FAILURE,
    payload: { error }
});
export const resetSearchRecordingTranscripts = () => ({
    type: SEARCH_RECORDING_TRANSCRIPTS_RESET,
});
export const downloadTranscriptsBegin = () => ({
    type: DOWNLOAD_TRANSCRIPTS_BEGIN
});
export const downloadTranscriptsSuccess = () => ({
    type: DOWNLOAD_TRANSCRIPTS_SUCCESS
});
export const downloadTranscriptsFailure = (error) => ({
    type: DOWNLOAD_TRANSCRIPTS_FAILURE,
    payload: { error }
});
export const searchTranscripts = (search) => {
    return (dispatch, getState) => {
        dispatch(searchRecordingTranscriptsBegin());
        const data = { search };
        return api.getTranscripts(data)
            .then(response => {
            const transcripts = response.map((_a) => {
                var { _id } = _a, transcript = __rest(_a, ["_id"]);
                return Object.assign({ id: _id }, transcript);
            });
            dispatch(searchRecordingTranscriptsSuccess(transcripts));
            return transcripts;
        })
            .catch(err => {
            dispatch(searchRecordingTranscriptsFailure(err));
            return Promise.reject(err);
        });
    };
};
export const downloadTranscripts = (fileIds, format, filters) => {
    return (dispatch, getState) => {
        dispatch(downloadTranscriptsBegin());
        const data = Object.assign({ fileIds, format }, filters);
        return api.downloadTranscripts(data)
            .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: response.type
            }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            dispatch(downloadTranscriptsSuccess());
            return response;
        })
            .catch(err => {
            dispatch(setErrorMessage(err.response.body.message));
            dispatch(downloadTranscriptsFailure(err));
            return Promise.reject(err);
        });
    };
};
