import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import FeedsSection from "src/features/account/FeedsSection";
import UploadRecordingModal from "src/features/uploadRecording/modals/UploadRecordingModal";
import { useIsMobile } from "src/common/hooks/useIsMobile";
import RecentActivitySection from "src/pages/home/RecentActivitySection";
import Header from "src/pages/home/Header";
const HomePage = () => {
    const isMobile = useIsMobile();
    const scrollBar = isMobile ? 1 : 0;
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: scrollBar, options: {
                    contentProps: { className: 'p-200 d-flex flex-column' },
                }, children: [_jsx(RecentActivitySection, {}), _jsx(FeedsSection, { className: "d-lg-none mb-200" })] }), _jsx(UploadRecordingModal, {})] }));
};
export default HomePage;
