import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BanafoModal, Button, Card, Form } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { resetConnectingProviderId, selectConnectingProvider, useAddIntegrationMutation } from "../integrationsSlice";
const ProviderConnectModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const { id, name, status, authInstructions, authFields = [] } = useSelector((state) => selectConnectingProvider(state));
    const [addIntegration, { isLoading }] = useAddIntegrationMutation();
    const errors = {};
    const handleHideModal = () => {
        dispatch(resetConnectingProviderId());
    };
    const handleInputChange = (e) => {
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const handleConnectProvider = () => {
        handleHideModal();
        addIntegration(Object.assign({ provider: name }, inputs))
            .unwrap()
            .then(({ url }) => window.location.replace(url))
            .catch(e => null);
    };
    return (_jsx(BanafoModal, { show: !!id, onHide: handleHideModal, footer: _jsx(Button, { variant: "primary", onClick: handleConnectProvider, children: t('Connect_') }), children: _jsxs(Card, { className: 'border-0', children: [_jsx(Card.Img, { variant: "top", src: name && require(`../images/provider-logo-${name}.png`) }), _jsxs(Card.Body, { children: [_jsx(Card.Text, { children: authInstructions }), _jsx(Form, { children: authFields.map(authField => (_jsxs(Form.Group, { controlId: authField.key, className: 'mb-100', children: [_jsx(Form.Label, { children: authField.label }), _jsx(Form.Control, { placeholder: authField.label, name: authField.key, onChange: handleInputChange, value: inputs[authField.key] || '', isInvalid: !!errors[authField.key], className: 'TeamsAndMembers-filter' }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors[authField.key] })] }, authField.key))) })] })] }) }));
};
export default ProviderConnectModal;
