import { combineReducers } from "redux";
import { FETCH_RECORDINGS_BEGIN, FETCH_RECORDINGS_FAILURE, FETCH_RECORDINGS_SUCCESS, RECORDINGS_DE_INITIALIZE, RECORDINGS_INITIALIZE, RECORDINGS_LOADED, RECORDINGS_LOADING, RECORDINGS_PAGINATION_RESET, RECORDINGS_PAGINATION_SAVE } from "../../actions/recordings";
function initialize(state = false, action) {
    switch (action.type) {
        case RECORDINGS_INITIALIZE:
            return true;
        case RECORDINGS_DE_INITIALIZE:
            return false;
        case FETCH_RECORDINGS_BEGIN:
        case FETCH_RECORDINGS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case RECORDINGS_LOADING:
            return state + 1;
        case RECORDINGS_LOADED:
            return state - 1;
        case FETCH_RECORDINGS_BEGIN:
            return state + 1;
        case FETCH_RECORDINGS_SUCCESS:
            return state - 1;
        case FETCH_RECORDINGS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
function pagination(state = {}, action) {
    switch (action.type) {
        case RECORDINGS_PAGINATION_SAVE:
            return action.payload.pagination;
        case RECORDINGS_PAGINATION_RESET:
            return {};
        case FETCH_RECORDINGS_BEGIN:
            return state;
        case FETCH_RECORDINGS_SUCCESS:
            return action.payload.pagination;
        case FETCH_RECORDINGS_FAILURE:
            return state;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading,
    pagination
});
