import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Row, Button } from "banafo-design-system";
import { useGetSubscriptionsQuery } from "src/features/account/accountApi";
import PlanTitle from "src/features/account/planDetails/PlanTitle";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import { useDispatch } from "react-redux";
import { showUnsubscribeModal } from "src/features/account/accountSlice";
import ConfirmUnsubscribeModal from "src/features/account/modals/ConfirmUnsubscribeModal";
const PlanDetailsSection = () => {
    const { t } = useTranslation();
    const { data: { nextPayment, id, remoteId, cancel_at } = {} } = useGetSubscriptionsQuery();
    const dispatch = useDispatch();
    const handleUnSubscribe = () => (e) => {
        e.stopPropagation();
        dispatch(showUnsubscribeModal());
    };
    return (_jsxs("div", { className: "my-200 mx-50", children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t("Current.plan_") }), _jsxs(Row, { className: "mb-150 g-0", children: [_jsx(PlanTitle, {}), !cancel_at && _jsxs(_Fragment, { children: [_jsxs("div", { children: [t("Next.payment.date_"), ": ", nextPayment ? moment(nextPayment).format('YYYY-MM-DD HH:mm:ss') : null] }), _jsx(OverlayTrigger, { placement: 'left', overlay: _jsx(Tooltip, { id: "cancel-subscription", children: t('Cancel.subscription_') }), children: _jsx("span", { children: remoteId && _jsx(Button, { variant: "warning", color: "dark", tt: "uppercase", onClick: handleUnSubscribe(), fullWidth: true, style: { flex: '1 1 270px' }, children: t("Unsubscribe_") }) }) })] }), cancel_at && _jsxs("div", { children: [t("Subscription.canceled.at_"), ": ", cancel_at ? moment(cancel_at).format('YYYY-MM-DD HH:mm:ss') : null] })] }), _jsx(ConfirmUnsubscribeModal, {})] }));
};
export default PlanDetailsSection;
