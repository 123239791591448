import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, SpinnerBackdrop } from "banafo-design-system";
import { useGetRecordingExtensionSettingsQuery, useUpdateRecordingExtensionSettingsMutation } from "./browserExtensionSlice";
import { CONTROLS_POSITION_ENUM } from "./config";
import { CONTROLS_POSITION_LEFT, CONTROLS_POSITION_RIGHT } from "src/features/auth/config/extension";
const RecordSettingsForm = () => {
    const { t } = useTranslation();
    const { data, data: { autoShowControls, autoSync, visual: { dockedLeft } } = { visual: {} }, isLoading } = useGetRecordingExtensionSettingsQuery();
    const [updateSettings, { isLoading: isUpdating }] = useUpdateRecordingExtensionSettingsMutation();
    const handleAutomaticShowChange = (e) => {
        updateSettings(Object.assign(Object.assign({}, data), { autoShowControls: e.target.checked }));
    };
    const handleControlPositionChange = (e) => {
        updateSettings(Object.assign(Object.assign({}, data), { visual: { dockedLeft: e.target.value === CONTROLS_POSITION_LEFT } }));
    };
    const handleAutoSyncChange = (e) => {
        updateSettings(Object.assign(Object.assign({}, data), { autoSync: e.target.checked }));
    };
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs(_Fragment, { children: [isUpdating && _jsx(SpinnerBackdrop, {}), _jsx(Form, { className: "mx-50 my-200", children: _jsxs(Form.Group, { children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t('Record.controls_') }), _jsxs(Row, { className: "mb-150 justify-content-between", children: [_jsx(Form.Label, { column: true, xs: "auto", children: t('Choose.record.controls.default.position_') }), _jsx(Col, { xs: "auto", children: _jsx(Form.Select, { value: dockedLeft ? CONTROLS_POSITION_LEFT : CONTROLS_POSITION_RIGHT, onChange: handleControlPositionChange, children: CONTROLS_POSITION_ENUM.map(({ value, text }) => {
                                            return (_jsx("option", { value: value, children: text }, value));
                                        }) }) })] }), _jsxs(Row, { className: "justify-content-between align-items-center", children: [_jsx(Form.Label, { column: true, xs: "auto", children: t('Show.record.controls.automatically.in.whitelisted.websites_') }), _jsx(Col, { xs: "auto", children: _jsx(Form.Check, { type: "switch", inline: true, reverse: true, label: autoShowControls ? t('Yes_') : t('No_'), id: "auto-show-controls", checked: autoShowControls, onChange: handleAutomaticShowChange }) })] }), _jsxs(Row, { className: "justify-content-between align-items-center", children: [_jsx(Form.Label, { column: true, xs: "auto", children: t('Automatic.sync.recording.and.settings_') }), _jsx(Col, { xs: "auto", children: _jsx(Form.Check, { type: "switch", inline: true, reverse: true, label: autoSync ? t('Yes_') : t('No_'), id: "auto-show-sync", checked: autoSync, onChange: handleAutoSyncChange }) })] })] }) })] }));
};
export default RecordSettingsForm;
