import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { useLocation } from "react-router-dom";
import { selectResellerCustomersApiArgs } from "src/features/resellerCustomers/resellerCustomersSlice";
import { makeSelectAllResellerBillings, selectTotalResellerBillings, useGetResellerBillingsQuery } from "src/features/resellerBillingHistory/resellerBillingHistoryApi";
import { incrementBillingHistoryPage, resetResellerBillingHistory, selectResellerBillingHistoryIsLastPage } from "src/features/resellerBillingHistory/resellerBillingHistorySlice";
const useGetResellerBillingHistory = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const args = useAppSelector(selectResellerCustomersApiArgs);
    const result = useGetResellerBillingsQuery(args);
    const { isFetching } = result;
    const selectAllResellerBillings = useMemo(makeSelectAllResellerBillings, []);
    const resellerBillings = useAppSelector(state => selectAllResellerBillings(state, args));
    const totalResellerBillings = useAppSelector(state => selectTotalResellerBillings(state, args));
    const hasNextPage = !useAppSelector(selectResellerBillingHistoryIsLastPage);
    const loadMoreResellerBillings = useCallback(() => {
        if (isFetching || !hasNextPage) {
            return;
        }
        dispatch(incrementBillingHistoryPage());
    }, [isFetching, hasNextPage]);
    useEffect(() => {
        return () => {
            dispatch(resetResellerBillingHistory());
        };
    }, [location.pathname]);
    return Object.assign({ resellerBillings,
        totalResellerBillings,
        hasNextPage,
        loadMoreResellerBillings }, result);
};
export default useGetResellerBillingHistory;
