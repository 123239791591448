import { combineReducers } from "redux";
import { EXTRACT_RECORDINGS, FETCH_RECORDINGS_SUCCESS, RECORDING_DELETE, RECORDINGS_ADD, RECORDINGS_RESET } from '../../actions/recordings';
import { addEntries, addIds, deleteEntry, removeId, setEntries, setIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case RECORDING_DELETE:
            return deleteEntry(state, action);
        case RECORDINGS_RESET:
            return {};
        case RECORDINGS_ADD:
            return addEntries(state, action);
        case FETCH_RECORDINGS_SUCCESS:
        case EXTRACT_RECORDINGS:
            return setEntries(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case RECORDING_DELETE:
            return removeId(state, action);
        case RECORDINGS_RESET:
            return [];
        case RECORDINGS_ADD:
            return addIds(state, action);
        case FETCH_RECORDINGS_SUCCESS:
        case EXTRACT_RECORDINGS:
            return setIds(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
