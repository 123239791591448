import i18n from "i18next";
export const AUTH_LOGIN_ROUTE = '/auth/login';
export const AUTH_REGISTER_ROUTE = '/auth/register';
export const AUTH_TOKEN_RENEW_SUCCESS_ROUTE = '/auth/token-renew-success';
export const HOME_ROUTE = '/home';
export const CONTACTS_ROUTE = '/contacts';
export const WIZARD_ROUTE = '/wizard';
export const SETTINGS_ROUTE = '/settings';
export const INVOICES_ROUTE = '/invoices';
export const CONVERSATIONS_ROUTE = '/conversations';
export const CONVERSATIONS_BY_CALL_ID_ROUTE = '/conversations/:callId';
export const CONVERSATIONS_BY_CALL_ID_TRANSCRIPT_ID_ROUTE = '/conversations/:callId/:transcriptId';
export const CALLS_ROUTE = '/calls'; // here for backward compatibility
export const CALLS_BY_CALL_ID_ROUTE = '/calls/:callId';
export const INTEGRATIONS_ROUTE = '/integrations';
export const DEVICE_ROUTE = '/device';
export const DEVICE_ACTIVATE_ROUTE = '/device/activate';
export const DEVICE_ACTIVATE_ANDROID_ROUTE = '/device/activate/android/:code?';
export const DEVICE_ACTIVATE_ZOIPER_ANDROID_ROUTE = '/device/activate/zoiper-android/:code?';
export const DEVICE_ACTIVATE_ZOIPER5_ROUTE = '/device/activate/zoiper5/:code?';
export const API_KEYS_ROUTE = '/api-keys';
export const RECORDING_EXTENSION_ROUTE = '/recording-extension';
export const RECORDING_EXTENSION_OLD_ROUTE = '/settings/recording-extension';
export const BILLING_ROUTE = '/payment/:plan';
export const BILLING_SUCCESS_ROUTE = '/payment/success/:sessionId';
export const BILLING_UPGRADE_ROUTE = '/payment/:plan/:upgrade';
export const RESELLER_INVITE_ROUTE = '/reseller/invite/:token';
export const RESELLER_CUSTOMERS_ROUTE = '/reseller-customers';
export const RESELLER_CUSTOMERS_SETTINGS_ROUTE = '/reseller-customers/:resellerCustomerId/settings';
export const RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE = '/reseller-customers/settings/guide-3cx';
export const RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE = '/reseller-customers/settings/guide';
export const RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE = '/reseller-customers/billing-history';
export const DOCUMENT_TITLES = [
    {
        path: "/auth/login/external",
        get title() { return i18n.t('Login.External_'); },
        get description() { return i18n.t('Login.External_'); }
    },
    {
        path: AUTH_LOGIN_ROUTE,
        get title() { return i18n.t('Login_'); },
        get description() { return i18n.t('infos:Login.description_'); }
    },
    {
        path: "/interaction/:uid",
        get title() { return i18n.t('Login.interaction_'); },
        get description() { return i18n.t('Login.interaction_'); }
    },
    {
        path: AUTH_REGISTER_ROUTE,
        get title() { return i18n.t('Register_'); },
        get description() { return i18n.t('infos:Register.description_'); }
    },
    {
        path: "/auth/recovery/:recoveryToken",
        get title() { return i18n.t('Change.password_'); },
        get description() { return i18n.t('infos:Change.password.description_'); }
    },
    {
        path: "/auth/verify/:code",
        get title() { return i18n.t('Verify.code_'); },
        get description() { return i18n.t('Verify.code_'); }
    },
    {
        path: "/auth/logout-success",
        get title() { return i18n.t('Logout_'); },
        get description() { return i18n.t('Logout_'); }
    },
    {
        path: HOME_ROUTE,
        get title() { return i18n.t('Home_'); },
        get description() { return i18n.t('infos:Home.description_'); }
    },
    {
        path: CONVERSATIONS_BY_CALL_ID_ROUTE,
        get title() { return i18n.t('Conversation.details_'); },
        get description() { return i18n.t('Conversation.details_'); }
    },
    {
        path: CALLS_BY_CALL_ID_ROUTE,
        get title() { return i18n.t('Conversation.details_'); },
        get description() { return i18n.t('Conversation.details_'); }
    },
    {
        path: CONVERSATIONS_ROUTE,
        get title() { return i18n.t('Conversations_'); },
        get description() { return i18n.t('Conversations_'); }
    },
    {
        path: CALLS_ROUTE,
        get title() { return i18n.t('Conversations_'); },
        get description() { return i18n.t('Conversations_'); }
    },
    {
        path: "/contacts/crm",
        get title() { return i18n.t('Contacts_'); },
        get description() { return i18n.t('Contacts_'); },
    },
    {
        path: "/contacts/favourites",
        get title() { return i18n.t('Contacts_'); },
        get description() { return i18n.t('Contacts_'); },
    },
    {
        path: "/contacts/address-book",
        get title() { return i18n.t('Contacts_'); },
        get description() { return i18n.t('Contacts_'); },
    },
    {
        path: "/settings/teams",
        get title() { return i18n.t('Teams_'); },
        get description() { return i18n.t('Teams_'); },
    },
    {
        path: "/settings/members",
        get title() { return i18n.t('Members_'); },
        get description() { return i18n.t('Members_'); },
    },
    {
        path: "/teams",
        get title() { return i18n.t('Teams_'); },
        get description() { return i18n.t('Teams_'); },
    },
    {
        path: "/members",
        get title() { return i18n.t('Members_'); },
        get description() { return i18n.t('Members_'); },
    },
    {
        path: SETTINGS_ROUTE,
        get title() { return i18n.t('General.Settings_'); },
        get description() { return i18n.t('General.Settings_'); },
    },
    {
        path: RECORDING_EXTENSION_ROUTE,
        get title() { return i18n.t('Browser.Extension_'); },
        get description() { return i18n.t('Browser.Extension_'); },
    },
    {
        path: INTEGRATIONS_ROUTE,
        get title() { return i18n.t('Integrations_'); },
        get description() { return i18n.t('Integrations_'); },
    },
    {
        path: API_KEYS_ROUTE,
        get title() { return i18n.t('Speech.to.text.api_'); },
        get description() { return i18n.t('Speech.to.text.api_'); },
    },
    {
        path: DEVICE_ACTIVATE_ROUTE,
        get title() { return i18n.t('Apps_'); },
        get description() { return i18n.t('Apps_'); },
    },
    {
        path: DEVICE_ACTIVATE_ANDROID_ROUTE,
        get title() { return i18n.t('Apps_'); },
        get description() { return i18n.t('Apps_'); },
    },
    {
        path: DEVICE_ACTIVATE_ZOIPER_ANDROID_ROUTE,
        get title() { return i18n.t('Apps_'); },
        get description() { return i18n.t('Apps_'); },
    },
    {
        path: DEVICE_ACTIVATE_ZOIPER5_ROUTE,
        get title() { return i18n.t('Apps_'); },
        get description() { return i18n.t('Apps_'); },
    },
    {
        path: INVOICES_ROUTE,
        get title() { return i18n.t('Invoices_'); },
        get description() { return i18n.t('Invoices_'); },
    },
    {
        path: RESELLER_INVITE_ROUTE,
        get title() { return i18n.t('Reseller.invite_'); },
        get description() { return i18n.t('infos:Reseller.invite.description_'); }
    },
    // reseller pages only
    {
        path: RESELLER_CUSTOMERS_ROUTE,
        get title() { return i18n.t('My.customers_'); },
        get description() { return i18n.t('infos:My.customers.description_'); }
    },
    {
        path: RESELLER_CUSTOMERS_SETTINGS_ROUTE,
        get title() { return i18n.t('My.customers_'); },
        get description() { return i18n.t('infos:My.customers.description_'); }
    },
    {
        path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE,
        get title() { return i18n.t('My.customers_'); },
        get description() { return i18n.t('infos:My.customers.description_'); }
    },
    {
        path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE,
        get title() { return i18n.t('My.customers_'); },
        get description() { return i18n.t('infos:My.customers.description_'); }
    },
    {
        path: RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE,
        get title() { return i18n.t('Billing.history_'); },
        get description() { return i18n.t('infos:My.customers.description_'); }
    },
];
