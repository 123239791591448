import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BifIconStarOutlines, BifIconArrowRight, ListGroup } from "banafo-design-system";
import { useTranslation } from "react-i18next";
const NewsItem = ({ newsItem }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs(ListGroup.Item, { action: true, as: "li", variant: "warning", 
            // className="bg-warning"
            onClick: () => window.open(newsItem.url, "_blank"), children: [_jsx("div", { children: _jsx(BifIconStarOutlines, { className: "mx-150 fs-5" }) }), _jsx("div", { className: 'fw-bold', children: newsItem.title }), _jsxs("div", { className: "ms-auto", children: [t('Read.more_'), _jsx(BifIconArrowRight, { className: "mx-50 fs-7" })] })] }) }));
};
export default NewsItem;
