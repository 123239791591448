import { apiClient } from "src/app/services/httpClient";
export const getTeams = (data) => {
    return apiClient
        .get(`/teams`)
        .query(data);
};
export const createTeam = (name) => {
    return apiClient
        .post('/teams')
        .send({ name });
};
export const removeTeam = (id) => {
    return apiClient
        .delete(`/teams/${id}`);
};
export const inviteMemberToTeam = (id, data) => {
    return apiClient
        .post(`/teams/${id}/members`)
        .send(data);
};
export const modifyTeamMember = (id, userId, data) => {
    return apiClient
        .put(`/teams/${id}/members/${userId}`)
        .send(data);
};
export const removeTeamMember = (id, userId) => {
    return apiClient
        .delete(`/teams/${id}/members/${userId}`);
};
export const getTeamPermissions = () => {
    return apiClient
        .get(`/teams/perms`);
};
