import { createSlice } from "@reduxjs/toolkit";
const conversationInitialState = {
    summaryModal: {
        isOpen: false
    },
    actionPointsModal: {
        isOpen: false
    },
};
export const conversationSlice = createSlice({
    name: 'conversation',
    initialState: conversationInitialState,
    reducers: {
        showSummaryModal: state => {
            state.summaryModal.isOpen = true;
        },
        hideSummaryModal: state => {
            state.summaryModal.isOpen = false;
        },
        showActionPointsModal: state => {
            state.actionPointsModal.isOpen = true;
        },
        hideActionPointsModal: state => {
            state.actionPointsModal.isOpen = false;
        },
    }
});
const { name, actions, reducer } = conversationSlice;
export const { showSummaryModal, hideSummaryModal, showActionPointsModal, hideActionPointsModal } = actions;
export { name, actions };
export default reducer;
export const selectSummaryModalIsOpen = (state) => state[name].summaryModal.isOpen;
export const selectActionPointsIsOpen = (state) => state[name].actionPointsModal.isOpen;
