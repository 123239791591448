import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { generateMessage } from "src/features/toasts/utils";
const toastsAdapter = createEntityAdapter();
const initialState = toastsAdapter.getInitialState();
export const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        toastAdded: toastsAdapter.addOne,
        toastDeleted: toastsAdapter.removeOne,
        toastOpened: (state, action) => {
            const { id } = action.payload;
            toastsAdapter.updateOne(state, { id, changes: { isOpen: true } });
        },
        toastClosed: (state, action) => {
            const { id } = action.payload;
            toastsAdapter.updateOne(state, { id, changes: { isOpen: false } });
        },
    },
});
export const { name, actions } = toastsSlice;
export const { toastAdded, toastDeleted, toastOpened, toastClosed } = toastsSlice.actions;
export default toastsSlice.reducer;
//SELECTORS
export const { selectAll: selectAllToasts, selectEntities: selectEntitiesToasts, selectById: selectToastById } = toastsAdapter.getSelectors(state => { var _a; return (_a = state.toasts) !== null && _a !== void 0 ? _a : initialState; });
export const selectTranslatedToasts = createSelector(selectAllToasts, (items) => items === null || items === void 0 ? void 0 : items.map(generateMessage));
