import { useTranslation } from "react-i18next";
import { isNotEmpty, useForm } from "@mantine/form";
const useActivateDeviceForm = () => {
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            code: '',
        },
        validate: {
            code: isNotEmpty(t('errors:Required.code_')),
        },
    });
    return form;
};
export default useActivateDeviceForm;
