import { combineReducers } from "redux";
import { CLEAR_CONTACTS, EXTRACT_CONTACT, EXTRACT_CONTACTS, FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_SUCCESS } from '../../actions/contacts';
import { addEntries, addEntry, addId, addIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case CLEAR_CONTACTS:
            return {};
        case EXTRACT_CONTACT:
            return addEntry(state, action);
        case EXTRACT_CONTACTS:
        case FETCH_CONTACTS_SUCCESS:
            return addEntries(state, action);
        case FETCH_CONTACTS_FAILURE:
            return {};
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case CLEAR_CONTACTS:
            return [];
        case EXTRACT_CONTACT:
            return addId(state, action);
        case EXTRACT_CONTACTS:
        case FETCH_CONTACTS_SUCCESS:
            return addIds(state, action);
        case FETCH_CONTACTS_FAILURE:
            return [];
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
