import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { Carousel } from "banafo-design-system";
const SettingsCarousel = ({ items = [], initialIndex, onSelect }) => {
    const [index, setIndex] = useState(initialIndex);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        onSelect(items[selectedIndex]);
    };
    return (_jsx(Carousel, { activeIndex: index, onSelect: handleSelect, indicators: false, interval: null, variant: "dark", children: items.map(({ value, text }, index) => {
            return (_jsx(Carousel.Item, { children: _jsx("div", { className: "text-center", children: text }) }, index));
        }) }));
};
export default SettingsCarousel;
