import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider } from "@mantine/core";
import ResellerBillingsTableFilters from "src/features/resellerBillingHistory/table/ResellerBillingsTableFilters";
import ResellerBillingsTable from "src/features/resellerBillingHistory/table/ResellerBillingsTable";
const ResellerBillings = forwardRef(({ resellerBillings, totalResellerBillings, loadMoreResellerBillings, isLoading, isFetching, hasNextPage, }, ref) => {
    const { t } = useTranslation();
    return (_jsxs(Box, { flex: "1", ta: "center", ref: ref, style: { scrollMargin: '2rem' }, children: [_jsx(ResellerBillingsTableFilters, { total: totalResellerBillings }), _jsx(Divider, { size: "md", my: "sm" }), _jsx(ResellerBillingsTable, { resellerBillings: resellerBillings }), hasNextPage && _jsx(Button, { onClick: loadMoreResellerBillings, variant: "outline-filled", loading: isFetching, tt: "uppercase", mt: "md", children: t('Load.more_') })] }));
});
export default ResellerBillings;
