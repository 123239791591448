import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BifIconNewNote, BifIconPin, Nav, OverlayTrigger, Tooltip } from "banafo-design-system";
const NotesExtraTab = () => {
    const { t } = useTranslation();
    const handlePlacePin = () => {
        let element = document.getElementById("new-pin-button");
        element.click();
    };
    const handleNewNote = () => {
        let element = document.getElementById("new-note-button");
        element.click();
    };
    return (_jsx(Nav.Item, { className: "ms-auto", children: _jsxs(Nav.Link, { children: [_jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "new-point-of-interest", children: t("New.point.of.interest_") }), children: _jsx("span", { children: _jsx(BifIconPin, { className: "px-50", onClick: handlePlacePin }) }) }), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "new-note", children: t("New.note_") }), children: _jsx("span", { children: _jsx(BifIconNewNote, { className: "px-50", onClick: handleNewNote }) }) })] }) }));
};
export default NotesExtraTab;
