import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { hideReviewModal, selectReviewModalIsOpen, useGetBillingCountriesQuery, useGetVatNumberQuery, useInitPaymentMutation } from "../paymentsSlice";
import { PAYMENT_CUSTOMER_TYPE_COMPANY, PAYMENT_CUSTOMER_TYPE_PRIVATE } from "../config";
import stripe from "src/app/services/stripe";
import './Billing.scss';
const BillingConfirmModal = ({ email, full_name, country: countryIso, customerType, company_address, company_name, vat_number, paymentMethod, userPlan, subtotal, billingPeriod }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [initPayment] = useInitPaymentMutation({ fixedCacheKey: "init-payment" });
    const vatNumber = customerType === PAYMENT_CUSTOMER_TYPE_COMPANY ? vat_number : '';
    const { data: { standardRate } = {} } = useGetVatNumberQuery({ country: countryIso, vatNumber });
    const { data: { list: countries = [] } = {} } = useGetBillingCountriesQuery();
    const { name: countryName } = countries.find(({ iso }) => iso === countryIso);
    const isOpen = useSelector(selectReviewModalIsOpen);
    const handleCloseModal = () => {
        dispatch(hideReviewModal());
    };
    const onShow = () => { };
    const handlePurchase = () => {
        initPayment({
            email,
            full_name,
            customerType,
            vat_number,
            company_name,
            company_address,
            countryIso,
            paymentMethod,
            userPlan,
            vatRate: standardRate,
            totalAmount
        })
            .unwrap()
            .then(({ sessionId }) => {
            stripe.redirectToCheckout({ sessionId });
        })
            .catch(e => null);
    };
    const vatPrice = (subtotal * standardRate / 100);
    const totalAmount = subtotal + vatPrice;
    return (_jsxs(Modal, { show: isOpen, onHide: handleCloseModal, onShow: onShow, "aria-labelledby": "profile-modal", centered: true, children: [_jsx(Modal.Header, { closeButton: true, className: "border-0" }), _jsxs(Modal.Body, { className: "px-300 pb-300 pt-0", children: [_jsx("h4", { className: "text-uppercase mb-150", children: t('Confirm.payment_') }), _jsx("h5", { children: "Payment details:" }), _jsxs("div", { className: "lh-lg", children: [_jsx("div", { children: email }), _jsx("div", { children: full_name }), _jsx("div", { children: countryName }), _jsx("div", { children: vat_number !== "" && (countryIso + vat_number) }), _jsx("div", { children: company_name }), _jsx("div", { children: company_address })] }), _jsxs("div", { className: "bg-secondary summuryPayment rounded mt-150 mb-150", children: [_jsx("h3", { className: "display-6 text-center mb-200 text-uppercase", children: "- Banafo PRO -" }), _jsxs("dl", { children: [_jsx("dt", { children: "Price" }), _jsxs("dd", { children: ["$", subtotal, "/", billingPeriod] }), _jsx("dt", { children: "VAT" }), _jsxs("dd", { children: ["$", vatPrice] }), _jsx("dt", { children: "Total" }), _jsxs("dd", { children: ["$", totalAmount] })] })] }), _jsx("div", { className: "text-center", children: _jsx(Button, { variant: "dark", onClick: handlePurchase, className: "px-500", children: t('Checkout_') }) })] })] }));
};
export default BillingConfirmModal;
