import { useEffect, useState } from "react";
const useIsTruncated = (element, content) => {
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        if (!element.current)
            return;
        setIsTruncated(element.current.scrollHeight > element.current.clientHeight);
    }, [content]);
    useEffect(() => {
        const determineIsTruncated = () => {
            if (!element.current)
                return false;
            return element.current.scrollHeight > element.current.clientHeight;
        };
        const resizeListener = () => setIsTruncated(determineIsTruncated());
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
    return isTruncated;
};
export default useIsTruncated;
