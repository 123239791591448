import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Header from "src/pages/deviceActivate/Header";
import DeviceTitle from "src/pages/deviceActivate/DeviceTitle";
const DeviceLayout = ({ children }) => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx(DeviceTitle, {}), children] }));
};
export default DeviceLayout;
