import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, LineSeparator } from "banafo-design-system";
import RecordSettingsForm from "src/features/browserExtension/RecordSettingsForm";
import WhitelistWebsites from "src/features/browserExtension/WhitelistWebsites";
import Header from "./Header";
import Title from "./Title";
const BrowserExtensionPage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx(Title, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(RecordSettingsForm, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(WhitelistWebsites, {})] }));
};
export default BrowserExtensionPage;
