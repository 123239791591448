var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Table } from "banafo-design-system";
import ContactsRow from "src/features/contacts/ContactsRow";
const ContactsTable = ({ contacts, }) => {
    const { t } = useTranslation();
    return (_jsxs(Table, { responsive: true, hover: true, children: [_jsx("thead", { children: _jsxs("tr", { className: "d-none d-md-table-row", children: [_jsx("th", { children: t('Type_') }), _jsx("th", { children: t('Name_') }), _jsx("th", { children: t('Company_') }), _jsx("th", { children: t('Email_') })] }) }), _jsx("tbody", { children: contacts.map((_a) => {
                    var { id } = _a, contact = __rest(_a, ["id"]);
                    return _jsx(ContactsRow, { contact: Object.assign({ id }, contact) }, id);
                }) })] }));
};
export default ContactsTable;
