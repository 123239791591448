import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, LineSeparator } from "banafo-design-system";
import Header from "./Header";
import Title from "./Title";
import PlanDetailsSection from "src/features/account/planDetails/PlanDetailsSection";
import PaymentsHistorySection from "src/features/payments/payments/PaymentsHistorySection";
const InvoicesPage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx(Title, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(PlanDetailsSection, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(PaymentsHistorySection, {})] }));
};
export default InvoicesPage;
