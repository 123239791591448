import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import classNames from "classnames";
import { EXTENSION_CHROME_URL, EXTENSION_EDGE_URL, EXTENSION_OPERA_URL } from "src/features/auth/config/extension";
import chromeColor from "images/browser/google-chrome-color.png";
import opera from "images/browser/opera.png";
import edge from "images/browser/microsoft-edge-color.png";
import "./BrowserExtensions.scss";
function BrowserExtensions({ className }) {
    const classes = classNames('card-container', className);
    return (_jsxs("div", { className: classes, children: [_jsxs("a", { className: "card", target: '_blank', rel: 'noreferrer', href: EXTENSION_CHROME_URL, children: [_jsx("img", { src: chromeColor, alt: "google-chrome" }), _jsx("p", { className: "card-text", children: "Chrome" })] }), _jsxs("a", { className: "card", target: '_blank', rel: 'noreferrer', href: EXTENSION_OPERA_URL, children: [_jsx("img", { src: opera, alt: "opera" }), _jsxs("p", { className: "card-text", children: ["Opera ", _jsx("br", {}), " ", _jsx("span", { className: "font-weight-normal", children: "(coming soon)" })] })] }), _jsxs("a", { className: "card", target: '_blank', rel: 'noreferrer', href: EXTENSION_EDGE_URL, children: [_jsx("img", { src: edge, alt: "microsoft-edge" }), _jsx("p", { className: "card-text", children: "Edge" })] })] }));
}
BrowserExtensions.propTypes = {
    className: PropTypes.string,
};
export default BrowserExtensions;
