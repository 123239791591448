export const FETCH_RECORDING_CONTACTS_BEGIN = 'FETCH_RECORDING_CONTACTS_BEGIN';
export const FETCH_RECORDING_CONTACTS_SUCCESS = 'FETCH_RECORDING_CONTACTS_SUCCESS';
export const FETCH_RECORDING_CONTACTS_FAILURE = 'FETCH_RECORDING_CONTACTS_FAILURE';
export const EXTRACT_RECORDING_CONTACT = 'EXTRACT_RECORDING_CONTACT';
export const fetchRecordingContactsBegin = () => ({
    type: FETCH_RECORDING_CONTACTS_BEGIN
});
export const fetchRecordingContactsSuccess = (items, pagination) => ({
    type: FETCH_RECORDING_CONTACTS_SUCCESS,
    payload: { items, pagination }
});
export const fetchRecordingContactsFailure = (error) => ({
    type: FETCH_RECORDING_CONTACTS_FAILURE,
    payload: { error }
});
export const extractRecordingContact = (item) => ({
    type: EXTRACT_RECORDING_CONTACT,
    payload: { item }
});
export const RECORDINGS_CONTACTS_ADD = 'RECORDINGS_CONTACTS_ADD';
export const RECORDINGS_CONTACTS_SET = 'RECORDINGS_CONTACTS_SET';
export const RECORDINGS_CONTACTS_RESET = 'RECORDINGS_CONTACTS_RESET';
export const addRecordingsContacts = (items) => ({
    type: RECORDINGS_CONTACTS_ADD,
    payload: { items }
});
export const resetRecordingsContacts = () => ({
    type: RECORDINGS_CONTACTS_RESET,
});
