import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import status from "http-status";
import { clearAudioError, unloadAudio } from "src/features/auth/store/actions/playback";
import { startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { loadCall } from "src/features/auth/store/actions/callsHistoryDetails";
import AudioTitle from "src/features/conversations/audioPlayer/AudioTitle";
import AudioDuration from "src/features/conversations/audioPlayer/AudioDuration";
import AudioControls from "src/features/conversations/audioPlayer/AudioControls";
import AudioVisualization from "src/features/conversations/audioPlayer/AudioVisualization";
import AudioLimitReached from "src/features/conversations/audioPlayer/AudioLimitReached";
import AudioActions from "src/features/conversations/audioPlayer/AudioActions";
import { selectConversation } from "src/features/conversations/conversationsSlice";
import "./AudioPlayer.scss";
import classNames from "classnames";
import DownloadTranscriptModal from "src/features/conversations/modals/DownloadTranscriptModal";
import { SETTINGS_ROUTE } from "src/app/router/config";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
const AudioPlayer = () => {
    const { callId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [limitReached, setLimitReached] = useState(false);
    const { recordings = [] } = useSelector((state) => selectConversation(state, callId)) || {};
    const { ready, loading, error: playbackError } = useSelector(state => state.app.playback);
    useEffect(() => {
        dispatch(clearAudioError);
        dispatch(startAppLoader);
        dispatch(loadCall(callId))
            .catch(e => {
            var _a;
            if (e.status === status.NOT_FOUND) {
                navigate('/page-not-found');
            }
            if (((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.body.name) === 'LimitReachedError') {
                setLimitReached(true);
            }
        })
            .finally(() => {
            dispatch(stopAppLoader);
        });
        return () => dispatch(unloadAudio());
    }, [callId]);
    if (limitReached) {
        return _jsx(AudioLimitReached, {});
    }
    if (!recordings.length) {
        return;
    }
    // if (playbackError) {
    // 	return (<div className="audio-player align-items-center">{`${t("Failed.to.load.the.recording")} - ${playbackError}`}</div>);
    // }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames("audio-player align-items-center", { "mt-lg-n100": !playbackError }), children: [(ready || (playbackError && playbackError === FILE_AUTO_DELETED_ERROR)) && _jsx("div", { className: "side-data actions", children: _jsx(AudioActions, { nonAudioOnly: !!playbackError }) }), (ready || (playbackError && playbackError === FILE_AUTO_DELETED_ERROR)) && _jsx("div", { className: "side-data title d-flex", children: _jsx(AudioTitle, {}) }), _jsx("div", { className: classNames("wavesurfer", { "text-center": !!playbackError }), children: (playbackError && playbackError === FILE_AUTO_DELETED_ERROR) ?
                            _jsxs(_Fragment, { children: [_jsxs("h4", { className: "mb-25 fw-bold", children: [t("Privacy.Mode.is.turned.ON_"), "."] }), _jsxs("h5", { className: "mb-25", children: [t("Audio.file.has.been.automatically.deleted_"), "."] }), _jsx(Trans, { i18nKey: "Go.to.Configurataion.to.change.Privacy.Mode.option_", components: [_jsx(Link, { to: SETTINGS_ROUTE, className: "link-white" })] })] }) : _jsx(AudioVisualization, {}) }), ready && _jsx("div", { className: "side-data time", children: _jsx(AudioDuration, {}) }), ready && _jsx("div", { className: "side-data controls", children: _jsx(AudioControls, {}) })] }), _jsx(DownloadTranscriptModal, {})] }));
};
export default AudioPlayer;
