import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BifIconArrowLeft, BifIconDownload, BifIconCog, BifIconVolumeUp, Dropdown, OverlayTrigger, Stack, Tooltip } from "banafo-design-system";
import CustomDropdownToggle from "src/common/components/ui/CustomDropdownToggle";
import AudioSettingsAction from "src/features/conversations/audioPlayer/AudioSettingsAction";
import AudioVolumeAction from "src/features/conversations/audioPlayer/AudioVolumeAction";
import AudioDownloadAction from "src/features/conversations/audioPlayer/AudioDownloadAction";
import { CONVERSATIONS_ROUTE } from "src/app/router/config";
const AudioActions = ({ nonAudioOnly }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsxs(Stack, { direction: "horizontal", className: "justify-content-end", children: [_jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "audio-volume-tooltip", children: t("Back_") }), children: _jsx("span", { className: "d-lg-none cursor-pointer me-auto", onClick: () => {
                        navigate(CONVERSATIONS_ROUTE);
                    }, children: _jsx(BifIconArrowLeft, {}) }) }), !nonAudioOnly && _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "audio-volume-tooltip", children: t("Volume_") }), children: _jsxs(Dropdown, { className: "d-inline-block", children: [_jsx(Dropdown.Toggle, { as: CustomDropdownToggle, el: BifIconVolumeUp, bsPrefix: " ", id: "audio-volume-dropdown" }), _jsx(Dropdown.Menu, { className: "dropdown-actions", children: _jsx(AudioVolumeAction, {}) })] }) }), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "audio-download-tooltip", children: t("Download_") }), children: _jsxs(Dropdown, { className: "d-inline-block mx-100", children: [_jsx(Dropdown.Toggle, { as: CustomDropdownToggle, el: BifIconDownload, bsPrefix: " ", id: "audio-download-dropdown" }), _jsx(Dropdown.Menu, { className: "dropdown-actions", children: _jsx(AudioDownloadAction, { nonAudioOnly: nonAudioOnly }) })] }) }), !nonAudioOnly && _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "audio-settings-tooltip", children: t("Settings_") }), children: _jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { as: CustomDropdownToggle, el: BifIconCog, bsPrefix: " ", id: "audio-settings-dropdown" }), _jsx(Dropdown.Menu, { className: "dropdown-actions py-150", children: _jsx(AudioSettingsAction, {}) })] }) })] }));
};
export default AudioActions;
