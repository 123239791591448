import { jsx as _jsx } from "react/jsx-runtime";
import useScrollItemsList from "src/hooks/useScrollItemsList";
import ResellerCustomers from "src/features/resellerCustomers/ResellerCustomers";
import useGetResellerCustomers from "src/features/resellerCustomers/hooks/useGetResellerCustomers";
const ResellerCustomersWrapper = () => {
    const { resellerCustomers, totalResellerCustomers, loadMoreResellerCustomers, isLoading, isFetching, hasNextPage } = useGetResellerCustomers();
    const { scrollRef } = useScrollItemsList(resellerCustomers);
    return _jsx(ResellerCustomers, { resellerCustomers: resellerCustomers, totalResellerCustomers: totalResellerCustomers, loadMoreResellerCustomers: loadMoreResellerCustomers, isLoading: isLoading, isFetching: isFetching, hasNextPage: hasNextPage, ref: scrollRef });
};
export default ResellerCustomersWrapper;
