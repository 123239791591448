import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import { selectRecordingDetailsByCallId } from "src/features/auth/store/selectors/recordingDetails";
import { selectCallDetailsInitialize } from "src/features/auth/store/selectors/historyCallDetails";
import { loadAudio, unloadAudio } from "src/features/auth/store/actions/playback";
import variables from "scss/variables-export.module.scss";
import MultiSourcePlugin from "src/features/auth/wavesurfer/MultiSourcePlugin";
import WaveSurferPlayer from "src/features/conversations/waveSurfer/WaveSurferPlayer";
const AudioVisualization = () => {
    const { callId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const waveformElementRef = useRef(null);
    const recording = useSelector((state) => selectRecordingDetailsByCallId(state)(callId));
    const { ready, error: playbackError } = useSelector(state => state.app.playback);
    const callDetailsInit = useSelector(selectCallDetailsInitialize);
    useEffect(() => {
        if (!callDetailsInit || !recording || !waveformElementRef.current) {
            return;
        }
        dispatch(loadAudio(recording.streams.map(stream => stream.fileId), {
            container: waveformElementRef.current,
            barGap: 1.6,
            barMinHeight: 2,
            barRadius: 2.5,
            barWidth: 3,
            cursorColor: variables.white,
            cursorWidth: 2,
            plugins: [MultiSourcePlugin.create()],
            responsive: true,
            height: 80,
            hideScrollbar: true,
            splitChannelsOptions: {
                overlay: true,
                channelColors: {
                    0: {
                        progressColor: variables.warning,
                        waveColor: variables['yellow-700'],
                    },
                    1: {
                        progressColor: variables.danger,
                        waveColor: variables['red-700'],
                    }
                }
            }
        }));
        // return () => dispatch(unloadAudio());
    }, [recording, callDetailsInit, waveformElementRef]);
    const getRecordingId = () => {
        return recording ? recording.id : null;
    };
    if (playbackError) {
        return `${t("Failed.to.load.the.recording")} - ${playbackError}`;
    }
    return (_jsx("div", { className: classNames("position-relative"), children: _jsx(WaveSurferPlayer, { ref: waveformElementRef, recordingId: getRecordingId() }) }));
};
export default AudioVisualization;
