import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PurchaseModal from "src/features/resellerCustomers/modals/PurchaseModal";
import PurchaseReviewModal from "src/features/resellerCustomers/modals/PurchaseReviewModal";
import { PurchaseFormProvider, usePurchaseForm } from "src/features/resellerCustomers/helpers/purchaseFormContext";
import { isEmail, isNotEmpty } from "@mantine/form";
import { useTranslation } from "react-i18next";
const PurchaseModalsWrapper = () => {
    const { t } = useTranslation();
    const form = usePurchaseForm({
        initialValues: {
            email: '',
            fullName: '',
            country: '',
            companyName: '',
            companyVatNumber: '',
            companyAddress: '',
            plan: '',
            vatRate: 0,
        },
        validate: {
            email: isEmail(t('errors:Invalid.email_')),
            fullName: isNotEmpty(t('errors:Invalid.full.name_')),
            country: isNotEmpty(t('errors:Invalid.country_')),
            companyName: isNotEmpty(t('errors:Invalid.company.name_')),
            // companyVatNumber: isNotEmpty(t('errors:Invalid.vatNumber_')),
            plan: isNotEmpty(t('errors:Invalid.plan_')),
        },
    });
    return (_jsxs(PurchaseFormProvider, { form: form, children: [_jsx(PurchaseModal, {}), _jsx(PurchaseReviewModal, {})] }));
};
export default PurchaseModalsWrapper;
