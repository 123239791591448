import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BifIconInformationCircle, Button, Col, Image, Row } from "banafo-design-system";
import upgrade from "./images/upgrade.png";
import useAccount from "src/features/account/hooks/useAccount";
const ApiKeysUpgradeScreen = () => {
    const { t } = useTranslation();
    const { upgradeAccount } = useAccount();
    const handleUpgradeAccount = () => {
        upgradeAccount();
    };
    return (_jsxs(Row, { children: [_jsx(Col, { md: "12 my-300", children: _jsx("div", { className: "text-center", children: _jsx(Image, { fluid: true, src: upgrade }) }) }), _jsx(Col, { md: "12", children: _jsxs("div", { className: "text-center", children: [_jsx("h5", { className: "mb-100 text-uppercase", children: t("Api.keys.not.available_") }), _jsx("p", { className: "mb-50", children: t('infos:Api.key.upgrade.page.paragraph.transcripts_') }), _jsx("p", { className: "mb-300", children: t('infos:Api.key.upgrade.page.paragraph.plan_') }), _jsx(Button, { variant: "outline-dark", className: "text-uppercase text-nowrap mx-75", children: t('Read.more_') }), _jsxs(Button, { variant: 'primary', onClick: handleUpgradeAccount, block: true, className: "text-white text-uppercase mx-75 with-icon end", children: [t('Upgrade.to.PRO_'), " ", _jsx(BifIconInformationCircle, {})] })] }) })] }));
};
export default ApiKeysUpgradeScreen;
