var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoImageModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import './ForgottenPasswordSuccess.scss';
import { SuccessIcon } from "src/features/auth/component-old/common/Icon";
function ForgottenPasswordSuccessModal(_a) {
    var { show, onHide } = _a, others = __rest(_a, ["show", "onHide"]);
    const { t } = useTranslation();
    return (_jsxs(BanafoImageModal, { show: show, onHide: onHide, title: `${t("We've.sent.you.an.email.to.reset.your.password_")}.`, className: "forgotten-password-success-modal", children: [_jsx(SuccessIcon, {}), _jsxs("p", { className: "mt-200", children: [t('infos:Please.look.in.your.inbox_'), "."] })] }));
}
export default ForgottenPasswordSuccessModal;
