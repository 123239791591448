import { jsx as _jsx } from "react/jsx-runtime";
import { selectAllNews, useGetNewsQuery } from "src/features/news/newsSlice";
import { useSelector } from "react-redux";
import NewsList from "src/features/news/NewsList";
const News = () => {
    useGetNewsQuery();
    const news = useSelector(selectAllNews);
    return (_jsx(NewsList, { news: news }));
};
export default News;
