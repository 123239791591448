import { withAuthHeader, withBodyParser } from "src/app/services/httpClient";
import { addNote, editNote } from "./notes";
import { createRecordingPlaceholder, getCall, getCalls, getMediaRecording, getPlaybackInfo, removeCall, removeRecording, resumableRecordingUpload, shareCalls, updateCall } from "./calls";
import { getMembers, inviteMember, removeMember, searchMembers } from "./members";
import { createTeam, getTeamPermissions, getTeams, inviteMemberToTeam, modifyTeamMember, removeTeam, removeTeamMember } from "./teams";
import { changePassword, getAvailablePlans, getOauthXsrfToken, interactionDetails, interactionLogin, login, oauthLogin, refreshToken, register, sendRecoveryMessage, sendVerification } from "./auth";
import { acceptInvitation, getContactUrl, getProviders, newsletterSubscribe } from "./common";
import { cancelPayment, changeProfilePassword, finishPayment, getProfileUser, updateProfileData, updateProfileWizard, uploadProfilePicture } from "./profile";
import { getNewsList, getRecordingExtensionSettings, getSettings, getSettingsLanguages, setSettings, setSettingsRecordingExtension } from "./settings";
import { downloadTranscripts, getTranscripts, updateSpeakerName } from "./transcripts";
import { createSubscription, getCountries, getCurrentPlan, getCurrentSubscription, getSubscriptions, getUsages, getVatRate, initPayment } from "./subscriptions";
// TODO: Should probably refactor to something like
//  "withModifiers" which will take all the functions and apply them
export default {
    getOauthXsrfToken: withBodyParser(getOauthXsrfToken),
    login: withBodyParser(login),
    oauthLogin: withBodyParser(oauthLogin),
    interactionLogin: withBodyParser(interactionLogin),
    interactionDetails: withBodyParser(interactionDetails),
    refreshToken,
    changePassword,
    sendRecoveryMessage,
    register,
    sendVerification,
    getContactUrl: withBodyParser(withAuthHeader(getContactUrl)),
    getProviders: withBodyParser(withAuthHeader(getProviders)),
    getMembers: withBodyParser(withAuthHeader(getMembers)),
    inviteMember: withBodyParser(withAuthHeader(inviteMember)),
    searchMembers: withBodyParser(withAuthHeader(searchMembers)),
    removeMember: withAuthHeader(removeMember),
    getTeams: withBodyParser(withAuthHeader(getTeams)),
    getTeamPermissions: withBodyParser(withAuthHeader(getTeamPermissions)),
    createTeam: withBodyParser(withAuthHeader(createTeam)),
    removeTeam: withAuthHeader(removeTeam),
    inviteMemberToTeam: withBodyParser(withAuthHeader(inviteMemberToTeam)),
    modifyTeamMember: withBodyParser(withAuthHeader(modifyTeamMember)),
    removeTeamMember: withAuthHeader(removeTeamMember),
    acceptInvitation: withBodyParser(withAuthHeader(acceptInvitation)),
    newsletterSubscribe: withBodyParser(withAuthHeader(newsletterSubscribe)),
    getCalls: withBodyParser(withAuthHeader(getCalls)),
    getCall: withBodyParser(withAuthHeader(getCall)),
    updateCall: withBodyParser(withAuthHeader(updateCall)),
    removeCall: withAuthHeader(removeCall),
    createRecordingPlaceholder: withBodyParser(withAuthHeader(createRecordingPlaceholder)),
    resumableRecordingUpload: withAuthHeader(resumableRecordingUpload),
    getMediaRecording: withAuthHeader(getMediaRecording),
    getPlaybackInfo: withBodyParser(withAuthHeader(getPlaybackInfo)),
    removeRecording: withAuthHeader(removeRecording),
    editNote: withBodyParser(withAuthHeader(editNote)),
    addNote: withBodyParser(withAuthHeader(addNote)),
    changeProfilePassword: withBodyParser(withAuthHeader(changeProfilePassword)),
    updateProfileData: withBodyParser(withAuthHeader(updateProfileData)),
    updateProfileWizard: withBodyParser(withAuthHeader(updateProfileWizard)),
    cancelPayment: withBodyParser(withAuthHeader(cancelPayment)),
    finishPayment: withBodyParser(withAuthHeader(finishPayment)),
    uploadProfilePicture: withAuthHeader(uploadProfilePicture),
    getProfileUser: withBodyParser(withAuthHeader(getProfileUser)),
    getSettings: withBodyParser(withAuthHeader(getSettings)),
    setSettings: withBodyParser(withAuthHeader(setSettings)),
    getSettingsLanguages: withBodyParser(withAuthHeader(getSettingsLanguages)),
    getTranscripts: withBodyParser(withAuthHeader(getTranscripts)),
    downloadTranscripts: withBodyParser(withAuthHeader(downloadTranscripts)),
    updateSpeakerName: withBodyParser(withAuthHeader(updateSpeakerName)),
    shareCalls: withBodyParser(withAuthHeader(shareCalls)),
    getRecordingExtensionSettings: withBodyParser(withAuthHeader(getRecordingExtensionSettings)),
    setSettingsRecordingExtension: withBodyParser(withAuthHeader(setSettingsRecordingExtension)),
    getNewsList: withBodyParser(withAuthHeader(getNewsList)),
    createSubscription: withBodyParser(withAuthHeader(createSubscription)),
    getSubscriptions: withBodyParser(withAuthHeader(getSubscriptions)),
    getCurrentSubscription: withBodyParser(withAuthHeader(getCurrentSubscription)),
    getCurrentPlan: withBodyParser(withAuthHeader(getCurrentPlan)),
    getCountries: withBodyParser(withAuthHeader(getCountries)),
    getUsages: withBodyParser(withAuthHeader(getUsages)),
    initPayment: withBodyParser(withAuthHeader(initPayment)),
    getVatRate: withBodyParser(withAuthHeader(getVatRate)),
    getAvailablePlans: withBodyParser(withAuthHeader(getAvailablePlans)),
};
