import { combineReducers } from "redux";
import { FETCH_RECORDING_TRANSCRIPTS_BEGIN, FETCH_RECORDING_TRANSCRIPTS_FAILURE, FETCH_RECORDING_TRANSCRIPTS_SUCCESS } from "../../actions/transcripts";
function initialize(state = false, action) {
    switch (action.type) {
        case FETCH_RECORDING_TRANSCRIPTS_SUCCESS:
        case FETCH_RECORDING_TRANSCRIPTS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case FETCH_RECORDING_TRANSCRIPTS_BEGIN:
            return state + 1;
        case FETCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return state - 1;
        case FETCH_RECORDING_TRANSCRIPTS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading
});
