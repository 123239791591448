import translationEN from 'src/app/locales/en/translation.json';
import errosEN from 'src/app/locales/en/errors.json';
import infosEN from 'src/app/locales/en/infos.json';
const bundledResources = {
    en: {
        translation: translationEN,
        infos: infosEN,
        errors: errosEN
    },
};
export default bundledResources;
