var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import update from 'immutability-helper';
import { LOGIN_CREDENTIALS_SAVED, LOGIN_INPUT_CHANGED, LOGIN_INTERACTION, LOGIN_OAUTH_SUCCESS, LOGIN_REDIRECT_URL_SET, LOGIN_SUCCESS, LOGIN_WRONG_CREDENTIALS, LOGOUT, LOGIN_RESET_TOO_MANY_REQUESTS, LOGIN_TOO_MANY_REQUESTS } from "src/features/auth/store/actions/auth/login";
import { RECOVERY_PASS_INPUT_CHANGED, RECOVERY_PASS_SUCCESS, RECOVERY_PASS_VALIDATION_ERROR, RECOVERY_SEND_INPUT_CHANGED, RECOVERY_SEND_SUCCESS, RECOVERY_SEND_SUCCESS_CLEAR, RECOVERY_SEND_VALIDATION_ERROR } from 'src/features/auth/store/actions/auth/recovery';
import { REGISTER_INPUT_CHANGED, REGISTER_SUCCESS, REGISTER_VALIDATION_ERROR, RESET_REGISTERED_USER } from "src/features/auth/store/actions/auth/register";
import { HIDE_PROFILE_WIZARD, UPDATE_PROFILE_PLAN } from "src/features/auth/store/actions/profile";
import { USER_SUBSCRIPTION_PLAN_SET } from "../../actions/subscriptions";
import { HIDE_PROFILE_WELCOME_MESSAGE } from "../../actions/profile";
import { CANCEL_PAYMENT_SUCCESS, FINISH_PAYMENT_SUCCESS } from "src/features/auth/store/actions/payment";
export const initial = {
    // Authenticated user
    key: "auth",
    redirectUrl: null,
    logoutInProgress: 0,
    oauthXsrf: null,
    oauthLoginSuccess: false,
    user: null,
    // Login
    showWrongCredentials: false,
    tooManyRequests: false,
    // Register
    registerValidationErrors: {},
    registeredUser: {},
    // Recovery
    recoverySent: false,
    recoverySendValidationErrors: {},
    recoveryPassChanged: false,
    recoveryPassValidationErrors: {},
    //Interaction
    interaction: {
        prompt: {}
    }
};
export default (state = initial, action) => {
    switch (action.type) {
        //------------------- LOGIN -------------------
        case LOGIN_OAUTH_SUCCESS:
            return Object.assign(Object.assign({}, state), { oauthLoginSuccess: true });
        case USER_SUBSCRIPTION_PLAN_SET:
            return update(state, { user: { currentPlan: { $set: action.payload.plan } } });
        case HIDE_PROFILE_WIZARD:
            return update(state, { user: { showWizard: { $set: false } } });
        case CANCEL_PAYMENT_SUCCESS:
        case FINISH_PAYMENT_SUCCESS:
            return update(state, { user: { $set: action.payload.plan } });
        case HIDE_PROFILE_WELCOME_MESSAGE:
            return update(state, { user: { showWelcomeMsg: { $set: false } } });
        case UPDATE_PROFILE_PLAN:
            const _a = action.payload, { plan: title } = _a, rest = __rest(_a, ["plan"]);
            return update(state, { user: { currentPlan: { $set: Object.assign({ title }, rest) } } });
        case LOGIN_SUCCESS:
        case LOGIN_CREDENTIALS_SAVED:
            return Object.assign(Object.assign({}, state), { user: action.user });
        case LOGIN_WRONG_CREDENTIALS:
            return Object.assign(Object.assign({}, state), { showWrongCredentials: true, tooManyRequests: false });
        case LOGIN_TOO_MANY_REQUESTS:
            return Object.assign(Object.assign({}, state), { tooManyRequests: true });
        case LOGIN_RESET_TOO_MANY_REQUESTS:
            return Object.assign(Object.assign({}, state), { tooManyRequests: false });
        case LOGIN_INPUT_CHANGED:
            return state.showWrongCredentials ? Object.assign(Object.assign({}, state), { showWrongCredentials: false }) : state;
        //------------------- LOGIN REDIRECT URL -------------------
        case LOGIN_REDIRECT_URL_SET:
            return Object.assign(Object.assign({}, state), { redirectUrl: action.payload.url });
        //----------------- REGISTER ------------------
        case REGISTER_INPUT_CHANGED:
            return `instance.${action.name}` in state.registerValidationErrors ? Object.assign(Object.assign({}, state), { registerValidationErrors: {} }) : state;
        case REGISTER_VALIDATION_ERROR:
            return Object.assign(Object.assign({}, state), { registerValidationErrors: action.err });
        case REGISTER_SUCCESS:
            return Object.assign(Object.assign({}, state), { registeredUser: action.user });
        case RESET_REGISTERED_USER:
            return Object.assign(Object.assign({}, state), { registeredUser: {} });
        //-------------- RECOVERY SEND ----------------
        case RECOVERY_SEND_VALIDATION_ERROR:
            return Object.assign(Object.assign({}, state), { recoverySendValidationErrors: action.err });
        case RECOVERY_SEND_SUCCESS:
            return Object.assign(Object.assign({}, state), { recoverySent: true });
        case RECOVERY_SEND_SUCCESS_CLEAR:
            return Object.assign(Object.assign({}, state), { recoverySent: false });
        case RECOVERY_SEND_INPUT_CHANGED:
            return `instance.${action.name}` in state.recoverySendValidationErrors ? Object.assign(Object.assign({}, state), { recoverySendValidationErrors: {} }) : state;
        //-------------- RECOVERY PASS ----------------
        case RECOVERY_PASS_VALIDATION_ERROR:
            return Object.assign(Object.assign({}, state), { recoverySendValidationErrors: action.err });
        case RECOVERY_PASS_SUCCESS:
            return Object.assign(Object.assign({}, state), { recoveryPassChanged: true });
        case RECOVERY_PASS_INPUT_CHANGED:
            return `instance.${action.name}` in state.recoveryPassValidationErrors ? Object.assign(Object.assign({}, state), { recoveryPassValidationErrors: {} }) : state;
        //-------------- INTERACTION LOGIN DETAILS ------------
        case LOGIN_INTERACTION:
            return Object.assign(Object.assign({}, state), { interaction: action.interaction });
        case LOGOUT:
            return initial; //redirectUrl: state.app.auth.redirectUrl
        default:
            return state;
    }
};
