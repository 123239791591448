import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LineSeparator } from "banafo-design-system";
import { useEffect, useState } from "react";
import SettingsForm from "src/features/apiKeys/SettingsForm";
import ApiKeysList from "src/features/apiKeys/ApiKeysList";
import ApiKeysUpgradeScreen from "src/features/apiKeys/ApiKeysUpgradeScreen";
import { useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
const ApiKeys = () => {
    const authUser = useSelector(selectAuthUser);
    const { isFeatureEnabled } = useAccount();
    const [thirdPartApi, setThirdPartApi] = useState(false);
    useEffect(() => {
        if (isFeatureEnabled(FeatureTypes.ThirdPartyApi)) {
            setThirdPartApi(true);
        }
        else {
            setThirdPartApi(false);
        }
    }, [authUser.features.length]);
    return (_jsxs(_Fragment, { children: [!thirdPartApi && _jsx(ApiKeysUpgradeScreen, {}), thirdPartApi &&
                _jsxs(_Fragment, { children: [_jsx(ApiKeysList, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(SettingsForm, {}), _jsx(LineSeparator, { color: '400', size: "2" })] })] }));
};
export default ApiKeys;
