import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { INTEGRATIONS_ROUTE } from "src/app/router/config";
import { Link } from "react-router-dom";
import { List, Text, Title, Button } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideGoogleDriveInstructionsModal, selectGoogleDriveInstructionsModalIsOpen } from "src/features/integrations/integrationsSlice";
const GoogleDriveInstructionsModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const show = useAppSelector(selectGoogleDriveInstructionsModalIsOpen);
    const handleHideModal = () => {
        dispatch(hideGoogleDriveInstructionsModal());
    };
    const handleGoToIntegrationsClick = () => {
        dispatch(hideGoogleDriveInstructionsModal());
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: _jsx(Button, { color: "teal", tt: "uppercase", component: Link, to: INTEGRATIONS_ROUTE, onClick: handleGoToIntegrationsClick, children: t('Go.to.Integrations_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(Title, { order: 4, size: "h4", mb: "sm", children: t('Google.Drive.integration.needed_') }), _jsxs(Text, { mb: "xs", children: [t('infos:Google.Drive.integration_'), ":"] }), _jsxs(List, { type: "ordered", children: [_jsx(List.Item, { children: t('infos:Google.Drive.integration.1_') }), _jsx(List.Item, { children: t('infos:Google.Drive.integration.2_') })] })] }));
};
export default GoogleDriveInstructionsModal;
