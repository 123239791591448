var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { formattedDateTime } from "src/features/auth/util/dateTime";
import { DATE_FORMAT } from "src/features/auth/config/datetime";
import { removePrevSort } from "src/features/auth/util/removePrevSort";
export const processFilters = (filters) => {
    const { startedAt = null, endedAt = null, directionInbound, directionOutbound, typeWebConference, typeOther } = filters, otherFilters = __rest(filters, ["startedAt", "endedAt", "directionInbound", "directionOutbound", "typeWebConference", "typeOther"]);
    const direction = (directionInbound && directionOutbound) ? null : (directionInbound) ? 'inbound' : (directionOutbound) ? 'outbound' : null;
    let types = (typeWebConference && typeOther) ? ['web-conference', 'other'] : (typeWebConference) ? ['web-conference'] : (typeOther) ? ['other'] : [];
    if (directionInbound || directionOutbound) {
        types.push('phone-call');
    }
    types = types.join(',');
    let processedFilters = Object.assign({ 'startedAt[gte]': startedAt ? formattedDateTime(startedAt, DATE_FORMAT) : null, 'startedAt[lte]': endedAt ? formattedDateTime(endedAt, DATE_FORMAT) : null, direction,
        types }, otherFilters);
    processedFilters = Object.fromEntries(Object.entries(processedFilters).filter(([key, value]) => value !== null));
    return processedFilters;
};
