import { transformValidationErrors } from "../../../util/validation";
import { httpError } from "../errors";
import status from "http-status";
import { loginPassword } from "./login";
import { setErrorMessage, setSuccessMessage } from "../app";
import api from "../../../../../api/api";
import i18n from "../../../../../app/services/i18n";
// Requesting the link
export const RECOVERY_SEND_SUCCESS = 'RECOVERY_SEND_SUCCESS';
export const RECOVERY_SEND_SUCCESS_CLEAR = 'RECOVERY_SEND_SUCCESS_CLEAR';
export const RECOVERY_SEND_INPUT_CHANGED = 'RECOVERY_SEND_INPUT_CHANGED';
export const RECOVERY_SEND_VALIDATION_ERROR = 'RECOVERY_SEND_VALIDATION_ERROR';
// Changing the password
export const RECOVERY_PASS_SUCCESS = 'RECOVERY_PASS_SUCCESS';
export const RECOVERY_PASS_INPUT_CHANGED = 'RECOVERY_PASS_INPUT_CHANGED';
export const RECOVERY_PASS_VALIDATION_ERROR = 'RECOVERY_PASS_VALIDATION_ERROR';
export const recoverySendSuccess = res => ({ type: RECOVERY_SEND_SUCCESS, res });
export const recoverySendSuccessClear = () => ({ type: RECOVERY_SEND_SUCCESS_CLEAR });
export const recoverySendInputChanged = name => ({ type: RECOVERY_SEND_INPUT_CHANGED, name });
export const recoverySendValidationError = errors => ({
    type: RECOVERY_SEND_VALIDATION_ERROR,
    err: transformValidationErrors(errors)
});
export const recoveryPassSuccess = res => ({ type: RECOVERY_PASS_SUCCESS, res });
export const recoveryPassInputChanged = name => ({ type: RECOVERY_PASS_INPUT_CHANGED, name });
export const recoveryPassValidationError = errors => ({
    type: RECOVERY_PASS_VALIDATION_ERROR,
    err: transformValidationErrors(errors)
});
export const recoveryChangePassword = (token, password, passConf) => dispatch => {
    if (password !== passConf) {
        dispatch(recoveryPassValidationError([{
                field: 'instance.passConf',
                message: "Passwords don't match",
                reason: 'no_match'
            }]));
        return;
    }
    api.changePassword({ password, token })
        .then(res => {
        dispatch(recoveryPassSuccess(res));
        dispatch(setSuccessMessage(i18n.t('Your.password.was.changed,.you.should.be.redirected.within.a.few.seconds_')));
        dispatch(loginPassword(res.body.user.email, password));
    })
        .catch(err => {
        var _a, _b, _c, _d;
        if (err.status === status.BAD_REQUEST) {
            dispatch(recoveryPassValidationError(err.response.body.detail));
        }
        dispatch(setErrorMessage((_d = (_c = (_b = (_a = err.response.body.detail) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.response.body.message) !== null && _d !== void 0 ? _d : i18n.t("errors:general_")));
    });
};
export const recoverySendMessage = (email, reCaptchaKey) => dispatch => {
    if (!email) {
        dispatch(recoverySendValidationError([{
                field: 'instance.email',
                message: "This field is required",
                reason: 'required'
            }]));
        return;
    }
    return api.sendRecoveryMessage({ email, reCaptchaKey })
        .then(res => {
        dispatch(setSuccessMessage(i18n.t('A.password.reset.link.was.sent.to.your.email_')));
        return dispatch(recoverySendSuccess(res));
    })
        .catch(err => {
        var _a, _b, _c, _d, _e;
        dispatch(recoverySendValidationError((_a = err.response.body.detail) === null || _a === void 0 ? void 0 : _a[0]));
        return dispatch(setErrorMessage((_e = (_d = (_c = (_b = err.response.body.detail) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : err.response.body.message) !== null && _e !== void 0 ? _e : i18n.t("errors:general_")));
    });
};
