import useHasRole from "src/features/account/hooks/useHasRole";
import { RoleTypes } from "src/features/account/types";
import useIsAccountFeatureEnabled from "src/features/account/hooks/useIsAccountFeatureEnabled";
import useSubscriptionPlan from "src/features/account/hooks/useSubscriptionPlan";
import useUpgradeAccount from "src/features/account/hooks/useUpgradeAccount";
const useAccount = () => {
    const isAdmin = useHasRole(RoleTypes.Admin);
    const isFeatureEnabled = useIsAccountFeatureEnabled();
    const subscriptionPlan = useSubscriptionPlan();
    const upgradeAccount = useUpgradeAccount(subscriptionPlan.isFree);
    return {
        isAdmin,
        subscriptionPlan,
        isFeatureEnabled,
        upgradeAccount,
    };
};
export default useAccount;
