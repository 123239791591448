import { combineReducers } from "redux";
import { CALLS_DE_INITIALIZE, CALLS_INITIALIZE, CALLS_LIMIT_SAVE, CALLS_LOADED, CALLS_LOADING, CALLS_PAGINATION_RESET, CALLS_PAGINATION_SAVE, FETCH_CALLS_BEGIN, FETCH_CALLS_FAILURE, FETCH_CALLS_SUCCESS } from "src/features/auth/store/actions/callsHistory";
function initialize(state = false, action) {
    switch (action.type) {
        case CALLS_INITIALIZE:
            return true;
        case CALLS_DE_INITIALIZE:
            return false;
        case FETCH_CALLS_SUCCESS:
        case FETCH_CALLS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case CALLS_LOADING:
            return state + 1;
        case CALLS_LOADED:
            return state - 1;
        case FETCH_CALLS_BEGIN:
            return state + 1;
        case FETCH_CALLS_SUCCESS:
            return state - 1;
        case FETCH_CALLS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
function pagination(state = {}, action) {
    switch (action.type) {
        case CALLS_PAGINATION_SAVE:
            return action.payload.pagination;
        case CALLS_PAGINATION_RESET:
            return {};
        case FETCH_CALLS_BEGIN:
            return state;
        case FETCH_CALLS_SUCCESS:
            return action.payload.pagination;
        case FETCH_CALLS_FAILURE:
            return state;
        default:
            return state;
    }
}
function exceededLimits(state = false, action) {
    switch (action.type) {
        case CALLS_LIMIT_SAVE:
            return action.payload.exceededLimits;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading,
    exceededLimits,
    pagination
});
