var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
const notesAdapter = createEntityAdapter({
    sortComparer: (note1, note2) => (note1.cuePoint - note2.cuePoint),
});
const initialState = notesAdapter.getInitialState({
    query: {}
});
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getNotes: builder.query({
            query: (callId) => {
                const payload = {
                    limit: 200,
                    parentType: 'call',
                    parentId: callId
                };
                const params = new URLSearchParams(payload);
                return `/local-notes?${params}`;
            },
            transformResponse: ({ list = [], query = {} }) => notesAdapter.setAll(Object.assign(Object.assign({}, initialState), { query }), list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["Notes"]
        }),
        deleteNote: builder.mutation({
            query(id) {
                return {
                    url: `/local-notes/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Notes"]
        }),
        addNote: builder.mutation({
            query(note) {
                return {
                    url: `/local-notes`,
                    method: "POST",
                    body: note
                };
            },
            invalidatesTags: ["Notes"]
        }),
        editNote: builder.mutation({
            query(_a) {
                var { id } = _a, note = __rest(_a, ["id"]);
                return {
                    url: `/local-notes/${id}`,
                    method: "PUT",
                    body: Object.assign({ id }, note)
                };
            },
            invalidatesTags: ["Notes"]
        }),
    })
});
export const { useGetNotesQuery, useDeleteNoteMutation, useAddNoteMutation, useEditNoteMutation, } = apiApp;
//SELECTORS
export const selectNotesResult = (state, args) => extendedApi.endpoints.getNotes.select(args)(state);
export const selectNotesData = createSelector(selectNotesResult, notesResult => notesResult.data);
export const selectNoteById = createSelector((state, args) => { var _a; return (_a = selectNotesData(state, args)) !== null && _a !== void 0 ? _a : initialState; }, (state, args, id) => id, (data, id) => (notesAdapter.getSelectors().selectById(data, id) || {}));
export const { selectAll: selectAllNotes, selectEntities: selectEntitiesNotes, } = notesAdapter.getSelectors((state, args) => { var _a; return (_a = selectNotesData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
export const selectNotesImageUrl = createSelector((state, args) => selectAllNotes(state, args), (data) => data.filter(note => (note === null || note === void 0 ? void 0 : note.attachmentType) === 'image').map(note => note.attachmentUrl));
// SLICE
const initGallery = {
    isOpen: false,
    activeIndex: null,
};
const initialSliceState = {
    gallery: initGallery,
};
export const notesSlice = createSlice({
    name: 'notes',
    initialState: initialSliceState,
    reducers: {
        reset: () => initialSliceState,
        openGallery: (state, action) => {
            const activeIndex = action.payload;
            if (activeIndex < 0) {
                return;
            }
            state.gallery = { isOpen: true, activeIndex };
        },
    },
});
export const { name, actions } = notesSlice;
export const { reset, openGallery, } = notesSlice.actions;
export default notesSlice.reducer;
export const selectGallery = state => state[name].gallery;
