import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BifIconGlobeLink, BifIconTrash, OverlayTrigger, Tooltip } from "banafo-design-system";
import DeleteWhitelistWebsitesModal from "./modals/DeleteWhitelistWebsitesModal";
const WhitelistWebsitesRow = ({ id, title, website }) => {
    const { t } = useTranslation();
    const [deleteWhitelistWebsiteModalShow, setDeleteWhitelistWebsiteModalShow] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsx("td", { children: _jsx(BifIconGlobeLink, {}) }), _jsx("td", { children: website }), _jsx("td", { className: "text-center", children: _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: `revoke-whitelist-website-${id}`, children: t('Revoke_') }), children: _jsx("span", { className: "cursor-pointer", children: _jsx(BifIconTrash, { onClick: () => setDeleteWhitelistWebsiteModalShow(true) }) }) }) })] }), _jsx(DeleteWhitelistWebsitesModal, { show: deleteWhitelistWebsiteModalShow, onHide: () => setDeleteWhitelistWebsiteModalShow(false), id: id })] }));
};
export default WhitelistWebsitesRow;
