var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import apiApp from "src/app/services/apiApp";
import { CHAT_THEME_COLOR_LIST, CHAT_USER_POSITION_LIST } from "src/features/transcripts/config";
import AcronymFirstOfWord from "src/features/transcripts/utils/acronym/AcronymFirstOfWord";
import MetaDataSequentially from "src/features/transcripts/utils/metaData/MetaDataSequentially";
import { selectConversationStreams } from "src/features/conversations/conversationsSlice";
import TranscriptChatFactory from "src/features/transcripts/utils/factory/TranscriptChatFactory";
import PlaybackSyncTime from "src/features/transcripts/utils/rowHighlighter/PlaybackSyncTime";
import TranscriptStreamFactory from "src/features/transcripts/utils/factory/TranscriptStreamFactory";
import TranscriptGroupChatFactory from "src/features/transcripts/utils/factory/TranscriptGroupChatFactory";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
import TranscriptId from "src/features/transcripts/utils/rowHighlighter/TranscriptId";
const transcriptsAdapter = createEntityAdapter();
const initialState = transcriptsAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getTranscripts: builder.query({
            query: ({ fileId }) => {
                const payload = { fileId };
                const params = new URLSearchParams(payload);
                return `/transcripts?${params}`;
            },
            transformResponse: (transcripts = []) => transcriptsAdapter.setAll(initialState, transcripts.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["Transcripts"]
        }),
        updateSpeakerName: builder.mutation({
            query({ fileId, speakerId, speakerName }) {
                return {
                    url: `/transcripts/speaker/${fileId}/${speakerId}`,
                    method: "PUT",
                    body: { speakerName }
                };
            },
            invalidatesTags: ["Transcripts"]
        }),
        getTranscriptLanguages: builder.query({
            query: () => "/settings/transcript-languages",
            transformResponse: ({ languages = [] }) => languages,
        }),
        updateTranscriptLanguage: builder.mutation({
            query({ fileIds, languageCode }) {
                return {
                    url: `/media/languages`,
                    method: "POST",
                    body: { fileIds, languageCode }
                };
            },
            invalidatesTags: ["Transcripts"]
        }),
    })
});
export const { useGetTranscriptsQuery, useGetTranscriptLanguagesQuery, useUpdateSpeakerNameMutation, useUpdateTranscriptLanguageMutation } = apiApp;
//SELECTORS
//Transcripts
export const selectTranscriptsResult = (state, args) => extendedApi.endpoints.getTranscripts.select(args)(state);
export const selectTranscriptsData = createSelector(selectTranscriptsResult, transcriptsResult => transcriptsResult.data);
export const { selectAll: selectAllTranscripts, selectEntities: selectEntitiesTranscripts, } = transcriptsAdapter.getSelectors((state, args) => { var _a; return (_a = selectTranscriptsData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
export const selectTranscriptLanguageCode = createSelector((state, { callId, fileId }) => selectAllTranscripts(state, { fileId }), (transcriptStreams) => { var _a; return (_a = transcriptStreams[0]) === null || _a === void 0 ? void 0 : _a.languageCode; });
export const selectTranscriptStreamsWithOrigin = createSelector((state, { callId, fileId }) => selectAllTranscripts(state, { fileId }), (state, { callId, fileId }) => selectConversationStreams(state, callId), (transcriptStreams, conversationStreams) => { var _a; return ((_a = transcriptStreams.map((transcriptStream, index) => (Object.assign(Object.assign({}, transcriptStream), conversationStreams[index])))) !== null && _a !== void 0 ? _a : []); });
export const selectTranscriptStreamsAsArray = createSelector((state, args) => selectTranscriptStreamsWithOrigin(state, args), (transcriptStreams) => {
    // TEST CASES
    const transcriptsSpeaker = [
        {
            origin: "mixed",
            transcript: [
                {
                    speakerId: "271e2f8bfd931",
                    startedAt: 0,
                    endedAt: 10,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b10cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd932",
                    startedAt: 10,
                    endedAt: 20,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b20cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd933",
                    startedAt: 20,
                    endedAt: 40,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b30cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd931",
                    startedAt: 40,
                    endedAt: 60,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b40cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd932",
                    startedAt: 60,
                    endedAt: 80,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b50cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd933",
                    startedAt: 80,
                    endedAt: 100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b60cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: "271e2f8bfd932",
                    startedAt: 100,
                    endedAt: 400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b70cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: 1,
                    speakerName: "Peter",
                    startedAt: 400,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7b70cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: 2,
                    speakerName: "",
                    startedAt: 14400,
                    endedAt: 24600,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    speakerId: 3,
                    startedAt: 24600,
                    endedAt: 28100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 28100,
                    endedAt: 29800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    speakerId: 2,
                    speakerName: "",
                    startedAt: 29800,
                    endedAt: 35200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    speakerId: 1,
                    speakerName: "Peter",
                    startedAt: 35200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
                {
                    speakerId: 1,
                    speakerName: "Peter",
                    startedAt: 40000,
                    endedAt: 41700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd937"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 41700,
                    endedAt: 143100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd938"
                },
                {
                    speakerId: 5,
                    speakerName: "Ivan Petrov Georgiev Todorov Stoqnov Kolev",
                    startedAt: 43700,
                    endedAt: 123100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd947"
                },
                {
                    speakerId: 1,
                    speakerName: "Peter",
                    startedAt: 45700,
                    endedAt: 103100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd956"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 143100,
                    endedAt: 150100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e3f8bfd936"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 155800,
                    endedAt: 175800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e4f8bfd937"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 188000,
                    endedAt: 192000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e5f8bfd938"
                },
                {
                    speakerId: 4,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 196000,
                    endedAt: 212000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e6f8bfd939"
                },
                {
                    speakerId: 5,
                    speakerName: "Ivan Petrov Georgiev Todorov Stoqnov Kolev",
                    startedAt: 212000,
                    endedAt: 213000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e7f8bfd940"
                },
                {
                    speakerId: 5,
                    speakerName: "Ivan Petrov Georgiev Todorov Stoqnov Kolev",
                    startedAt: 213000,
                    endedAt: 214000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e7f8bfd941"
                },
            ]
        }
    ];
    const transcriptsSpeakerStreams = [
        {
            origin: "local",
            transcript: [
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 0,
                    endedAt: 14700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 14700,
                    endedAt: 24500,
                    text: "Continuing devonshire but considered its.Do depend better praise do friend garden an wonder to. Intention age nay otherwise but breakfast. Around garden beyond to extent by.",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 24500,
                    endedAt: 28000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 28000,
                    endedAt: 30800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 30800,
                    endedAt: 39200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    speakerId: 1,
                    speakerName: "Stanislav Yordanov",
                    startedAt: 39200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
            ]
        },
        {
            origin: "remote",
            transcript: [
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 200,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    speakerId: 2,
                    speakerName: "",
                    startedAt: 14400,
                    endedAt: 24600,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    speakerId: 3,
                    startedAt: 24600,
                    endedAt: 28100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 28100,
                    endedAt: 29800,
                    text: "Continuing devonshire but considered its.Do depend better praise do friend garden an wonder to. Intention age nay otherwise but breakfast. Around garden beyond to extent by.",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    speakerId: 2,
                    speakerName: "",
                    startedAt: 29800,
                    endedAt: 35200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 35200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 40000,
                    endedAt: 41700,
                    text: "Continuing devonshire but considered its.Do depend better praise do friend garden an wonder to. Intention age nay otherwise but breakfast. Around garden beyond to extent by.",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd937"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 41700,
                    endedAt: 143100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd938"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 143100,
                    endedAt: 235000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd939"
                },
                {
                    speakerId: 5,
                    speakerName: "Ivan Petrov Georgiev Todorov Stoqnov Kolev ",
                    startedAt: 235000,
                    endedAt: 275000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd940"
                },
            ]
        }
    ];
    const transcriptsMixedSpeakerStreams = [
        {
            origin: "local",
            transcript: [
                {
                    startedAt: 0,
                    endedAt: 14700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    startedAt: 14700,
                    endedAt: 24500,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    startedAt: 24500,
                    endedAt: 28000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    startedAt: 28000,
                    endedAt: 30800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    startedAt: 30800,
                    endedAt: 39200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    startedAt: 39200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
            ]
        },
        {
            origin: "remote",
            transcript: [
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 200,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    startedAt: 14400,
                    endedAt: 24600,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    speakerId: 3,
                    startedAt: 24600,
                    endedAt: 28100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 28100,
                    endedAt: 29800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    startedAt: 29800,
                    endedAt: 35200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 35200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
                {
                    speakerId: 5,
                    speakerName: "Peter",
                    startedAt: 40000,
                    endedAt: 41700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd937"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 41700,
                    endedAt: 143100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd938"
                },
                {
                    speakerId: 4,
                    speakerName: "Martin Dimitrov",
                    startedAt: 143100,
                    endedAt: 235000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd939"
                },
                {
                    speakerId: 7,
                    speakerName: "",
                    startedAt: 14400,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd941"
                },
                {
                    speakerId: 6,
                    speakerName: "Ivan Petrov Georgiev Todorov Stoqnov Kolev ",
                    startedAt: 235000,
                    endedAt: 275000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd940"
                },
            ]
        }
    ];
    const transcripts = [{
            origin: "mixed",
            transcript: [
                {
                    startedAt: 0,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    startedAt: 14400,
                    endedAt: 24600,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    startedAt: 24600,
                    endedAt: 28100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    startedAt: 28100,
                    endedAt: 29800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    startedAt: 29800,
                    endedAt: 35200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    startedAt: 35200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
                {
                    startedAt: 40000,
                    endedAt: 41700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd937"
                },
                {
                    startedAt: 41700,
                    endedAt: 143100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd938"
                },
                {
                    startedAt: 143100,
                    endedAt: 235000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd939"
                },
                {
                    startedAt: 235000,
                    endedAt: 275000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd940"
                },
            ]
        }];
    const transcriptsStreams = [
        {
            origin: "local",
            transcript: [
                {
                    startedAt: 0,
                    endedAt: 14700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    startedAt: 14700,
                    endedAt: 24500,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    startedAt: 24500,
                    endedAt: 28000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    startedAt: 28000,
                    endedAt: 30800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    startedAt: 30800,
                    endedAt: 39200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    startedAt: 39200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "17be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
            ]
        },
        {
            origin: "remote",
            transcript: [
                {
                    startedAt: 200,
                    endedAt: 14400,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd931"
                },
                {
                    startedAt: 14400,
                    endedAt: 24600,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd932"
                },
                {
                    startedAt: 24600,
                    endedAt: 28100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd933"
                },
                {
                    startedAt: 28100,
                    endedAt: 29800,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd934"
                },
                {
                    startedAt: 29800,
                    endedAt: 35200,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd935"
                },
                {
                    startedAt: 35200,
                    endedAt: 40000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd936"
                },
                {
                    startedAt: 40000,
                    endedAt: 41700,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd937"
                },
                {
                    startedAt: 41700,
                    endedAt: 143100,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd938"
                },
                {
                    startedAt: 143100,
                    endedAt: 235000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd939"
                },
                {
                    startedAt: 235000,
                    endedAt: 275000,
                    text: "The American Staff for our terrier also known as the Am Staff is a medium sized short coated American dog breed. It is one of several breeds in the Pitball group",
                    _id: "7be0cc00-bc0d-11ed-8b89-271e2f8bfd940"
                },
            ]
        }
    ];
    //END TEST CASES
    const transcriptStreamFactory = new TranscriptStreamFactory();
    const transcriptStreamsArray = transcriptStreamFactory.create(transcriptStreams);
    // const transcriptStreamsArray = transcriptStreamFactory.create(transcriptsSpeaker);
    return transcriptStreamsArray;
});
export const selectTranscriptsAsChat = createSelector((state, args) => selectTranscriptStreamsAsArray(state, args), (state, args) => state.app.playback, (state, args) => selectActiveTranscriptId(state), (transcriptStreamsArray, playback, activeTranscriptId) => {
    const { currentTime, error } = playback;
    const acronymGenerator = new AcronymFirstOfWord();
    const colorGenerator = new MetaDataSequentially(CHAT_THEME_COLOR_LIST);
    const positionGenerator = new MetaDataSequentially(CHAT_USER_POSITION_LIST);
    const rowHighlighter = error !== FILE_AUTO_DELETED_ERROR ? new PlaybackSyncTime(currentTime) : new TranscriptId(activeTranscriptId);
    const transcriptChatFactory = new TranscriptChatFactory(acronymGenerator, colorGenerator, positionGenerator, rowHighlighter);
    const transcriptsChat = transcriptChatFactory.create(transcriptStreamsArray);
    return transcriptsChat;
});
export const selectFilteredTranscriptsAsChat = createSelector((state, args) => selectTranscriptStreamsAsArray(state, args), (state, args) => state.app.playback, (state, args, filters) => filters, (state, args) => selectActiveTranscriptId(state), (transcriptStreamsArray, playback, filters, activeTranscriptId) => {
    const { currentTime, error } = playback;
    const acronymGenerator = new AcronymFirstOfWord();
    const colorGenerator = new MetaDataSequentially(CHAT_THEME_COLOR_LIST);
    const positionGenerator = new MetaDataSequentially(CHAT_USER_POSITION_LIST);
    const rowHighlighter = error !== FILE_AUTO_DELETED_ERROR ? new PlaybackSyncTime(currentTime) : new TranscriptId(activeTranscriptId);
    const transcriptChatFactory = new TranscriptGroupChatFactory(acronymGenerator, colorGenerator, positionGenerator, rowHighlighter);
    const transcriptsChat = transcriptChatFactory.create(transcriptStreamsArray, filters);
    return transcriptsChat;
});
// SLICE
const initFilters = {
    search: '',
};
const initialSliceState = {
    filters: initFilters,
    showSearch: false,
    changeTranscriptLanguageModalOpen: false,
    changeTranscriptLanguage: undefined,
    initialActiveTranscriptId: undefined,
    activeTranscriptId: undefined,
};
export const transcriptsSlice = createSlice({
    name: 'transcripts',
    initialState: initialSliceState,
    reducers: {
        reset: () => initialSliceState,
        changeFilter: (state, action) => {
            const { name, value } = action.payload;
            state.filters[name] = value;
        },
        showSearch: state => {
            state.showSearch = true;
        },
        hideSearch: state => {
            state.showSearch = false;
            state.filters = initFilters;
        },
        showChangeTranscriptLanguageModal: (state, action) => {
            state.changeTranscriptLanguage = action.payload;
            state.changeTranscriptLanguageModalOpen = true;
        },
        hideChangeTranscriptLanguageModal: state => {
            state.changeTranscriptLanguage = undefined;
            state.changeTranscriptLanguageModalOpen = false;
        },
        setInitialActiveTranscriptId: (state, action) => {
            const initialActiveTranscriptId = action.payload;
            state.initialActiveTranscriptId = initialActiveTranscriptId;
        },
        resetInitialActiveTranscriptId: (state) => state.initialActiveTranscriptId = undefined,
        setActiveTranscriptId: (state, action) => {
            const activeTranscriptId = action.payload;
            state.activeTranscriptId = activeTranscriptId;
        },
        resetActiveTranscriptId: (state) => state.activeTranscriptId = undefined,
    },
});
export const { name, actions } = transcriptsSlice;
export const { reset, changeFilter, showSearch, hideSearch, showChangeTranscriptLanguageModal, hideChangeTranscriptLanguageModal, setInitialActiveTranscriptId, resetInitialActiveTranscriptId, setActiveTranscriptId, resetActiveTranscriptId } = transcriptsSlice.actions;
export default transcriptsSlice.reducer;
export const selectFilters = state => state[name].filters;
export const selectSearchIsOpen = state => state[name].showSearch;
export const selectIsSearchViewTriggered = state => state[name].filters.search.length > 3;
export const selectChangeTranscriptLanguageModalIsOpen = state => state[name].changeTranscriptLanguageModalOpen;
export const selectChangeTranscriptLanguage = state => state[name].changeTranscriptLanguage;
export const selectInitialActiveTranscriptId = state => state[name].initialActiveTranscriptId;
export const selectActiveTranscriptId = state => state[name].activeTranscriptId;
