import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas } from "banafo-design-system";
import { hideOffcanvas, selectOffcanvasIsVisible } from "src/features/layout/pageLayout/layoutSlice";
import Sidebar from "src/features/sidebar/Sidebar";
import './OffcanvasSidebar.scss';
import ResellerSidebar from "src/features/sidebar/ResellerSidebar";
const OffcanvasSidebar = ({ isReseller }) => {
    const dispatch = useDispatch();
    const isOffcanvasVisible = useSelector(selectOffcanvasIsVisible);
    const handleCloseOffcanvas = () => dispatch(hideOffcanvas());
    return (_jsx(Offcanvas, { show: isOffcanvasVisible, onHide: handleCloseOffcanvas, className: "offcanvas-sidebar", children: _jsx(Offcanvas.Body, { className: "p-0", children: isReseller ? _jsx(ResellerSidebar, {}) : _jsx(Sidebar, {}) }) }));
};
export default OffcanvasSidebar;
