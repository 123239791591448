import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import bundledResources from "src/app/locales/bundledResources";
i18n
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
    // lng: "en", comment default language for LanguageDetector to work
    fallbackLng: "en",
    ns: ['translation', 'infos', 'errors'],
    defaultNS: 'translation',
    debug: false,
    detection: {
        order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'querystring', 'path', 'subdomain'],
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'lng',
        lookupLocalStorage: 'lng',
        lookupSessionStorage: 'lng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        // cache user language on
        caches: ['localStorage', 'cookie'],
    },
    load: 'languageOnly',
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b']
    },
    backend: {
        backends: [
            resourcesToBackend(bundledResources),
            HttpBackend,
        ],
    },
});
export default i18n;
