import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from "src/app/store/hooks";
import { selectResellerCustomersApiArgs } from "src/features/resellerCustomers/resellerCustomersSlice";
import { selectResellerCustomerEntity } from "src/features/resellerCustomers/resellerCustomersApi";
const DynamicResellerCustomerBreadcrumb = ({ match }) => {
    const args = useAppSelector(selectResellerCustomersApiArgs);
    const { name } = useAppSelector(state => selectResellerCustomerEntity(state, args, match.params.resellerCustomerId));
    return (_jsx("span", { children: name }));
};
export default DynamicResellerCustomerBreadcrumb;
