import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { selectErrorMessage } from "src/features/auth/store/selectors/common";
import { useEffect } from "react";
import { clearErrorMessage } from "src/features/auth/store/actions/app";
const GeneralError = ({ as: Component = 'div' }) => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(selectErrorMessage);
    useEffect(() => {
        return () => dispatch(clearErrorMessage());
    }, [dispatch]);
    return (_jsx(Component, { className: "text-danger", children: errorMessage }));
};
export default GeneralError;
