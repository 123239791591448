export const FETCH_VAT_RATE_BEGIN = 'FETCH_VAT_RATE_BEGIN';
export const FETCH_VAT_RATE_SUCCESS = 'FETCH_VAT_RATE_SUCCESS';
export const FETCH_VAT_RATE_FAILURE = 'FETCH_VAT_RATE_FAILURE';
export const FETCH_COUNTRIES_BEGIN = 'FETCH_COUNTRIES_BEGIN';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS';
export const FINISH_PAYMENT_SUCCESS = 'FINISH_PAYMENT_SUCCESS';
export const cancelPaymentSuccess = (plan) => ({
    type: CANCEL_PAYMENT_SUCCESS,
    payload: { plan }
});
export const finishPaymentSuccess = (plan) => ({
    type: FINISH_PAYMENT_SUCCESS,
    payload: { plan }
});
