import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import monitorReducerEnhancer from './enhancers/monitorReducer';
import sentryReduxEnhancer from "../sentry/sentryReduxEnhancer";
import loggerMiddleware from './middleware/logger';
import wavesurferPlayback from "src/app/store/middleware/wavesurferPlayback";
import rtkQueryLogger from "src/app/store/middleware/rtkQueryLogger";
import crashReporterMiddleware from './middleware/crashReporter';
import rootReducerPersisted, { actions } from './rootReducer';
import apiApp from "src/app/services/apiApp";
import { DEBUG } from "src/app/config";
import rtkErrorHandler from "src/app/store/middleware/rtkErrorHandler";
export default function configureAppStore(options) {
    let middlewares = [
        rtkQueryLogger,
        rtkErrorHandler,
        apiApp.middleware,
        wavesurferPlayback,
    ];
    let enhancers = [
        sentryReduxEnhancer
    ];
    if (process.env.NODE_ENV !== 'production' && DEBUG) {
        middlewares.unshift(loggerMiddleware, crashReporterMiddleware);
        enhancers.unshift(monitorReducerEnhancer);
    }
    const store = configureStore(Object.assign({ reducer: rootReducerPersisted, middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(middlewares), enhancers: (getDefaultEnhancers) => {
            return getDefaultEnhancers().concat(enhancers);
        }, devTools: { actionCreators: actions } }, options
    // preloadedState,
    ));
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducerPersisted));
    }
    return store;
}
const store = configureAppStore();
const persistor = persistStore(store);
export { store, persistor };
