import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Spinner } from "banafo-design-system";
const propTypes = {
    loading: PropTypes.bool,
    loader: PropTypes.node,
    children: PropTypes.node,
};
const Loadable = ({ loading, loader = _jsx(Spinner, { animation: 'border' }), children }) => {
    if (loading) {
        return loader;
    }
    return children !== null && children !== void 0 ? children : null;
};
Loadable.propTypes = propTypes;
export default Loadable;
