import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { BanafoModal } from "banafo-design-system";
import { Button, Text, Title } from "@mantine/core";
import { hidePurchaseReviewModal, selectPurchaseModalResellerCustomerId, selectPurchaseReviewModalIsOpen, switchPurchaseModals } from "src/features/resellerCustomers/resellerCustomersSlice";
import { usePurchaseFormContext } from "src/features/resellerCustomers/helpers/purchaseFormContext";
import { selectBillingCountry } from "src/features/payments/billingApi";
import { selectPlanDetails } from "src/features/payments/paymentsSlice";
import stripe from "src/app/services/stripe";
import { PAYMENT_CUSTOMER_TYPE_COMPANY, PAYMENT_METHOD_VISA } from "src/features/payments/config";
import { useInitResellerPaymentMutation } from "src/features/resellerCustomers/resellerCustomersApi";
import useGetResellerCustomer from "src/features/resellerCustomers/hooks/useGetResellerCustomer";
const PurchaseReviewModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [initResellerPayment, { isLoading }] = useInitResellerPaymentMutation();
    const form = usePurchaseFormContext();
    const { email, fullName, country: countryIso, companyName, companyVatNumber, companyAddress, plan, vatRate } = form.getValues();
    const planDetails = useAppSelector(state => selectPlanDetails(state, plan));
    const show = useAppSelector(selectPurchaseReviewModalIsOpen);
    const resellerCustomerId = useAppSelector(selectPurchaseModalResellerCustomerId);
    const resellerCustomer = useGetResellerCustomer(resellerCustomerId);
    const country = useAppSelector(state => selectBillingCountry(state, countryIso));
    const priceInclVat = ((planDetails === null || planDetails === void 0 ? void 0 : planDetails.initialAmount) * vatRate / 100);
    const totalAmount = (planDetails === null || planDetails === void 0 ? void 0 : planDetails.initialAmount) + priceInclVat;
    const handleGoBack = () => {
        dispatch(switchPurchaseModals());
    };
    const handlePurchase = () => {
        initResellerPayment({
            email,
            full_name: fullName,
            customerType: PAYMENT_CUSTOMER_TYPE_COMPANY,
            vat_number: companyVatNumber,
            company_name: companyName,
            company_address: companyAddress,
            countryIso,
            paymentMethod: PAYMENT_METHOD_VISA,
            userPlan: plan,
            vatRate,
            totalAmount,
            resellerCustomerId
        })
            .unwrap()
            .then(({ sessionId }) => {
            stripe.redirectToCheckout({ sessionId });
        })
            .catch(() => null);
    };
    const handleHideModal = () => {
        form.reset();
        dispatch(hidePurchaseReviewModal());
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t("Confirm.Payment_"), footer: _jsxs(_Fragment, { children: [_jsx(Button, { tt: "uppercase", fw: "bold", variant: "outline-filled", onClick: handleGoBack, children: t("Back_") }), _jsx(Button, { tt: "uppercase", fw: "bold", color: "dark", loading: isLoading, onClick: handlePurchase, children: t("Checkout_") })] }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(Title, { order: 5, mt: "md", children: "Payment details:" }), _jsxs("div", { className: "lh-lg", children: [_jsx("div", { children: email }), _jsx("div", { children: fullName }), _jsx("div", { children: country === null || country === void 0 ? void 0 : country.name }), _jsx("div", { children: `${country === null || country === void 0 ? void 0 : country.iso}${companyVatNumber}` }), _jsx("div", { children: companyName }), _jsx("div", { children: companyAddress })] }), _jsxs("div", { className: "bg-secondary summuryPayment rounded mt-150 mb-150 px-200", children: [_jsx("h3", { className: "display-6 text-center mb-200 text-uppercase", children: "- Banafo PRO -" }), _jsxs("dl", { children: [_jsx("dt", { children: "Price" }), _jsxs("dd", { children: ["$", planDetails === null || planDetails === void 0 ? void 0 : planDetails.initialAmount, "/", planDetails === null || planDetails === void 0 ? void 0 : planDetails.billingPeriod] }), _jsx("dt", { children: "VAT" }), _jsxs("dd", { children: ["$", priceInclVat] }), _jsx("dt", { children: "Total" }), _jsxs("dd", { children: ["$", totalAmount] })] })] }), _jsxs(Text, { size: "md", mt: "md", children: [t('Purchase.order.for.client_'), " - ", _jsx(Text, { span: true, fw: "bold", children: resellerCustomer === null || resellerCustomer === void 0 ? void 0 : resellerCustomer.email })] })] }));
};
export default PurchaseReviewModal;
