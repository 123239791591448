import { combineReducers } from "redux";
export function key(state = "calls", action) {
    switch (action.type) {
        default:
            return state;
    }
}
export default combineReducers({
    key,
});
