import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import { Divider } from "@mantine/core";
import Title from "./Title";
const ResellerCustomersSettingsGuidePage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "p-200 d-flex flex-column" },
        }, children: [_jsx(Title, {}), _jsx(Divider, { size: "md", my: "sm" }), "Guide"] }));
};
export default ResellerCustomersSettingsGuidePage;
