import MultiSourceBackend from './MultiSourceBackend';
export default class MultiSourcePlugin {
    static create(params) {
        return {
            name: 'MultiSourcePlugin',
            deferInit: false,
            params: params,
            staticProps: {
                load(urls, peaks, preload, duration) {
                    this.empty();
                    this.backend.load(urls, this.mediaContainer, peaks, preload);
                    this.tmpEvents.push(this.backend.once('canplay', () => {
                        // ignore when backend was already destroyed
                        if (!this.backend.destroyed) {
                            this.drawBuffer();
                            this.isReady = true;
                            this.fireEvent('ready');
                        }
                    }), this.backend.once('error', err => this.fireEvent('error', err)));
                    if (peaks) {
                        this.backend.setPeaks(peaks, duration);
                        this.drawBuffer();
                        this.fireEvent('waveform-ready');
                    }
                },
                getSourceVolume(source) {
                    return this.backend.getSourceVolume(source);
                },
                setSourceVolume(source, value) {
                    this.backend.setSourceVolume(source, value);
                }
            },
            instance: MultiSourcePlugin
        };
    }
    constructor(params, ws) {
        this.waveSurfer = ws;
    }
    init() {
        this.waveSurfer.params = Object.assign(Object.assign({}, this.waveSurfer.params), { backend: 'MediaElement', splitChannels: true });
        this.waveSurfer.Backend = MultiSourceBackend;
    }
    destroy() { }
}
