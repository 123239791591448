import { darken, lighten, getGradient, parseThemeColor, rem, rgba } from "@mantine/core";
export const variantColorResolver = ({ color, theme, variant, gradient }) => {
    const parsed = parseThemeColor({ color, theme });
    if (variant === 'filled') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: `var(--mantine-color-${color}-filled)`,
                    hover: `var(--mantine-color-${color}-filled-hover)`,
                    color: 'var(--mantine-color-white)',
                    border: `${rem(2)} solid transparent`,
                };
            }
            return {
                background: `var(--mantine-color-${parsed.color}-${parsed.shade})`,
                hover: `var(--mantine-color-${parsed.color}-${parsed.shade === 1 ? 2 : parsed.shade - 1})`,
                color: 'var(--mantine-color-white)',
                border: `${rem(2)} solid transparent`,
            };
        }
        return {
            background: color,
            hover: lighten(color, 0.1),
            color: 'var(--mantine-color-white)',
            border: `${rem(2)} solid transparent`,
        };
    }
    if (variant === 'light') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: `var(--mantine-color-${color}-light)`,
                    hover: `var(--mantine-color-${color}-light-hover)`,
                    color: `var(--mantine-color-${color}-light-color)`,
                    border: `${rem(1)} solid transparent`,
                };
            }
            const parsedColor = theme.colors[parsed.color][parsed.shade];
            return {
                background: rgba(parsedColor, 0.1),
                hover: rgba(parsedColor, 0.12),
                color: `var(--mantine-color-${parsed.color}-${Math.min(parsed.shade, 6)})`,
                border: `${rem(2)} solid transparent`,
            };
        }
        return {
            background: rgba(color, 0.1),
            hover: rgba(color, 0.12),
            color: color,
            border: `${rem(2)} solid transparent`,
        };
    }
    if (variant === 'outline') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: 'transparent',
                    hover: `var(--mantine-color-${color}-outline-hover)`,
                    color: `var(--mantine-color-${color}-outline)`,
                    border: `${rem(2)} solid var(--mantine-color-${color}-outline)`,
                };
            }
            return {
                background: 'transparent',
                hover: rgba(theme.colors[parsed.color][parsed.shade], 0.05),
                color: `var(--mantine-color-${parsed.color}-${parsed.shade})`,
                border: `${rem(2)} solid var(--mantine-color-${parsed.color}-${parsed.shade})`,
            };
        }
        return {
            background: 'transparent',
            hover: rgba(color, 0.05),
            color: color,
            border: `${rem(2)} solid ${color}`,
        };
    }
    if (variant === 'outline-filled') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: 'transparent',
                    hover: `var(--mantine-color-${color}-outline)`,
                    color: `var(--mantine-color-${color}-outline)`,
                    border: `${rem(2)} solid var(--mantine-color-${color}-outline)`,
                };
            }
            return {
                background: 'transparent',
                hover: `var(--mantine-color-${parsed.color}-${parsed.shade})`,
                color: `var(--mantine-color-${parsed.color}-${parsed.shade})`,
                border: `${rem(2)} solid var(--mantine-color-${parsed.color}-${parsed.shade})`,
            };
        }
        return {
            background: 'transparent',
            hover: color,
            color: color,
            border: `${rem(2)} solid ${color}`,
        };
    }
    if (variant === 'subtle') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: 'transparent',
                    hover: `var(--mantine-color-${color}-light-hover)`,
                    color: `var(--mantine-color-${color}-light-color)`,
                    border: `${rem(2)} solid transparent`,
                };
            }
            const parsedColor = theme.colors[parsed.color][parsed.shade];
            return {
                background: 'transparent',
                hover: rgba(parsedColor, 0.12),
                color: `var(--mantine-color-${parsed.color}-${Math.min(parsed.shade, 6)})`,
                border: `${rem(2)} solid transparent`,
            };
        }
        return {
            background: 'transparent',
            hover: rgba(color, 0.12),
            color: color,
            border: `${rem(2)} solid transparent`,
        };
    }
    if (variant === 'transparent') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: 'transparent',
                    hover: 'transparent',
                    color: `var(--mantine-color-${color}-light-color)`,
                    border: `${rem(2)} solid transparent`,
                };
            }
            return {
                background: 'transparent',
                hover: 'transparent',
                color: `var(--mantine-color-${parsed.color}-${Math.min(parsed.shade, 6)})`,
                border: `${rem(2)} solid transparent`,
            };
        }
        return {
            background: 'transparent',
            hover: 'transparent',
            color: color,
            border: `${rem(2)} solid transparent`,
        };
    }
    if (variant === 'white') {
        if (parsed.isThemeColor) {
            if (parsed.shade === undefined) {
                return {
                    background: 'var(--mantine-color-white)',
                    hover: darken(theme.white, 0.01),
                    color: `var(--mantine-color-${color}-filled)`,
                    border: `${rem(2)} solid transparent`,
                };
            }
            return {
                background: 'var(--mantine-color-white)',
                hover: darken(theme.white, 0.01),
                color: `var(--mantine-color-${parsed.color}-${parsed.shade})`,
                border: `${rem(2)} solid transparent`,
            };
        }
        return {
            background: 'var(--mantine-color-white)',
            hover: darken(theme.white, 0.01),
            color: color,
            border: `${rem(2)} solid transparent`,
        };
    }
    if (variant === 'gradient') {
        return {
            background: getGradient(gradient, theme),
            hover: getGradient(gradient, theme),
            color: 'var(--mantine-color-white)',
            border: 'none',
        };
    }
    if (variant === 'default') {
        return {
            background: 'var(--mantine-color-default)',
            hover: 'var(--mantine-color-default-hover)',
            color: 'var(--mantine-color-default-color)',
            border: `${rem(2)} solid var(--mantine-color-default-border)`,
        };
    }
    return {};
};
export default variantColorResolver;
