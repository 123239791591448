import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, LineSeparator, SpinnerBackdrop } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import apiApp from "src/app/services/apiApp";
import range from "src/common/utils/range";
import { incrementPage, selectCombinedContactsResult, selectFilters, selectPage, } from "./apiContactsSlice";
import { CONTACTS_PAGINATION_LIMIT } from "./config";
import ContactsTable from "./ContactsTable";
import TableFilters from "./TableFilters";
const Contacts = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const perPage = CONTACTS_PAGINATION_LIMIT;
    const start = page * perPage;
    const { data: { contacts, hasNextPage }, isLoading: isLoadingContacts } = useSelector((state) => selectCombinedContactsResult(state, { searchFilters, start }));
    useEffect(() => {
        const results = [];
        for (let x of range(0, start, perPage)) {
            const result = dispatch(apiApp.endpoints.getContacts.initiate({ searchFilters, start }));
            results.push(result);
        }
        return () => {
            for (let result of results) {
                result.unsubscribe();
            }
        };
    }, [page, JSON.stringify(searchFilters)]);
    const handleLoadMore = () => {
        dispatch(incrementPage());
    };
    if (isLoadingContacts) {
        //return <SpinnerBackdrop />;
    }
    return (_jsxs(_Fragment, { children: [_jsx(TableFilters, {}), _jsx(LineSeparator, { size: 3, color: 'secondary' }), _jsxs("div", { className: "position-relative mb-200", children: [isLoadingContacts && _jsx(SpinnerBackdrop, { position: "absolute" }), _jsx(ContactsTable, { contacts: contacts }), hasNextPage &&
                        _jsx(Button, { onClick: handleLoadMore, variant: "outline-dark", className: "d-block mx-auto mt-200 text-uppercase", children: t('Load.more_') })] })] }));
};
export default Contacts;
