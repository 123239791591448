import { combineReducers } from "redux";
import { EXTRACT_RECORDING_CONTACT, FETCH_RECORDING_CONTACTS_SUCCESS, RECORDINGS_CONTACTS_ADD, RECORDINGS_CONTACTS_RESET } from '../../actions/recordingContacts';
import { addEntries, addIds, setEntries, setEntry, setId, setIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case RECORDINGS_CONTACTS_ADD:
            return addEntries(state, action);
        case RECORDINGS_CONTACTS_RESET:
            return {};
        case EXTRACT_RECORDING_CONTACT:
            return setEntry(state, action);
        case FETCH_RECORDING_CONTACTS_SUCCESS:
            return setEntries(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case RECORDINGS_CONTACTS_ADD:
            return addIds(state, action);
        case RECORDINGS_CONTACTS_RESET:
            return [];
        case EXTRACT_RECORDING_CONTACT:
            return setId(state, action);
        case FETCH_RECORDING_CONTACTS_SUCCESS:
            return setIds(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
