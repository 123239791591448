import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, Title, Image } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "src/features/deviceActivate/AppsList.module.css";
import { FaDownLong } from "react-icons/fa6";
import React, { cloneElement } from "react";
import ActivateDeviceForm from "src/features/deviceActivate/ActivateDeviceForm";
const ActivateApp = ({ title, appCard, downloadUrl, images }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { ml: "md", maw: 600, children: [_jsx(Title, { order: 4, mb: "sm", tt: "uppercase", children: title }), _jsxs(Flex, { justify: "space-between", align: "center", gap: { base: 'sm', xs: 'md' }, wrap: { base: 'wrap', xs: 'nowrap' }, my: "xl", children: [cloneElement(appCard, { className: classes.app }), _jsx(Flex, { gap: "md", className: classes.button, children: _jsx(Button, { variant: "outline-filled", color: "dark", leftSection: _jsx(FaDownLong, { size: 14 }), component: "a", href: downloadUrl, target: "_blank", children: t('Download_') }) })] }), _jsx(ActivateDeviceForm, {})] }), _jsx(Flex, { wrap: "wrap", gap: "xl", ml: "md", mt: "calc(4 * var(--mantine-spacing-md))", children: images.map((image, index) => _jsx(Image, { src: image, fit: "contain" }, index)) })] }));
};
export default ActivateApp;
