import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, SpinnerBackdrop } from "banafo-design-system";
import illustration from "images/other/sound-wave-illustration.png";
import useAccount from "src/features/account/hooks/useAccount";
import { useSelector } from "react-redux";
import { selectAppLoading } from "src/features/auth/store/selectors/common";
const AudioLimitReached = () => {
    const { t } = useTranslation();
    const { upgradeAccount } = useAccount();
    const loadingApp = useSelector(selectAppLoading);
    const handlePlanUpgrade = () => {
        upgradeAccount();
    };
    return (_jsxs(_Fragment, { children: [loadingApp && _jsx(SpinnerBackdrop, {}), _jsx("div", { className: "text-white text-center text-lg-start", children: _jsxs(Row, { className: "justify-content-center", children: [_jsx(Col, { lg: 4, children: _jsxs("div", { className: "mx-100 my-300", children: [_jsx("h4", { className: "font-weight-bold mb-100 mt-300 pt-300", children: t("Conversation.not.available_") }), _jsxs("div", { className: "me-300", children: [_jsxs("p", { children: [t("You.are.trying.to.access.a.recording.in.an.older.conversation_"), "."] }), _jsxs("p", { children: [t("If.you.need.access.to.your.older.Conversations.you.have.to.upgrade_"), ":"] }), _jsx(Button, { block: true, className: "font-weight-bolder mt-300 text-uppercase", onClick: handlePlanUpgrade, children: t("Upgrade.to.PRO_") })] })] }) }), _jsx(Col, { lg: 4, children: _jsx(Card.Img, { variant: "bottom", src: illustration }) })] }) })] }));
};
export default AudioLimitReached;
