import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Card, SpinnerBackdrop } from "banafo-design-system";
import ProviderItemBadge from "src/features/integrations/ProviderItemBadge";
import ProviderItemActivationButton from "src/features/integrations/ProviderItemActivationButton";
import "./ProviderItems.scss";
const ProviderItem = ({ provider, provider: { name, title, type, description, features, activating, isActive, isVirtuallyActive, } = {}, }) => {
    const { t } = useTranslation();
    return (_jsxs(Card, { className: 'integrated-provider shadow', children: [activating && _jsx(SpinnerBackdrop, { position: "absolute", className: "rounded" }), _jsx(ProviderItemBadge, { isActive: isActive }), _jsx(Card.Img, { variant: "top", src: require(`./images/provider-logo-${name}.png`) }), _jsxs(Card.Body, { children: [_jsx(Card.Title, { children: title }), _jsx(Card.Text, { children: description }), _jsxs(Card.Text, { className: "text-start", children: [_jsx("span", { className: "fw-bold", children: t('Supported.features_') }), ": ", features.join(', ')] })] }), _jsx(Card.Footer, { className: "text-center", children: _jsx(ProviderItemActivationButton, { provider: provider }) })] }));
};
export default ProviderItem;
