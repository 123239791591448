import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import LanguagesModal from "src/features/wizard/modals/LanguagesModal";
import ExtensionModal from "src/features/wizard/modals/ExtensionModal";
import MobileAppModal from "src/features/wizard/modals/MobileAppModal";
const Wizard = () => {
    const [step, setStep] = useState(1);
    return (_jsxs(_Fragment, { children: [_jsx(LanguagesModal, { show: step === 1, setStep: setStep }), _jsx(ExtensionModal, { show: step === 2, setStep: setStep }), _jsx(MobileAppModal, { show: step === 3, setStep: setStep })] }));
};
export default Wizard;
