export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const SHOW_PROFILE_MODAL = 'SHOW_PROFILE_MODAL';
export const HIDE_PROFILE_MODAL = 'HIDE_PROFILE_MODAL';
export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL';
export const HIDE_CHANGE_PASSWORD_MODAL = 'HIDE_CHANGE_PASSWORD_MODAL';
export const startAppLoader = () => ({
    type: START_LOADER
});
export const stopAppLoader = () => ({
    type: STOP_LOADER
});
export const setError = (error) => ({
    type: SET_ERROR,
    payload: { error }
});
export const clearError = () => ({
    type: CLEAR_ERROR
});
export const setSuccessMessage = (successMessage) => ({
    type: SET_SUCCESS_MESSAGE,
    payload: { successMessage }
});
export const clearSuccessMessage = () => ({
    type: CLEAR_SUCCESS_MESSAGE
});
export const setErrorMessage = (errorMessage) => ({
    type: SET_ERROR_MESSAGE,
    payload: { errorMessage }
});
export const clearErrorMessage = () => ({
    type: CLEAR_ERROR_MESSAGE
});
//UI
export const showProfileModal = () => ({
    type: SHOW_PROFILE_MODAL
});
export const hideProfileModal = () => ({
    type: HIDE_PROFILE_MODAL
});
export const showChangePasswordModal = () => ({
    type: SHOW_CHANGE_PASSWORD_MODAL
});
