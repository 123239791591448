import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "banafo-design-system";
const propTypes = {
    componentStack: PropTypes.any,
    error: PropTypes.any,
    resetError: PropTypes.any,
    eventId: PropTypes.any
};
const SentryFallback = ({ error, componentStack, resetError, eventId }) => {
    const { t } = useTranslation();
    return (_jsx("div", { className: "d-flex justify-content-center", children: _jsxs("div", { children: [_jsxs("h1", { children: [t("General.error_"), "!"] }), _jsxs("p", { children: [t("errors:general_"), "."] }), _jsxs("p", { children: ["Error ID ", eventId] }), _jsx("p", { children: _jsx(Button, { onClick: () => { resetError(); }, as: Link, to: "/", reloadDocument: true, children: t("Go.Home_") }) })] }) }));
};
SentryFallback.propTypes = propTypes;
export default SentryFallback;
