import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideAccountUpgradeModal, selectAccountUpgradeModal } from "src/features/account/accountSlice";
import { BanafoModal } from "banafo-design-system";
import AccountUpgradeContent from "src/features/account/modals/accountUpgrade/AccountUpgradeContent";
import { AccountUpgradeModalTypes } from "src/features/account/types";
import ResellerButton from "src/features/account/modals/accountUpgrade/footer/ResellerButton";
import UpdateButton from "src/features/account/modals/accountUpgrade/footer/UpdateButton";
const AccountUpgradeModal = () => {
    const dispatch = useAppDispatch();
    const { isOpen: show, contentType } = useAppSelector(selectAccountUpgradeModal);
    const handleHideModal = () => {
        dispatch(hideAccountUpgradeModal());
    };
    return (_jsx(BanafoModal, { show: show, onHide: handleHideModal, footer: contentType === AccountUpgradeModalTypes.Reseller ? _jsx(ResellerButton, {}) : _jsx(UpdateButton, {}), "aria-labelledby": "contained-modal-title-vcenter", children: _jsx(AccountUpgradeContent, { contentType: contentType }) }));
};
export default AccountUpgradeModal;
