var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import api from "../../../../api/api";
import { extractRecordingDetails } from "./recordingDetails";
import { extractRecordingContact } from "./recordingContacts";
export const FETCH_CALL_DETAILS_BEGIN = 'FETCH_CALL_DETAILS_BEGIN';
export const FETCH_CALL_DETAILS_SUCCESS = 'FETCH_CALL_DETAILS_SUCCESS';
export const FETCH_CALL_DETAILS_FAILURE = 'FETCH_CALL_DETAILS_FAILURE';
export const EXTRACT_CALL_DETAILS = 'EXTRACT_CALL_DETAILS';
export const loadCall = (callId) => {
    return (dispatch, getState) => {
        dispatch(fetchCallDetailsBegin());
        return api.getCall(callId)
            .then(response => {
            const { _id: id, contact, notes, recordings, sharedWith } = response, callDetailsOther = __rest(response, ["_id", "contact", "notes", "recordings", "sharedWith"]);
            const _a = contact || {}, { _id: contactId } = _a, contactOther = __rest(_a, ["_id"]);
            const callDetails = Object.assign({ id, contactId }, callDetailsOther);
            const recordingDetails = recordings.map((_a) => {
                var { _id } = _a, recordingDetails = __rest(_a, ["_id"]);
                return Object.assign({ id: _id, callId: id, contactId }, recordingDetails);
            });
            dispatch(extractRecordingDetails(recordingDetails));
            dispatch(extractRecordingContact(Object.assign({ id: contactId }, contactOther)));
            dispatch(fetchCallDetailsSuccess(callDetails));
            return response;
        })
            .catch(err => {
            dispatch(fetchCallDetailsFailure(err));
            // dispatch(setErrorMessage(err.response.body.message));
            return Promise.reject(err);
        });
    };
};
export const fetchCallDetailsBegin = () => ({
    type: FETCH_CALL_DETAILS_BEGIN
});
export const fetchCallDetailsSuccess = (item) => ({
    type: FETCH_CALL_DETAILS_SUCCESS,
    payload: { item }
});
export const fetchCallDetailsFailure = (error) => ({
    type: FETCH_CALL_DETAILS_FAILURE,
    payload: { error }
});
