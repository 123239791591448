import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Card, Col, Container, Row } from "banafo-design-system";
import { useQueryParams } from "src/features/auth/util/path";
import { loginThirdParty } from "src/features/auth/store/actions/auth/login";
import { get } from "src/features/auth/util/storage";
function LoginExternalPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const query = useQueryParams();
    useEffect(() => {
        dispatch(loginThirdParty(query.get('state'), query.get('code'), get('discountCode')));
    }, []);
    return (_jsx("section", { className: "login-external", children: _jsx(Container, { fluid: 'xl', className: 'pt-300 pb-800', children: _jsx(Row, { children: _jsx(Col, { xs: 12, children: _jsx(Card, { className: "px-lg-300 py-lg-400 border-0 shadow-md", children: _jsx(Card.Body, { children: _jsxs(Card.Text, { children: [t('Logging.you.in_'), "..."] }) }) }) }) }) }) }));
}
export default LoginExternalPage;
