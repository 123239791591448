import { PAGINATION_LIMIT_DEFAULT } from "src/features/auth/config/pagination";
import { SubscriptionPlanTypes } from "src/features/account/types";
import i18n from "src/app/services/i18n";
export const RESELLER_CUSTOMERS_PAGINATION_LIMIT = PAGINATION_LIMIT_DEFAULT;
export const SORT_ASC = 1;
export const SORT_DESC = -1;
export const RESELLER_SUBSCRIPTION_PLAN_NAMES = {
    get [SubscriptionPlanTypes.Premium]() { return i18n.t('PRO.1.month_'); },
    get [SubscriptionPlanTypes.PremiumYearly]() { return i18n.t('PRO.1.year_'); },
};
