import { useEffect } from "react";
import i18n from "i18next";
import { completeLogin, getUser } from "src/app/services/authService";
import { userLogged } from "src/features/auth/store/actions/auth/login";
import { loadLanguage } from "src/features/auth/store/actions/settings";
import { acceptInvitation } from "src/features/auth/store/actions/invitations";
import { useAppDispatch } from "src/app/store/hooks";
import { useAcceptResellerInvitationMutation } from "src/features/resellerCustomers/resellerCustomersApi";
function LoginSuccessPage() {
    const dispatch = useAppDispatch();
    const [acceptResellerInvitation] = useAcceptResellerInvitationMutation();
    useEffect(() => {
        completeLogin()
            .then(() => getUser())
            .then((res) => {
            dispatch(loadLanguage())
                .then((language) => {
                i18n.changeLanguage(language);
            })
                .catch((err) => {
                // console.log(err);
            });
            localStorage.removeItem('registeredAs');
            localStorage.removeItem('discountCode');
            const resellerToken = localStorage.getItem('resellerInvitationToken');
            if (resellerToken) {
                acceptResellerInvitation({ token: resellerToken })
                    .unwrap()
                    .catch((e) => e)
                    .finally(() => localStorage.removeItem('resellerInvitationToken'));
            }
            const token = localStorage.getItem('invitationToken');
            if (token) {
                dispatch(acceptInvitation(token))
                    .catch(() => null)
                    .finally(() => {
                    localStorage.removeItem('invitationToken');
                });
            }
            dispatch(userLogged(res.profile));
        });
    });
    return null;
}
export default LoginSuccessPage;
