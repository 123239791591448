import { jsx as _jsx } from "react/jsx-runtime";
import useGetActivities from "src/features/activityLog/hooks/useGetActivities";
import Activities from "src/features/activityLog/Activities";
import useScrollItemsList from "src/hooks/useScrollItemsList";
const ActivitiesWrapper = () => {
    const { activities, isLoading, isFetching, loadMoreActivities, hasNextPage } = useGetActivities();
    const { scrollRef } = useScrollItemsList(activities);
    return _jsx(Activities, { isLoading: isLoading, isFetching: isFetching, activities: activities, hasNextPage: hasNextPage, loadMoreActivities: loadMoreActivities, ref: scrollRef });
};
export default ActivitiesWrapper;
