import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import 'src/app/services/i18n';
import 'src/app/sentry/sentry';
import 'src/app/services/clarity';
import 'src/app/services/browserExtension';
import 'scss/index.scss';
import '@mantine/core/styles.css';
import App from 'src/app/App';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(App, {}));
