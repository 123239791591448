import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BanafoModal, BifIconTrash, Button, LineSeparator, SpinnerBackdrop } from "banafo-design-system";
import { useDeleteNoteMutation } from "src/features/notes/notesSlice";
const DeleteNoteModal = ({ id, show, onHide, }) => {
    const { t } = useTranslation();
    const [deleteNote, { isLoading: isDeleting }] = useDeleteNoteMutation();
    const handleHideModal = () => {
        onHide();
    };
    const handleDeleteNote = () => {
        deleteNote(id)
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(_Fragment, { children: [isDeleting && _jsx(SpinnerBackdrop, {}), _jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t('Delete.note_'), className: 'text-center', "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-400', children: t('Are.you.sure.you.want.to.remove.note_') }), _jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(Button, { variant: 'outline-dark', className: 'text-uppercase mx-100', onClick: handleHideModal, children: t('Cancel_') }), _jsxs(Button, { variant: 'danger', className: 'text-uppercase mx-100 with-icon end', onClick: handleDeleteNote, children: [t('Delete_'), " ", _jsx(BifIconTrash, {})] })] })] })] }));
};
DeleteNoteModal.propTypes = {
    id: PropTypes.any,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
export default DeleteNoteModal;
