var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { ToastContainer } from "banafo-design-system";
import { selectTranslatedToasts } from "./toastsSlice";
import ToastMessage from "./ToastMessage";
import "./ToastMessagesContainer.scss";
const ToastMessagesContainer = () => {
    const toastsWithMessage = useSelector(selectTranslatedToasts);
    return (_jsx(ToastContainer, { className: "position-absolute", children: toastsWithMessage.map((_a) => {
            var { id } = _a, toast = __rest(_a, ["id"]);
            return (_jsx(ToastMessage, { id: id, toast: Object.assign({ id }, toast) }, id));
        }) }));
};
export default ToastMessagesContainer;
