import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showUploadModal } from "src/features/conversations/conversationsSlice";
import { TbPlus } from "react-icons/tb";
import { Button } from "@mantine/core";
const UploadRecording = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleShowUploadModal = () => {
        dispatch(showUploadModal());
    };
    return (_jsx(Button, Object.assign({ leftSection: _jsx(TbPlus, { size: 16 }), variant: "outline-filled", color: "white", tt: "uppercase", onClick: handleShowUploadModal, fullWidth: true, "data-hover-color-dark": true }, props, { children: t("Upload.recording_") })));
};
export default UploadRecording;
