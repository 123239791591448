import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button, Group, Title } from "@mantine/core";
import { showInviteCustomerModal } from "src/features/resellerCustomers/resellerCustomersSlice";
import { useAppDispatch } from "src/app/store/hooks";
const ResellerCustomersTableFilters = ({ total }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleShowInviteModal = () => {
        dispatch(showInviteCustomerModal());
    };
    return (_jsxs(Group, { justify: "space-between", children: [_jsx(Group, { gap: "sm", children: _jsxs(Title, { order: 1, size: "h6", tt: "uppercase", fw: "bolder", children: [t('My.customers_'), " (", total, ")"] }) }), _jsx(Button, { variant: 'filled', color: 'red', size: 'sm', tt: "uppercase", fw: "bold", onClick: handleShowInviteModal, children: t('Invite.user_') })] }));
};
export default ResellerCustomersTableFilters;
