import { combineReducers } from "redux";
import { FETCH_SETTINGS_SUCCESS, UPDATE_SETTINGS_SUCCESS } from "../../actions/settings";
function calls(state = {}, action) {
    switch (action.type) {
        case FETCH_SETTINGS_SUCCESS:
        case UPDATE_SETTINGS_SUCCESS:
            return action.payload.item.calls;
        default:
            return state;
    }
}
function transcripts(state = {}, action) {
    switch (action.type) {
        case FETCH_SETTINGS_SUCCESS:
        case UPDATE_SETTINGS_SUCCESS:
            return action.payload.item.transcripts;
        default:
            return state;
    }
}
function language(state = '', action) {
    switch (action.type) {
        case FETCH_SETTINGS_SUCCESS:
        case UPDATE_SETTINGS_SUCCESS:
            return action.payload.item.language;
        default:
            return state;
    }
}
export default combineReducers({
    calls,
    transcripts,
    language,
});
