import { createSlice } from "@reduxjs/toolkit";
import { AccountUpgradeModalTypes } from "src/features/account/types";
import apiApp from "src/app/services/apiApp";
const accountInitialState = {
    accountUpgradeModal: {
        isOpen: false,
        contentType: AccountUpgradeModalTypes.General
    },
    unsubscribeModal: {
        isOpen: false
    },
};
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        unSubscribe: builder.mutation({
            query: (body) => ({
                url: `/limiter/unsubscribe`,
                method: "POST",
                body
            }),
        }),
    })
});
export const accountSlice = createSlice({
    name: 'account',
    initialState: accountInitialState,
    reducers: {
        showAccountUpgradeModal: (state, action) => {
            state.accountUpgradeModal.isOpen = true;
            state.accountUpgradeModal.contentType = action.payload;
        },
        hideAccountUpgradeModal: state => {
            state.accountUpgradeModal.isOpen = false;
        },
        showUnsubscribeModal: (state, action) => {
            state.unsubscribeModal.isOpen = true;
        },
        hideUnsubscribeModal: state => {
            state.unsubscribeModal.isOpen = false;
        },
    }
});
export const { useUnSubscribeMutation } = extendedApi;
export const { name, actions } = accountSlice;
export const { showAccountUpgradeModal, hideAccountUpgradeModal, showUnsubscribeModal, hideUnsubscribeModal } = accountSlice.actions;
export default accountSlice.reducer;
export const selectAccountUpgradeModal = (state) => state[name].accountUpgradeModal;
export const selectUnsubscribeModal = (state) => state[name].unsubscribeModal;
export const selectActiveSubscriptionId = (state) => state.account.unsubscribeModal.activeSubscriptionId;
export const selectUnsubscribeModalIsOpen = (state) => state[name].unsubscribeModal.isOpen;
