import { combineReducers } from "redux";
import { BROWSER_EXTENSION_AVAILABLE, BROWSER_EXTENSION_NOT_AVAILABLE } from "../../actions/browserExtension";
function available(state = 0, action) {
    switch (action.type) {
        case BROWSER_EXTENSION_AVAILABLE:
            return 1;
        case BROWSER_EXTENSION_NOT_AVAILABLE:
            return 0;
        default:
            return state;
    }
}
export default combineReducers({
    available,
});
