import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { useAppSelector } from "src/app/store/hooks";
const useHasRole = (role) => {
    const user = useAppSelector(selectAuthUser);
    if (!user) {
        return undefined;
    }
    const { role: userRole } = user;
    return userRole === role;
};
export default useHasRole;
