import { combineReducers } from "redux";
import { CLEAR_CONTACTS, FETCH_CONTACTS_BEGIN, FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_SUCCESS } from "../../actions/contacts";
function initialize(state = false, action) {
    switch (action.type) {
        case FETCH_CONTACTS_SUCCESS:
        case FETCH_CONTACTS_FAILURE:
            return true;
        case CLEAR_CONTACTS:
            return false;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case FETCH_CONTACTS_BEGIN:
            return state + 1;
        case FETCH_CONTACTS_SUCCESS:
            return state - 1;
        case FETCH_CONTACTS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
function pagination(state = {}, action) {
    switch (action.type) {
        case CLEAR_CONTACTS:
            return {};
        case FETCH_CONTACTS_BEGIN:
            return state;
        case FETCH_CONTACTS_SUCCESS:
            return action.payload.pagination;
        case FETCH_CONTACTS_FAILURE:
            return state;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading,
    pagination
});
