import api from "../../../../api/api";
export const CLEAR_RECORDING_DETAILS = 'CLEAR_RECORDING_DETAILS';
export const ADD_RECORDING_DETAILS_SUCCESS = 'ADD_RECORDING_DETAILS_SUCCESS';
export const FETCH_RECORDING_DETAILS_BEGIN = 'FETCH_RECORDING_DETAILS_BEGIN';
export const FETCH_RECORDING_DETAILS_SUCCESS = 'FETCH_RECORDING_DETAILS_SUCCESS';
export const FETCH_RECORDING_DETAILS_FAILURE = 'FETCH_RECORDING_DETAILS_FAILURE';
export const FETCH_MEDIA_RECORDING_DETAILS_BEGIN = 'FETCH_MEDIA_RECORDING_DETAILS_BEGIN';
export const FETCH_MEDIA_RECORDING_DETAILS_SUCCESS = 'FETCH_MEDIA_RECORDING_DETAILS_SUCCESS';
export const FETCH_MEDIA_RECORDING_DETAILS_FAILURE = 'FETCH_MEDIA_RECORDING_DETAILS_FAILURE';
export const EXTRACT_RECORDING_DETAILS = 'EXTRACT_RECORDING_DETAILS';
export const loadMediaRecording = (fileId) => {
    return (dispatch, getState) => {
        dispatch(fetchMediaRecordingDetailsBegin());
        return api.getMediaRecording(fileId)
            .then(response => {
            dispatch(fetchMediaRecordingDetailsSuccess());
            return response;
        })
            .catch(err => {
            dispatch(fetchMediaRecordingDetailsFailure(err));
            return Promise.reject(err);
        });
    };
};
export const fetchMediaRecordingDetailsBegin = () => ({
    type: FETCH_MEDIA_RECORDING_DETAILS_BEGIN
});
export const fetchMediaRecordingDetailsSuccess = () => ({
    type: FETCH_MEDIA_RECORDING_DETAILS_SUCCESS
});
export const fetchMediaRecordingDetailsFailure = (error) => ({
    type: FETCH_MEDIA_RECORDING_DETAILS_FAILURE,
    payload: { error }
});
export const extractRecordingDetails = (items) => ({
    type: EXTRACT_RECORDING_DETAILS,
    payload: { items }
});
