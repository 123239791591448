import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Burger, Menu, rem } from "@mantine/core";
import { TbCurrencyDollar, TbLock, TbPower, TbUser } from "react-icons/tb";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { showChangePasswordModal, showProfileModal } from "src/features/sidebar/sidebarSlice";
import { logoutUser } from "src/features/auth/store/actions/auth/login";
import { Link } from "react-router-dom";
import { INVOICES_ROUTE } from "src/app/router/config";
import useAccount from "src/features/account/hooks/useAccount";
const ProfileDropdown = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const { subscriptionPlan: { isGroupFree } } = useAccount();
    const authUser = useSelector(selectAuthUser);
    const handleOpenProfileModal = () => dispatch(showProfileModal());
    const handleOpenChangePasswordModal = () => dispatch(showChangePasswordModal());
    const handleLogout = () => dispatch(logoutUser());
    return (_jsxs(Menu, { position: "bottom-start", shadow: "sm", offset: 0, width: 200, opened: opened, onChange: setOpened, zIndex: 2000, id: "profile-dropdown", children: [_jsx(Menu.Target, { children: _jsx(Burger, { size: "sm", ml: "auto", "aria-label": "Toggle navigation", opened: opened }) }), _jsxs(Menu.Dropdown, { children: [_jsx(Menu.Label, { children: authUser.email }), _jsx(Menu.Item, { leftSection: _jsx(TbUser, { style: { width: rem(14), height: rem(14) } }), onClick: handleOpenProfileModal, children: t('Profile_') }), _jsx(Menu.Item, { leftSection: _jsx(TbLock, { style: { width: rem(14), height: rem(14) } }), onClick: handleOpenChangePasswordModal, children: t('Change.password_') }), _jsx(Menu.Item, { leftSection: _jsx(TbCurrencyDollar, { style: { width: rem(14), height: rem(14) } }), component: Link, to: INVOICES_ROUTE, disabled: isGroupFree, children: t('Subscription.and.payments_') }), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { color: "red", leftSection: _jsx(TbPower, { style: { width: rem(14), height: rem(14) } }), onClick: handleLogout, children: t('Logout_') })] })] }));
};
export default ProfileDropdown;
