class AcronymFirstOfWord {
    generate(name) {
        return name
            .split(" ")
            .map(word => word.charAt(0).toUpperCase())
            .join("")
            .slice(0, 2);
    }
}
export default AcronymFirstOfWord;
