import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table } from "banafo-design-system";
import SearchResultRow from "./SearchResultRow";
import { selectAuth } from "src/features/auth/store/selectors/auth";
function SearchResultTable({ searchTranscripts, targetValue, handleHideModal }) {
    const { t } = useTranslation();
    const auth = useSelector((state) => selectAuth(state));
    return (_jsx(_Fragment, { children: _jsxs(Table, { responsive: true, hover: true, className: "calls-table", children: [_jsx("thead", { children: _jsxs("tr", { className: "d-none d-md-table-row", children: [_jsx("th", { children: t('Created.At_') }), _jsx("th", { children: t('Content_') })] }) }), _jsx("tbody", { children: searchTranscripts.map((searchTranscript) => searchTranscript.transcript.map(transcriptRow => _jsx(SearchResultRow, { searchTranscript: searchTranscript, transcriptRow: transcriptRow, targetValue: targetValue, userId: auth.user._id, handleHideModal: handleHideModal }, transcriptRow._id))) })] }) }));
}
SearchResultTable.propTypes = {
    searchTranscripts: PropTypes.arrayOf(PropTypes.object).isRequired,
    targetValue: PropTypes.string,
    handleHideModal: PropTypes.func
};
export default SearchResultTable;
