import { createSlice } from "@reduxjs/toolkit";
export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        offcanvasVisibility: false
    },
    reducers: {
        hideOffcanvas: state => {
            state.offcanvasVisibility = false;
        },
        showOffcanvas: state => {
            state.offcanvasVisibility = true;
        },
        toggleOffcanvasVisibility: state => {
            state.offcanvasVisibility = !state.offcanvasVisibility;
        },
    }
});
export const { name, actions } = layoutSlice;
export const { hideOffcanvas, showOffcanvas, toggleOffcanvasVisibility } = layoutSlice.actions;
export default layoutSlice.reducer;
export const selectOffcanvasIsVisible = state => state[name].offcanvasVisibility;
