import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { Navigate, Outlet } from "react-router-dom";
import { AUTH_LOGIN_ROUTE } from "src/app/router/config";
function WizardRequireAuth() {
    const authUser = useSelector((state) => selectAuthUser(state));
    if (authUser && authUser.showWizard) {
        return _jsx(Outlet, {});
    }
    return _jsx(Navigate, { to: AUTH_LOGIN_ROUTE });
}
export default WizardRequireAuth;
