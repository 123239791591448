import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { useLocation } from "react-router-dom";
import { makeSelectAllResellerCustomers, selectTotalResellerCustomers, useGetResellerCustomersQuery } from "src/features/resellerCustomers/resellerCustomersApi";
import { incrementResellerCustomersPage, resetResellerCustomers, selectResellerCustomersApiArgs, selectResellerCustomersIsLastPage } from "src/features/resellerCustomers/resellerCustomersSlice";
const useGetResellerCustomers = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const args = useAppSelector(selectResellerCustomersApiArgs);
    const result = useGetResellerCustomersQuery(args);
    const { isFetching } = result;
    const selectAllResellerCustomers = useMemo(makeSelectAllResellerCustomers, []);
    const resellerCustomers = useAppSelector(state => selectAllResellerCustomers(state, args));
    const totalResellerCustomers = useAppSelector(state => selectTotalResellerCustomers(state, args));
    const hasNextPage = !useAppSelector(selectResellerCustomersIsLastPage);
    const loadMoreResellerCustomers = useCallback(() => {
        if (isFetching || !hasNextPage) {
            return;
        }
        dispatch(incrementResellerCustomersPage());
    }, [isFetching, hasNextPage]);
    useEffect(() => {
        return () => {
            dispatch(resetResellerCustomers());
        };
    }, [location.pathname]);
    return Object.assign({ resellerCustomers, totalResellerCustomers, hasNextPage, loadMoreResellerCustomers }, result);
};
export default useGetResellerCustomers;
