import status from 'http-status';
export const HTTP_ERROR = 'HTTP_ERROR';
export const UNHANDLED_ERROR = 'UNHANDLED_ERROR';
export const TOKEN_EXPIRED_ERROR = 'TOKEN_EXPIRED_ERROR';
const inArray = (needle, haystack) => haystack.indexOf(needle) > -1;
export function httpError(err) {
    if (err.status === status.UNAUTHORIZED) {
        if (inArray(err.response.body.name, ['BadTokenError', 'TokenExpiredError'])) {
            return {
                type: TOKEN_EXPIRED_ERROR
            };
        }
    }
    return {
        type: HTTP_ERROR,
        err
    };
}
export function defaultError(err) {
    return {
        type: UNHANDLED_ERROR,
        err
    };
}
