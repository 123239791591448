import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip, BifIconTrash } from "banafo-design-system";
const DeleteNoteButton = ({ handleDeleteNote }) => {
    const { t } = useTranslation();
    return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "open-note", children: t('Delete_') }), children: _jsx(Button, { variant: "link", size: 'sm', className: "text-dark me-50", onClick: handleDeleteNote, children: _jsx(BifIconTrash, {}) }) }));
};
DeleteNoteButton.propTypes = {
    handleDeleteNote: PropTypes.func
};
export default DeleteNoteButton;
