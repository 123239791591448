import { combineReducers } from "redux";
import { START_LOADER, STOP_LOADER, SET_ERROR, CLEAR_ERROR, SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE, CLEAR_ERROR_MESSAGE, SET_ERROR_MESSAGE } from "../../actions/app";
function loading(state = 0, action) {
    switch (action.type) {
        case START_LOADER:
            return state + 1;
        case STOP_LOADER:
            return state - 1;
        default:
            return state;
    }
}
function error(state = {}, action) {
    switch (action.type) {
        case SET_ERROR:
            return action.payload.error;
        case CLEAR_ERROR:
            return {};
        default:
            return state;
    }
}
function successMessage(state = null, action) {
    switch (action.type) {
        case SET_SUCCESS_MESSAGE:
            return action.payload.successMessage;
        case CLEAR_SUCCESS_MESSAGE:
            return null;
        default:
            return state;
    }
}
function errorMessage(state = null, action) {
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return action.payload.errorMessage;
        case CLEAR_ERROR_MESSAGE:
            return null;
        default:
            return state;
    }
}
export default combineReducers({
    loading,
    error,
    successMessage,
    errorMessage,
});
