import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { USER_PLAN_FREE } from "src/features/auth/config/user";
import { useUpdateAccountMutation } from "src/features/account/accountApi";
import { SUBSCRIPTION_PLAN_DEMO_PAID } from "src/features/account/config";
import { useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
const StartTrial = () => {
    const { t } = useTranslation();
    const authUser = useSelector(selectAuthUser);
    const [updateAccount] = useUpdateAccountMutation({ fixedCacheKey: "upgrade-account" });
    const handleLinkClick = (e) => {
        e.preventDefault();
        updateAccount(SUBSCRIPTION_PLAN_DEMO_PAID);
    };
    if (authUser.title !== USER_PLAN_FREE || !authUser.isTrialAvailable) {
        return null;
    }
    return (_jsx("a", { href: "#", className: "link-primary fw-bold mx-auto mb-50", onClick: handleLinkClick, children: t('infos:start.trial_') }));
};
export default StartTrial;
