import { BifIconSoundwave, BifIconTask, BifIconGroup, BifIconIntegrationOk, BifIconIntegrationOff, BifIconTranscribe, BifIconContacts, BifIconNoteFile, BifIconNewNote, BifIconUserPlus, BifIconKey } from "banafo-design-system";
import i18n from "src/app/services/i18n";
export const ACTIVITY_LOCAL_NOTE_REMOVED = 'local-note.removed';
export const ACTIVITY_LOCAL_NOTE_CREATED = 'local-note.created';
export const ACTIVITY_NOTE_CREATED = 'note.created';
export const ACTIVITY_CONTACT_CREATED = 'contact.created';
export const ACTIVITY_CALL_CREATED = 'call.created';
export const ACTIVITY_CALL_REMOVED = 'call.removed';
export const ACTIVITY_CONVERSATION_TRANSCRIBED = 'conversation.transcribed';
export const ACTIVITY_CONVERSATION_SUMMARIZED = 'conversation.summarized';
export const ACTIVITY_INTEGRATION_ACTIVATED = 'integration.activated';
export const ACTIVITY_INTEGRATION_DEACTIVATED = 'integration.deactivated';
export const ACTIVITY_TASK_CREATED = 'task.created';
export const ACTIVITY_LOCAL_TASK_CREATED = 'local-task.created';
export const ACTIVITY_USER_UPDATED = 'user.updated';
export const ACTIVITY_USER_CREATED = 'user.created';
export const ACTIVITY_API_KEY_CREATED = 'apiKey.created';
export const ACTIVITY_API_KEY_REVOKED = 'apiKey.revoked';
export const ACTIVITY_TYPES_ENUM = [
    { value: ACTIVITY_LOCAL_NOTE_REMOVED, get text() { return i18n.t('Note.removed_'); }, icon: BifIconNoteFile },
    { value: ACTIVITY_LOCAL_NOTE_CREATED, get text() { return i18n.t('Note.created_'); }, icon: BifIconNewNote },
    { value: ACTIVITY_NOTE_CREATED, get text() { return i18n.t('External.note.created_'); }, icon: BifIconNewNote },
    { value: ACTIVITY_CONTACT_CREATED, get text() { return i18n.t('Contact.created_'); }, icon: BifIconContacts },
    { value: ACTIVITY_CALL_CREATED, get text() { return i18n.t('Conversation.created_'); }, icon: BifIconSoundwave, color: 'danger' },
    { value: ACTIVITY_CALL_REMOVED, get text() { return i18n.t('Conversation.removed_'); }, icon: BifIconSoundwave },
    { value: ACTIVITY_CONVERSATION_TRANSCRIBED, get text() { return i18n.t('Conversation.transcribed_'); }, icon: BifIconTranscribe, color: 'success' },
    { value: ACTIVITY_CONVERSATION_SUMMARIZED, get text() { return i18n.t('Conversation.summarized_'); }, icon: BifIconTranscribe, color: 'success' },
    { value: ACTIVITY_INTEGRATION_ACTIVATED, get text() { return i18n.t('Integration.activated_'); }, icon: BifIconIntegrationOk },
    { value: ACTIVITY_INTEGRATION_DEACTIVATED, get text() { return i18n.t('Integration.deactivated_'); }, icon: BifIconIntegrationOff },
    { value: ACTIVITY_TASK_CREATED, get text() { return i18n.t('External.task.created_'); }, icon: BifIconTask },
    { value: ACTIVITY_LOCAL_TASK_CREATED, get text() { return i18n.t('Task.created_'); }, icon: BifIconTask },
    { value: ACTIVITY_USER_UPDATED, get text() { return i18n.t('User.updated_'); }, icon: BifIconGroup, color: 'secondary' },
    { value: ACTIVITY_USER_CREATED, get text() { return i18n.t('User.created_'); }, icon: BifIconUserPlus },
    { value: ACTIVITY_API_KEY_CREATED, get text() { return i18n.t('Api.key.created_'); }, icon: BifIconKey },
    { value: ACTIVITY_API_KEY_REVOKED, get text() { return i18n.t('Api.key.revoked_'); }, icon: BifIconKey },
];
