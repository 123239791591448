var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from "@sentry/react";
import { Log, UserManager, WebStorageStateStore } from "oidc-client";
import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_CLIENT_ROOT } from "src/app/config";
import { apiClient } from "./httpClient";
import { AUTH_TOKEN_RENEW_SUCCESS_ROUTE } from "src/app/router/config";
const stsAuthority = OIDC_AUTHORITY;
const clientScope = "openid profile email api banafoApi.*";
const clientRoot = OIDC_CLIENT_ROOT;
const clientId = OIDC_CLIENT_ID;
const settings = {
    authority: stsAuthority,
    client_id: clientId,
    redirect_uri: `${clientRoot}/auth/login-success`,
    silent_redirect_uri: `${clientRoot}${AUTH_TOKEN_RENEW_SUCCESS_ROUTE}`,
    automaticSilentRenew: true,
    post_logout_redirect_uri: `${clientRoot}/auth/logout-success`,
    response_type: "code",
    scope: clientScope,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};
const userManager = new UserManager(settings);
userManager.events.addAccessTokenExpired(function () {
    oidcLogout();
});
userManager.events.addSilentRenewError(function () {
    oidcLogout();
});
Log.logger = console;
Log.level = Log.INFO;
export const createUserManager = () => {
    let userManager;
    try {
        if (!userManager) {
            const settings = {
                authority: stsAuthority,
                client_id: clientId,
                redirect_uri: `${clientRoot}/auth/login-success`,
                silent_redirect_uri: `${clientRoot}${AUTH_TOKEN_RENEW_SUCCESS_ROUTE}`,
                automaticSilentRenew: true,
                post_logout_redirect_uri: `${clientRoot}/auth/logout-success`,
                response_type: "code",
                scope: clientScope,
                userStore: new WebStorageStateStore({ store: window.localStorage }),
            };
            userManager = new UserManager(settings);
            userManager.events.addAccessTokenExpired(function () {
                oidcLogout();
            });
            userManager.events.addSilentRenewError(function () {
                oidcLogout();
            });
        }
        Log.logger = console;
        Log.level = Log.INFO;
        return userManager;
    }
    catch (e) {
        throw e;
    }
};
export function getUser() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield userManager.getUser();
        }
        catch (e) {
            Sentry.captureException(e);
            throw e;
        }
    });
}
export function signinRedirect() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return userManager.signinRedirect();
        }
        catch (e) {
            Sentry.captureException(e);
            throw e;
        }
    });
}
export function oidcLogin() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const userManager = createUserManager();
            const user = yield userManager.getUser();
            if (!user) {
                return userManager.signinRedirect();
            }
        }
        catch (e) {
            Sentry.captureException(e);
            throw e;
        }
    });
}
export function completeLogin() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const user = yield userManager.getUser();
            const token = user ? user.access_token : null;
            yield apiClient.auth(token, { type: 'bearer' });
            return yield userManager.signinRedirectCallback();
        }
        catch (e) {
            Sentry.captureException(e);
            throw e;
        }
    });
}
export function oidcLogout() {
    try {
        userManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        userManager.clearStaleState();
    }
    catch (e) {
        Sentry.captureException(e);
        throw e;
    }
}
export function completeLogout() {
    try {
        return userManager
            .signoutRedirectCallback()
            .then(() => {
            userManager.removeUser();
        });
    }
    catch (e) {
        Sentry.captureException(e);
        throw e;
    }
}
export function renewToken() {
    try {
        const userManager = createUserManager();
        return userManager.signinSilent().catch((err) => {
            // console.log(err);
        });
    }
    catch (e) {
        Sentry.captureException(e);
        throw e;
    }
}
export function completeRenewToken() {
    try {
        return userManager.signinSilentCallback();
    }
    catch (e) {
        Sentry.captureException(e);
        throw e;
    }
}
