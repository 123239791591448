var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUser } from "src/app/services/authService";
export const apiApp = createApi({
    reducerPath: 'apiApp',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/v1',
        prepareHeaders: (headers, api) => __awaiter(void 0, void 0, void 0, function* () {
            const user = yield getUser();
            const token = user ? user.access_token : null;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            // if(user !== null){
            //
            // 	const { expired = true } = user ?? {};
            //
            // 	if(expired){
            // 		return oidcLogout();
            // 	}
            //
            // 	const token = user ? user.access_token : null;
            //
            // 	if (token) {
            // 		headers.set('authorization', `Bearer ${token}`);
            // 	}
            // }
            return headers;
        }),
    }),
    tagTypes: [
        'ApiKey', 'Subscriptions', 'Transcripts', 'Integrations', 'OauthXsrfToken', 'Call', 'TranscriptsAnalyses',
        'ResellerCustomers',
        'ResellerApiKeys'
    ],
    endpoints: (builder) => ({}),
});
export default apiApp;
