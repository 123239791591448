import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "banafo-design-system";
import ResellerCustomersRow from "src/features/resellerCustomers/table/ResellerCustomersRow";
import { useTranslation } from "react-i18next";
import { changeSortFilter, selectResellerCustomersFilters } from "src/features/resellerCustomers/resellerCustomersSlice";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import SortableCol from "src/common/components/ui/SortableCol";
const ResellerCustomersTable = ({ resellerCustomers }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const filters = useAppSelector(selectResellerCustomersFilters);
    const handleFilterChange = (name) => () => {
        dispatch(changeSortFilter(name));
    };
    return (_jsxs(Table, { responsive: true, hover: true, children: [_jsx("thead", { children: _jsxs("tr", { className: "d-none d-md-table-row", children: [_jsx("td", { className: "text-start", children: _jsx(SortableCol, { sort: handleFilterChange('name'), sortType: filters['sort[name]'], children: t('Name_') }) }), _jsx("td", { className: "text-start", children: _jsx(SortableCol, { sort: handleFilterChange('email'), sortType: filters['sort[email]'], children: t('Email_') }) }), _jsx("td", { className: "text-start", children: _jsx(SortableCol, { sort: handleFilterChange('status'), sortType: filters['sort[status]'], children: t('Status_') }) }), _jsx("td", { className: "text-start", children: _jsx(SortableCol, { sort: handleFilterChange('plan'), sortType: filters['sort[plan]'], children: t('Plan_') }) }), _jsx("td", { className: "text-start", children: _jsx(SortableCol, { sort: handleFilterChange('startedAt'), sortType: filters['sort[startedAt]'], children: t('Started.At_') }) }), _jsx("td", { className: "text-start", style: { width: '150px' }, children: t('Actions_') })] }) }), _jsx("tbody", { children: resellerCustomers.map((resellerCustomer) => _jsx(ResellerCustomersRow, { resellerCustomer: resellerCustomer }, resellerCustomer.id)) })] }));
};
export default ResellerCustomersTable;
