export const SCHEME = window.__RUNTIME_CONFIG__.SCHEME;
export const API_URL = window.__RUNTIME_CONFIG__.API_URL;
export const WEB_URL = window.__RUNTIME_CONFIG__.WEB_URL;
export const WEB_API_URL = window.__RUNTIME_CONFIG__.WEB_API_URL;
export const HELP_URL = window.__RUNTIME_CONFIG__.HELP_URL;
export const BLOG_URL = window.__RUNTIME_CONFIG__.BLOG_URL;
export const PUBLICDOCS_URL = window.__RUNTIME_CONFIG__.PUBLICDOCS_URL;
export const SENTRY_FRONTEND_DSN = window.__RUNTIME_CONFIG__.SENTRY_FRONTEND_DSN;
export const SENTRY_ENV_NAME = window.__RUNTIME_CONFIG__.SENTRY_ENV_NAME;
export const GOOGLE_REDIRECT_URI_IDENTITY = window.__RUNTIME_CONFIG__.GOOGLE_REDIRECT_URI_IDENTITY;
export const GOOGLE_CLIENT_ID = window.__RUNTIME_CONFIG__.GOOGLE_CLIENT_ID;
export const SITE_KEY = window.__RUNTIME_CONFIG__.SITE_KEY;
export const ENABLED_FEATURES = window.__RUNTIME_CONFIG__.FEATURE_ENABLED;
export const OIDC_AUTHORITY = window.__RUNTIME_CONFIG__.OIDC_AUTHORITY;
export const OIDC_CLIENT_ROOT = window.__RUNTIME_CONFIG__.OIDC_CLIENT_ROOT;
export const OIDC_CLIENT_ID = window.__RUNTIME_CONFIG__.OIDC_CLIENT_ID;
export const STRIPE_KEY = window.__RUNTIME_CONFIG__.STRIPE_KEY;
export const CLARITY_KEY = window.__RUNTIME_CONFIG__.CLARITY_KEY;
export const GOOGLE_TAG_MANAGER_KEY = window.__RUNTIME_CONFIG__.GOOGLE_TAG_MANAGER_KEY;
export const MICROSOFT_CLIENT_ID = window.__RUNTIME_CONFIG__.MICROSOFT_CLIENT_ID;
export const MICROSOFT_REDIRECT_URI_IDENTITY = window.__RUNTIME_CONFIG__.MICROSOFT_REDIRECT_URI_IDENTITY;
export const WEB_URL_FULL = `${SCHEME}://${WEB_URL}`;
export const PUBLICDOCS_URL_FULL = `${SCHEME}://${PUBLICDOCS_URL}`;
export const HELP_URL_FULL = `${SCHEME}://${HELP_URL}`;
export const API_VERSION_PREFIX = '/api/v1';
export const API_PREFIX = `${SCHEME}://${API_URL}`;
//STATIC URLS
export const FACEBOOK_URL = "https://www.facebook.com/banafocom";
export const TWITTER_URL = "https://twitter.com/banafo_com";
export const LINKEDIN_URL = "https://www.linkedin.com/company/banafo";
export const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.banafo.app";
export const DOWNLOAD_ZOIPER_APP_URL = "https://www.zoiper.com/en/voip-softphone/download/current";
//RTK QUERY
export const RTK_REQUEST_STATUS_PENDING = 'pending';
export const RTK_REQUEST_STATUS_FULFILLED = 'fulfilled';
export const RTK_REQUEST_STATUS_REJECTED = 'rejected';
//CONFIGS
export const DEBUG = false;
export const LANGUAGE_EN_US = 'en-US';
export const SORT_ASC = 1;
export const SORT_DESC = -1;
