import { useMemo } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { SubscriptionPlanTypes } from "src/features/account/types";
const useSubscriptionPlan = () => {
    const { title } = useSelector(selectAuthUser);
    const displayName = useMemo(() => title === SubscriptionPlanTypes.Free ? i18n.t('Free_') : i18n.t('PRO_'), [title]);
    const isFree = useMemo(() => title === SubscriptionPlanTypes.Free, [title]);
    const isGroupFree = useMemo(() => [SubscriptionPlanTypes.Free, SubscriptionPlanTypes.DemoPaid].includes(title), [title]);
    return {
        displayName,
        isFree,
        isGroupFree,
    };
};
export default useSubscriptionPlan;
