import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useLazyGetContactUrlQuery } from "src/features/contacts/apiContactsSlice";
import { BifIconShare, Button, OverlayTrigger, Tooltip } from "banafo-design-system";
import DetailsData from "src/features/conversation/DetailsData";
import { CONTACT_PROVIDER_ZOIPER5 } from "src/features/contacts/config";
const DetailsDataContact = ({ title, contact: { id, remote: { provider }, displayName } }) => {
    const { t } = useTranslation();
    const [loadContactUrl] = useLazyGetContactUrlQuery();
    const handleOpenContact = (id, type, provider) => () => {
        loadContactUrl({ provider, contactId: id, type })
            .unwrap()
            .then(({ contactUrl }) => {
            window.open(contactUrl, "_blank");
        })
            .catch(e => null);
    };
    const text = _jsxs(_Fragment, { children: [displayName, provider && ` (${provider})`, (provider && provider !== CONTACT_PROVIDER_ZOIPER5) && _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "open-in-crm", children: t("Open.in.CRM_") }), children: _jsx(Button, { size: "sm", variant: "chevron-thin-right", onClick: handleOpenContact(id, "contact", provider), className: "mx-50", children: _jsx(BifIconShare, {}) }) })] });
    return _jsx(DetailsData, { title: title, text: text });
};
export default DetailsDataContact;
