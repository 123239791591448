import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Layout, LineSeparator } from "banafo-design-system";
import TranscriptsForm from "src/features/apiKeys/TranscriptsForm";
import Header from "./Header";
import gears from "./images/gears.png";
import Title from "./Title";
const GeneralSettingsPage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx("div", { className: "text-center", children: _jsx(Image, { fluid: true, src: gears, alt: "settings" }) }), _jsx(Title, {}), _jsx(LineSeparator, { color: "400", size: "2" }), _jsx(TranscriptsForm, {}), _jsx(LineSeparator, { color: "400", size: "2" })] }));
};
export default GeneralSettingsPage;
