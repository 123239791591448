import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "src/features/deviceActivate/AppsList.module.css";
import { useGetOauthXsrfTokenQuery, useOauthLoginMutation } from "src/features/deviceActivate/deviceActivateApi";
import { SpinnerBackdrop } from "banafo-design-system";
import useActivateDeviceCode from "src/features/deviceActivate/hooks/useActivateDeviceCode";
const ActivateDeviceForm = () => {
    const { t } = useTranslation();
    const [, { isLoading: isDeviceActivateLoading }] = useOauthLoginMutation({ fixedCacheKey: "activate-device-code" });
    const { isLoading: isXsrfLoading } = useGetOauthXsrfTokenQuery();
    const { form, onSubmit } = useActivateDeviceCode();
    return (_jsxs(_Fragment, { children: [(isXsrfLoading || isDeviceActivateLoading) && _jsx(SpinnerBackdrop, {}), _jsxs(Flex, { component: "form", onSubmit: onSubmit, justify: "space-between", gap: { base: "sm", xs: "lg" }, wrap: { base: "wrap", xs: "nowrap" }, my: "xl", children: [_jsx(TextInput, Object.assign({ className: classes.app, placeholder: t("Code_") }, form.getInputProps('code'))), _jsx(Flex, { gap: "md", className: classes.button, children: _jsx(Button, { type: "submit", color: "dark", tt: "uppercase", w: 137, children: t("Submit_") }) })] })] }));
};
export default ActivateDeviceForm;
