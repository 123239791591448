import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BanafoModal, BifIconTrash, Button, Col, Form, LineSeparator, Row } from "banafo-design-system";
import { hideCreateContactModal, selectActiveCallId, selectCombinedConversationsById, selectCreateContactModalIsOpen, selectFilters, selectPage, switchContactModals, useUpdateCallMutation } from "src/features/conversations/conversationsSlice";
import { Link } from "react-router-dom";
import Ajv from "ajv";
import cx from "classnames";
import { useEffect, useState } from "react";
import update from "immutability-helper";
import validateSchema from "src/features/auth/util/validateSchema";
import { selectActiveContactsIntegrations, useGetIntegrationsQuery } from "src/features/integrations/integrationsSlice";
import { CONVERSATIONS_PAGINATION_LIMIT } from "src/features/conversations/config";
import * as Sentry from "@sentry/react";
import { PROVIDER_NAME_BANAFO } from "src/features/integrations/config";
import { INTEGRATIONS_ROUTE } from "src/app/router/config";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, useDefaults: "empty" });
require("ajv-errors")(ajv /*, {singleError: true} */);
const innerSchema = {
    "type": "object",
    "properties": {
        "phone": {
            "type": "string"
        },
        "type": {
            "type": "string"
        }
    },
    "allOf": [
        {
            "if": {
                "properties": {
                    "phone": {
                        "minLength": 1
                    }
                }
            },
            "then": {
                "required": ["type"],
                properties: {
                    "type": {
                        "minLength": 1
                    }
                }
            }
        },
        {
            "if": {
                "properties": {
                    "type": {
                        "minLength": 1
                    }
                }
            },
            "then": {
                "required": ["phone"],
                properties: {
                    "phone": {
                        "minLength": 1
                    }
                }
            }
        }
    ],
    errorMessage: {
        properties: {
            phone: "errors:Invalid.phone_",
            type: "errors:Invalid.type_"
        }
    }
};
const innerArraySchema = {
    "type": "array",
    "items": innerSchema
};
const schema = {
    type: "object",
    "properties": {
        "email": {
            type: ["string"],
            format: "email",
            "minLength": 3,
            "maxLength": 32
        },
        "first_name": {
            type: ["string"],
            "minLength": 3,
            "maxLength": 32
        },
        "last_name": {
            type: ["string"],
            "minLength": 3,
            "maxLength": 32
        },
        "type": {
            type: ["string"],
            "minLength": 1
        },
        "company": {
            type: ["string"],
            "minLength": 3,
            "maxLength": 32
        },
        "phones": innerArraySchema
    },
    errorMessage: {
        properties: {
            email: "errors:Invalid.email_",
            first_name: "errors:Invalid.first.name_",
            last_name: "errors:Invalid.last.name_",
            type: "errors:Invalid.type_",
            company: "errors:Invalid.company_"
        }
    }
};
const CreateContactModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialFormState = {
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        type: "",
        provider: "",
        phones: [{ phone: "", type: "" }]
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const { isSuccess: isSuccessIntegrations, isLoading: isLoadingIntegrations } = useGetIntegrationsQuery();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const integrations = useSelector(selectActiveContactsIntegrations);
    const show = useSelector(selectCreateContactModalIsOpen);
    const callId = useSelector(selectActiveCallId);
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    const start = page * perPage;
    const call = useSelector(state => selectCombinedConversationsById(state, { start, filters: searchFilters }, callId));
    const [updateRecording] = useUpdateCallMutation({ fixedCacheKey: "update-recording" });
    useEffect(() => {
        if (!isSuccessIntegrations) {
            return;
        }
        if (integrations.length > 0) {
            setInputs((inputs) => (Object.assign(Object.assign({}, inputs), { ['provider']: integrations[0].providerName })));
        }
    }, [isSuccessIntegrations]);
    useEffect(() => {
        setInputs((inputs) => (Object.assign(Object.assign({}, inputs), { phone: (call === null || call === void 0 ? void 0 : call.remotePhone) || "", type: '' })));
    }, [callId]);
    const handleInputChange = (e) => {
        setInputs(Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value }));
    };
    const handlePhoneChange = (index) => (e) => {
        e.persist();
        setInputs(update(inputs, { phones: { [index]: { phone: { $set: e.target.value } } } }));
    };
    const handlePhoneTypeChange = (index) => (e) => {
        e.persist();
        setInputs(update(inputs, { phones: { [index]: { type: { $set: e.target.value } } } }));
    };
    const handleHideModal = () => {
        var _a;
        setInputs(Object.assign(Object.assign({}, initialFormState), { provider: ((_a = integrations[0]) === null || _a === void 0 ? void 0 : _a.providerName) || "" }));
        setErrors({});
        dispatch(hideCreateContactModal());
    };
    const handleCreateContact = () => {
        setErrors({});
        const { hasErrors, errors } = validateSchema(ajv, schema, inputs, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        const contact = {
            fullName: `${inputs.first_name} ${inputs.last_name}`,
            firstName: inputs.first_name,
            lastName: inputs.last_name,
            email: inputs.email,
            company: inputs.company,
            provider: inputs.provider,
            phones: inputs.phones,
            type: inputs.type
        };
        const remote = contact.provider === PROVIDER_NAME_BANAFO ? {} : { provider: contact.provider, id: contact === null || contact === void 0 ? void 0 : contact.id };
        const data = {
            contact: Object.assign({ remote, displayName: contact.fullName }, contact)
        };
        updateRecording({ id: callId, data })
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    const handleAddPhoneRow = () => {
        setInputs(update(inputs, { phones: { $push: [{ phone: "", type: "" }] } }));
    };
    const handleRemovePhoneRow = (index) => () => {
        if (inputs.phones.length <= 1) {
            return;
        }
        setInputs(update(inputs, { phones: { $splice: [[index, 1]] } }));
    };
    const renderContactTypes = () => {
        if (!inputs.provider) {
            return null;
        }
        return integrations
            .find(integration => integration.providerName === inputs.provider)
            .contactTypes.map((contactType, index) => {
            return _jsx("option", { children: contactType }, index);
        });
    };
    const renderPhoneTypes = () => {
        if (!inputs.provider) {
            return null;
        }
        return integrations
            .find(integration => integration.providerName === inputs.provider)
            .phoneTypes.map((phoneType, index) => {
            return _jsx("option", { children: phoneType }, index);
        });
    };
    const renderModalBody = () => {
        if (integrations.length <= 0) {
            return (_jsxs(_Fragment, { children: [t("You.don/'t.have.integrations_"), ". ", " ", t("Create.an.integration_"), " ", _jsx(Link, { to: INTEGRATIONS_ROUTE, children: t("here_") }), "."] }));
        }
        return (_jsxs(_Fragment, { children: [_jsxs(Form.Group, { controlId: "provider", children: [_jsx(Form.Label, { children: t("Save.contact.to_") }), _jsx("div", { children: integrations.map(({ providerName }, index) => {
                                return (_jsxs(Form.Check, { inline: true, id: providerName, children: [_jsx(Form.Check.Input, { type: "radio", name: "provider", value: providerName, onChange: handleInputChange, checked: providerName === inputs.provider }), _jsx(Form.Check.Label, { children: providerName })] }, providerName));
                            }) })] }), _jsxs(Form.Group, { controlId: "formFirstName", children: [_jsx(Form.Label, { className: "required", children: t("First.name_") }), _jsx(Form.Control, { placeholder: t("First.name_"), name: "first_name", onChange: handleInputChange, value: inputs.first_name, isInvalid: !!errors.first_name }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.first_name })] }), _jsxs(Form.Group, { controlId: "formLastName", children: [_jsx(Form.Label, { className: "required", children: t("Last.name_") }), _jsx(Form.Control, { placeholder: t("Last.name_"), name: "last_name", onChange: handleInputChange, value: inputs.last_name, isInvalid: !!errors.last_name }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.last_name })] }), _jsxs(Form.Group, { controlId: "formEmail", children: [_jsx(Form.Label, { className: "required", children: t("Email_") }), _jsx(Form.Control, { placeholder: t("Email_"), name: "email", onChange: handleInputChange, value: inputs.email, isInvalid: !!errors.email }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.email })] }), _jsxs(Form.Group, { controlId: "company", children: [_jsx(Form.Label, { className: "required", children: t("Company_") }), _jsx(Form.Control, { placeholder: t("Company_"), name: "company", onChange: handleInputChange, value: inputs.company, isInvalid: !!errors.company }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.company })] }), _jsxs(Form.Group, { controlId: "type", children: [_jsx(Form.Label, { className: "required", children: t("Type_") }), _jsxs(Form.Select, { placeholder: t("Type_"), name: "type", onChange: handleInputChange, value: inputs.type, isInvalid: !!errors.type, children: [_jsx("option", { value: "", children: "Choose..." }), renderContactTypes()] }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.type })] }), _jsx(LineSeparator, { color: "400" }), inputs.phones.map((item, index, array) => {
                    return (_jsxs(Row, { className: "g-25", children: [_jsxs(Form.Group, { as: Col, controlId: `formPhone${index}`, children: [_jsx(Form.Label, { children: t("Phone_") }), _jsx(Form.Control, { placeholder: t("Phone_"), onChange: handlePhoneChange(index), value: item.phone, isInvalid: !!errors[`phones/${index}/phone`] }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors[`phones/${index}/phone`] })] }), _jsxs(Form.Group, { as: Col, controlId: `formPhoneType${index}`, children: [_jsx(Form.Label, { children: t("Type_") }), _jsxs(Form.Select, { onChange: handlePhoneTypeChange(index), value: item.type, isInvalid: !!errors[`phones/${index}/type`], children: [_jsx("option", { value: "", children: "Choose..." }), renderPhoneTypes()] }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors[`phones/${index}/type`] })] }), _jsxs(Form.Group, { as: Col, xs: "1", children: [_jsx(Form.Label, { className: "invisible", children: "-" }), _jsx("div", { className: "py-75 p-50", children: _jsx(BifIconTrash, { onClick: handleRemovePhoneRow(index), className: cx({ "text-muted": array.length === 1 }, { "cursor-pointer": array.length > 1 }) }) })] })] }, index));
                }), _jsx(LineSeparator, { color: "400" }), _jsxs(Form.Text, { className: "btn-link link-danger d-block cursor-pointer text-end", onClick: handleAddPhoneRow, children: ["+", t("Add.one.more_")] })] }));
    };
    const handleSwitchModals = (e) => {
        e.preventDefault();
        dispatch(switchContactModals());
    };
    const renderModalFooter = () => {
        if (integrations.length <= 0) {
            return null;
        }
        return (_jsx(Button, { variant: "danger", onClick: handleCreateContact, children: t("Create_") }));
    };
    const renderSwitchModal = () => {
        if (integrations.length <= 0) {
            return null;
        }
        return (_jsxs("div", { className: "mb-50", children: [t("Add.to.an_"), " ", " ", _jsx(Link, { onClick: handleSwitchModals, to: "", className: "link-danger", children: t("existing.contact_") }), "."] }));
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: renderModalFooter(), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t("Create.New.Contact_") }), renderSwitchModal(), renderModalBody()] }));
};
export default CreateContactModal;
