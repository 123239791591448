import { selectRecordingTranscriptsByFileId } from "./recordingTranscripts";
export const selectRecordingDetailsById = (state) => id => {
    return state.entities.recordingDetails.byId[id];
};
export const selectRecordingDetailsByCallId = (state) => callId => {
    return Object.values(state.entities.recordingDetails.byId).find(recording => recording.callId === callId);
};
export const selectRecordingDetailsStreamsById = (state) => id => {
    return state.entities.recordingDetails.byId[id]
        ? state.entities.recordingDetails.byId[id]
            .streams.map(stream => (Object.assign(Object.assign({}, stream), { transcripts: selectRecordingTranscriptsByFileId(state)(stream.fileId) })))
        : [];
};
