import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment/moment";
import { useDownloadInvoiceMutation } from "src/features/payments/paymentsSlice";
const PaymentsRow = (payment) => {
    const { id, createdAt, transactionNumber, paymentProvider, status, amount, invoiceNumber, invoiceEnLocation } = payment;
    const [downloadInvoice] = useDownloadInvoiceMutation({ fixedCacheKey: 'download-invoice' });
    const handleDownload = () => {
        downloadInvoice({ url: invoiceEnLocation, name: invoiceNumber });
    };
    return (_jsxs("tr", { children: [_jsx("td", { children: createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : '-' }), _jsx("td", { children: id }), _jsx("td", { children: transactionNumber }), _jsx("td", { children: paymentProvider }), _jsx("td", { children: status }), _jsx("td", { children: amount }), _jsx("td", { children: invoiceNumber && _jsx("a", { href: "#", onClick: handleDownload, children: invoiceNumber }) })] }));
};
export default PaymentsRow;
