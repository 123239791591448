import { combineReducers } from "redux";
import { FETCH_SETTINGS_BEGIN, FETCH_SETTINGS_FAILURE, FETCH_SETTINGS_SUCCESS, UPDATE_SETTINGS_BEGIN, UPDATE_SETTINGS_FAILURE, UPDATE_SETTINGS_SUCCESS } from "../../actions/settings";
function initialize(state = false, action) {
    switch (action.type) {
        case FETCH_SETTINGS_SUCCESS:
        case FETCH_SETTINGS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case FETCH_SETTINGS_BEGIN:
        case UPDATE_SETTINGS_BEGIN:
            return state + 1;
        case FETCH_SETTINGS_SUCCESS:
        case UPDATE_SETTINGS_SUCCESS:
            return state - 1;
        case FETCH_SETTINGS_FAILURE:
        case UPDATE_SETTINGS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading
});
