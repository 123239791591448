import { jsx as _jsx } from "react/jsx-runtime";
import { Image } from "banafo-design-system";
import processing from './images/processing.jpg';
import brokenAudio from './images/brokenAudio.jpg';
import limitReached from './images/limitReached.jpg';
import invalidVoice from './images/invalidVoice.jpg';
const ProcessingTranscripts = ({ status }) => {
    let imagePath;
    switch (status) {
        case 'LimitReached':
            imagePath = limitReached;
            break;
        case 'BrokenAudio':
            imagePath = brokenAudio;
            break;
        case 'InvalidVoice':
            imagePath = invalidVoice;
            break;
        case 'FileTooShort':
            imagePath = limitReached;
            break;
        default:
            imagePath = processing;
    }
    return (_jsx("div", { className: "d-flex my-200 justify-content-center", children: _jsx(Image, { fluid: true, src: imagePath, alt: status }) }));
};
export default ProcessingTranscripts;
