import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Col, Form, Row, BanafoInput } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { changeFilter, selectFilters } from "src/features/contacts/apiContactsSlice";
import { selectActiveContactsIntegrations, useGetIntegrationsQuery } from "src/features/integrations/integrationsSlice";
import { useDispatch, useSelector } from "react-redux";
const ContactsFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const searchFilters = useSelector(selectFilters);
    const integrations = useSelector(selectActiveContactsIntegrations);
    const { isSuccess: isSuccessIntegrations } = useGetIntegrationsQuery();
    useEffect(() => {
        handleFilterChange({ target: { name: 'provider', value: 'banafo' } });
    }, []);
    useEffect(() => {
        if (!isSuccessIntegrations) {
            return;
        }
        if (integrations.length > 0) {
            handleFilterChange({ target: { name: 'provider', value: integrations[0].providerName } });
            handleFilterChange({ target: { name: "types", value: [contactTypesOptions()[0]] } });
        }
    }, [isSuccessIntegrations]);
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        let filter = [];
        if (searchFilters.types.includes(value)) {
            filter = searchFilters.types.filter(item => !value.includes(item));
        }
        else {
            filter = [...searchFilters.types, value];
        }
        dispatch(changeFilter({ name, value }));
    };
    const contactTypesOptions = () => {
        if (!searchFilters.provider) {
            return [];
        }
        return integrations.find(integration => integration.providerName === searchFilters.provider).contactTypes;
    };
    return (_jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xs: 12, xl: 8, children: _jsxs(Row, { className: "gx-25", children: [_jsx(Col, { xs: 12, lg: 7, className: "mb-50 mb-lg-0", children: _jsx(BanafoInput, { name: "search", mode: "dark", value: searchFilters.search, placeholder: t('Search.contacts_'), onChange: handleFilterChange }) }), _jsx(Col, { xs: 12, lg: true, className: "mb-50 mb-lg-0", children: _jsx(Form.Select, { name: "provider", value: searchFilters.provider, onChange: handleFilterChange, children: integrations.map(({ providerName }, index) => {
                                return _jsx("option", { value: providerName, children: providerName }, index);
                            }) }) })] }) }) }));
};
export default ContactsFilters;
