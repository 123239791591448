import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { BifIconNoteFile, BifIconPin } from "banafo-design-system";
import DeleteNoteButton from "./utils/DeleteNoteButton";
import EditNoteButton from "./utils/EditNoteButton";
import "./NotesRow.scss";
import PreviewImageButton from "src/features/notes/utils/PreviewImageButton";
import { useDispatch, useSelector } from "react-redux";
import { openGallery, selectNotesImageUrl } from "src/features/notes/notesSlice";
import { useParams } from "react-router-dom";
import { formattedDuration } from "src/features/auth/util/dateTime";
const NotesRow = ({ selected, isActive, hasActiveRow, setActiveRow, onNoteClick, note, time, author, description, createdAt, handleDeleteButtonClick, handleEditButtonClick, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { callId } = useParams();
    const images = useSelector(state => selectNotesImageUrl(state, callId));
    const handleOpenGallery = () => {
        dispatch(openGallery(images.findIndex(image => image === note.attachmentUrl)));
    };
    const handleRowClick = (e) => {
        if (!e.altKey) {
            if (onNoteClick) {
                onNoteClick(note);
            }
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("tr", { className: cx('cursor-pointer', { "table-active": selected, "border-bottom-0": isActive, "backdrop": hasActiveRow && !isActive }), onClick: handleRowClick, children: [_jsx("td", { children: description.length ? _jsx(BifIconNoteFile, { variant: "dark" }) : _jsx(BifIconPin, { variant: "dark" }) }), _jsx("td", { children: time }), _jsx("td", { className: "content", children: description }), _jsx("td", { className: "text-end", children: (note === null || note === void 0 ? void 0 : note.attachmentType) === "image" ?
                        _jsxs(_Fragment, { children: [_jsx(PreviewImageButton, { handlePreviewImage: handleOpenGallery }), _jsx(DeleteNoteButton, { handleDeleteNote: handleDeleteButtonClick })] }) :
                        _jsxs(_Fragment, { children: [_jsx(EditNoteButton, { handleEditNote: handleEditButtonClick }), _jsx(DeleteNoteButton, { handleDeleteNote: handleDeleteButtonClick })] }) })] }) }));
};
NotesRow.propTypes = {
    selected: PropTypes.any,
    hasActiveRow: PropTypes.any,
    isActive: PropTypes.any,
    setActiveRow: PropTypes.any,
    author: PropTypes.any,
    description: PropTypes.any,
    note: PropTypes.any,
    onNoteClick: PropTypes.any,
    time: PropTypes.any,
    createdAt: PropTypes.any,
    handleDeleteButtonClick: PropTypes.func,
    handleEditButtonClick: PropTypes.func,
};
export default NotesRow;
