import { jsx as _jsx } from "react/jsx-runtime";
import ActivateApp from "src/features/deviceActivate/ActivateApp/ActivateApp";
import desktop1 from "../images/desktop01.png";
import desktop2 from "../images/desktop02.png";
import { useTranslation } from "react-i18next";
import { DOWNLOAD_ZOIPER_APP_URL } from "src/app/config";
import Zoiper5Card from "src/features/deviceActivate/AppCard/Zoiper5Card";
const Zoiper5App = () => {
    const { t } = useTranslation();
    return (_jsx(ActivateApp, { title: t('INTEGRATION.WITH.ZOIPER5_'), appCard: _jsx(Zoiper5Card, {}), downloadUrl: DOWNLOAD_ZOIPER_APP_URL, images: [desktop1, desktop2] }));
};
export default Zoiper5App;
