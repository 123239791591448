import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { BanafoModal, BifIconTrash, Button, LineSeparator } from "banafo-design-system";
import { hideDeleteModal, selectActiveCallId, selectDeleteModalIsOpen, useDeleteCallMutation } from "src/features/conversations/conversationsSlice";
const DeleteCallModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const show = useSelector(selectDeleteModalIsOpen);
    const callId = useSelector(selectActiveCallId);
    const [deleteCall] = useDeleteCallMutation({ fixedCacheKey: "delete-recording" });
    const handleHideModal = () => {
        dispatch(hideDeleteModal());
    };
    const handleDeleteCall = () => {
        deleteCall(callId)
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t('Delete.conversation_'), className: 'text-center', "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-400', children: t('Are.you.sure.you.want.to.delete.this.conversation.You.cannot.undo.this_') }), _jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(Button, { variant: 'outline-dark', className: 'text-uppercase mx-100', onClick: handleHideModal, children: t('Cancel_') }), _jsxs(Button, { variant: 'danger', className: 'text-uppercase mx-100 with-icon end', onClick: handleDeleteCall, children: [t('Delete_'), " ", _jsx(BifIconTrash, {})] })] })] }));
};
export default DeleteCallModal;
