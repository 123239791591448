var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import status from "http-status";
import i18n from "../../../../../app/services/i18n";
import { loadingAppFinish, loadingAppStart } from "../loader";
import { selectAuthKey } from "src/features/auth/store/selectors/auth";
import api from "../../../../../api/api";
import { setErrorMessage } from "../app";
import { get } from "src/features/auth/util/storage";
import { oidcLogout, getUser, completeLogout, signinRedirect } from "src/app/services/authService";
export const LOGIN_INTERACTION = 'LOGIN_INTERACTION';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_CREDENTIALS_SAVED = 'LOGIN_CREDENTIALS_SAVED';
export const LOGIN_WRONG_CREDENTIALS = 'LOGIN_WRONG_CREDENTIALS';
export const LOGIN_TOO_MANY_REQUESTS = 'LOGIN_TOO_MANY_REQUESTS';
export const LOGIN_RESET_TOO_MANY_REQUESTS = 'LOGIN_RESET_TOO_MANY_REQUESTS';
export const LOGIN_INACTIVE_ACCOUNT = 'LOGIN_INACTIVE_ACCOUNT';
export const LOGIN_INPUT_CHANGED = 'LOGIN_INPUT_CHANGED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_OAUTH_SUCCESS = 'LOGIN_OAUTH_SUCCESS';
export const LOGIN_REDIRECT_URL_SET = 'LOGIN_REDIRECT_URL_SET';
export const loginInteraction = interaction => ({ type: LOGIN_INTERACTION, interaction });
export const userLogged = user => ({ type: LOGIN_SUCCESS, user });
export const credentialsSaved = user => ({ type: LOGIN_CREDENTIALS_SAVED, user });
export const wrongLoginCredentials = err => ({ type: LOGIN_WRONG_CREDENTIALS, err });
export const tooManyRequests = err => ({ type: LOGIN_TOO_MANY_REQUESTS });
export const resetTooManyRequests = err => ({ type: LOGIN_RESET_TOO_MANY_REQUESTS });
export const inactiveAccount = err => ({ type: LOGIN_INACTIVE_ACCOUNT, err });
export const loginInputChanged = () => ({ type: LOGIN_INPUT_CHANGED });
const logout = () => ({ type: LOGOUT });
export const oauthLoginSuccess = () => ({ type: LOGIN_OAUTH_SUCCESS });
export const setRedirectUrl = (url) => ({
    type: LOGIN_REDIRECT_URL_SET,
    payload: { url }
});
export const logoutUser = () => {
    return (dispatch, getState) => {
        oidcLogout();
    };
};
export const oidcLogoutUser = () => {
    return (dispatch, getState) => {
        return completeLogout()
            .then(() => dispatch(logout()));
    };
};
export const interactionLoginDetails = (uid) => {
    return (dispatch, getState) => {
        return api.interactionDetails(uid)
            .then(res => {
            dispatch(loginInteraction(res));
        });
    };
};
export const interactionLogin = (uid, data) => {
    return (dispatch, getState) => {
        return api.interactionLogin(uid, data)
            .catch(err => {
            if (err.status === status.UNAUTHORIZED) {
                dispatch(wrongLoginCredentials(err));
            }
            if (err.status === status.FORBIDDEN) {
                dispatch(inactiveAccount(err));
            }
            return Promise.reject(err);
        });
    };
};
const login = data => (dispatch, getState) => {
    const keyLoading = selectAuthKey(getState());
    dispatch(loadingAppStart(keyLoading));
    return api.login(data)
        .then((res) => __awaiter(void 0, void 0, void 0, function* () {
        const user = yield getUser();
        if (user) {
            window.location = '';
            return;
        }
        if (!user) {
            signinRedirect();
        }
    }))
        .catch(err => {
        var _a, _b, _c, _d;
        dispatch(resetTooManyRequests());
        if (err.status === status.UNAUTHORIZED) {
            dispatch(wrongLoginCredentials(err));
        }
        else if (err.status === status.TOO_MANY_REQUESTS) {
            dispatch(wrongLoginCredentials(err));
            dispatch(tooManyRequests(err));
        }
        else if (err.status === status.FORBIDDEN) {
            if (err.response.body.name === 'AccountPendingApprovalError') {
                window.location = '/auth/login/pending-approval';
            }
            dispatch(inactiveAccount(err));
        }
        else {
            dispatch(setErrorMessage((_d = (_c = (_b = (_a = err.response.body.detail) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.response.body.message) !== null && _d !== void 0 ? _d : i18n.t("errors:general_")));
        }
        return Promise.reject(err);
    })
        .finally(() => dispatch(loadingAppFinish(keyLoading)));
};
export const loginAuthorizationCode = code => login({
    code,
    grant_type: 'authorization_code'
});
export const loginThirdParty = (provider, code, discountCode) => login({
    code,
    provider,
    discountCode,
    grant_type: 'third_party',
    registeredAs: get('registeredAs', '')
});
export const loginPassword = (username, password, reCaptchaKey) => login({
    username,
    password,
    reCaptchaKey,
    grant_type: 'password'
});
export const loginRemote = () => {
    return (dispatch, getState) => {
        const keyLoading = selectAuthKey(getState());
        dispatch(loadingAppStart(keyLoading));
        return api.getProfileUser()
            .catch(err => {
            var _a, _b, _c, _d;
            dispatch(setErrorMessage((_d = (_c = (_b = (_a = err.response.body.detail) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.response.body.message) !== null && _d !== void 0 ? _d : i18n.t("errors:general_")));
            return Promise.reject(err);
        })
            .then(res => dispatch(userLogged(res)))
            .finally(() => dispatch(loadingAppFinish(keyLoading)));
    };
};
