import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Anchor, Flex, TextInput } from "@mantine/core";
import { useAnalysesQuestionMutation, useAnalysesTypeMutation } from "src/features/aiAssistant/aiAssistantApi";
import { selectConversationStreams } from "src/features/conversations/conversationsSlice";
import { getHotkeyHandler } from "@mantine/hooks";
import "./AiAssistantContainer.scss";
import AnalysesChatList from "src/features/aiAssistant/AnalysesChatList";
import { TbSend } from "react-icons/tb";
import { SpinnerBackdrop } from "banafo-design-system";
import { activeAiAssistantFeatures } from "src/features/aiAssistant/constants";
const AiAssistantContainer = () => {
    const { callId } = useParams();
    const { t } = useTranslation();
    const [text, setText] = useState("");
    // @ts-ignore
    const fileIdsArray = useSelector((state) => selectConversationStreams(state, callId)).map(stream => stream.fileId);
    const [analysesType, { isLoading: isUpdatingAnalysesType }] = useAnalysesTypeMutation();
    const [analysesQuestion, { isLoading: isUpdatingAnalysesQuestion }] = useAnalysesQuestionMutation();
    const handleSubmit = () => {
        if (text.length <= 0) {
            return;
        }
        analysesQuestion({ fileIds: fileIdsArray, question: text });
        setText("");
    };
    const handleTypeClick = (type) => (e) => {
        analysesType({ fileIds: fileIdsArray, type });
    };
    return (_jsxs(_Fragment, { children: [(isUpdatingAnalysesType || isUpdatingAnalysesQuestion) && _jsx(SpinnerBackdrop, {}), _jsx(AnalysesChatList, {}), _jsxs("div", { className: "ai-analyses-footer", children: [_jsx(Flex, { wrap: "wrap", columnGap: "md", mb: "sm", children: activeAiAssistantFeatures.map(({ id, type }) => _jsx(Anchor, { id: id, fw: 500, component: Link, to: "#", onClick: handleTypeClick(type), children: t(`infos:assistant.${type}_`) })) }), _jsx(TextInput, { placeholder: `${t("Was.slang.used_")}?`, rightSection: _jsx(TbSend, {}), value: text, onChange: (event) => setText(event.target.value), onKeyDown: getHotkeyHandler([
                            ["Enter", handleSubmit]
                        ]) })] })] }));
};
export default AiAssistantContainer;
