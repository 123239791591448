import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { BifIconPlus, Button, Stack } from "banafo-design-system";
import DropdownMenu from "src/features/conversations/DropdownMenu";
import { useTranslation } from "react-i18next";
import { selectCombinedConversationsResult, selectFilters, selectPage, showUploadModal } from "src/features/conversations/conversationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CONVERSATIONS_PAGINATION_LIMIT } from "src/features/conversations/config";
const TableFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    const start = page * perPage;
    const { data: { total } } = useSelector((state) => selectCombinedConversationsResult(state, { start, filters: searchFilters }));
    const handleShowUploadModal = () => {
        dispatch(showUploadModal());
    };
    return (_jsxs(Stack, { direction: 'horizontal', children: [_jsxs("h6", { className: 'text-uppercase fw-bold m-0', children: [t('Conversations_'), " (", total, ")"] }), _jsx(DropdownMenu, {}), _jsxs(Button, { variant: 'danger', size: 'sm', className: 'ms-auto text-uppercase with-icon start', onClick: handleShowUploadModal, children: [_jsx(BifIconPlus, {}), " ", t('Upload_')] })] }));
};
export default TableFilters;
