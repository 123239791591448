import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { reset, selectGallery, selectNotesImageUrl } from "src/features/notes/notesSlice";
import ImageViewer from "react-simple-image-viewer";
const HighlightsGallery = () => {
    const dispatch = useDispatch();
    const { callId } = useParams();
    const images = useSelector(state => selectNotesImageUrl(state, callId));
    const { isOpen: isViewerOpen, activeIndex: currentImage } = useSelector(selectGallery);
    const closeImageViewer = () => {
        dispatch(reset());
    };
    return (_jsx("div", { children: isViewerOpen && (_jsx(ImageViewer, { src: images, currentIndex: currentImage, onClose: closeImageViewer, disableScroll: false, backgroundStyle: {
                backgroundColor: "rgba(0,0,0,0.4)",
                zIndex: 1600
            }, closeOnClickOutside: true })) }));
};
export default HighlightsGallery;
