import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
const useGetDeviceCode = () => {
    const [searchParams] = useSearchParams();
    const { code: userCode } = useParams();
    const [code, setCode] = useState();
    useEffect(() => {
        let userCode = searchParams.get('user_code');
        if (userCode == null) {
            return;
        }
        setCode(userCode);
    }, [searchParams]);
    useEffect(() => {
        if (userCode == null) {
            return;
        }
        setCode(userCode);
    }, [userCode]);
    return code;
};
export default useGetDeviceCode;
