import { combineReducers } from "redux";
import { FETCH_CALL_DETAILS_BEGIN, FETCH_CALL_DETAILS_FAILURE, FETCH_CALL_DETAILS_SUCCESS } from "src/features/auth/store/actions/callsHistoryDetails";
function initialize(state = false, action) {
    switch (action.type) {
        case FETCH_CALL_DETAILS_SUCCESS:
        case FETCH_CALL_DETAILS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case FETCH_CALL_DETAILS_BEGIN:
            return state + 1;
        case FETCH_CALL_DETAILS_SUCCESS:
            return state - 1;
        case FETCH_CALL_DETAILS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading
});
