import i18n from "i18next";
export const CHAT_USER_POSITION_LEFT = "left";
export const CHAT_USER_POSITION_RIGHT = "right";
export const CHAT_USER_POSITION_LIST = [
    CHAT_USER_POSITION_LEFT,
    CHAT_USER_POSITION_RIGHT
];
export const CHAT_THEME_COLOR_LIST = ["danger", "success", "warning", "primary", "secondary", "dark", "light", "info"];
export const TRANSCRIPT_PLAIN_FORMAT = 'text/plain';
export const TRANSCRIPT_JSON_FORMAT = 'application/json';
export const TRANSCRIPT_SRT_FORMAT = 'application/x-subrip';
export const TRANSCRIPT_DOWNLOAD_FORMATS_ENUM = [
    { value: TRANSCRIPT_PLAIN_FORMAT, get text() { return i18n.t('Transcript.Plain.text_'); } },
    { value: TRANSCRIPT_JSON_FORMAT, get text() { return i18n.t('Transcript.JSON_'); } },
    { value: TRANSCRIPT_SRT_FORMAT, get text() { return i18n.t('Transcript.Captions.(SRT)_'); } },
];
