import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ResellerCustomerPlanTypes, ResellerCustomerStatusTypes } from "src/features/resellerCustomers/types";
import "./ResellerCustomerRow.scss";
import { ActionIcon, Group } from "@mantine/core";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import { useInviteResellerCustomerMutation } from "src/features/resellerCustomers/resellerCustomersApi";
import { ShoppingCart, Settings, SendHorizontal } from 'lucide-react';
import { useTranslation } from "react-i18next";
import { showPurchaseModal } from "src/features/resellerCustomers/resellerCustomersSlice";
import { useAppDispatch } from "src/app/store/hooks";
import { generatePath, useNavigate } from "react-router-dom";
import { RESELLER_CUSTOMERS_SETTINGS_ROUTE } from "src/app/router/config";
const ResellerCustomersRow = ({ resellerCustomer }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inviteResellerCustomer, { isLoading, reset }] = useInviteResellerCustomerMutation();
    const { id, email, status, plan } = resellerCustomer;
    const handleSettings = () => {
        navigate(generatePath(RESELLER_CUSTOMERS_SETTINGS_ROUTE, { resellerCustomerId: id }));
    };
    const handlePurchase = () => {
        dispatch(showPurchaseModal(id));
    };
    const handleResend = (e) => {
        inviteResellerCustomer({ email });
    };
    const handleDelete = (e) => {
        // e.stopPropagation();
        // dispatch(showDeleteModal(id));
    };
    const renderActions = () => {
        return (_jsxs(Group, { justify: "center", gap: "md", children: [status === ResellerCustomerStatusTypes.Accepted && plan !== ResellerCustomerPlanTypes.Free &&
                    _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "settings", children: t('Settings_') }), children: _jsx(ActionIcon, { variant: "transparent", "aria-label": "Settings", onClick: handleSettings, children: _jsx(Settings, { size: 20 }) }) }), status === ResellerCustomerStatusTypes.Accepted && (plan === ResellerCustomerPlanTypes.Free || plan === ResellerCustomerPlanTypes.DemoPaid) &&
                    _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "shop", children: t('Shop') }), children: _jsx(ActionIcon, { variant: "transparent", "aria-label": "Shop", onClick: handlePurchase, children: _jsx(ShoppingCart, { size: 20 }) }) }), status === ResellerCustomerStatusTypes.Pending &&
                    _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "resend", children: t('Resend') }), children: _jsx(ActionIcon, { variant: "transparent", "aria-label": "Resend", onClick: handleResend, loading: isLoading, children: _jsx(SendHorizontal, { size: 20 }) }) })] }));
    };
    return (_jsxs("tr", { className: "cursor-pointer", children: [_jsx("td", { className: "text-start", children: resellerCustomer.name }), _jsx("td", { className: "text-start", children: resellerCustomer.email }), _jsx("td", { className: "text-start capitalize-first", children: resellerCustomer.status }), _jsx("td", { className: "text-start text-uppercase", children: resellerCustomer.plan }), _jsx("td", { className: "text-start", children: resellerCustomer.startedAt }), _jsx("td", { className: "text-start", children: renderActions() })] }));
};
export default ResellerCustomersRow;
