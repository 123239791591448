import { Button, createTheme, Input, Popover, Combobox, Select, Menu, rem, } from "@mantine/core";
import comboboxClasses from './Combobox.module.css';
import popoverClasses from './Popover.module.css';
import selectClasses from './Select.module.css';
import inputClasses from './Input.module.css';
import menuClasses from './Menu.module.css';
import buttonClasses from './Button.module.css';
import variantColorResolver from './variantColorResolver';
const theme = createTheme({
    focusRing: 'never',
    scale: 1,
    primaryColor: "dark",
    colors: {
        //customized
        red: ['#ffeee8', '#f8ddd6', '#f1bbad', '#ea9983', '#e3775a', '#dc5531', '#b04427', '#84331d', '#582214', '#2c110a'],
        blue: ['#e5f4ff', '#cfe2ff', '#9ec5fe', '#6ea8fe', '#3d8bfd', '#0d6efd', '#0a58ca', '#084298', '#052c65', '#031633'],
        green: ['#eefcf5', '#d1e7dd', '#a3cfbb', '#75b798', '#479f76', '#198754', '#146c43', '#0f5132', '#0a3622', '#051b11'],
        yellow: ['#fffae0', '#fef4d4', '#fce9a9', '#fbde7f', '#f9d354', '#f8c829', '#c6a021', '#957819', '#635010', '#322808'],
        orange: ['#fff2e1', '#ffe5d0', '#fecba1', '#feb272', '#fd9843', '#fd7e14', '#ca6510', '#984c0c', '#653208', '#331904'],
        indigo: ['#f5eaff', '#e0cffc', '#c29ffa', '#a370f7', '#8540f5', '#6610f2', '#520dc2', '#3d0a91', '#290661', '#140330'],
        cyan: ['#e0feff', '#cff4fc', '#9eeaf9', '#6edff6', '#3dd5f3', '#0dcaf0', '#0aa2c0', '#087990', '#055160', '#032830'],
        teal: ['#f5fce7', '#e9f3d6', '#d3e7ad', '#bedb85', '#a8cf5c', '#92c333', '#759c29', '#58751f', '#3a4e14', '#1d270a'],
        pink: ['#ffebf8', '#f7d6e6', '#efadce', '#e685b5', '#de5c9d', '#d63384', '#ab296a', '#801f4f', '#561435', '#2b0a1a'],
        violet: ['#f4efff', '#e2d9f3', '#c5b3e6', '#a98eda', '#8c68cd', '#6f42c1', '#59359a', '#432874', '#2c1a4d', '#160d27'],
        gray: ["#fdfcff", "#f8f9fa", "#f3f6f9", "#f0f0f0", "#eceef0", "#eaeaea", "#e5e4e3", "#d6d6d6", "#c2c2c2", "#b8b8b8"],
        dark: ["#bfbfbf", "#7b7c8e", "#6c757d", "#676873", "#545663", "#41424c", "#26262c", "#1c1d21", "#131316", "#090a0b"],
        //default
        lime: ["#f4fce3", "#e9fac8", "#d8f5a2", "#c0eb75", "#a9e34b", "#94d82d", "#82c91e", "#74b816", "#66a80f", "#5c940d"],
        grape: ["#f8f0fc", "#f3d9fa", "#eebefa", "#e599f7", "#da77f2", "#cc5de8", "#be4bdb", "#ae3ec9", "#9c36b5", "#862e9c"],
    },
    primaryShade: { light: 5, dark: 7 },
    variantColorResolver,
    defaultRadius: "sm",
    radius: {
        xs: "calc(0.8rem * var(--mantine-scale))",
        sm: "calc(1rem * var(--mantine-scale))",
        md: "calc(1.2rem * var(--mantine-scale))",
        lg: "calc(1.6rem * var(--mantine-scale))",
        xl: "calc(2rem * var(--mantine-scale))",
    },
    breakpoints: {
        xs: '36em',
        sm: '48em',
        md: '62em',
        lg: '92em',
        xl: '125em', // 2000px
    },
    spacing: {
        xxs: rem(8),
        xs: rem(10),
        sm: rem(12),
        md: rem(16),
        lg: rem(20),
        xl: rem(24),
        xxl: rem(32), // 2rem
    },
    fontSizes: {
        xs: "calc(0.750rem * var(--mantine-scale))",
        sm: "calc(0.875rem * var(--mantine-scale))",
        md: "calc(1.000rem * var(--mantine-scale))",
        lg: "calc(1.125rem * var(--mantine-scale))",
        xl: "calc(1.250rem * var(--mantine-scale))",
    },
    headings: {
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
        fontWeight: "500",
        sizes: {
            h1: { fontSize: 'calc(2.5rem * var(--mantine-scale))', lineHeight: '1.3' },
            h2: { fontSize: 'calc(2rem * var(--mantine-scale))', lineHeight: '1.35' },
            h3: { fontSize: 'calc(1.75rem * var(--mantine-scale))', lineHeight: '1.4' },
            h4: { fontSize: 'calc(1.5rem * var(--mantine-scale))', lineHeight: '1.45' },
            h5: { fontSize: 'calc(1.25rem * var(--mantine-scale))', lineHeight: '1.5' },
            h6: { fontSize: 'calc(1rem * var(--mantine-scale))', lineHeight: '1.5' },
        }
    },
    activeClassName: '',
    components: {
        Button: Button.extend({
            defaultProps: {
                size: "md",
            },
            classNames: {
                root: buttonClasses.root
            },
        }),
        Input: Input.extend({
            defaultProps: {
                size: "md",
            },
            classNames: Object.assign({}, inputClasses),
        }),
        TextInput: {
            defaultProps: {
                size: "md",
            },
        },
        Combobox: Combobox.extend({
            defaultProps: {
                size: "md",
            },
            classNames: Object.assign({}, comboboxClasses),
        }),
        Popover: Popover.extend({
            classNames: Object.assign({}, popoverClasses),
        }),
        Select: Select.extend({
            defaultProps: {
                size: "md",
            },
            classNames: Object.assign({}, selectClasses),
        }),
        Menu: Menu.extend({
            classNames: {
                label: menuClasses.label,
                item: menuClasses.item,
            }
        }),
        // Anchor: Anchor.extend({
        // 	defaultProps: {
        // 		c: 'cyan',
        // 	},
        // 	// @ts-ignore
        // 	styles: (theme, props) => {
        // 		return {
        // 			root: {
        // 				'&:hover': {
        // 					color: darken('rgb(0, 0, 0)', 0.5),
        // 				}
        // 			}
        // 		}
        // 	},
        //
        // 	// @ts-ignore
        // 	vars: (theme, props) => {
        // 		return {
        // 			root: {
        // 				'--mantine-color-anchor': props.c,
        // 			}
        // 		}
        // 	},
        // }),
    },
});
export default theme;
