import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoImageModal, BifIconArrowLeft, BifIconArrowRight, Button, LineSeparator, Stack } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { isChrome, isEdge, isOpera } from "react-device-detect";
import BrowserExtensions from "src/features/auth/component-old/partials/sections/browserExtensions/BrowserExtensions";
import BrowserExtensionButton from "src/features/auth/component-old/partials/sections/browserExtensions/BrowserExtensionButton";
import { EXTENSION_CHROME_URL, EXTENSION_EDGE_URL } from "src/features/auth/config/extension";
import chromeColor from "images/browser/google-chrome-color.png";
import edgeColor from "images/browser/microsoft-edge-color.png";
import WizardModalFooter from "src/features/wizard/WizardModalFooter";
import './ExtensionModal.scss';
const ExtensionModal = ({ show, setStep }) => {
    const { t } = useTranslation();
    const isSupportedBrowser = isChrome || isEdge;
    const getDetectBrowser = () => {
        if (isChrome) {
            return { name: 'Chrome', url: EXTENSION_CHROME_URL, img: chromeColor };
        }
        // if(isOpera){
        // 	return {name: 'Opera', url: EXTENSION_OPERA_URL};
        // }
        if (isEdge) {
            return { name: 'Edge', url: EXTENSION_EDGE_URL, img: edgeColor };
        }
        return null;
    };
    return (_jsxs(BanafoImageModal, { show: show, backdrop: "static", title: `${t("infos:wizard.step.two.title_")}!`, className: "wizard-browser-extension", footer: _jsx(WizardModalFooter, { activeStep: 2 }), children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: "mb-100 fw-bold", children: t('Install.the.browser.extension.to.start.recording_') }), _jsx("div", { className: "d-inline-flex align-items-center justify-content-between mb-150", children: !isSupportedBrowser ? _jsx(BrowserExtensions, { className: 'flex-nowrap' }) : _jsx(BrowserExtensionButton, Object.assign({ className: 'smaller' }, getDetectBrowser())) }), _jsxs(Stack, { direction: "horizontal", className: "mt-200", gap: 100, children: [_jsxs(Button, { variant: "outline-dark", className: "text-pre with-icon start flex-fill text-uppercase", onClick: () => setStep(1), children: [_jsx(BifIconArrowLeft, {}), " ", t('Prev_')] }), _jsxs(Button, { variant: "dark", className: "text-pre with-icon end flex-fill text-uppercase", onClick: () => setStep(3), children: [t("Next_"), " ", _jsx(BifIconArrowRight, {})] })] })] }));
};
export default ExtensionModal;
