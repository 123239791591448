import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch } from "react-redux";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import * as Sentry from "@sentry/react";
import { BifIconInformationCircle, BifIconPencil, BifIconPhoneArrowIn, BifIconPhoneArrowOut, BifIconSoundwave, BifIconTrash, BifIconUserMinus, BifIconUserPlus, OverlayTrigger, Stack, Tooltip } from "banafo-design-system";
import { CONVERSATION_DIRECTION_INBOUND, CONVERSATION_TYPE_OTHER, CONVERSATION_TYPE_PHONE_CALL, CONVERSATION_TYPE_WEB_CONFERENCE } from "src/features/auth/config/conversations";
import { formattedDateTime, formattedDuration } from "src/features/auth/util/dateTime";
import { getConversationTitle } from "src/features/auth/util/common";
import { showCreateContactModal, showDeleteModal, showEditModal, useUpdateCallUnmapContactMutation } from "src/features/conversations/conversationsSlice";
import CallDetailsPopover from "src/features/conversations/CallDetailsPopover";
import { CONVERSATIONS_BY_CALL_ID_ROUTE } from "src/app/router/config";
const CallsRow = ({ call }) => {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateRecording] = useUpdateCallUnmapContactMutation({ fixedCacheKey: "update-recording" });
    const renderContactName = () => {
        // if (call?.contact?._id) {
        // 	return (
        // 		<>
        // 			<span className={"align-top me-25"}>
        // 				{call.contact.displayName}
        // 			</span>
        // 			{call?.contact?.remote?.provider && <span onClick={(e) => {e.stopPropagation();}} className={"align-top"}>
        // 				<OverlayTrigger
        // 					trigger={'click'}
        // 					placement={"bottom"}
        // 					rootClose
        // 					rootCloseEvent={'mousedown'}
        // 					delay={{show: 250, hide: 0}}
        // 					call={call}
        // 					overlay={<CallDetailsPopover call={call} onClick={(e) => {e.stopPropagation();}} />}
        // 				>
        // 					<BifIconInformationCircle />
        // 				</OverlayTrigger>
        // 			</span>}
        // 		</>
        // 	);
        // }
        var _a;
        return (_jsx("span", { children: call.remotePhone || ((_a = call === null || call === void 0 ? void 0 : call.contact) === null || _a === void 0 ? void 0 : _a.displayName) || t('Unknown_') }));
    };
    const handleRowClick = () => {
        const activeTab = !call.recordings.length ? 1 : 0;
        navigate(`/conversations/${call.id}`, { state: { activeTab } });
    };
    const handleDeleteCall = (id) => (e) => {
        e.stopPropagation();
        dispatch(showDeleteModal(id));
    };
    const handleEditCall = (id) => (e) => {
        e.stopPropagation();
        dispatch(showEditModal(id));
    };
    const handleAddContact = (id) => (e) => {
        e.stopPropagation();
        dispatch(showCreateContactModal(id));
    };
    const handleContactUnmap = (id) => (e) => {
        e.stopPropagation();
        const data = {
            contact: null
        };
        updateRecording({ id: call.id, data })
            .unwrap()
            .catch(e => null);
    };
    const renderActions = () => {
        var _a, _b;
        return (_jsxs(Stack, { direction: 'horizontal', className: 'justify-content-evenly', children: [(!((_a = call === null || call === void 0 ? void 0 : call.contact) === null || _a === void 0 ? void 0 : _a._id))
                    && _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "add-contact", children: t('Add.contact_') }), children: _jsx("span", { children: _jsx(BifIconUserPlus, { className: 'cursor-pointer text-dark', onClick: handleAddContact(call.id) }) }) }), ((_b = call === null || call === void 0 ? void 0 : call.contact) === null || _b === void 0 ? void 0 : _b._id)
                    && _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "unmap-contact", children: t('Unmap.contact_') }), children: _jsx("span", { children: _jsx(BifIconUserMinus, { className: 'cursor-pointer text-dark', onClick: handleContactUnmap(call.id) }) }) }), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "edit-conversation", children: t('Edit.conversation_') }), children: _jsx("span", { children: _jsx(BifIconPencil, { className: 'cursor-pointer text-dark mx-25', onClick: handleEditCall(call.id) }) }) }), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "delete-conversation", children: t('Delete.conversation_') }), children: _jsx("span", { children: _jsx(BifIconTrash, { className: 'cursor-pointer text-dark', onClick: handleDeleteCall(call.id) }) }) })] }));
    };
    const renderType = () => {
        if (call.type === CONVERSATION_TYPE_PHONE_CALL) {
            return (_jsxs(_Fragment, { children: [call.direction === CONVERSATION_DIRECTION_INBOUND ? (_jsx(BifIconPhoneArrowIn, { variant: 'primary' })) : (_jsx(BifIconPhoneArrowOut, { variant: 'primary' })), ' ', t('Phone.call_')] }));
        }
        if (call.type === CONVERSATION_TYPE_WEB_CONFERENCE) {
            return (_jsx(_Fragment, { children: t('Web.conference_') }));
        }
        if (call.type === CONVERSATION_TYPE_OTHER) {
            return (_jsx(_Fragment, { children: t('Other_') }));
        }
        return null;
    };
    return (_jsxs("tr", { className: "cursor-pointer", onClick: handleRowClick, children: [_jsx("td", { children: !!call.recordings.length && _jsx(BifIconSoundwave, { className: 'fs-6' }) }), _jsx("td", { className: cx({ "cursor-pointer": !!((_a = call === null || call === void 0 ? void 0 : call.contact) === null || _a === void 0 ? void 0 : _a._id) }), children: _jsx(Link, { to: generatePath(CONVERSATIONS_BY_CALL_ID_ROUTE, { callId: call.id }), rel: "noreferrer", className: "link-dark", onClick: e => e.stopPropagation(), children: getConversationTitle(call) }) }), _jsx("td", { children: renderType() }), _jsx("td", { children: call.startedAt ? formattedDateTime(call.startedAt) : '-' }), _jsx("td", { className: cx({ 'cursor-pointer': !!((_b = call === null || call === void 0 ? void 0 : call.contact) === null || _b === void 0 ? void 0 : _b._id) }), children: renderContactName() }), _jsx("td", { children: call.duration ? formattedDuration(call.duration, 'HH:mm:ss') : '-' }), _jsx("td", { children: renderActions() })] }));
};
export default CallsRow;
