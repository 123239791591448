import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/app/store/hooks";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { Button } from "@mantine/core";
const ResellerButton = () => {
    const { t } = useTranslation();
    const { reseller } = useAppSelector(selectAuthUser);
    return (_jsx(Button, { component: "a", href: `mailto:${reseller.email}`, tt: "uppercase", color: "teal", children: t('Contact.Reseller_') }));
};
export default ResellerButton;
