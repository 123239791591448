import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
export const billingCountryAdapter = createEntityAdapter({
    selectId: (billingCountry) => billingCountry.iso,
});
const initialState = billingCountryAdapter.getInitialState();
export const localizedBillingCountrySelectors = billingCountryAdapter.getSelectors();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getPaymentCountries: builder.query({
            query: () => "/limiter/countries",
            transformResponse: ({ list: billingCountries = [], currentCountry }) => {
                return billingCountryAdapter.setAll(Object.assign(Object.assign({}, initialState), { currentCountry }), billingCountries);
            },
        }),
    })
});
export const { useGetPaymentCountriesQuery, } = extendedApi;
//SELECTORS
export const selectBillingCountriesResult = extendedApi.endpoints.getPaymentCountries.select();
export const selectBillingCountriesData = createSelector((state) => selectBillingCountriesResult(state), (billingCountriesResult) => { var _a; return (_a = billingCountriesResult === null || billingCountriesResult === void 0 ? void 0 : billingCountriesResult.data) !== null && _a !== void 0 ? _a : initialState; });
export const selectAllBillingCountries = createSelector((state) => selectBillingCountriesData(state), (billingCountriesData) => {
    return localizedBillingCountrySelectors.selectAll(billingCountriesData);
});
export const selectBillingCountry = createSelector((state) => selectBillingCountriesData(state), (state, id) => id, (billingCountriesData, id) => {
    return localizedBillingCountrySelectors.selectById(billingCountriesData, id);
});
export const selectComboboxBillingCountries = createSelector(selectAllBillingCountries, (allBillingCountries) => {
    return allBillingCountries.map(({ iso, name }) => ({ value: iso, label: name }));
});
