import { jsx as _jsx } from "react/jsx-runtime";
import './Underline.scss';
import PropTypes from "prop-types";
import classNames from "classnames";
const propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'notice', 'dark', 'light'
    ]).isRequired,
    type: PropTypes.oneOf(['wavy', 'solid']).isRequired,
};
function Underline({ className, type, color, }) {
    const classes = classNames(className, type && `underline-${type}`, color && `text-${color}`);
    return _jsx("span", { className: classes, children: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" });
}
Underline.propTypes = propTypes;
export default Underline;
