import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCallDetailsById } from "src/features/auth/store/selectors/historyCallDetails";
import { getConversationTitle } from "src/features/auth/util/common";
const AudioTitle = () => {
    const { callId } = useParams();
    const callDetails = useSelector((state) => selectCallDetailsById(state)(callId));
    if (!callDetails) {
        return;
    }
    return (_jsx("h6", { className: "m-0 text-truncate", children: getConversationTitle(callDetails) }));
};
export default AudioTitle;
