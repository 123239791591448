import { combineReducers } from "redux";
import { SEARCH_RECORDING_TRANSCRIPTS_BEGIN, SEARCH_RECORDING_TRANSCRIPTS_FAILURE, SEARCH_RECORDING_TRANSCRIPTS_SUCCESS } from "../../actions/transcripts";
function initialize(state = false, action) {
    switch (action.type) {
        case SEARCH_RECORDING_TRANSCRIPTS_SUCCESS:
        case SEARCH_RECORDING_TRANSCRIPTS_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case SEARCH_RECORDING_TRANSCRIPTS_BEGIN:
            return state + 1;
        case SEARCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return state - 1;
        case SEARCH_RECORDING_TRANSCRIPTS_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading
});
