import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Col } from "banafo-design-system";
import FeaturesCard from "src/features/account/FeaturesCard";
import BrowserExtensionsSection from "src/features/auth/component-old/partials/sections/browserExtensions/BrowserExtensionsSection";
import GooglePlayBadge from "src/features/account/GooglePlayBadge";
const FeedsSection = ({ className }) => {
    return (_jsxs("div", { className: classNames('feeds-section d-flex flex-column', className), children: [_jsxs(Col, { xs: 12, sm: 6, lg: 12, xl: 10, className: "mx-auto text-center my-400 my-lg-200", children: [_jsx(BrowserExtensionsSection, {}), _jsx(GooglePlayBadge, {})] }), _jsx(FeaturesCard, { className: "mt-auto" })] }));
};
export default FeedsSection;
