var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Table } from "banafo-design-system";
import NotesRow from "./NotesRow";
import { pauseAudio, seekAudio } from "src/features/auth/store/actions/playback";
import { selectRecordingDetailsByCallId } from "src/features/auth/store/selectors/recordingDetails";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { NOTE_TYPE_SIMPLE, NOTE_TYPE_TIME } from "src/features/auth/config/notes";
import DeleteNoteModal from "src/features/notes/modals/DeleteNoteModal";
import { MODE_ADD, MODE_EDIT, MODE_VIEW } from "src/features/notes/config";
import { formattedDuration } from "src/features/auth/util/dateTime";
const NotesTable = ({ callId, notes, recording, userFullName, mode, setSavedActiveNote, activeNote, setSavedSyncWithPlaythrough, setMode, setDescriptionInput, setNoteTypeInput, setNoteId, setErrors }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activeRow, setActiveRow] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [deleteNoteModalShow, setDeleteNoteModalShow] = useState(false);
    const handleSetActiveRow = (id) => {
        setActiveRow(activeRow === id ? null : id);
    };
    const handleNoteClick = useCallback((note) => {
        let notePosition = null;
        if (recording) {
            const { startedAt: recordingStart, endedAt: recordingEnd, duration: recordingDuration } = recording;
            notePosition = (note.cuePoint - recordingStart) / recordingDuration;
        }
        if (mode === MODE_VIEW) {
            setSavedActiveNote(note);
            setSavedSyncWithPlaythrough(false);
            if (recording) {
                dispatch(seekAudio(notePosition));
            }
        }
    }, [mode]);
    const handleHideDeleteNoteModal = () => {
        setActiveId(null);
        setDeleteNoteModalShow(false);
    };
    const handleDeleteButtonClick = (id) => (e) => {
        e.stopPropagation();
        setActiveId(id);
        setDeleteNoteModalShow(true);
    };
    const handleEditButtonClick = (_a) => {
        var { id, cuePoint, description } = _a, note = __rest(_a, ["id", "cuePoint", "description"]);
        return (e) => {
            e.stopPropagation();
            recording && dispatch(pauseAudio());
            setMode(MODE_EDIT);
            let notePosition = null;
            if (recording) {
                const { startedAt: recordingStart, endedAt: recordingEnd, duration: recordingDuration } = recording;
                notePosition = (cuePoint - recordingStart) / recordingDuration;
                setSavedSyncWithPlaythrough(false);
                dispatch(seekAudio(notePosition));
            }
            setNoteId(id);
            setNoteTypeInput(cuePoint === null ? NOTE_TYPE_SIMPLE : NOTE_TYPE_TIME);
            setDescriptionInput(description);
            setErrors({});
        };
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { responsive: true, hover: true, children: [_jsx("thead", { children: _jsxs("tr", { className: "d-none", children: [_jsx("td", {}), _jsx("td", { children: t('Time_') }), _jsx("td", { children: t('Content_') }), _jsx("td", { className: "text-center", children: t('Actions_') })] }) }), _jsxs("tbody", { className: "notes-table", children: [notes.map((note, index) => {
                                let time = '-';
                                let createdAt = '-';
                                if (recording) {
                                    const { startedAt: recordingStartedAt } = recording;
                                    const cuePoint = note.cuePoint === null ? null : note.cuePoint;
                                    time = cuePoint === null ? null : formattedDuration(cuePoint - recordingStartedAt, 'HH:mm:ss');
                                    createdAt = moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss');
                                }
                                return _jsx(NotesRow, { selected: note === activeNote, hasActiveRow: !!activeRow, isActive: activeRow === note.id, setActiveRow: handleSetActiveRow, author: note.authorName, description: note.description, note: note, onNoteClick: handleNoteClick, time: time, createdAt: createdAt, handleDeleteButtonClick: handleDeleteButtonClick(note.id), handleEditButtonClick: handleEditButtonClick(note) }, note.id);
                            }), mode === MODE_ADD && _jsx(NotesRow, { active: true, author: userFullName, description: t('New.note_'), time: "-" })] })] }), _jsx(DeleteNoteModal, { show: deleteNoteModalShow, onHide: handleHideDeleteNoteModal, id: activeId })] }));
};
function mapStateToProps(state, ownProps) {
    return {
        userFullName: selectAuthUser(state).fullName,
        recording: selectRecordingDetailsByCallId(state)(ownProps.callId),
    };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NotesTable);
NotesTable.propTypes = {
    activeNote: PropTypes.any,
    callId: PropTypes.any,
    mode: PropTypes.any,
    notes: PropTypes.any,
    recording: PropTypes.any,
    savedSyncWithPlaythrough: PropTypes.any,
    setSavedSyncWithPlaythrough: PropTypes.any,
    setSavedActiveNote: PropTypes.any,
    userFullName: PropTypes.any,
    setMode: PropTypes.func,
    setDescriptionInput: PropTypes.func,
    setNoteTypeInput: PropTypes.func,
    setNoteId: PropTypes.func,
    setErrors: PropTypes.func,
};
