import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Dropdown } from "banafo-design-system";
import { useParams } from "react-router-dom";
import { selectRecordingDetailsByCallId, selectRecordingDetailsStreamsById } from "src/features/auth/store/selectors/recordingDetails";
import VolumeControl from "src/features/conversations/audioPlayer/VolumeControl";
const AudioVolumeAction = () => {
    const { callId } = useParams();
    const recording = useSelector((state) => selectRecordingDetailsByCallId(state)(callId));
    const streams = useSelector((state) => selectRecordingDetailsStreamsById(state)(recording === null || recording === void 0 ? void 0 : recording.id));
    return (_jsx(_Fragment, { children: streams.map((stream, source) => (_jsx(Dropdown.ItemText, { children: _jsx(VolumeControl, { source: source }) }, source))) }));
};
export default AudioVolumeAction;
