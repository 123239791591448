import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BanafoToast } from "banafo-design-system";
import banafoIcon from "images/logo/banafo-icon.png";
import { isFulfilled } from "src/features/toasts/utils";
import { toastClosed, toastDeleted, toastOpened } from "./toastsSlice";
import { useTranslation } from "react-i18next";
const ToastMessage = ({ toast }) => {
    const { id, isOpen, message } = toast;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(toastOpened({ id }));
    }, []);
    const handleClose = () => {
        dispatch(toastClosed({ id }));
    };
    const handleTransitionEnd = (e) => {
        if (isOpen) {
            return;
        }
        dispatch(toastDeleted(id));
    };
    return (_jsx(BanafoToast, { show: isOpen, onClose: handleClose, icon: banafoIcon, title: t('Banafo_'), message: message, type: isFulfilled(toast) ? "success" : "failure", autohide: true, onTransitionEnd: handleTransitionEnd }));
};
export default ToastMessage;
