import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Ajv from "ajv";
import * as Sentry from "@sentry/react";
import { Button, Modal, Container, Row, Col, Form, Avatar } from "banafo-design-system";
import validateSchema from "src/features/auth/util/validateSchema";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { selectProfilePictureLoading } from "src/features/auth/store/selectors/profile";
import { credentialsSaved, userLogged } from "src/features/auth/store/actions/auth/login";
import { selectProfileModalIsOpen, hideProfileModal, useUploadProfilePictureMutation, useUpdateProfileDataMutation } from "src/features/sidebar/sidebarSlice";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, useDefaults: 'empty' });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    "properties": {
        "email": {
            default: null,
            type: ['string', 'null'],
            format: "email",
            "minLength": 3,
            "maxLength": 32
        },
        "full_name": {
            default: null,
            type: ['string', 'null'],
            "minLength": 3,
            "maxLength": 32
        },
    },
    anyOf: [
        {
            "required": ["email"],
            "properties": {
                "email": {
                    "not": {
                        "type": ["null"]
                    }
                },
            },
        },
        {
            "required": ["full_name"],
            "properties": {
                "full_name": {
                    "not": {
                        "type": ["null"]
                    }
                },
            },
        }
    ],
    errorMessage: {
        properties: {
            email: 'errors:Invalid.email_',
            full_name: 'errors:Invalid.full.name_',
        }
    },
};
const ProfileModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [updateProfileData, { isLoading: isUpdatingProfileData }] = useUpdateProfileDataMutation({ fixedCacheKey: "update-profile-data" });
    const [uploadProfilePicture, { isLoading: isUploadingProfilePicture, isSuccess }] = useUploadProfilePictureMutation({ fixedCacheKey: "upload-profile-picture" });
    const handleCloseModal = () => {
        dispatch(hideProfileModal());
        setInputs(initialFormState);
        setErrors({});
    };
    const isOpen = useSelector(selectProfileModalIsOpen);
    const user = useSelector((state) => selectAuthUser(state));
    const isProfilePictureLoading = useSelector((state) => selectProfilePictureLoading(state));
    const initialFormState = {
        email: user.email,
        full_name: user.fullName,
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const inputFileRef = useRef(null);
    const handleInputChange = (e) => {
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const handleChangeSettings = () => {
        setErrors({});
        const { hasErrors, errors } = validateSchema(ajv, schema, inputs, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        const userData = { fullName: inputs.full_name, email: inputs.email };
        updateProfileData(userData)
            .unwrap()
            .then(() => {
            dispatch(credentialsSaved(Object.assign(Object.assign({}, user), userData)));
            handleCloseModal();
        })
            .catch(e => null);
    };
    const handleBtnClick = () => {
        inputFileRef.current.click();
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file || file.type !== 'image/jpeg') {
            return;
        }
        uploadProfilePicture({ userId: user._id, file })
            .unwrap()
            .then(() => handleCloseModal())
            .catch(e => null);
    };
    const onShow = () => {
        setInputs(initialFormState);
    };
    return (_jsxs(Modal, { show: isOpen, onHide: handleCloseModal, onShow: onShow, "aria-labelledby": "profile-modal", centered: true, children: [_jsx(Modal.Header, { closeButton: true, className: "border-0" }), _jsxs(Modal.Body, { className: 'basic text-center', children: [_jsx("h4", { children: t('Profile.settings_') }), _jsx(Container, { children: _jsxs(Row, { children: [_jsx(Col, { xs: 9, children: _jsxs(Form, { className: "text-start", children: [_jsxs(Form.Group, { controlId: "formFullName", children: [_jsx(Form.Label, { children: t('Full.name_') }), _jsx(Form.Control, { placeholder: t('Full.name_'), name: "full_name", onChange: handleInputChange, value: inputs.full_name, isInvalid: !!errors.full_name, className: 'mb-100' }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.full_name })] }), _jsxs(Form.Group, { controlId: "formEmail", children: [_jsx(Form.Label, { children: t('Email_') }), _jsx(Form.Control, { placeholder: t('Email_'), name: "email", onChange: handleInputChange, value: inputs.email, isInvalid: !!errors.email, className: 'TeamsAndMembers-filter' }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.email })] })] }) }), _jsxs(Col, { xs: 3, className: 'text-center', children: [t('Profile_'), _jsx(Avatar, { src: `/api/v1/media/profile-photos/${user._id}.jpg`, alt: 'profile-picture', size: 87, shadow: 'md', border: 1 }), _jsx(Button, { variant: "secondary", size: "sm", disabled: isProfilePictureLoading, onClick: !isProfilePictureLoading ? handleBtnClick : null, className: "mt-100", children: t('Upload_') }), _jsx("input", { type: "file", accept: "image/jpeg", ref: inputFileRef, onChange: (e) => handleFileChange(e), className: 'd-none' })] })] }) })] }), _jsx(Modal.Footer, { className: "border-0", children: _jsx(Button, { variant: "primary", className: "text-white", onClick: handleChangeSettings, children: t('Save_') }) })] }));
};
export default ProfileModal;
