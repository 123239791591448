import client, { apiClient } from "src/app/services/httpClient";
export const changeProfilePassword = (data) => {
    return apiClient
        .post(`/profile/password`)
        .send(data);
};
export const updateProfileData = (data) => {
    return apiClient
        .put(`/profile`)
        .send(data);
};
export const uploadProfilePicture = (userId, formData) => {
    return apiClient
        .set('Content-Type', null)
        .put(`/media/profile-photos/${userId}.jpg`)
        .send(formData);
};
export const getProfileUser = () => {
    return apiClient
        .get(`/profile`);
};
export const updateProfileWizard = (showWizard) => {
    return apiClient
        .put(`/profile/wizard`)
        .send({ showWizard });
};
export const cancelPayment = () => {
    return apiClient
        .put(`/profile/payment/cancel`)
        .send({});
};
export const finishPayment = () => {
    return apiClient
        .put(`/profile/payment/finished`)
        .send({});
};
