import { jsx as _jsx } from "react/jsx-runtime";
import { BanafoBreadcrumb } from "banafo-design-system";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { pageRoutes } from "./config";
import { HOME_ROUTE } from "src/app/router/config";
const PageNavBreadcrumbs = ({ mode = 'default' }) => {
    const breadcrumbs = useBreadcrumbs(pageRoutes, {
        disableDefaults: false,
        excludePaths: [HOME_ROUTE]
    });
    return _jsx(BanafoBreadcrumb, { mode: mode, breadcrumbs: breadcrumbs });
};
export default PageNavBreadcrumbs;
