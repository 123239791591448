import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccountUpgradeModalTypes } from "src/features/account/types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/app/store/hooks";
import { selectConnectingProvider } from "src/features/integrations/integrationsSlice";
import * as Sentry from "@sentry/react";
import TextBody from "src/features/account/modals/accountUpgrade/TextBody";
import ImageBody from "src/features/account/modals/accountUpgrade/ImageBody";
import capitalize from "src/helpers/capitalize";
import { List, Text } from "@mantine/core";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
const AccountUpgradeContent = ({ contentType }) => {
    const { t } = useTranslation();
    const { reseller } = useAppSelector(selectAuthUser);
    const { name } = useAppSelector(selectConnectingProvider);
    if (contentType === AccountUpgradeModalTypes.General) {
        return _jsx(TextBody, { title: t('Account.upgrade_'), content: t('infos:Upgrade.to.pro_', { feature: "this" }) });
    }
    if (contentType === AccountUpgradeModalTypes.Reseller) {
        return (_jsx(TextBody, { title: t('Account.upgrade_'), content: t('infos:Upgrade.to.pro.reseller_'), children: _jsxs(List, { listStyleType: "none", children: [_jsxs(List.Item, { children: [t('Contact.information_'), ":"] }), _jsx(List.Item, { children: _jsxs(List, { withPadding: true, listStyleType: "disc", children: [_jsxs(List.Item, { children: [t('Name_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.name] }), _jsxs(List.Item, { children: [t('Email_'), ": ", _jsx(Text, { span: true, fw: "bold", children: reseller === null || reseller === void 0 ? void 0 : reseller.email })] }), _jsxs(List.Item, { children: [t('Address_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.address] }), _jsxs(List.Item, { children: [t('Website_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.website] })] }) })] }) }));
    }
    if (contentType === AccountUpgradeModalTypes.GoogleDrive) {
        return _jsx(TextBody, { title: t('Account.upgrade_'), content: t('infos:Upgrade.to.pro_', { feature: "GoogleDrive" }) });
    }
    if (contentType === AccountUpgradeModalTypes.Integrations) {
        let providerLogo;
        try {
            providerLogo = require(`../../../integrations/images/provider-logo-${name}.png`);
        }
        catch (e) {
            Sentry.captureException(e);
        }
        return _jsx(ImageBody, { image: providerLogo, alt: "integration-logo", subTitle: t('Pro.feature.only_'), content: t('infos:Provider.integration.upgrade.text_', { providerName: capitalize(name) }) });
    }
    contentType;
};
export default AccountUpgradeContent;
