import { combineReducers } from '@reduxjs/toolkit';
import { createMigrate, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import apiApp from "src/app/services/apiApp";
import entitiesReducer from "../../features/auth/store/reducers/entities/entitiesReducer";
import appReducer from "../../features/auth/store/reducers/app/appReducer";
import sidebarSlice, { actions as sidebarSliceActions, name as sidebarSliceName } from "src/features/sidebar/sidebarSlice";
import toastMessagesSlice, { actions as toastMessagesSliceActions, name as toastMessagesSliceName } from "src/features/toasts/toastsSlice";
import layoutSlice, { actions as layoutSliceActions, name as layoutSliceName } from "src/features/layout/pageLayout/layoutSlice";
import integrationsSlice, { actions as integrationsSliceActions, name as integrationsSliceName } from "src/features/integrations/integrationsSlice";
import browserExtensionSlice, { actions as browserExtensionSliceActions, name as browserExtensionSliceName } from "src/features/browserExtension/browserExtensionSlice";
import conversationsSlice, { actions as conversationsSliceActions, name as conversationsSliceName } from "src/features/conversations/conversationsSlice";
import activitiesSlice, { actions as activitiesSliceActions, name as activitiesSliceName } from "src/features/activityLog/activityLogSlice";
import transcriptsSlice, { actions as transcriptsSliceActions, name as transcriptsSliceName } from "src/features/transcripts/transcriptsSlice";
import notesSlice, { actions as notesSliceActions, name as notesSliceName } from "src/features/notes/notesSlice";
import paymentsSlice, { name as paymentsSliceName } from "src/features/payments/paymentsSlice";
import apiContactsSlice, { actions as contactsSliceActions, name as contactsSliceName } from "src/features/contacts/apiContactsSlice";
import conversationSlice, { actions as conversationActions, name as conversationSliceName } from "src/features/conversation/conversationSlice";
import newsSlice, { name as newsSliceName } from "src/features/news/newsSlice";
import accountSlice, { name as accountSliceName } from "src/features/account/accountSlice";
import resellerCustomersSlice, { name as resellerCustomersSliceName } from "src/features/resellerCustomers/resellerCustomersSlice";
import resellerBillingHistorySlice, { name as resellerBillingHistorySliceName } from "src/features/resellerBillingHistory/resellerBillingHistorySlice";
const migrations = {
    // @ts-ignore
    1: (state) => {
        // migration clear out device state
        return Object.assign(Object.assign({}, state), { toasts: undefined });
    },
};
const migrate = createMigrate(migrations);
export const actions = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, sidebarSliceActions), toastMessagesSliceActions), layoutSliceActions), integrationsSliceActions), browserExtensionSliceActions), conversationsSliceActions), activitiesSliceActions), transcriptsSliceActions), notesSliceActions), contactsSliceActions), conversationActions);
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [
        apiApp.reducerPath,
        sidebarSliceName,
        layoutSliceName,
        toastMessagesSliceName, integrationsSliceName,
        browserExtensionSliceName,
        conversationsSliceName,
        contactsSliceName,
        activitiesSliceName,
        newsSliceName,
        accountSliceName,
        paymentsSliceName,
        transcriptsSliceName,
        notesSliceName,
        conversationSliceName,
        resellerCustomersSliceName,
        resellerBillingHistorySliceName,
        "entities",
        "app",
    ],
    migrate,
};
const rootReducer = combineReducers({
    entities: entitiesReducer,
    app: appReducer,
    [apiApp.reducerPath]: apiApp.reducer,
    [sidebarSliceName]: sidebarSlice,
    [layoutSliceName]: layoutSlice,
    [toastMessagesSliceName]: toastMessagesSlice,
    [integrationsSliceName]: integrationsSlice,
    [browserExtensionSliceName]: browserExtensionSlice,
    [paymentsSliceName]: paymentsSlice,
    [conversationsSliceName]: conversationsSlice,
    [contactsSliceName]: apiContactsSlice,
    [activitiesSliceName]: activitiesSlice,
    [transcriptsSliceName]: transcriptsSlice,
    [notesSliceName]: notesSlice,
    [newsSliceName]: newsSlice,
    [accountSliceName]: accountSlice,
    [conversationSliceName]: conversationSlice,
    [resellerCustomersSliceName]: resellerCustomersSlice,
    [resellerBillingHistorySliceName]: resellerBillingHistorySlice,
});
const rootReducerPersisted = persistReducer(persistConfig, rootReducer);
export default rootReducerPersisted;
