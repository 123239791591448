import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BanafoImageModal, BifIconArrowRight, Button, Form, LineSeparator } from "banafo-design-system";
import { startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { fetchSettings, loadLanguageEnum } from "src/features/auth/store/actions/settings";
import LanguageQualityMessage from "src/common/components/ui/LanguageQualityMessage";
import WizardModalFooter from "src/features/wizard/WizardModalFooter";
import { useGetApiKeysSettingsQuery, useUpdateApiKeysSettingsMutation } from "src/features/apiKeys/apiKeysSlice";
import "./LanguagesModal.scss";
const LanguagesModal = ({ show, setStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [languages, setLanguages] = useState([]);
    const [inputLanguage, setInputLanguage] = useState('');
    const [infoLanguage, setInfoLanguage] = useState('');
    const { data: { transcripts } = { transcripts: {} }, isLoading } = useGetApiKeysSettingsQuery();
    const [updateSettings, { isLoading: isUpdating }] = useUpdateApiKeysSettingsMutation();
    useEffect(() => {
        dispatch(startAppLoader());
        Promise.all([dispatch(fetchSettings()), dispatch(loadLanguageEnum())])
            .then(([settings, languages]) => {
            setInputLanguage(settings.transcripts.language);
            setLanguages(languages);
        })
            .finally(() => {
            stopAppLoader();
        });
    }, []);
    const renderLanguageTypes = () => {
        if (!languages) {
            return null;
        }
        return languages.map((language, index) => _jsx("option", { value: language.code, children: language.name }, index));
    };
    const handleTranscriptLanguageChange = (e) => {
        setInputLanguage(e.target.value);
        setInfoLanguage(e.target.value);
    };
    const handleLanguageChange = () => {
        updateSettings({ transcripts: Object.assign(Object.assign({}, transcripts), { language: inputLanguage }) });
    };
    return (_jsxs(BanafoImageModal, { show: show, backdrop: "static", title: `${t("Yello_")}!`, className: "wizard-language-modal", footer: _jsx(WizardModalFooter, { activeStep: 1 }), children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: "mb-100 fw-bold", children: t('Choose.a.default.language.for.your.transcripts_') }), _jsxs("div", { className: "d-block d-md-flex align-items-center justify-content-center mb-150", children: [_jsx(LanguageQualityMessage, { language: infoLanguage }), _jsx(Form.Select, { value: inputLanguage, onChange: handleTranscriptLanguageChange, className: "d-inline-block d-md-block w-auto me-100", children: renderLanguageTypes() }), _jsx(Button, { variant: 'light', onClick: handleLanguageChange, children: "Change" })] }), _jsxs(Button, { variant: 'dark', className: 'mt-200 text-pre with-icon start text-uppercase col-6', onClick: () => setStep(2), children: [t('Next_'), " ", _jsx(BifIconArrowRight, {})] })] }));
};
export default LanguagesModal;
