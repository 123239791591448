import { apiClient } from "src/app/services/httpClient";
export const getSettings = () => {
    return apiClient
        .get(`/settings`);
};
export const setSettings = (data) => {
    return apiClient
        .put(`/settings`)
        .send(data);
};
export const getSettingsLanguages = () => {
    return apiClient
        .get(`/settings/transcript-languages`);
};
export const getRecordingExtensionSettings = () => {
    return apiClient
        .get(`/settings/recording-extension`);
};
export const setSettingsRecordingExtension = (data) => {
    return apiClient
        .put(`/settings/recording-extension`)
        .send(data);
};
export const getNewsList = (data) => {
    return apiClient
        .get(`/news`)
        .query(data);
};
