var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { BifIconChevronThinRight, Button, OverlayTrigger, Popover, Tooltip } from "banafo-design-system";
import { useLazyGetContactUrlQuery } from "src/features/contacts/apiContactsSlice";
import './CallDetailsPopover.scss';
const CallDetailsPopover = forwardRef((_a, ref) => {
    var { call } = _a, props = __rest(_a, ["call"]);
    const { t } = useTranslation();
    const [loadContactUrl] = useLazyGetContactUrlQuery();
    const renderPhones = () => {
        if (!call.contact || call.contact.phones.length <= 0) {
            return null;
        }
        return (_jsx("div", { className: "contacts", children: call.contact.phones.map((phone, index) => {
                return (_jsx("div", { className: "contact px-50 py-25 d-flex", children: _jsxs("div", { children: [phone.phone, " (", phone.type, ")"] }) }, index));
            }) }));
    };
    const renderLogo = () => {
        var _a, _b;
        if (!((_a = call.contact.remote) === null || _a === void 0 ? void 0 : _a.provider)) {
            return null;
        }
        return (_jsx("div", { className: "me-auto", children: _jsx("img", { className: "provider-logo", src: require(`images/other/provider-logo-${(_b = call.contact.remote) === null || _b === void 0 ? void 0 : _b.provider}.png`), alt: "provider" }) }));
    };
    const handleOpenContact = () => {
        var _a, _b;
        loadContactUrl({ provider: call.contact.provider || ((_a = call.contact.remote) === null || _a === void 0 ? void 0 : _a.provider), contactId: call.contact._id || ((_b = call.contact.remote) === null || _b === void 0 ? void 0 : _b.id), type: call.contact.type })
            .unwrap()
            .then(({ contactUrl }) => {
            window.open(contactUrl, '_blank');
        })
            .catch(e => null);
    };
    return (_jsx(Popover, Object.assign({ ref: ref }, props, { onClick: (e) => { e.stopPropagation(); }, children: _jsxs(Popover.Body, { children: [_jsxs("div", { className: "header-contact-info", children: [_jsx("div", { className: "d-flex", children: _jsx("h5", { children: call.contact.displayName }) }), _jsx("div", { className: "d-flex", children: call.contact.company }), call.contact.type && _jsx("div", { className: "d-flex py-25", children: call.contact.type })] }), call.contact._id && call.contact.email && _jsx("div", { className: "d-flex py-50", children: _jsx("span", { children: _jsx("u", { children: call.contact.email }) }) }), renderPhones(), _jsxs("div", { className: "d-flex flex-row justify-content-between", children: [_jsx("div", { className: "d-flex py-50", children: renderLogo() }), call.contact._id && _jsx("div", { className: "d-flex py-50", children: _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "open-contact", children: t('Open.contact_') }), children: _jsx(Button, { size: "sm", onClick: handleOpenContact, children: _jsx(BifIconChevronThinRight, {}) }) }) })] })] }) })));
});
export default CallDetailsPopover;
