import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row, SpinnerBackdrop } from "banafo-design-system";
import { selectAllWhitelistWebsites, useAddWhitelistWebsiteMutation, useGetWhitelistWebsitesQuery, useRemoveWhitelistWebsiteMutation } from "./browserExtensionSlice";
import WhitelistWebsitesTable from "./WhitelistWebsitesTable";
const WhitelistWebsites = () => {
    const { t } = useTranslation();
    const [website, setWebsite] = useState('');
    const { isLoading } = useGetWhitelistWebsitesQuery();
    const whitelistWebsites = useSelector(selectAllWhitelistWebsites);
    const [addWhitelistWebsite, { isLoading: isAddingWhitelistWebsite }] = useAddWhitelistWebsiteMutation();
    const [, { isLoading: isRemovingWhitelistWebsite }] = useRemoveWhitelistWebsiteMutation({ fixedCacheKey: 'remove-whitelist-website' });
    const handleSiteChange = (e) => {
        setWebsite(e.target.value);
    };
    const handleAddToWhitelist = () => {
        addWhitelistWebsite({ title: '', website });
        setWebsite('');
    };
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs(_Fragment, { children: [(isAddingWhitelistWebsite || isRemovingWhitelistWebsite) && _jsx(SpinnerBackdrop, {}), _jsxs("div", { className: "my-200 mx-50", children: [_jsx("h5", { className: "text-uppercase", children: t('Whitelisted.sites_') }), _jsx(Row, { className: "mb-150 g-0", children: _jsx(Col, { className: "d-flex justify-content-between align-items-start", children: _jsxs("div", { children: [t('Enter.the.site.you.want.Banafo.controls.to.appear_'), " ", _jsx("b", { children: "Example: https://*.example.com/*/document/*?mode=edit" })] }) }) }), _jsxs("div", { className: "d-flex", children: [_jsx(Form.Control, { placeholder: t('Enter.website_'), type: "text", value: website, name: "website", onChange: handleSiteChange, className: "w-auto d-flex flex-grow-1" }), _jsx(Button, { variant: "dark", onClick: handleAddToWhitelist, className: "ms-100", children: t('Add_') })] }), _jsx(WhitelistWebsitesTable, { whitelistWebsites: whitelistWebsites })] })] }));
};
export default WhitelistWebsites;
