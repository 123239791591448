import { apiClient } from "src/app/services/httpClient";
export const getMembers = (data) => {
    return apiClient
        .get(`/teams/members`)
        .query(data);
};
export const inviteMember = (data) => {
    return apiClient
        .post(`/teams/invites`)
        .send(data);
};
export const searchMembers = (keyword) => {
    return apiClient
        .get(`/teams/members/search`)
        .query({ q: keyword });
};
export const removeMember = (userId) => {
    return apiClient
        .delete(`/teams/members/${userId}`);
};
