import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, LineSeparator } from "banafo-design-system";
import Integrations from "src/features/integrations/Integrations";
import FiltersForm from "src/features/integrations/FiltersForm";
import Title from "./Title";
import Header from "./Header";
const IntegrationsPage = () => {
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: [_jsx(Title, {}), _jsx(LineSeparator, { color: '400', size: "2" }), _jsx(FiltersForm, {}), _jsx(Integrations, {})] }));
};
export default IntegrationsPage;
