import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BanafoImageModal, Button, LineSeparator } from "banafo-design-system";
import WizardModalFooter from "src/features/wizard/WizardModalFooter";
import { startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { hideAndSaveProfileWizard } from "src/features/auth/store/actions/profile";
import { GOOGLE_PLAY_URL } from "src/app/config";
import googlePlayImg from "src/features/account/images/google-play-badge.png";
import "./MobileAppModal.scss";
const MobileAppModal = ({ show, setStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleFinish = (e) => {
        e.preventDefault();
        dispatch(startAppLoader());
        dispatch(hideAndSaveProfileWizard())
            .finally(() => {
            dispatch(stopAppLoader());
        });
    };
    return (_jsxs(BanafoImageModal, { show: show, backdrop: "static", closeButton: false, title: `${t('Meet.the.Banafo.App.for.Android_')}!`, className: "wizard-mobile-app", footer: _jsx(WizardModalFooter, { activeStep: 3 }), children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: "mb-200 fw-bold", children: t('Meet.the.Banafo.App.for.Android_') }), _jsx("a", { className: "d-flex justify-content-center mb-100", href: GOOGLE_PLAY_URL, target: "_blank", rel: "noreferrer", children: _jsx("img", { src: googlePlayImg, height: "50", alt: 'google-play' }) }), _jsx(Button, { variant: 'dark', className: 'mt-200 text-uppercase col-6 d-inline-flex justify-content-center align-items-center', onClick: handleFinish, children: t('Done_') })] }));
};
export default MobileAppModal;
