import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import cx from "classnames";
const ContactsRow = ({ contact, }) => {
    const { t } = useTranslation();
    const renderContactName = () => {
        return (contact === null || contact === void 0 ? void 0 : contact.fullName) ? contact.fullName : contact === null || contact === void 0 ? void 0 : contact.displayName;
    };
    return (_jsx(_Fragment, { children: _jsxs("tr", { children: [_jsx("td", { children: contact === null || contact === void 0 ? void 0 : contact.type }), _jsx("td", { children: _jsx("div", { className: cx("d-inline-block", { "cursor-pointer": !!(contact === null || contact === void 0 ? void 0 : contact.contactId) }), children: renderContactName() }) }), _jsx("td", { className: "d-none d-md-table-cell", children: contact === null || contact === void 0 ? void 0 : contact.company }), _jsx("td", { className: "d-none d-md-table-cell", children: contact === null || contact === void 0 ? void 0 : contact.email.join(" ") })] }) }));
};
export default ContactsRow;
