import { store } from "src/app/store/store";
import { sendDashboardAvailableMessage } from "src/features/browserExtension/utils/extensionMessages";
import { browserExtensionAvailable } from "src/features/browserExtension/browserExtensionSlice";
import { EXTENSION_AVAILABLE } from "src/features/browserExtension/config";
sendDashboardAvailableMessage();
/*
 * Add an "onmessage" event listener and send a message to notify that
 * the dashboard is ready to listen for messages from the browser extension
 */
window.onmessage = (e) => {
    // Ignore messages from non-Banafo iframes
    if (e.origin !== window.origin)
        return;
    // Ignore messages that are not from the browser extension or the dashboard itself
    if (typeof e.data !== 'object' || e.data === null || typeof e.data.type !== 'string')
        return;
    const msg = e.data;
    switch (msg.type) {
        case EXTENSION_AVAILABLE:
            store.dispatch(browserExtensionAvailable());
            break;
    }
};
