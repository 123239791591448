// Transforms server-formatted errors into local format that is more convenient to use on frontend
export function transformValidationErrors(errors) {
    // Validation errors come in the form of an array from the server,
    // which is not really convenient for frontend use
    if (!(errors instanceof Array))
        return errors;
    // https://www.samanthaming.com/tidbits/43-3-ways-to-remove-array-duplicates
    const keys = [...new Set(errors.map(err => err.field))];
    const values = keys.map(key => errors.filter(err => err.field === key));
    return keys.reduce((o, key, index) => Object.assign(o, { [key]: values[index] }), {});
}
