import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { selectAllNotes } from "src/features/notes/notesSlice";
import { selectRecordingDetailsById } from "src/features/auth/store/selectors/recordingDetails";
import { seekAudio } from "src/features/auth/store/actions/playback";
import PropTypes from "prop-types";
import { BifIconNoteFile, BifIconPin } from "banafo-design-system";
const AudioPlayerMarkers = ({ recording, seekAudio, recordingId, showNotes, showPoi }) => {
    const { callId } = useParams();
    const notes = useSelector(state => selectAllNotes(state, callId));
    if (!recording) {
        return null;
    }
    const { startedAt, endedAt, duration } = recording;
    const renderNotes = () => {
        return notes.map(({ id, cuePoint, description }, index) => {
            if (!cuePoint)
                return null;
            if (cuePoint < startedAt || cuePoint > endedAt) {
                return null;
            }
            if (description !== '' && !showNotes)
                return null;
            let markerType = 'note';
            if (description === '' && cuePoint !== null) {
                markerType = 'poi';
                if (!showPoi)
                    return null;
            }
            const notePosition = (cuePoint - startedAt) / duration;
            return (_jsx(Fragment, { children: markerType === 'note' ?
                    _jsx(BifIconNoteFile, { onClick: () => {
                            seekAudio(notePosition);
                        }, style: {
                            left: notePosition * 100 + '%'
                        }, className: "position-absolute translate-middle" }) :
                    _jsx(BifIconPin, { onClick: () => {
                            seekAudio(notePosition);
                        }, style: {
                            left: notePosition * 100 + '%'
                        }, className: "position-absolute translate-middle" }) }, id));
        });
    };
    return (_jsx("div", { className: "markers-track", children: renderNotes() }));
};
function mapStateToProps(state, ownProps) {
    return {
        recording: selectRecordingDetailsById(state)(ownProps.recordingId),
    };
}
const mapDispatchToProps = {
    seekAudio
};
export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayerMarkers);
AudioPlayerMarkers.propTypes = {
    recording: PropTypes.any,
    recordingId: PropTypes.any,
    seekAudio: PropTypes.any,
    showNotes: PropTypes.any,
    showPoi: PropTypes.any
};
