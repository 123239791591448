var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import apiApp from "src/app/services/apiApp";
import { sendExtensionSettingsMessage } from "src/features/browserExtension/utils/extensionMessages";
const whitelistWebsitesAdapter = createEntityAdapter();
const initialState = whitelistWebsitesAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getWhitelistWebsites: builder.query({
            query: () => "/settings/recording-extension/whitelist",
            transformResponse: (list = []) => whitelistWebsitesAdapter.setAll(initialState, list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["Whitelist"]
        }),
        getRecordingExtensionSettings: builder.query({
            query: () => "/settings/recording-extension",
            providesTags: ["RecordingExtensionSettings"]
        }),
        addWhitelistWebsite: builder.mutation({
            query(body) {
                return {
                    url: `/settings/recording-extension/whitelist`,
                    method: "POST",
                    body
                };
            },
            onQueryStarted(arg, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        sendExtensionSettingsMessage();
                    }
                    catch (_a) {
                    }
                });
            },
            invalidatesTags: ["Whitelist"]
        }),
        removeWhitelistWebsite: builder.mutation({
            query(id) {
                return {
                    url: `/settings/recording-extension/whitelist/${id}`,
                    method: "DELETE"
                };
            },
            onQueryStarted(arg, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        sendExtensionSettingsMessage();
                    }
                    catch (_a) {
                    }
                });
            },
            invalidatesTags: ["Whitelist"]
        }),
        updateRecordingExtensionSettings: builder.mutation({
            query: (body) => ({
                url: `/settings/recording-extension`,
                method: "PUT",
                body
            }),
            onQueryStarted(arg, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                        sendExtensionSettingsMessage();
                    }
                    catch (_a) {
                    }
                });
            },
            invalidatesTags: ["RecordingExtensionSettings"]
        })
    })
});
export const { useGetWhitelistWebsitesQuery, useGetRecordingExtensionSettingsQuery, useUpdateRecordingExtensionSettingsMutation, useAddWhitelistWebsiteMutation, useRemoveWhitelistWebsiteMutation } = apiApp;
//SELECTORS
export const selectWhitelistWebsitesResult = extendedApi.endpoints.getWhitelistWebsites.select();
export const selectWhitelistWebsitesData = createSelector(selectWhitelistWebsitesResult, whitelistWebsitesResult => whitelistWebsitesResult.data);
export const { selectAll: selectAllWhitelistWebsites, selectEntities: selectEntitiesWhitelistWebsites, selectById: selectWhitelistWebsiteById } = whitelistWebsitesAdapter.getSelectors(state => { var _a; return (_a = selectWhitelistWebsitesData(state)) !== null && _a !== void 0 ? _a : initialState; });
//SLICE
export const browserExtensionSlice = createSlice({
    name: 'browserExtension',
    initialState: {
        isAvailable: false,
    },
    reducers: {
        browserExtensionAvailable: (state) => {
            state.isAvailable = true;
        },
        browserExtensionNotAvailable: (state) => {
            state.isAvailable = false;
        },
    }
});
export const { name, actions } = browserExtensionSlice;
export const { browserExtensionAvailable, browserExtensionNotAvailable, } = browserExtensionSlice.actions;
export default browserExtensionSlice.reducer;
export const selectIsBrowserExtensionAvailable = state => state[name].isAvailable;
