import api from "../../../../api/api";
import { setErrorMessage } from "./app";
import i18n from "../../../../app/services/i18n";
export const USER_SUBSCRIPTION_PLAN_SET = 'USER_SUBSCRIPTION_PLAN_SET';
export const fetchCurrentUserPlan = (currentPlan) => {
    return (dispatch, getState) => {
        return api.getCurrentPlan(currentPlan)
            .then(response => {
            dispatch(setUserSubscriptionPlan(response));
        })
            .catch(err => {
            var _a, _b, _c, _d;
            dispatch(setErrorMessage((_d = (_c = (_b = (_a = err.response.body.detail) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.response.body.message) !== null && _d !== void 0 ? _d : i18n.t("errors:general_")));
            return Promise.reject(err);
        });
    };
};
const setUserSubscriptionPlan = (plan) => ({
    type: USER_SUBSCRIPTION_PLAN_SET,
    payload: { plan }
});
