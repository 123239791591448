import i18n from "src/app/services/i18n";
/** @deprecated */
export const SUBSCRIPTION_PLAN_FREE = 'free';
/** @deprecated */
export const SUBSCRIPTION_PLAN_DEMO_PAID = 'demoPaid';
/** @deprecated */
export const SUBSCRIPTION_PLAN_PREMIUM = 'premium';
/** @deprecated */
export const SUBSCRIPTION_PLAN_PREMIUM_YEARLY = 'premiumYear';
export const UPGRADE_PLAN = SUBSCRIPTION_PLAN_PREMIUM;
/** @deprecated */
export const SUBSCRIPTION_PLANS_ENUM = [
    { value: SUBSCRIPTION_PLAN_FREE, get text() { return i18n.t('Free_'); } },
    { value: SUBSCRIPTION_PLAN_PREMIUM, get text() { return i18n.t('PRO_'); } },
    { value: SUBSCRIPTION_PLAN_DEMO_PAID, get text() { return i18n.t('PRO_'); } },
    { value: SUBSCRIPTION_PLAN_PREMIUM_YEARLY, get text() { return i18n.t('PRO_'); } },
];
export const SUBSCRIPTION_PERIOD_MONTHLY = 'monthly';
export const SUBSCRIPTION_PERIOD_YEARLY = 'yearly';
