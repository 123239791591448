var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
const newsAdapter = createEntityAdapter();
const initialState = newsAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getNews: builder.query({
            query: () => "/news",
            transformResponse: ({ list = [] }) => newsAdapter.setAll(initialState, list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["News"]
        }),
    })
});
export const { useGetNewsQuery, } = apiApp;
//SELECTORS
export const selectNewsResult = (state, args) => extendedApi.endpoints.getNews.select(args)(state);
export const selectNewsData = createSelector(selectNewsResult, newsResult => newsResult.data);
export const { selectAll: selectAllNews, selectEntities: selectEntitiesNews, } = newsAdapter.getSelectors((state, args) => { var _a; return (_a = selectNewsData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
// SLICE
const initialSliceState = {};
export const newsSlice = createSlice({
    name: 'news',
    initialState: initialSliceState,
});
export const { name, actions } = newsSlice;
export default newsSlice.reducer;
