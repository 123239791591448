import { jsx as _jsx } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Header from "src/pages/deviceActivate/Header";
import AppsList from "src/features/deviceActivate/AppsList";
const AppsActivatePage = () => {
    return (_jsx(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: _jsx(AppsList, {}) }));
};
export default AppsActivatePage;
