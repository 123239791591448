import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import cx from "classnames";
import { BifIconChevronThinDown, BifIconChevronThinUp, Nav } from "banafo-design-system";
import { ItemLinkImage } from "./ItemLinkImage";
const propTypes = {
    item: PropTypes.any,
    activeClassName: PropTypes.any,
};
const SidebarItem = ({ item: { key, title, icon, href, subitems, routes, onClick }, activeClassName = 'active', }) => {
    const location = useLocation();
    const isActive = !!matchRoutes(routes, location.pathname);
    const hasSubitems = (subitems && !!subitems.length);
    return (_jsxs(Nav.Item, { className: cx({ [`${activeClassName}`]: isActive }), children: [_jsxs(Nav.Link, { as: Link, to: href || '', className: cx({ [`${activeClassName}`]: isActive }), onClick: onClick, children: [_jsx(ItemLinkImage, { src: icon, title: title, id: key }), _jsx("span", { className: "collapsed-hidden text-dark_ ms-100 fs-7 text-uppercase", children: title }), hasSubitems && (isActive ? _jsx(BifIconChevronThinUp, { className: "ms-auto" }) : _jsx(BifIconChevronThinDown, { className: "ms-auto" }))] }), hasSubitems && (_jsx(Nav, { className: "flex-column", children: subitems.map(subItem => (_jsx(SidebarItem, { item: subItem }, subItem.key))) }))] }));
};
SidebarItem.propTypes = propTypes;
export default SidebarItem;
