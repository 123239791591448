import AbstractTranscriptChatFactory from "src/features/transcripts/utils/factory/AbstractTranscriptChatFactory";
class AiAnalysisChatFactory extends AbstractTranscriptChatFactory {
    constructor(acronymGenerator, colorGenerator, positionGenerator) {
        super(acronymGenerator, colorGenerator, positionGenerator, undefined);
    }
    create(analyses) {
        const aiAnalysisChat = this._convertAnalysesToChat(analyses);
        return aiAnalysisChat;
    }
}
export default AiAnalysisChatFactory;
