import i18n from "i18next";
export const CONVERSATIONS_PAGINATION_LIMIT = 20;
export const CONVERSATION_DIRECTION_INBOUND = 'inbound';
export const CONVERSATION_DIRECTION_OUTBOUND = 'outbound';
export const CONVERSATION_DIRECTION_LIST = [
    CONVERSATION_DIRECTION_INBOUND,
    CONVERSATION_DIRECTION_OUTBOUND
];
export const CONVERSATION_DIRECTIONS_ENUM = [
    { value: CONVERSATION_DIRECTION_INBOUND, get text() { return i18n.t('Inbound_'); } },
    { value: CONVERSATION_DIRECTION_OUTBOUND, get text() { return i18n.t('Outbound_'); } },
];
export const CONVERSATION_TYPE_OTHER = 'other';
export const CONVERSATION_TYPE_WEB_CONFERENCE = 'web-conference';
export const CONVERSATION_TYPE_PHONE_CALL = 'phone-call';
export const CONVERSATION_TYPES_ENUM = [
    { value: CONVERSATION_TYPE_OTHER, get text() { return i18n.t('Other_'); } },
    { value: CONVERSATION_TYPE_WEB_CONFERENCE, get text() { return i18n.t('Web.conference_'); } },
    { value: CONVERSATION_TYPE_PHONE_CALL, get text() { return i18n.t('Phone.call_'); } },
];
export const FILE_UPLOAD_MAX_SIZE = '500 MB';
export const FILE_UPLOAD_FORMAT_LIST = ['aac', 'mp3', 'mp4', 'ogg', 'wav', 'flac', 'webm', 'mov', 'm4a'];
export const FILE_UPLOAD_ACCEPTED_FORMATS = FILE_UPLOAD_FORMAT_LIST.map(format => `.${format}`).join(',');
export const FILE_UPLOAD_MIMETYPE_LIST = [
    'audio/aac',
    'audio/x-aac',
    'audio/vnd.dlna.adts',
    'audio/x-wav',
    'audio/wav',
    'audio/wave',
    'audio/ogg',
    'video/ogg',
    'audio/mpeg',
    'audio/x-flac',
    'audio/flac',
    'audio/webm',
    'video/webm',
    'video/mp4',
    'video/quicktime',
    'audio/x-m4a',
    'audio/m4a'
];
export const MIMETYPE_MAP = {
    'audio/wav': 'wav',
    'audio/wave': 'wav',
    'audio/x-wav': 'wav',
    'audio/ogg': 'ogg',
    'video/ogg': 'ogg',
    'audio/mpeg': 'mp3',
    'video/mpeg': 'mp3',
    'audio/webm': 'webm',
    'video/webm': 'webm',
    'application/octet-stream': 'mp3',
    'audio/x-flac': 'flac',
    'audio/flac': 'flac',
    'audio/mp4a-latm': 'mp4',
    'video/mp4': 'mp4',
    'audio/mp3': 'mp3',
    'video/quicktime': 'mov',
    'audio/aac': 'aac',
    'audio/x-aac': 'aac',
    'audio/vnd.dlna.adts': 'aac',
    'audio/x-m4a': 'm4a',
    'audio/m4a': 'm4a'
};
export const AUDIO_PLAYER_FAST_FORWARD_INTERVAL_1 = 1;
export const AUDIO_PLAYER_FAST_FORWARD_INTERVAL_5 = 5;
export const AUDIO_PLAYER_FAST_FORWARD_INTERVAL_10 = 10;
export const AUDIO_PLAYER_FAST_FORWARD_INTERVAL_20 = 20;
export const AUDIO_PLAYER_FAST_FORWARD_INTERVAL_40 = 40;
export const AUDIO_PLAYER_FAST_FORWARD_INTERVALS = [
    { value: AUDIO_PLAYER_FAST_FORWARD_INTERVAL_1, text: '1' },
    { value: AUDIO_PLAYER_FAST_FORWARD_INTERVAL_5, text: '5' },
    { value: AUDIO_PLAYER_FAST_FORWARD_INTERVAL_10, text: '10' },
    { value: AUDIO_PLAYER_FAST_FORWARD_INTERVAL_20, text: '20' },
    { value: AUDIO_PLAYER_FAST_FORWARD_INTERVAL_40, text: '40' },
];
export const AUDIO_PLAYER_PLAYBACK_SPEEDS = [
    { value: 0.25, text: '0.25' },
    { value: 0.5, text: '0.50' },
    { value: 0.75, text: '0.75' },
    { value: 1, text: 'Normal' },
    { value: 1.25, text: '1.25' },
    { value: 1.5, text: '1.50' },
    { value: 1.75, text: '1.75' },
    { value: 2, text: '2' }
];
