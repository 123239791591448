import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Image, Col, Container, Row, Button } from "banafo-design-system";
import notFound from 'images/other/404.jpg';
import { selectAuthUser } from "./store/selectors/auth";
import { HOME_ROUTE } from "src/app/router/config";
const NotFoundPage = ({ authUser, }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleBackBtnClick = () => {
        navigate(HOME_ROUTE);
    };
    if (authUser) {
        return (_jsx(_Fragment, { children: _jsx("div", { className: "bg-white container-fluid min-vh-100", children: _jsx("main", { role: "main", className: "pt-300", children: _jsx(Container, { children: _jsx(Row, { children: _jsxs(Col, { md: 12, lg: 12, children: [_jsx(Row, { className: "justify-content-center text-center", children: _jsxs("div", { className: "col-md-6", children: [_jsx("h3", { className: "text-dark font-weight-bolder mb-200", children: t('404.and.the.Banana.slip_') }), _jsxs("p", { className: "text-muted", children: [t("We.couldn/'t.find.the.page.you.were.looking.for_"), "."] }), _jsx(Button, { onClick: handleBackBtnClick, className: "font-weight-bolder mt-200 btn-outline-dark btn-light text-uppercase", children: t('Go.Home_') })] }) }), _jsx(Row, { md: 12, lg: 12, children: _jsx(Image, { src: notFound, fluid: true }) })] }) }) }) }) }) }));
    }
    return (_jsx(_Fragment, { children: _jsx("div", { className: "bg-white container-fluid h-100", children: _jsx("main", { role: "main", className: "ms-lg-auto pt-300 col-md-12", children: _jsx(Container, { children: _jsx(Row, { children: _jsxs(Col, { md: 12, lg: 12, children: [_jsx(Row, { className: "justify-content-center text-center", children: _jsxs("div", { className: "col-md-6", children: [_jsx("h3", { className: "text-dark font-weight-bolder mb-200", children: t('404.and.the.Banana.slip_') }), _jsxs("p", { className: "text-muted", children: [t("We.couldn/'t.find.the.page.you.were.looking.for_"), "."] }), _jsx(Button, { onClick: handleBackBtnClick, className: "font-weight-bolder mt-200 btn-outline-dark btn-light text-uppercase", children: t('Go.Home_') })] }) }), _jsx(Row, { md: 12, lg: 12, children: _jsx(Image, { src: notFound, fluid: true }) })] }) }) }) }) }) }));
};
NotFoundPage.propTypes = {
    authUser: PropTypes.any,
};
function mapStateToProps(state) {
    return {
        authUser: selectAuthUser(state),
    };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
