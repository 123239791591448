import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useParams } from "react-router-dom";
import { RESELLER_CUSTOMERS_ROUTE } from "src/app/router/config";
import useGetResellerCustomer from "src/features/resellerCustomers/hooks/useGetResellerCustomer";
import { useAppSelector } from "src/app/store/hooks";
import { selectResellerCustomersApiArgs } from "src/features/resellerCustomers/resellerCustomersSlice";
import { useGetResellerCustomersQuery } from "src/features/resellerCustomers/resellerCustomersApi";
const ExistingResellerCustomer = ({ children }) => {
    const args = useAppSelector(selectResellerCustomersApiArgs);
    useGetResellerCustomersQuery(args);
    const { resellerCustomerId } = useParams();
    const hasResellerCustomer = !!useGetResellerCustomer(resellerCustomerId);
    if (!hasResellerCustomer) {
        return _jsx(Navigate, { to: RESELLER_CUSTOMERS_ROUTE });
    }
    return children;
};
export default ExistingResellerCustomer;
