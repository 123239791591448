import { apiClient } from "src/app/services/httpClient";
export const createSubscription = (plan, cancelExisting = false) => {
    return apiClient
        .post(`/limiter/subscriptions`)
        .send({ plan, cancelExisting });
};
export const getSubscriptions = () => {
    return apiClient
        .get(`/limiter/subscriptions`);
};
export const getUsages = () => {
    return apiClient
        .get(`/limiter/usages`);
};
export const getCurrentSubscription = () => {
    return apiClient
        .get(`/limiter/subscriptions/current`);
};
export const getCurrentPlan = (currentPlan) => {
    return apiClient
        .get(`/limiter/availability/plans/${currentPlan}`);
};
export const initPayment = (plan) => {
    return apiClient
        // .post(`/limiter/payments`)
        .post(`/limiter/payment-sessions`)
        .send(plan);
};
export const getVatRate = (countryCode, query) => {
    return apiClient
        .get(`/limiter/vat-rate/${countryCode}`)
        .query(query);
};
export const getCountries = () => {
    return apiClient
        .get(`/limiter/countries`);
};
