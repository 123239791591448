import api from "../../../../api/api";
export const FETCH_SETTINGS_BEGIN = 'FETCH_SETTINGS_BEGIN';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const UPDATE_SETTINGS_BEGIN = 'UPDATE_SETTINGS_BEGIN';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
export const fetchSettings = () => {
    return (dispatch, getState) => {
        dispatch(fetchSettingsBegin());
        return api.getSettings()
            .then(response => {
            const settings = response;
            dispatch(fetchSettingsSuccess(settings));
            return settings;
        })
            .catch(err => {
            dispatch(fetchSettingsFailure(err));
            return Promise.reject(err);
        });
    };
};
export const loadLanguage = () => {
    return (dispatch, getState) => {
        return api.getSettings()
            .then(response => {
            return response.language;
        });
    };
};
export const loadLanguageEnum = () => {
    return (dispatch, getState) => {
        return api.getSettingsLanguages()
            .then(response => {
            return response.languages;
        });
    };
};
export const fetchSettingsBegin = () => ({
    type: FETCH_SETTINGS_BEGIN
});
export const fetchSettingsSuccess = (item) => ({
    type: FETCH_SETTINGS_SUCCESS,
    payload: { item }
});
export const fetchSettingsFailure = (error) => ({
    type: FETCH_SETTINGS_FAILURE,
    payload: { error }
});
