import i18n from "src/app/services/i18n";
import { PAGINATION_LIMIT_DEFAULT } from "src/features/auth/config/pagination";
export const PAYMENTS_PAGINATION_LIMIT = PAGINATION_LIMIT_DEFAULT;
export const PAYMENT_METHOD_VISA = 'visa';
export const PAYMENT_METHOD_MASTERCARD = 'mastercard';
export const PAYMENT_METHOD_LIST = [
    PAYMENT_METHOD_VISA,
    PAYMENT_METHOD_MASTERCARD
];
export const PAYMENT_CUSTOMER_TYPE_PRIVATE = 'private';
export const PAYMENT_CUSTOMER_TYPE_COMPANY = 'company';
export const PAYMENT_CUSTOMER_TYPES_ENUM = [
    { value: PAYMENT_CUSTOMER_TYPE_PRIVATE, get text() { return i18n.t('Private.customer_'); } },
    { value: PAYMENT_CUSTOMER_TYPE_COMPANY, get text() { return i18n.t('Company_'); } },
];
