import { jsx as _jsx } from "react/jsx-runtime";
import AppCard from "src/features/deviceActivate/AppCard/AppCard";
import { AiFillAndroid } from "react-icons/ai";
import { SCHEME, WEB_URL } from "src/app/config";
import { useTranslation } from "react-i18next";
const BanafoVoiceRecorderCard = (props) => {
    const { t } = useTranslation();
    return _jsx(AppCard, Object.assign({ icon: _jsx(AiFillAndroid, { size: "100%" }), name: t('Banafo.Voice.Recorder_'), moreInfoUrl: `${SCHEME}://${WEB_URL}/en/application/android` }, props));
};
export default BanafoVoiceRecorderCard;
