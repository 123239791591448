export const selectAuth = (state) => state.app.auth;
export const selectAuthUser = (state) => state.app.auth.user;
/**
 * @deprecated use const { data: {title, trialEndDate} = {} } = useGetSubscriptionsQuery();
 * @param state
 * @returns {{$set: *}|{$set: {title: *}}|*|{features: *[], title: null}}
 */
export const selectAuthUserPlan = (state) => state.app.auth.user.currentPlan || { title: null, features: [] };
export const selectAuthKey = (state) => state.app.auth.key;
export const selectAuthInteractionName = (state) => state.app.auth.interaction.prompt.name;
