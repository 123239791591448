import { isChrome, isEdge, isOpera } from "react-device-detect";
import { EXTENSION_CHROME_URL, EXTENSION_EDGE_URL, EXTENSION_OPERA_URL } from "../config";
export const getDetectedBrowserName = () => {
    if (isChrome) {
        return 'chrome';
    }
    if (isOpera) {
        return 'opera';
    }
    if (isEdge) {
        return 'edge';
    }
    return null;
};
export const getDetectedBrowserUrl = () => {
    if (isChrome) {
        return EXTENSION_CHROME_URL;
    }
    if (isOpera) {
        return EXTENSION_OPERA_URL;
    }
    if (isEdge) {
        return EXTENSION_EDGE_URL;
    }
    return EXTENSION_CHROME_URL;
};
export const getBrowserUrlByName = (name) => {
    if (name === 'chrome') {
        return EXTENSION_CHROME_URL;
    }
    if (name === 'opera') {
        return EXTENSION_OPERA_URL;
    }
    if (name === 'edge') {
        return EXTENSION_EDGE_URL;
    }
    return null;
};
