import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGenerateApiKeyMutation } from "../apiKeysSlice";
import { BanafoModal, BifIconCopy, BifIconInformationCircle, BifIconKey, Button, Col, Form, LineSeparator, Row } from "banafo-design-system";
const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};
const AddApiKeyModal = ({ show, onHide, }) => {
    const { t, i18n } = useTranslation();
    const [generateApiKey, { data, isSuccess }] = useGenerateApiKeyMutation({ fixedCacheKey: "generate-api-key" });
    useEffect(() => {
        if (!isSuccess) {
            return;
        }
        setApiKeyInput(data === null || data === void 0 ? void 0 : data.apiKey);
        setCopyInfoText(`* ${i18n.t('infos:Copy.API.key.to.clipboard_')}`);
    }, [isSuccess]);
    const [apiKeyInput, setApiKeyInput] = useState("");
    const [copyInfoText, setCopyInfoText] = useState('');
    const apiKeyRef = useRef(null);
    useEffect(() => {
        return () => {
            setApiKeyInput("");
            setCopyInfoText("");
        };
    }, []);
    const handleHideModal = () => {
        onHide();
        setApiKeyInput("");
        setCopyInfoText("");
    };
    const handleCreateApiKey = () => {
        generateApiKey();
    };
    const handleCopyApiKey = (e) => {
        try {
            apiKeyRef.current.focus();
            apiKeyRef.current.select();
            document.execCommand('copy');
            setCopyInfoText(`${i18n.t('Copied.to.clipboard_')}`);
        }
        catch (err) {
            setCopyInfoText(err.message);
        }
    };
    return (_jsxs(BanafoModal, { size: apiKeyInput && 'xl', show: show, title: t('Speech.to.text.api_'), onHide: handleHideModal, className: 'text-center', children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Api.key.add.modal.info.text.first_') }), _jsx("p", { className: 'fw-bold mb-400', children: t('infos:Api.key.add.modal.info.text.second_') }), !apiKeyInput &&
                _jsxs(Button, { variant: "dark", disabled: apiKeyInput !== "", onClick: handleCreateApiKey, className: "mb-150 with-icon end", children: [t("Generate_"), " ", _jsx(BifIconKey, {})] }), apiKeyInput && (_jsx(Row, { className: "justify-content-center", children: _jsxs(Col, { xs: 10, className: "d-flex justify-content-center align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, placeholder: t("Click.the.button.to.generate.your.API.key_"), name: "apiKey", ref: apiKeyRef, value: apiKeyInput, className: "d-inline-block" }), _jsx(Form.Text, { className: "d-block text-dark", children: copyInfoText })] }), _jsxs(Button, { variant: "dark", onClick: handleCopyApiKey, className: "text-uppercase text-nowrap with-icon end", children: [t('Copy_'), " ", _jsx(BifIconCopy, {})] })] }) }))] }));
};
AddApiKeyModal.propTypes = propTypes;
export default AddApiKeyModal;
