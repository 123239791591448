export const AUDIO_CURRENT_TIME_CHANGED = 'AUDIO_CURRENT_TIME_CHANGED';
export const AUDIO_ERROR = 'AUDIO_ERROR';
export const AUDIO_FINISHED = 'AUDIO_FINISHED';
export const AUDIO_PAUSED = 'AUDIO_PAUSED';
export const AUDIO_PLAYING = 'AUDIO_PLAYING';
export const AUDIO_READY = 'AUDIO_READY';
export const LOAD_AUDIO = 'LOAD_AUDIO';
export const PAUSE_AUDIO = 'PAUSE_AUDIO';
export const PLAY_AUDIO = 'PLAY_AUDIO';
export const SEEK_AUDIO = 'SEEK_AUDIO';
export const SET_AUDIO_VOLUME = 'SET_AUDIO_VOLUME';
export const SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE';
export const SET_FAST_FORWARD_INTERVAL = 'SET_FAST_FORWARD_INTERVAL';
export const SKIP_AUDIO = 'SKIP_AUDIO';
export const UNLOAD_AUDIO = 'UNLOAD_AUDIO';
export const CLEAR_AUDIO_ERROR = 'CLEAR_AUDIO_ERROR';
export const loadAudio = (fileIds, options) => ({
    type: LOAD_AUDIO,
    fileIds,
    options
});
export const pauseAudio = () => ({
    type: PAUSE_AUDIO
});
export const playAudio = () => ({
    type: PLAY_AUDIO
});
export const playPauseAudio = () => {
    return (dispatch, getState) => {
        if (getState().app.playback.playing) {
            dispatch(pauseAudio());
        }
        else {
            dispatch(playAudio());
        }
    };
};
export const seekAudio = (progress) => ({
    type: SEEK_AUDIO,
    progress
});
export const setAudioVolume = (volume, source) => ({
    type: SET_AUDIO_VOLUME,
    volume,
    source
});
export const setPlaybackRate = (rate) => ({
    type: SET_PLAYBACK_RATE,
    rate
});
export const setFastForwardInterval = (interval) => ({
    type: SET_FAST_FORWARD_INTERVAL,
    interval
});
export const skipAudio = (seconds) => ({
    type: SKIP_AUDIO,
    seconds
});
export const unloadAudio = () => ({
    type: UNLOAD_AUDIO
});
export const clearAudioError = () => ({
    type: CLEAR_AUDIO_ERROR
});
