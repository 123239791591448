var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _MetaDataSequentially_metaPropertyList, _MetaDataSequentially_metaData, _MetaDataSequentially_reserveCorrectionCount;
class MetaDataSequentially {
    constructor(metaPropertyList = [], inversed = false) {
        _MetaDataSequentially_metaPropertyList.set(this, []);
        _MetaDataSequentially_metaData.set(this, []);
        _MetaDataSequentially_reserveCorrectionCount.set(this, 0);
        __classPrivateFieldSet(this, _MetaDataSequentially_metaPropertyList, inversed ? [...metaPropertyList].reverse() : [...metaPropertyList], "f");
    }
    generate(id, reserve) {
        var _a;
        let metaData = __classPrivateFieldGet(this, _MetaDataSequentially_metaData, "f").find(({ id: metaId }) => metaId === id);
        if (!metaData) {
            const metaPropertyListLength = __classPrivateFieldGet(this, _MetaDataSequentially_metaPropertyList, "f").length;
            const metaDataLength = __classPrivateFieldGet(this, _MetaDataSequentially_metaData, "f").length;
            const metaProperty = __classPrivateFieldGet(this, _MetaDataSequentially_metaPropertyList, "f")[(metaDataLength - __classPrivateFieldGet(this, _MetaDataSequentially_reserveCorrectionCount, "f")) % metaPropertyListLength];
            if (reserve && metaPropertyListLength > 1) {
                __classPrivateFieldGet(this, _MetaDataSequentially_metaPropertyList, "f").splice((metaDataLength - __classPrivateFieldGet(this, _MetaDataSequentially_reserveCorrectionCount, "f")) % metaPropertyListLength, 1);
                __classPrivateFieldSet(this, _MetaDataSequentially_reserveCorrectionCount, (_a = __classPrivateFieldGet(this, _MetaDataSequentially_reserveCorrectionCount, "f"), _a++, _a), "f");
            }
            metaData = { id, metaProperty };
            __classPrivateFieldGet(this, _MetaDataSequentially_metaData, "f").push(metaData);
        }
        const { metaProperty } = metaData;
        return metaProperty;
    }
}
_MetaDataSequentially_metaPropertyList = new WeakMap(), _MetaDataSequentially_metaData = new WeakMap(), _MetaDataSequentially_reserveCorrectionCount = new WeakMap();
export default MetaDataSequentially;
