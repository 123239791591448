import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from "banafo-design-system";
import classNames from "classnames";
import PlanPrice from "src/features/account/pricingPlans/PlanPrice";
import "./PlanItem.scss";
import getConfigEnumText from "src/common/utils/config/configEnumText";
import { SUBSCRIPTION_PLANS_ENUM } from "src/features/account/config";
const PlanItem = ({ plan, subscriptionPeriod, active, className, handleOnChange, }) => {
    const classes = classNames(className, 'pricing-plan-item d-flex flex-wrap justify-content-between align-items-center shadow rounded p-150 mb-200 lh-1', { 'selected-plan': active });
    const title = getConfigEnumText(SUBSCRIPTION_PLANS_ENUM, plan);
    return (_jsxs("label", { className: classes, children: [_jsx(Form.Check, { className: "d-none", name: "registeredAs", value: plan, type: 'radio', checked: active, onChange: handleOnChange }), _jsx("div", { className: "h2 m-0", children: title }), _jsx(PlanPrice, { plan: plan, subscriptionPeriod: subscriptionPeriod })] }));
};
export default PlanItem;
