import { useEffect } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { DOCUMENT_TITLES } from "src/app/router/config";
function useDocumentTitle() {
    const location = useLocation();
    useEffect(() => {
        const getPageTitle = (pathname) => {
            const match = matchRoutes(DOCUMENT_TITLES, pathname);
            if (!match) {
                return { title: "Banafo", description: "Banafo" };
            }
            const { title = "Banafo", description = "Banafo" } = match[0].route || { title, description };
            return { title, description };
        };
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = getPageTitle(location.pathname).description;
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
        document.title = getPageTitle(location.pathname).title;
        return () => {
            metaDescription.parentNode.removeChild(metaDescription);
        };
    }, [location]);
}
export default useDocumentTitle;
