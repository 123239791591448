import { jsx as _jsx } from "react/jsx-runtime";
import useScrollItemsList from "src/hooks/useScrollItemsList";
import useGetResellerBillingHistory from "src/features/resellerBillingHistory/hooks/useGetResellerBillingHistory";
import ResellerBillings from "src/features/resellerBillingHistory/ResellerBillings";
const ResellerBillingsWrapper = () => {
    const { resellerBillings, totalResellerBillings, loadMoreResellerBillings, isLoading, isFetching, hasNextPage } = useGetResellerBillingHistory();
    const { scrollRef } = useScrollItemsList(resellerBillings);
    return _jsx(ResellerBillings, { resellerBillings: resellerBillings, totalResellerBillings: totalResellerBillings, loadMoreResellerBillings: loadMoreResellerBillings, isLoading: isLoading, isFetching: isFetching, hasNextPage: hasNextPage, ref: scrollRef });
};
export default ResellerBillingsWrapper;
