import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { GOOGLE_PLAY_URL } from "src/app/config";
import banafo1 from "../images/banafo01.png";
import banafo2 from "../images/banafo02.png";
import ActivateApp from "src/features/deviceActivate/ActivateApp/ActivateApp";
import BanafoVoiceRecorderCard from "src/features/deviceActivate/AppCard/BanafoVoiceRecorderCard";
const AndroidBanafoApp = () => {
    const { t } = useTranslation();
    return (_jsx(ActivateApp, { title: t('Banafo.app.for.android_'), appCard: _jsx(BanafoVoiceRecorderCard, {}), downloadUrl: GOOGLE_PLAY_URL, images: [banafo1, banafo2] }));
};
export default AndroidBanafoApp;
