var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchCurrentUserPlan } from "src/features/auth/store/actions/subscriptions";
import { PAYMENTS_PAGINATION_LIMIT } from "src/features/payments/config";
import range from "src/common/utils/range";
const paymentsAdapter = createEntityAdapter();
const initialPaymentsState = paymentsAdapter.getInitialState({
    query: {},
});
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getCurrentPlan: builder.query({
            query: (currentPlan) => `/limiter/availability/plans/${currentPlan}`,
            onQueryStarted(arg, { dispatch }) {
                return __awaiter(this, void 0, void 0, function* () {
                    dispatch(fetchCurrentUserPlan(arg));
                });
            },
        }),
        getBillingCountries: builder.query({
            query: () => "/limiter/countries",
        }),
        getPayments: builder.query({
            query: ({ start }) => {
                const payload = { start, limit: PAYMENTS_PAGINATION_LIMIT };
                const params = new URLSearchParams(payload);
                return `/limiter/payments?${params}`;
            },
            transformResponse: ({ list = [], query = {} }) => paymentsAdapter.setAll(Object.assign(Object.assign({}, initialPaymentsState), { query }), list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["Payments"]
        }),
        getVatNumber: builder.query({
            query: ({ country, vatNumber }) => {
                const params = new URLSearchParams();
                if (vatNumber) {
                    params.set('vatNumber', vatNumber);
                }
                return `/limiter/vat-rate/${country}?${params}`;
            },
        }),
        cancelPayment: builder.mutation({
            query: (body) => ({
                url: `/profile/payment/cancel`,
                method: "PUT",
                body
            })
        }),
        finishPayment: builder.mutation({
            query: (body) => ({
                url: `/profile/payment/finished`,
                method: "PUT",
                body
            })
        }),
        initPayment: builder.mutation({
            query({ email, full_name, customerType, vat_number, company_name, company_address, countryIso, paymentMethod, totalAmount, vatRate, userPlan }) {
                const purchase = {
                    paymentDetails: {
                        type: "subscription",
                        vatRate: vatRate,
                        amount: totalAmount,
                        purchase: {
                            name: userPlan
                        },
                        paymentMethodDetails: {
                            type: "card",
                            brand: paymentMethod
                        }
                    },
                    customerDetails: {
                        type: customerType,
                        name: full_name,
                        email: email,
                        companyVat: vat_number,
                        companyAddress: company_address,
                        companyName: company_name,
                        country: countryIso
                    }
                };
                return {
                    url: `/limiter/payment-sessions`,
                    method: "POST",
                    body: purchase,
                };
            },
        }),
        downloadInvoice: builder.mutation({
            queryFn: ({ url, name }, { signal, dispatch, getState }, extraOptions, baseQuery) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                let meta;
                try {
                    const result = yield baseQuery({
                        url,
                        responseHandler: ((response) => response.blob())
                    });
                    meta = result.meta;
                    if (result.error) {
                        result.error.data = null;
                        return result;
                    }
                    let hiddenElement = document.createElement("a");
                    let domUrl = window.URL || window.webkitURL;
                    let blobPDF = domUrl.createObjectURL(result.data);
                    hiddenElement.href = blobPDF;
                    hiddenElement.target = "_blank";
                    hiddenElement.download = `Invoice_${name}.pdf`;
                    hiddenElement.click();
                    return {
                        data: name,
                        meta: result.meta
                    };
                }
                catch (e) {
                    return {
                        error: {
                            status: (_a = e === null || e === void 0 ? void 0 : e.cause) === null || _a === void 0 ? void 0 : _a.status,
                            data: ((_b = e === null || e === void 0 ? void 0 : e.cause) === null || _b === void 0 ? void 0 : _b.data) || e.message,
                        },
                        meta,
                    };
                }
            })
        })
    })
});
export const { useGetCurrentPlanQuery, useLazyGetCurrentPlanQuery, useGetBillingCountriesQuery, useGetVatNumberQuery, useLazyGetVatNumberQuery, useGetPaymentsQuery, useCancelPaymentMutation, useFinishPaymentMutation, useInitPaymentMutation, useDownloadInvoiceMutation, } = apiApp;
//SELECTORS
export const selectPaymentsResult = (state, args) => extendedApi.endpoints.getPayments.select(args)(state);
export const selectPaymentsData = createSelector(selectPaymentsResult, paymentsResult => paymentsResult.data);
export const selectPlanDetailsResult = createSelector((args) => args, (args) => extendedApi.endpoints.getCurrentPlan.select(args));
export const selectPlanDetails = createSelector((state, args) => selectPlanDetailsResult(args)(state), (planDetailsResult) => planDetailsResult === null || planDetailsResult === void 0 ? void 0 : planDetailsResult.data);
export const { selectAll: selectAllPayments, selectEntities: selectEntitiesPayments, selectById: selectPaymentById } = paymentsAdapter.getSelectors((state, args) => { var _a; return (_a = selectPaymentsData(state, args)) !== null && _a !== void 0 ? _a : initialPaymentsState; });
export const selectHasNextPage = createSelector(selectPaymentsData, (data = initialPaymentsState) => {
    const { query: { total, start, limit } } = data;
    const hasNextPage = (start + limit) < total;
    return hasNextPage;
});
export const selectCombinedPayments = (state, args) => {
    const { start } = args;
    const perPage = PAYMENTS_PAGINATION_LIMIT;
    let payments = [];
    for (let x of range(0, start, perPage)) {
        payments = [...payments, ...selectAllPayments(state, Object.assign(Object.assign({}, args), { start: x }))];
    }
    return payments;
};
//SLICE
const initialSliceState = {
    reviewModalOpen: false,
    page: 0,
};
export const paymentsSlice = createSlice({
    name: 'billing',
    initialState: initialSliceState,
    reducers: {
        showReviewModal: state => {
            state.reviewModalOpen = true;
        },
        hideReviewModal: state => {
            state.reviewModalOpen = false;
        },
        resetPage: state => { state.page = initialSliceState.page; },
        incrementPage: state => {
            state.page++;
        },
    },
});
export const { name } = paymentsSlice;
export const { showReviewModal, hideReviewModal, resetPage, incrementPage, } = paymentsSlice.actions;
export default paymentsSlice.reducer;
export const selectReviewModalIsOpen = state => state[name].reviewModalOpen;
export const selectPage = state => state[name].page;
