import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { SubscriptionPlanTypes } from "src/features/account/types";
const Title = ({ label }) => _jsx("div", { className: "fw-bold", children: label });
const PlanTitle = () => {
    const { t } = useTranslation();
    const authUser = useSelector(selectAuthUser);
    switch (authUser.title) {
        case SubscriptionPlanTypes.DemoPaid:
        case SubscriptionPlanTypes.Premium:
            return _jsx(Title, { label: t("Banafo.PRO_") });
        case SubscriptionPlanTypes.PremiumYearly:
            return _jsx(Title, { label: t("Banafo.PRO.Annually_") });
        default:
            return null;
    }
};
export default PlanTitle;
