import { combineReducers } from "redux";
import historyCalls from "./historyCalls";
import historyCallDetails from "./historyCallDetails";
import contacts from "./contacts";
import recordings from "./recordings";
import recording_calls from "./recordingCalls";
import recording_contacts from "./recordingContacts";
import recordingDetails from "./recordingDetails";
import recording_notes from "./recordingNotes";
import recording_transcripts from "./recordingTranscripts";
import search_transcripts from "./searchTranscripts";
import settings from "./settings";
export default combineReducers({
    historyCalls,
    historyCallDetails,
    contacts,
    recordings,
    recording_calls,
    recording_contacts,
    recordingDetails,
    recording_notes,
    recording_transcripts,
    search_transcripts,
    settings,
});
