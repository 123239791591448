var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const fileHash = (file) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const arrayBuffer = yield file.arrayBuffer();
        // Use the subtle crypto API to perform a SHA256 Sum of the file's
        // Array Buffer. The resulting hash is stored in an array buffer
        const hashAsArrayBuffer = yield crypto.subtle.digest("SHA-1", arrayBuffer);
        // To display it as a string we will get the hexadecimal value of
        // each byte of the array buffer. This gets us an array where each byte
        // of the array buffer becomes one item in the array
        const uint8ViewOfHash = new Uint8Array(hashAsArrayBuffer);
        // We then convert it to a regular array so we can convert each item
        // to hexadecimal strings, where characters of 0-9 or a-f represent
        // a number between 0 and 15, containing 4 bits of information,
        // so 2 of them is 8 bits (1 byte).
        const hashAsString = Array.from(uint8ViewOfHash)
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");
        return hashAsString;
    }
    catch (e) {
        throw new Error(e.name, {
            cause: {
                status: e.code,
                data: String(e),
            }
        });
    }
});
export default fileHash;
