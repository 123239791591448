import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
const Icon = ({ type }) => {
    return (_jsx("div", { className: `Icon ${type}`, children: type === 'success' && _jsx("div", {}) }));
};
Icon.propTypes = {
    type: PropTypes.oneOf([
        'success', 'failure'
    ]),
};
export default Icon;
export function FailureIcon(props) {
    return _jsx(Icon, { type: 'failure' });
}
export function SuccessIcon(props) {
    return _jsx(Icon, { type: 'success' });
}
