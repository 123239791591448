var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _AbstractTranscriptChatFactory_speakerIdArray;
import { jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formattedDuration } from "src/features/auth/util/dateTime";
import i18n from "src/app/services/i18n";
import moment from "moment/moment";
class AbstractTranscriptChatFactory {
    constructor(acronymGenerator, colorGenerator, positionGenerator, rowHighlighter) {
        _AbstractTranscriptChatFactory_speakerIdArray.set(this, []);
        this._genarateSpeakerName = ({ speakerId, speakerName }, origin) => {
            if (speakerId) {
                if (!__classPrivateFieldGet(this, _AbstractTranscriptChatFactory_speakerIdArray, "f").includes(speakerId)) {
                    __classPrivateFieldGet(this, _AbstractTranscriptChatFactory_speakerIdArray, "f").push(speakerId);
                }
                const speakerIdIndex = __classPrivateFieldGet(this, _AbstractTranscriptChatFactory_speakerIdArray, "f").indexOf(speakerId) + 1;
                return speakerName !== null && speakerName !== void 0 ? speakerName : `${i18n.t('Speaker_')} ${speakerIdIndex}`;
            }
            else {
                return (origin === 'local') ? i18n.t('Me_') : (origin === 'mixed') ? i18n.t('Mixed_') : i18n.t('Others_');
            }
        };
        this._formatMilliseconds = (milliseconds) => {
            return formattedDuration(milliseconds);
        };
        this._convertStreamsToChat = (transcriptStreams) => {
            return transcriptStreams.map((_a, index) => {
                var { id, speakerId, speakerName, origin, matched, startedAt, endedAt } = _a, transcriptStreamRest = __rest(_a, ["id", "speakerId", "speakerName", "origin", "matched", "startedAt", "endedAt"]);
                let showSpeakerDetails = true;
                if (index !== 0 && speakerId === transcriptStreams[index - 1].speakerId) {
                    showSpeakerDetails = false;
                }
                speakerName = this._genarateSpeakerName({ speakerId, speakerName }, origin);
                const acronym = this._acronymGenerator.generate(speakerName);
                const position = this._positionGenerator.generate(speakerId !== null && speakerId !== void 0 ? speakerId : origin);
                const color = this._colorGenerator.generate(speakerId !== null && speakerId !== void 0 ? speakerId : origin);
                const timestamp = this._formatMilliseconds(startedAt);
                const isHighlighted = this._rowHighlighter.sync({ id, startedAt, endedAt, matched });
                return Object.assign({ id,
                    speakerId,
                    speakerName,
                    showSpeakerDetails,
                    startedAt,
                    endedAt,
                    matched,
                    acronym,
                    timestamp,
                    position, acronymColor: color, isHighlighted }, transcriptStreamRest);
            });
        };
        this._convertAnalysesToChat = (analyses) => {
            return analyses.flatMap((_a) => {
                var { prompt, result, model, usages, assistant, createdAt } = _a, rest = __rest(_a, ["prompt", "result", "model", "usages", "assistant", "createdAt"]);
                const userName = `${i18n.t('Me_')}-${assistant}`;
                const aiName = `${i18n.t('AI.Assistant_')}-${assistant}`;
                const _result = _jsxs(_Fragment, { children: [_jsxs("b", { children: [model, " ", usages, " tokens"] }), " ", result] });
                return [
                    {
                        text: prompt,
                        speakerName: userName,
                        acronym: this._acronymGenerator.generate(userName),
                        acronymColor: this._colorGenerator.generate('me'),
                        position: this._positionGenerator.generate('me'),
                        showSpeakerDetails: true,
                        timestamp: moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
                    },
                    {
                        text: _result,
                        speakerName: aiName,
                        acronym: this._acronymGenerator.generate(aiName),
                        acronymColor: this._colorGenerator.generate('ai'),
                        position: this._positionGenerator.generate('ai'),
                        showSpeakerDetails: true,
                        timestamp: moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
                    }
                ];
            });
        };
        if (new.target === AbstractTranscriptChatFactory) {
            throw new TypeError("Cannot construct AbstractTranscriptChatFactory instances directly");
        }
        this._acronymGenerator = acronymGenerator;
        this._colorGenerator = colorGenerator;
        this._positionGenerator = positionGenerator;
        this._rowHighlighter = rowHighlighter;
    }
    create() {
        throw new Error("You must implement the create");
    }
}
_AbstractTranscriptChatFactory_speakerIdArray = new WeakMap();
export default AbstractTranscriptChatFactory;
