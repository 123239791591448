var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import moment from "moment";
import * as Sentry from "@sentry/react";
import Ajv from "ajv";
import { plugin } from "ajv-moment";
import { BanafoModal, Button, Form, FormControl } from "banafo-design-system";
import { hideEditModal, selectActiveCallId, selectCombinedConversationsById, selectEditModalIsOpen, selectFilters, selectPage, useUpdateCallMutation } from "src/features/conversations/conversationsSlice";
import { CONVERSATION_DIRECTION_INBOUND, CONVERSATION_DIRECTION_LIST, CONVERSATION_DIRECTIONS_ENUM, CONVERSATION_TYPE_OTHER, CONVERSATION_TYPE_PHONE_CALL, CONVERSATION_TYPES_ENUM } from "src/features/auth/config/conversations";
import getConfigEnumText from "src/common/utils/config/configEnumText";
import DatePickerInput from "src/common/components/ui/DatePickerInput";
import { getConversationTitle } from "src/features/auth/util/common";
import { CONVERSATIONS_PAGINATION_LIMIT } from "src/features/conversations/config";
import validateSchema from "src/features/auth/util/validateSchema";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, useDefaults: 'empty' });
plugin({ ajv, moment });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    "properties": {
        "type": {
            type: ['string'],
            "minLength": 3,
            "maxLength": 128
        },
        startedAt: {
            type: 'string',
            moment: true
        },
    },
    allOf: [{
            "if": {
                "properties": {
                    "type": {
                        "const": CONVERSATION_TYPE_PHONE_CALL
                    }
                }
            },
            "then": {
                required: ["finishedAt"],
                finishedAt: {
                    type: 'string',
                    moment: {
                        validate: [{
                                test: 'isSameOrAfter',
                                value: {
                                    $data: '1/startedAt',
                                }
                            }]
                    }
                }
            }
        }],
    required: [
        'startedAt'
    ],
    errorMessage: {
        properties: {
            startedAt: 'errors:Invalid.startedAt_',
            finishedAt: 'errors:Invalid.finishedAt_',
        }
    },
};
const EditCallModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const show = useSelector(selectEditModalIsOpen);
    const callId = useSelector(selectActiveCallId);
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    const start = page * perPage;
    const call = useSelector(state => selectCombinedConversationsById(state, { start, filters: searchFilters }, callId));
    const [updateRecording] = useUpdateCallMutation({ fixedCacheKey: "update-recording" });
    const initialFormState = {
        type: call.type || CONVERSATION_TYPE_OTHER,
        remotePhone: call.remotePhone || "",
        direction: call.direction || CONVERSATION_DIRECTION_INBOUND,
        startedAt: call.startedAt ? moment(call.startedAt) : moment().startOf("hour"),
        finishedAt: call.finishedAt ? moment(call.finishedAt) : moment(call.startedAt).add(1, "hour").startOf("hour"),
        source: call.source || "",
        title: getConversationTitle(call),
        summary: call.summary || ""
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        setInputs({
            type: call.type || CONVERSATION_TYPE_OTHER,
            remotePhone: call.remotePhone || "",
            direction: call.direction || CONVERSATION_DIRECTION_INBOUND,
            startedAt: call.startedAt ? moment(call.startedAt) : moment().startOf("hour"),
            finishedAt: call.finishedAt ? moment(call.finishedAt) : moment(call.startedAt).add(1, "hour").startOf("hour"),
            source: call.source || "",
            title: getConversationTitle(call),
            summary: call.summary || ""
        });
    }, [callId]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs(Object.assign(Object.assign(Object.assign({}, inputs), { [name]: value }), (name === 'startedAt' && value && { ['finishedAt']: new Date(moment(value).add(1, 'hour')) })));
    };
    const handleHideModal = () => {
        setInputs(initialFormState);
        setErrors({});
        dispatch(hideEditModal());
    };
    const handleEditCall = () => {
        setErrors({});
        const { startedAt, finishedAt, type } = inputs, inputsRest = __rest(inputs, ["startedAt", "finishedAt", "type"]);
        const data = Object.assign(Object.assign(Object.assign({}, inputsRest), { type, startedAt: startedAt ? startedAt.toISOString() : '' }), (type === CONVERSATION_TYPE_PHONE_CALL && { finishedAt: finishedAt ? finishedAt.toISOString() : '' }));
        const { hasErrors, errors } = validateSchema(ajv, schema, data, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        updateRecording({ id: callId, data })
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    const getSelectedDateTime = (datetime) => {
        return datetime ? new Date(datetime) : datetime;
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: _jsx(Button, { variant: "danger", onClick: handleEditCall, children: t("Save_") }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t("Edit.conversation_") }), _jsxs(Form, { children: [_jsxs(Form.Group, { controlId: "title", children: [_jsx(Form.Label, { children: t("Title_") }), _jsx(FormControl, { placeholder: t("Title_"), name: "title", onChange: handleInputChange, value: inputs.title, isInvalid: !!errors.title }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.title })] }), _jsxs(Form.Group, { controlId: "formSummary", children: [_jsx(Form.Label, { children: t("Summary_") }), _jsx(FormControl, { as: "textarea", rows: 3, placeholder: t("Summary_"), name: "summary", onChange: handleInputChange, value: inputs.summary, isInvalid: !!errors.summary }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.summary })] }), _jsxs(Form.Group, { controlId: "type", children: [_jsx(Form.Label, { children: t("Conversation.type_") }), _jsx("div", { children: CONVERSATION_TYPES_ENUM.map(({ text, value }, index) => {
                                    return (_jsxs(Form.Check, { inline: true, id: index, readOnly: !call.isManuallyCreated, children: [_jsx(Form.Check.Input, { type: "radio", name: "type", value: value, onChange: handleInputChange, checked: value === inputs.type, disabled: !call.isManuallyCreated }), _jsx(Form.Check.Label, { children: text })] }, value));
                                }) })] }), _jsxs(Form.Group, { controlId: "formSource", children: [_jsx(Form.Label, { children: t("From.Source_") }), _jsx(FormControl, { placeholder: t("Source_"), name: "source", onChange: handleInputChange, value: inputs.source, isInvalid: !!errors.source, disabled: !call.isManuallyCreated }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.source })] }), inputs.type === CONVERSATION_TYPE_PHONE_CALL && _jsxs(_Fragment, { children: [_jsxs(Form.Group, { controlId: "phone", children: [_jsx(Form.Label, { children: t("Phone_") }), _jsx(FormControl, { placeholder: t("Phone_"), name: "remotePhone", onChange: handleInputChange, value: inputs.remotePhone, isInvalid: !!errors.remotePhone, disabled: !call.isManuallyCreated }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.remotePhone })] }), _jsxs(Form.Group, { controlId: "direction", children: [_jsx(Form.Label, { children: t("Direction_") }), _jsx("div", { children: CONVERSATION_DIRECTION_LIST.map((type, index) => {
                                            return (_jsxs(Form.Check, { inline: true, id: type, children: [_jsx(Form.Check.Input, { name: "direction", type: "radio", value: type, onChange: handleInputChange, checked: type === inputs.direction, disabled: !call.isManuallyCreated }), _jsx(Form.Check.Label, { children: getConfigEnumText(CONVERSATION_DIRECTIONS_ENUM, type) })] }, type));
                                        }) })] })] }), _jsxs(Form.Group, { controlId: "startedAt", children: [_jsx(Form.Label, { children: t("Started.Datetime_") }), _jsx(DatePicker, { customInput: _jsx(DatePickerInput, {}), placeholderText: `${t("From_")}:`, selected: getSelectedDateTime(inputs.startedAt), onChange: date => handleInputChange({ target: { name: "startedAt", value: date } }), showTimeInput: true, dateFormat: "dd.MM.yyyy HH:mm", wrapperClassName: classNames(!!errors.finishedAt && "is-invalid", "d-block"), className: classNames(!!errors.startedAt && "is-invalid", "form-control"), readOnly: !call.isManuallyCreated }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.startedAt })] }), inputs.type === CONVERSATION_TYPE_PHONE_CALL && _jsxs(Form.Group, { controlId: "finishedAt", children: [_jsx(Form.Label, { children: t("Finished.Datetime_") }), _jsx(DatePicker, { customInput: _jsx(DatePickerInput, {}), placeholderText: `${t("To_")}:`, selected: getSelectedDateTime(inputs.finishedAt), onChange: date => handleInputChange({ target: { name: "finishedAt", value: date } }), showTimeInput: true, dateFormat: "dd.MM.yyyy HH:mm", wrapperClassName: classNames(!!errors.finishedAt && "is-invalid", "d-block"), className: classNames(!!errors.finishedAt && "is-invalid", "form-control"), readOnly: !call.isManuallyCreated }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.finishedAt })] })] })] }));
};
export default EditCallModal;
