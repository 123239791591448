import { combineReducers } from "redux";
import { CALL_DELETE, CALL_MODIFY, CALLS_ADD, CALLS_RESET, FETCH_CALLS_SUCCESS, } from "../../actions/callsHistory";
import { addEntries, addIds, deleteEntry, modifyEntry, removeId, setEntries, setIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case CALL_DELETE:
            return deleteEntry(state, action);
        case CALLS_ADD:
            return addEntries(state, action);
        case CALLS_RESET:
            return {};
        case CALL_MODIFY:
            return modifyEntry(state, action);
        case FETCH_CALLS_SUCCESS:
            return setEntries(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case CALL_DELETE:
            return removeId(state, action);
        case CALLS_ADD:
            return addIds(state, action);
        case CALLS_RESET:
            return [];
        case FETCH_CALLS_SUCCESS:
            return setIds(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
