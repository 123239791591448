import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar } from "banafo-design-system";
import logoDark from "images/logo/logo-dark.svg";
import { Link } from "react-router-dom";
import { SCHEME, WEB_API_URL, WEB_URL } from "src/app/config";
import { AUTH_LOGIN_ROUTE } from "src/app/router/config";
function Header() {
    const { t } = useTranslation();
    return (_jsx("header", { children: _jsx(Navbar, { bg: "white", variant: "light", expand: 'lg', sticky: "top", children: _jsxs(Container, { fluid: "xl", className: "px-lg-400", children: [_jsx(Navbar.Brand, { href: `${SCHEME}://${WEB_URL}/`, children: _jsx("img", { src: logoDark, height: "66", className: "d-inline-block align-top", alt: "Banafo logo" }) }), _jsx(Navbar.Toggle, { className: "border-0", "aria-controls": "basic-navbar-nav" }), _jsx(Navbar.Collapse, { id: "basic-navbar-nav", children: _jsxs(Nav, { className: "ms-auto font-weight-bold text-center", children: [_jsx(Nav.Link, { className: "pe-lg-400", href: `${SCHEME}://${WEB_URL}/en/extension`, children: t('Browser.Extension_') }), _jsx(Nav.Link, { className: "pe-lg-400", href: `${SCHEME}://${WEB_URL}/en/integrations`, children: t('Integrations_') }), _jsx(Nav.Link, { className: "pe-lg-400", href: `${SCHEME}://${WEB_URL}/en/pricing`, children: t('Pricing_') }), _jsx(Nav.Link, { className: "pe-lg-400", href: `${SCHEME}://${WEB_API_URL}`, children: t('Api_') }), _jsx(Nav.Link, { as: Link, className: "pe-lg-400 active", to: AUTH_LOGIN_ROUTE, children: t('Login_') }), _jsx("a", { href: "/", className: "btn btn-dark", children: t("Try.now_") })] }) })] }) }) }));
}
export default Header;
