export const FETCH_RECORDINGS_BEGIN = 'FETCH_RECORDINGS_BEGIN';
export const FETCH_RECORDINGS_SUCCESS = 'FETCH_RECORDINGS_SUCCESS';
export const FETCH_RECORDINGS_FAILURE = 'FETCH_RECORDINGS_FAILURE';
export const EXTRACT_RECORDINGS = 'EXTRACT_RECORDINGS';
export const RECORDINGS_INITIALIZE = 'RECORDINGS_INITIALIZE';
export const RECORDINGS_DE_INITIALIZE = 'RECORDINGS_DE_INITIALIZE';
export const RECORDINGS_LOADING = 'RECORDINGS_LOADING';
export const RECORDINGS_LOADED = 'RECORDINGS_LOADED';
export const RECORDINGS_PAGINATION_SAVE = 'RECORDINGS_PAGINATION_SAVE';
export const RECORDINGS_PAGINATION_RESET = 'RECORDINGS_PAGINATION_RESET';
export const RECORDINGS_ADD = 'RECORDINGS_ADD';
export const RECORDINGS_RESET = 'RECORDINGS_RESET';
export const RECORDING_DELETE = 'RECORDING_DELETE';
