import api from "../../../../api/api";
import { setSuccessMessage } from "./app";
import i18n from "../../../../app/services/i18n";
import { credentialsSaved } from "./auth/login";
import { set } from "../../util/storage";
export const UPDATE_PROFILE_DATA_BEGIN = 'UPDATE_PROFILE_DATA_BEGIN';
export const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA_SUCCESS';
export const UPDATE_PROFILE_DATA_FAILURE = 'UPDATE_PROFILE_DATA_FAILURE';
export const UPDATE_PROFILE_PICTURE_BEGIN = 'UPDATE_PROFILE_PICTURE_BEGIN';
export const UPDATE_PROFILE_PICTURE_SUCCESS = 'UPDATE_PROFILE_PICTURE_SUCCESS';
export const UPDATE_PROFILE_PICTURE_FAILURE = 'UPDATE_PROFILE_PICTURE_FAILURE';
export const HIDE_PROFILE_WELCOME_MESSAGE = 'HIDE_PROFILE_WELCOME_MESSAGE';
export const updateProfileData = (fullName, email) => {
    return (dispatch, getState) => {
        dispatch(updateProfileDataBegin());
        const data = Object.assign(Object.assign({}, (fullName ? { fullName } : {})), (email ? { email } : {}));
        return api.updateProfileData(data)
            .then(response => {
            const user = Object.assign(Object.assign({}, getState().app.auth.user), data);
            set('user', user);
            dispatch(setSuccessMessage(i18n.t('Profile.data.updated.successfully_')));
            dispatch(updateProfileDataSuccess());
            dispatch(credentialsSaved(user));
        })
            .catch(err => {
            dispatch(updateProfileDataFailure(err));
            return Promise.reject(err);
        });
    };
};
export const updateProfileDataBegin = () => ({
    type: UPDATE_PROFILE_DATA_BEGIN
});
export const updateProfileDataSuccess = () => ({
    type: UPDATE_PROFILE_DATA_SUCCESS,
});
export const updateProfileDataFailure = (error) => ({
    type: UPDATE_PROFILE_DATA_FAILURE,
    payload: { error }
});
export const uploadProfilePicture = (userId, formData) => {
    return (dispatch, getState) => {
        dispatch(updateProfilePictureBegin());
        return api.uploadProfilePicture(userId, formData)
            .then(response => {
            dispatch(updateProfilePictureSuccess());
        })
            .catch(err => {
            dispatch(updateProfilePictureFailure(err));
            return Promise.reject(err);
        });
    };
};
export const updateProfilePictureBegin = () => ({
    type: UPDATE_PROFILE_PICTURE_BEGIN
});
export const updateProfilePictureSuccess = () => ({
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
});
export const updateProfilePictureFailure = (error) => ({
    type: UPDATE_PROFILE_PICTURE_FAILURE,
    payload: { error }
});
export const UPDATE_PROFILE_PLAN = 'UPDATE_PROFILE_PLAN';
export const updateProfilePlan = (payload) => ({
    type: UPDATE_PROFILE_PLAN,
    payload
});
export const HIDE_PROFILE_WIZARD = 'HIDE_PROFILE_WIZARD';
const hideWizard = () => ({
    type: HIDE_PROFILE_WIZARD
});
export const hideAndSaveProfileWizard = () => {
    return (dispatch, getState) => {
        return api.updateProfileWizard(false)
            .then(response => {
            dispatch(hideWizard());
            return response;
        });
    };
};
