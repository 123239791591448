import { jsx as _jsx } from "react/jsx-runtime";
import AppCard from "src/features/deviceActivate/AppCard/AppCard";
import { AiFillChrome } from "react-icons/ai";
import { SCHEME, WEB_URL } from "src/app/config";
import { useTranslation } from "react-i18next";
const BrowserExtensionCard = (props) => {
    const { t } = useTranslation();
    return _jsx(AppCard, Object.assign({ icon: _jsx(AiFillChrome, { size: "100%" }), name: t('Browser.Extension_'), moreInfoUrl: `${SCHEME}://${WEB_URL}/en/extension` }, props));
};
export default BrowserExtensionCard;
