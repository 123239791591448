import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ACTIVITY_PAGINATION_LIMIT } from "src/features/activityLog/constants";
const activitiesInitialState = {
    page: 0,
    isLastPage: false
};
const activitiesSlice = createSlice({
    name: 'activities',
    initialState: activitiesInitialState,
    reducers: {
        resetActivities: () => activitiesInitialState,
        incrementPage: (state) => {
            state.page += 1;
        },
        lastPageReached: (state) => {
            state.isLastPage = true;
        },
    }
});
const { name, actions, reducer } = activitiesSlice;
export const { resetActivities, incrementPage, lastPageReached, } = actions;
export { name, actions };
export default reducer;
export const selectPage = (state) => state[name].page;
export const selectIsLastPage = (state) => state[name].isLastPage;
export const selectActivitiesApiArgs = createSelector(selectPage, (page) => {
    const args = { start: page * ACTIVITY_PAGINATION_LIMIT };
    return args;
});
