import { jsx as _jsx } from "react/jsx-runtime";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import FallbackImage from "src/common/components/ui/FallbackImage";
import { selectSidebarIsExpanded } from "./sidebarSlice";
import { useAppSelector } from "src/app/store/hooks";
export const ItemLinkImage = ({ id, title, src }) => {
    const isExpanded = useAppSelector(selectSidebarIsExpanded);
    if (isExpanded) {
        return _jsx(FallbackImage, { src: src, srcFallback: 'https://via.placeholder.com/150?text=Icon', alt: title });
    }
    return (_jsx(OverlayTrigger, { overlay: _jsx(Tooltip, { id: `tooltip-${id}`, children: title }), children: _jsx(FallbackImage, { src: src, srcFallback: 'https://via.placeholder.com/150?text=Icon', alt: title }) }));
};
