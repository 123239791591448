import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Nav, Tab } from "banafo-design-system";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TranscriptsTab from "src/features/transcripts/TranscriptsTab";
import Notes from "src/features/notes/Notes";
import NotesExtraTab from "src/features/notes/NotesExtraTab";
import TranscriptsExtraTab from "src/features/transcripts/TranscriptsExtraTab";
import AiAssistantTab from "src/features/aiAssistant/AiAssistantTab";
import './ConversationDetailsTabs.scss';
import useAccount from "src/features/account/hooks/useAccount";
const ConversationDetailsTabs = () => {
    var _a;
    const location = useLocation();
    const { t } = useTranslation();
    const { isAdmin } = useAccount();
    const activeTabIndex = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.activeTab) ? location.state.activeTab : 0;
    const [activeTab, setActiveTab] = useState(activeTabIndex);
    return (_jsx(_Fragment, { children: _jsxs(Tab.Container, { defaultActiveKey: activeTabIndex, children: [_jsxs(Nav, { variant: "tabs", activeKey: activeTab, onSelect: (selectedKey) => setActiveTab(selectedKey), children: [_jsx(Nav.Item, { className: "fw-bold text-uppercase", children: _jsx(Nav.Link, { eventKey: 0, children: t("Transcript_") }) }), _jsx(Nav.Item, { className: "fw-bold text-uppercase", children: _jsx(Nav.Link, { eventKey: 1, children: t("Highlights_") }) }), isAdmin && _jsx(Nav.Item, { className: "fw-bold text-uppercase", children: _jsx(Nav.Link, { eventKey: 2, children: t("AI.assistant_") }) }), activeTab == 0 && _jsx(TranscriptsExtraTab, {}), activeTab == 1 && _jsx(NotesExtraTab, {})] }), _jsxs(Tab.Content, { className: "conversation-tabs-container", children: [_jsx(Tab.Pane, { eventKey: 0, children: _jsx(TranscriptsTab, {}) }), _jsx(Tab.Pane, { eventKey: 1, children: _jsx(Notes, {}) }), isAdmin && _jsx(Tab.Pane, { eventKey: 2, className: "ai-assistant", children: _jsx(AiAssistantTab, {}) })] })] }) }));
};
export default ConversationDetailsTabs;
