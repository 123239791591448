var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import api from "../../../../api/api";
import { setErrorMessage, setSuccessMessage } from "./app";
import i18n from '../../../../app/services/i18n';
export const FETCH_RECORDING_NOTES_BEGIN = 'FETCH_RECORDING_NOTES_BEGIN';
export const FETCH_RECORDING_NOTES_SUCCESS = 'FETCH_RECORDING_NOTES_SUCCESS';
export const FETCH_RECORDING_NOTES_FAILURE = 'FETCH_RECORDING_NOTES_FAILURE';
export const CLEAR_RECORDING_NOTES = 'CLEAR_RECORDING_NOTES';
export const ADD_RECORDING_NOTE_SUCCESS = 'ADD_RECORDING_NOTE_SUCCESS';
export const REMOVE_RECORDING_NOTE_SUCCESS = 'REMOVE_RECORDING_NOTE_SUCCESS';
export const UPDATE_RECORDING_NOTE_SUCCESS = 'UPDATE_RECORDING_NOTE_SUCCESS';
export const EXTRACT_NOTES = 'EXTRACT_NOTES';
;
export const addNoteSuccess = (item) => ({
    type: ADD_RECORDING_NOTE_SUCCESS,
    payload: { item }
});
export const addNote = (note) => {
    return (dispatch, getState) => {
        return api.addNote(note)
            .then(response => {
            const { _id: id, description } = response, other = __rest(response, ["_id", "description"]);
            dispatch(addNoteSuccess(Object.assign({ id, description }, other)));
            if (!description) {
                dispatch(setSuccessMessage(i18n.t('Point.of.interest.created.successfully_')));
            }
            else {
                dispatch(setSuccessMessage(i18n.t('Note.created.successfully_')));
            }
        })
            .catch(err => {
            dispatch(setErrorMessage(err.response.body.message));
            return Promise.reject(err);
        });
    };
};
