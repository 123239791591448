var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _TranscriptStreamFactory_mergeSortedUtterances, _TranscriptStreamFactory_mergeStreams;
class TranscriptStreamFactory {
    constructor() {
        _TranscriptStreamFactory_mergeSortedUtterances.set(this, (utterances1, utterances2) => {
            const merged = new Array(utterances1.length + utterances2.length);
            let index1 = 0;
            let index2 = 0;
            let indexMerged = 0;
            for (; index1 < utterances1.length && index2 < utterances2.length; indexMerged += 1) {
                if (utterances1[index1].startedAt < utterances2[index2].startedAt) {
                    merged[indexMerged] = utterances1[index1];
                    index1 += 1;
                }
                else {
                    merged[indexMerged] = utterances2[index2];
                    index2 += 1;
                }
            }
            for (; index1 < utterances1.length; index1 += 1, indexMerged += 1) {
                merged[indexMerged] = utterances1[index1];
            }
            for (; index2 < utterances2.length; index2 += 1, indexMerged += 1) {
                merged[indexMerged] = utterances2[index2];
            }
            return merged;
        });
        _TranscriptStreamFactory_mergeStreams.set(this, (streams) => {
            return streams.reduce((transcriptsAcc, { origin, fileId, transcript: transcripts }) => {
                return __classPrivateFieldGet(this, _TranscriptStreamFactory_mergeSortedUtterances, "f").call(this, transcriptsAcc, transcripts.map((_a) => {
                    var { _id: id } = _a, transcript = __rest(_a, ["_id"]);
                    return Object.assign({ id,
                        origin,
                        fileId }, transcript);
                }));
            }, []);
        });
    }
    create(transcriptStreams) {
        return __classPrivateFieldGet(this, _TranscriptStreamFactory_mergeStreams, "f").call(this, transcriptStreams);
    }
}
_TranscriptStreamFactory_mergeSortedUtterances = new WeakMap(), _TranscriptStreamFactory_mergeStreams = new WeakMap();
export default TranscriptStreamFactory;
