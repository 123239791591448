import { AUDIO_CURRENT_TIME_CHANGED, AUDIO_ERROR, AUDIO_FINISHED, AUDIO_PAUSED, AUDIO_PLAYING, AUDIO_READY, CLEAR_AUDIO_ERROR, LOAD_AUDIO, SET_FAST_FORWARD_INTERVAL, SET_PLAYBACK_RATE, UNLOAD_AUDIO } from "../../actions/playback";
import { WAVE_SURFER_FAST_FORWARD_INTERVAL_10 } from "../../../wavesurfer/config";
const initial = {
    audioUrls: null,
    currentTime: null,
    duration: null,
    error: null,
    loading: false,
    playing: false,
    rate: 1,
    ready: false,
    fastForwardInterval: WAVE_SURFER_FAST_FORWARD_INTERVAL_10,
    format: "audio/wav"
};
export default function playback(state = initial, action) {
    switch (action.type) {
        case AUDIO_CURRENT_TIME_CHANGED:
            return Object.assign(Object.assign({}, state), { currentTime: action.currentTime });
        case AUDIO_ERROR:
            return Object.assign(Object.assign({}, state), { error: action.error.message, loading: false });
        case CLEAR_AUDIO_ERROR:
            return Object.assign(Object.assign({}, state), { error: null });
        case AUDIO_PAUSED:
            return Object.assign(Object.assign({}, state), { playing: false });
        case AUDIO_FINISHED:
            return Object.assign(Object.assign({}, state), { currentTime: action.currentTime, playing: false });
        case AUDIO_PLAYING:
            return Object.assign(Object.assign({}, state), { playing: true });
        case AUDIO_READY:
            return Object.assign(Object.assign({}, state), { currentTime: action.currentTime, duration: action.duration, format: action.format, loading: false, ready: true });
        case LOAD_AUDIO:
            return Object.assign(Object.assign({}, initial), { audioUrls: action.audioUrls, loading: true });
        case SET_PLAYBACK_RATE:
            return Object.assign(Object.assign({}, state), { rate: action.rate });
        case SET_FAST_FORWARD_INTERVAL:
            return Object.assign(Object.assign({}, state), { fastForwardInterval: action.interval });
        case UNLOAD_AUDIO:
            return initial;
        default:
            return state;
    }
}
