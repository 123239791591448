import { apiClient } from "src/app/services/httpClient";
export const getProviders = () => {
    return apiClient
        .get(`/providers`);
};
export const acceptInvitation = (tokenId) => {
    return apiClient
        .post(`/teams/invites/accept`)
        .send({ tokenId });
};
export const getContactUrl = (contactId, type, provider) => {
    return apiClient
        .get(`/contacts/contact-url`)
        .query({ provider, contactId, type });
};
export const newsletterSubscribe = (provider, genericNotification, source) => {
    return apiClient
        .post(`/newsletter/authenticated`)
        .send({ provider, genericNotification, source });
};
