import { combineReducers } from "redux";
import common from "./common";
import historyCalls from "./historyCalls";
import historyCallDetails from "./historyCallDetails";
import recordings from "./recordings";
import loader from "./loader";
import playback from "./playback";
import recording_notes from "./recordingNotes";
import recording_calls from "./recordingCalls";
import recording_transcripts from "./recordingTranscripts";
import search_transcripts from "./searchTranscripts";
import recordingDetails from "./recordingDetails";
import auth from "./authReducer";
import payment from "./payment";
import profile from "./profile";
import settings from "./settings";
import contacts from "./contacts";
import browserExtension from "./browserExtension";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['user', 'redirectUrl']
};
const appReducer = combineReducers({
    common,
    auth: persistReducer(authPersistConfig, auth),
    historyCalls,
    historyCallDetails,
    recordings,
    loader,
    playback,
    recording_notes,
    recording_calls,
    recording_transcripts,
    recordingDetails,
    payment,
    profile,
    search_transcripts,
    settings,
    contacts,
    browserExtension
});
export default appReducer;
