import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { SpinnerBackdrop } from "banafo-design-system";
import { persistor, store } from "./store/store";
import SentryFallback from "src/app/sentry/SentryFallback";
import { PersistGate } from "redux-persist/integration/react";
import { gtmParams } from "src/app/services/googleTagManager";
import MainRouter from "./router/MainRouter";
import { MantineProvider } from "@mantine/core";
import theme from "src/app/mantine/theme";
import cssVariablesResolver from "src/app/mantine/cssVariablesResolver";
function App() {
    return (_jsx(MantineProvider, { theme: theme, defaultColorScheme: "light", cssVariablesResolver: cssVariablesResolver, children: _jsx(React.Suspense, { fallback: _jsx(SpinnerBackdrop, {}), children: _jsx(Provider, { store: store, children: _jsx(PersistGate, { loading: _jsx(SpinnerBackdrop, {}), persistor: persistor, children: _jsx(Router, { children: _jsx(GTMProvider, { state: gtmParams, children: _jsx(Sentry.ErrorBoundary, { fallback: (props) => _jsx(SentryFallback, Object.assign({}, props)), children: _jsx(MainRouter, {}) }) }) }) }) }) }) }));
}
export default Sentry.withProfiler(App);
