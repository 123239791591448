import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Stack } from "banafo-design-system";
import DropdownMenu from "src/features/contacts/DropdownMenu";
import { useTranslation } from "react-i18next";
import { selectCombinedContactsResult, selectFilters, selectPage, } from "src/features/contacts/apiContactsSlice";
import { useSelector } from "react-redux";
import { CONTACTS_PAGINATION_LIMIT } from "./config";
const TableFilters = () => {
    const { t } = useTranslation();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const perPage = CONTACTS_PAGINATION_LIMIT;
    const start = page * perPage;
    const { data: { total } } = useSelector((state) => selectCombinedContactsResult(state, { searchFilters, start }));
    return (_jsxs(Stack, { direction: 'horizontal', children: [_jsxs("h6", { className: 'text-uppercase fw-bold m-0', children: [t('Contacts_'), " (", total, ")"] }), _jsx(DropdownMenu, {})] }));
};
export default TableFilters;
