import { useEffect, useRef } from "react";
const useScrollItemsList = (items) => {
    const scrollRef = useRef(null);
    useEffect(() => {
        if (!scrollRef.current)
            return;
        if (items.length <= 0) {
            return;
        }
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }, [items]);
    return { scrollRef };
};
export default useScrollItemsList;
