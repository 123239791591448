import moment from "moment";
import { DATE_TIME_FORMAT, DURATION_PAD_STRING, DURATION_TARGET_LENGTH } from "../config/datetime";
/**
 * Format datetime.
 *
 * @param dateTime
 * @param format
 * @returns {string}
 */
export const formattedDateTime = (dateTime, format = DATE_TIME_FORMAT) => {
    return moment(dateTime).format(format);
};
export const formattedDateTimeFromNow = (dateTime) => {
    return moment(dateTime).fromNow();
};
/**
 * Formatting time to hours minutes and seconds.
 *
 * @description Formatted time must be in milliseconds!
 * @param {string, number} time
 * @param format
 * @returns {string}
 */
export const formattedDuration = (time, format = 'mm:ss') => {
    return moment.utc(time).format(format);
};
