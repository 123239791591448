import { jsx as _jsx } from "react/jsx-runtime";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import { FaGoogleDrive } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { AccountUpgradeModalTypes, FeatureTypes } from "src/features/account/types";
import useAccount from "src/features/account/hooks/useAccount";
const GoogleDriveOpenButton = ({ googleDriveUrl }) => {
    const { t } = useTranslation();
    const { isFeatureEnabled, upgradeAccount } = useAccount();
    const googleDriveFeatureEnabled = isFeatureEnabled(FeatureTypes.GoogleDriveIntegration);
    const handleOpenDrive = () => {
        if (!googleDriveFeatureEnabled) {
            return upgradeAccount(AccountUpgradeModalTypes.GoogleDrive);
        }
        window.open(googleDriveUrl, "_blank");
    };
    return (_jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "google-drive-add", children: t("Open.in.Google.Drive_") }), children: _jsx("span", { children: _jsx(FaGoogleDrive, { size: 18, onClick: handleOpenDrive }) }) }));
};
export default GoogleDriveOpenButton;
