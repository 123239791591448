import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RESELLER_CUSTOMERS_PAGINATION_LIMIT, SORT_ASC, SORT_DESC } from "src/features/resellerCustomers/constants";
const initFilters = {
    search: '',
};
const resellerCustomersInitialState = {
    page: 0,
    isLastPage: false,
    filters: initFilters,
    inviteCustomerModal: {
        isOpen: false
    },
    purchaseModal: {
        isOpen: false,
        resellerCustomerId: undefined
    },
    purchaseReviewModal: {
        isOpen: false,
    }
};
const resellerCustomersSlice = createSlice({
    name: 'resellerCustomers',
    initialState: resellerCustomersInitialState,
    reducers: {
        resetResellerCustomers: () => resellerCustomersInitialState,
        incrementResellerCustomersPage: (state) => {
            state.page += 1;
        },
        resellerCustomersLastPageReached: (state) => {
            state.isLastPage = true;
        },
        changeFilter: (state, action) => {
            const { name, value } = action.payload;
            state.filters[name] = value;
            state.page = 0;
            state.isLastPage = false;
        },
        changeSortFilter: (state, action) => {
            const name = action.payload;
            const field = `sort[${name}]`;
            state.page = 0;
            state.isLastPage = false;
            if (!state.filters[field]) {
                state.filters[field] = SORT_ASC;
                return;
            }
            if (state.filters[field] === SORT_ASC) {
                state.filters[field] = SORT_DESC;
                return;
            }
            delete state.filters[field];
        },
        showInviteCustomerModal: state => {
            state.inviteCustomerModal.isOpen = true;
        },
        hideInviteCustomerModal: state => {
            state.inviteCustomerModal.isOpen = false;
        },
        showPurchaseModal: (state, action) => {
            state.purchaseModal.isOpen = true;
            state.purchaseModal.resellerCustomerId = action.payload;
        },
        hidePurchaseModal: state => {
            state.purchaseModal.isOpen = false;
            state.purchaseModal.resellerCustomerId = undefined;
        },
        showPurchaseReviewModal: state => {
            state.purchaseReviewModal.isOpen = true;
        },
        hidePurchaseReviewModal: state => {
            state.purchaseReviewModal.isOpen = false;
            state.purchaseModal.resellerCustomerId = undefined;
        },
        switchPurchaseModals: state => {
            state.purchaseModal.isOpen = !state.purchaseModal.isOpen;
            state.purchaseReviewModal.isOpen = !state.purchaseReviewModal.isOpen;
        }
    }
});
const { name, actions, reducer } = resellerCustomersSlice;
export const { resetResellerCustomers, incrementResellerCustomersPage, resellerCustomersLastPageReached, showInviteCustomerModal, hideInviteCustomerModal, showPurchaseModal, hidePurchaseModal, showPurchaseReviewModal, hidePurchaseReviewModal, switchPurchaseModals, changeFilter, changeSortFilter, } = actions;
export { name, actions };
export default reducer;
export const selectResellerCustomersFilters = (state) => state[name].filters;
export const selectResellerCustomersPage = (state) => state[name].page;
export const selectResellerCustomersIsLastPage = (state) => state[name].isLastPage;
export const selectResellerCustomersApiArgs = createSelector(selectResellerCustomersPage, selectResellerCustomersFilters, (page, filters) => {
    const args = { start: page * RESELLER_CUSTOMERS_PAGINATION_LIMIT, filters };
    return args;
});
export const selectInviteCustomerModalIsOpen = (state) => state[name].inviteCustomerModal.isOpen;
export const selectPurchaseModalIsOpen = (state) => state[name].purchaseModal.isOpen;
export const selectPurchaseModalResellerCustomerId = (state) => state[name].purchaseModal.resellerCustomerId;
export const selectPurchaseReviewModalIsOpen = (state) => state[name].purchaseReviewModal.isOpen;
