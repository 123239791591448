import { apiClient } from "src/app/services/httpClient";
export const getNotes = (query) => {
    return apiClient
        .get(`/local-notes`)
        .query(query);
};
export const removeNote = (noteId) => {
    return apiClient
        .delete(`/local-notes/${noteId}`);
};
export const editNote = (note) => {
    return apiClient
        .put(`/local-notes/${note.id}`)
        .send(note);
};
export const addNote = (note) => {
    return apiClient
        .post(`/local-notes`)
        .send(note);
};
