import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BanafoModal, Button, Card, Form } from "banafo-design-system";
import codePlaceholder from "images/other/code-placeholder.jpg";
import { DASHBOARD_INTEGRATIONS } from "src/features/auth/config/newsletter";
import { useDispatch, useSelector } from "react-redux";
import { resetConnectingProviderId, selectConnectingProvider } from "src/features/integrations/integrationsSlice";
import { useNewsletterSubscribeMutation } from "src/features/account/accountApi";
const ComingSoonModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [subscribe, setSubscribe] = useState(true);
    const [newsletterSubscribe] = useNewsletterSubscribeMutation({ fixedCacheKey: "newsletter-subscribe" });
    const { id, name } = useSelector((state) => selectConnectingProvider(state));
    const handleHideModal = () => {
        dispatch(resetConnectingProviderId());
    };
    const handleSubscribeChange = (e) => {
        const { checked } = e.target;
        setSubscribe(checked);
    };
    const handleNotifyMe = () => {
        newsletterSubscribe({ provider: name, genericNotification: subscribe, source: DASHBOARD_INTEGRATIONS })
            .unwrap()
            .then(() => {
            handleHideModal();
        })
            .catch(e => null);
    };
    return (_jsx(BanafoModal, { show: !!id, onHide: handleHideModal, className: 'Integrations-modal', "aria-labelledby": "contained-modal-title-vcenter", children: _jsxs(Card, { className: 'border-0', children: [_jsx(Card.Img, { variant: "top", src: require(`../images/provider-logo-${name}.png`) }), _jsxs(Card.Body, { className: "text-center", children: [_jsx(Card.Text, { children: t('under.development_') }), _jsx(Card.Img, { variant: "null", className: "mb-200", src: codePlaceholder }), _jsxs(Card.Text, { children: [t('infos:Provider.integration.vote_', { providerName: name }), ":"] }), _jsxs(Button, { className: "mt-100 mb-300", variant: "success", onClick: handleNotifyMe, children: [t('Notify.me_'), "!"] }), _jsx(Form.Check, { label: t('Get.news.about.beta.programs,.new.features.and.more_'), type: 'checkbox', id: `general-subscription`, checked: subscribe, name: "direction_inbound", onChange: handleSubscribeChange, className: "text-start" })] })] }) }));
};
export default ComingSoonModal;
