import { jsx as _jsx } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Contacts from "src/features/contacts/Contacts";
import Header from "./Header";
const ContactsPage = () => {
    return (_jsx(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "p-200" }
        }, children: _jsx(Contacts, {}) }));
};
export default ContactsPage;
