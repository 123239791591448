import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown, Form } from "banafo-design-system";
import CustomDropdownToggle from "src/common/components/ui/CustomDropdownToggle";
import HamburgerMenu from "src/common/components/ui/HamburgerMenu";
import { changeFilter, selectFilters, selectFiltersHamburgerIsExpanded, toggleFiltersHamburgerMenu, } from "src/features/contacts/apiContactsSlice";
import { selectActiveContactsIntegrations, useGetIntegrationsQuery } from "src/features/integrations/integrationsSlice";
import FilterMenu from "src/common/components/ui/FilterMenu";
const DropdownMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchFilters = useSelector(selectFilters);
    const integrations = useSelector(selectActiveContactsIntegrations);
    const { isSuccess: isSuccessIntegrations } = useGetIntegrationsQuery();
    const hasActiveFilters = !!searchFilters.types.length;
    const contactTypesOptions = () => {
        if (!searchFilters.provider) {
            return [];
        }
        return integrations.find(integration => integration.providerName === searchFilters.provider).contactTypes;
    };
    const isHamburgerMenuExpanded = useSelector(selectFiltersHamburgerIsExpanded);
    const toggleMenu = () => dispatch(toggleFiltersHamburgerMenu());
    const handleMenuToggle = () => {
        toggleMenu();
    };
    const handleCheckboxFilterChange = (e) => {
        const { name, value, checked } = e.target;
        let filter = [];
        if (searchFilters.types.includes(value)) {
            filter = searchFilters.types.filter(item => !value.includes(item));
        }
        else {
            filter = [...searchFilters.types, value];
        }
        dispatch(changeFilter({ name, value: filter }));
        toggleMenu();
    };
    return (_jsxs(Dropdown, { onToggle: handleMenuToggle, autoClose: "outside", className: 'ms-100', children: [hasActiveFilters && _jsx("span", { className: "position-absolute top-0 start-100 translate-middle p-25 bg-danger border border-light rounded-circle" }), _jsx(Dropdown.Toggle, { as: CustomDropdownToggle, el: (props) => _jsx(FilterMenu, Object.assign({}, props)), isOpen: isHamburgerMenuExpanded, bsPrefix: " ", id: "sidebar-dropdown-menu" }), _jsx(Dropdown.Menu, { children: isSuccessIntegrations && contactTypesOptions().map(contactType => {
                    return _jsx(Dropdown.Item, { as: "button", children: _jsx(Form.Check, { custom: true, inline: true, variant: "tertiary", label: contactType, type: 'checkbox', id: `types_${contactType}`, checked: searchFilters.types.includes(contactType), name: `types`, value: contactType, onChange: handleCheckboxFilterChange }, contactType) });
                }) })] }));
};
export default DropdownMenu;
