import { APP_LOADING_START, APP_LOADING_FINISH } from '../../actions/loader';
const initial = [];
export default function loader(state = initial, action) {
    switch (action.type) {
        case APP_LOADING_START:
            return [...state, action.key];
        case APP_LOADING_FINISH:
            return state.filter(scope => scope !== action.key);
        default:
            return state;
    }
}
