import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URI_IDENTITY, MICROSOFT_CLIENT_ID, MICROSOFT_REDIRECT_URI_IDENTITY } from "src/app/config";
export const googleUrl = `https://accounts.google.com/o/oauth2/auth?${new URLSearchParams({
    response_type: "code",
    scope: [
        'email',
        'profile',
        'openid',
        // 'https://www.googleapis.com/auth/contacts.readonly',
        // 'https://www.googleapis.com/auth/calendar',
    ].join(' '),
    redirect_uri: GOOGLE_REDIRECT_URI_IDENTITY,
    client_id: GOOGLE_CLIENT_ID,
    state: 'google',
    // prompt: 'select_account'
    access_type: 'offline',
    prompt: 'consent'
})}`;
export const microsoftUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${new URLSearchParams({
    response_type: "code",
    response_mode: "query",
    scope: [
        'offline_access',
        'openid',
        // '	Calendars.ReadWrite'
    ].join(' '),
    redirect_uri: MICROSOFT_REDIRECT_URI_IDENTITY,
    client_id: MICROSOFT_CLIENT_ID,
    state: 'microsoft'
})}`;
