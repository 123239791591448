import { combineReducers } from "redux";
import { setEntries, setIds } from "../../../util/reducers";
import { FETCH_RECORDING_TRANSCRIPTS_SUCCESS } from "../../actions/transcripts";
function byId(state = {}, action) {
    switch (action.type) {
        case FETCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return setEntries(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case FETCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return setIds(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
