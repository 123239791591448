import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import parse from 'html-react-parser';
import { CONVERSATIONS_BY_CALL_ID_ROUTE, CONVERSATIONS_BY_CALL_ID_TRANSCRIPT_ID_ROUTE } from "src/app/router/config";
import { setInitialActiveTranscriptId } from "src/features/transcripts/transcriptsSlice";
function SearchResultRow({ searchTranscript, transcriptRow, targetValue, handleHideModal }) {
    const dispatch = useDispatch();
    const handleLinkClick = (e) => {
        e.stopPropagation();
        handleHideModal();
        dispatch(setInitialActiveTranscriptId(transcriptRow._id));
    };
    const highlightTranscript = (transcriptRow, inputSearch) => {
        const strRegExp = new RegExp(inputSearch, 'g');
        return parse(transcriptRow.replace(strRegExp, `<strong>${inputSearch}</strong>`));
    };
    return (_jsxs("tr", { className: "cursor-pointer position-relative", children: [_jsx("td", { children: searchTranscript.createdAt ? moment(searchTranscript.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-' }), _jsx("td", { children: _jsx(Link, { to: generatePath(CONVERSATIONS_BY_CALL_ID_TRANSCRIPT_ID_ROUTE, { callId: searchTranscript.callId, transcriptId: transcriptRow._id }), className: "link-dark stretched-link", onClick: handleLinkClick, children: highlightTranscript(transcriptRow.text, targetValue) }) })] }));
}
SearchResultRow.propTypes = {
    searchTranscript: PropTypes.object.isRequired,
    transcriptRow: PropTypes.object.isRequired,
    targetValue: PropTypes.string,
    userId: PropTypes.string,
    handleHideModal: PropTypes.func
};
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SearchResultRow);
