var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { BifIconCopy, BifIconInformationCircle, BifIconKey, Col, Form, Row } from "banafo-design-system";
import { Button, ComboboxChevron, Divider, Select, Text } from "@mantine/core";
import useConvertToCombobox from "src/common/hooks/useConvertToCombobox";
import { VoIpServerTypes } from "src/features/resellerCustomerSettings/types";
import { useField } from "@mantine/form";
import { makeSelectAllResellerCustomerApiKeys, useGenerateResellerCustomerApiKeyMutation, useGenerateResellerCustomerFtpPassMutation, useGetResellerCustomerApiKeysQuery, useGetResellerCustomerFtpSettingsQuery, useRevokeResellerCustomerApiKeyMutation } from "src/features/resellerCustomerSettings/resellerCustomerSettingsApi";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ApiKeysTable from "src/features/apiKeys/ApiKeysTable";
import { useAppSelector } from "src/app/store/hooks";
import ApiKeysRow from "src/features/apiKeys/ApiKeysRow";
import DeleteApiKeyModal from "src/features/apiKeys/modals/DeleteApiKeyModal";
import { RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE } from "src/app/router/config";
const SettingsForm = () => {
    const { t } = useTranslation();
    const { resellerCustomerId } = useParams();
    const field = useField({
        initialValue: '',
        onValueChange: (value) => {
            resetFtpPass();
            resetApiKey();
            setCopyInfoText(t('infos:Copy.FTP.password.to.clipboard_'));
            setCopyApiKeyInfoText(t('infos:Copy.API.key.to.clipboard_'));
        },
    });
    const voIpServers = useConvertToCombobox(VoIpServerTypes);
    // 3CX
    const { data: resellerCustomerFtpSettings, isLoading: isLoadingFtpSettings } = useGetResellerCustomerFtpSettingsQuery(resellerCustomerId, { skip: field.getValue() !== VoIpServerTypes["3CX"] });
    const [generateFtpPass, { data: dataFtpPass, isLoading: isLoadingGeneratePass, isSuccess: isSuccessGeneratePass, reset: resetFtpPass }] = useGenerateResellerCustomerFtpPassMutation();
    const handleCreateFtpPassword = () => {
        generateFtpPass(resellerCustomerId);
    };
    const [copyInfoText, setCopyInfoText] = useState('');
    const ftpPassRef = useRef(null);
    const handleCopyFtpPassword = () => {
        var _a, _b;
        try {
            (_a = ftpPassRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = ftpPassRef.current) === null || _b === void 0 ? void 0 : _b.select();
            document.execCommand('copy');
            setCopyInfoText(t('Copied.to.clipboard_'));
        }
        catch (err) {
            // @ts-ignore
            setCopyInfoText(err.message);
        }
    };
    // OTHER
    // @ts-ignore
    const { isLoading: isLoadingApiKeys } = useGetResellerCustomerApiKeysQuery(resellerCustomerId, { skip: ![VoIpServerTypes.Asterisk, VoIpServerTypes.FreeSwitch, VoIpServerTypes.Other].includes(field.getValue()) });
    const selectAllResellerCustomerApiKeys = useMemo(makeSelectAllResellerCustomerApiKeys, []);
    const resellerCustomerApiKeys = useAppSelector(state => selectAllResellerCustomerApiKeys(state, resellerCustomerId));
    const [generateResellerApiKey, { data: dataApiKey, isLoading: isLoadingGenerateApiKey, isSuccess: isSuccessApiKey, reset: resetApiKey }] = useGenerateResellerCustomerApiKeyMutation();
    const handleCreateResellerApiKey = () => {
        generateResellerApiKey(resellerCustomerId);
    };
    const [revokeResellerCustomerApiKey] = useRevokeResellerCustomerApiKeyMutation();
    const [deleteApiKeyModalShow, setDeleteApiKeyModalShow] = useState(false);
    const [deleteApiKeyId, setDeleteApiKeyId] = useState('');
    const handleDeleteApiKey = (id) => () => {
        setDeleteApiKeyModalShow(true);
        setDeleteApiKeyId(id);
    };
    const [copyApiKeyRefInfoText, setCopyApiKeyInfoText] = useState('');
    const apiKeyRef = useRef(null);
    const handleCopyApiKey = () => {
        var _a, _b;
        try {
            (_a = apiKeyRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = apiKeyRef.current) === null || _b === void 0 ? void 0 : _b.select();
            document.execCommand('copy');
            setCopyApiKeyInfoText(t('Copied.to.clipboard_'));
        }
        catch (err) {
            // @ts-ignore
            setCopyApiKeyInfoText(err.message);
        }
    };
    useEffect(() => {
        resetFtpPass();
        resetApiKey();
    }, [field.getValue()]);
    return (_jsx(Form, { className: "mx-50 my-200", children: _jsxs(Form.Group, { children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t('Configure.Client.Options_') }), _jsx(Row, { className: "justify-content-between align-items-center", children: _jsx(Col, { xs: "auto", children: _jsx(Select, Object.assign({ allowDeselect: false, rightSection: _jsx(ComboboxChevron, { c: "dark.6" }), comboboxProps: {
                                zIndex: 1300
                            }, placeholder: t('Select.VoIP.Server_'), data: voIpServers }, field.getInputProps())) }) }), field.getValue() === VoIpServerTypes["3CX"] && !isLoadingFtpSettings &&
                    _jsxs(_Fragment, { children: [_jsx(Text, { size: "lg", mt: "md", children: _jsx(Trans, { i18nKey: "Click.here.for.Guide.how.to.setup.for.3Cx_", components: [_jsx(Link, { to: RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE, target: "_blank" })] }) }), _jsxs(Row, { className: "justify-content-between align-items-center mt-150", children: [_jsxs(Form.Label, { className: "d-block", children: ["FTP server: ", resellerCustomerFtpSettings === null || resellerCustomerFtpSettings === void 0 ? void 0 : resellerCustomerFtpSettings.server] }), _jsxs(Form.Label, { className: "d-block", children: ["FTP username: ", resellerCustomerFtpSettings === null || resellerCustomerFtpSettings === void 0 ? void 0 : resellerCustomerFtpSettings.username] }), _jsxs(Form.Label, { className: "d-block", children: ["FTP password:", _jsx(Button, { rightSection: _jsx(BifIconKey, {}), onClick: handleCreateFtpPassword, loading: isLoadingGeneratePass, tt: "uppercase", ml: "sm", children: t('Generate.new_') })] }), _jsx(Form.Label, { className: "d-block", children: _jsx(Text, { fw: "bold", children: t('infos:Lost.your.FTP.password_') }) })] }), isSuccessGeneratePass &&
                                _jsxs(_Fragment, { children: [_jsx(Divider, { size: "md", my: "sm" }), _jsxs(Row, { className: "justify-content-center", children: [_jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Your.generated.FTP.password_') }), _jsxs(Col, { className: "d-flex align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, ref: ftpPassRef, value: dataFtpPass === null || dataFtpPass === void 0 ? void 0 : dataFtpPass.password, className: "d-inline-block" }), _jsxs(Form.Text, { className: "d-block text-dark", children: ["* ", copyInfoText] })] }), _jsx(Button, { rightSection: _jsx(BifIconCopy, {}), onClick: handleCopyFtpPassword, tt: "uppercase", children: t('Copy_') })] })] })] })] }), (field.getValue() === VoIpServerTypes.FreeSwitch || field.getValue() === VoIpServerTypes.Asterisk || field.getValue() === VoIpServerTypes.Other) && !isLoadingApiKeys &&
                    _jsxs(_Fragment, { children: [_jsx(Text, { size: "lg", mt: "md", children: _jsx(Trans, { i18nKey: "Click.here.for.Guide.how.to.setup.it.up_", components: [_jsx(Link, { to: RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE, target: "_blank" })] }) }), _jsxs(Row, { className: "my-150 g-0", children: [_jsx("h5", { className: "text-uppercase", children: t('Api.keys_') }), _jsx(Col, { className: "d-flex justify-content-between align-items-start", children: _jsx(Button, { rightSection: _jsx(BifIconKey, {}), onClick: handleCreateResellerApiKey, loading: isLoadingGenerateApiKey, tt: "uppercase", ml: "auto", children: t('Generate.new_') }) })] }), _jsx(ApiKeysTable, { apiKeys: resellerCustomerApiKeys, 
                                // @ts-ignore
                                renderItem: (_a) => {
                                    var { id } = _a, apiKey = __rest(_a, ["id"]);
                                    return _jsx(ApiKeysRow, Object.assign({ id: id }, apiKey, { handleDelete: handleDeleteApiKey(id) }), id);
                                } }), _jsx(DeleteApiKeyModal, { show: deleteApiKeyModalShow, onHide: () => setDeleteApiKeyModalShow(false), deleteApiKey: () => revokeResellerCustomerApiKey({ userId: resellerCustomerId, apiKeyId: deleteApiKeyId }).unwrap() }), _jsxs("div", { children: ["* ", t('infos:Api.keys.page.info.bullets.second_')] }), isSuccessApiKey &&
                                _jsxs(_Fragment, { children: [_jsx(Divider, { size: "md", my: "sm" }), _jsxs(Row, { className: "justify-content-center", children: [_jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Your.generated.FTP.password_') }), _jsxs(Col, { className: "d-flex align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, ref: apiKeyRef, value: dataApiKey === null || dataApiKey === void 0 ? void 0 : dataApiKey.apiKey, className: "d-inline-block" }), _jsxs(Form.Text, { className: "d-block text-dark", children: ["* ", copyApiKeyRefInfoText] })] }), _jsx(Button, { rightSection: _jsx(BifIconCopy, {}), onClick: handleCopyApiKey, tt: "uppercase", children: t('Copy_') })] })] })] })] })] }) }));
};
export default SettingsForm;
