import { combineReducers } from "redux";
import { setEntries, setIds } from "src/features/auth/util/reducers";
import { SEARCH_RECORDING_TRANSCRIPTS_SUCCESS, SEARCH_RECORDING_TRANSCRIPTS_RESET } from "../../actions/transcripts";
function byId(state = {}, action) {
    switch (action.type) {
        case SEARCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return setEntries(state, action);
        case SEARCH_RECORDING_TRANSCRIPTS_RESET:
            return {};
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case SEARCH_RECORDING_TRANSCRIPTS_SUCCESS:
            return setIds(state, action);
        case SEARCH_RECORDING_TRANSCRIPTS_RESET:
            return [];
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
