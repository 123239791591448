import { combineReducers } from "redux";
import { setEntry, setId } from "../../../util/reducers";
import { EXTRACT_CALL_DETAILS, FETCH_CALL_DETAILS_SUCCESS } from "../../actions/callsHistoryDetails";
function byId(state = {}, action) {
    switch (action.type) {
        case EXTRACT_CALL_DETAILS:
        case FETCH_CALL_DETAILS_SUCCESS:
            return setEntry(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case EXTRACT_CALL_DETAILS:
        case FETCH_CALL_DETAILS_SUCCESS:
            return setId(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
