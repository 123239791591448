import apiApp from "src/app/services/apiApp";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        updateConversationSummary: builder.mutation({
            query({ id, summary }) {
                return {
                    url: `/calls/${id}`,
                    method: "PUT",
                    body: { summary }
                };
            },
            invalidatesTags: ["Call"]
        }),
        updateConversationActionPoints: builder.mutation({
            query({ id, actionPoints }) {
                return {
                    url: `/calls/${id}`,
                    method: "PUT",
                    body: { actionPoints }
                };
            },
            invalidatesTags: ["Call"]
        }),
    })
});
export const { useUpdateConversationSummaryMutation, useUpdateConversationActionPointsMutation } = extendedApi;
