import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Badge, BifIconLink } from "banafo-design-system";
const ProviderItemBadge = ({ isActive }) => {
    const { t } = useTranslation();
    if (!isActive) {
        return;
    }
    return (_jsxs(Badge, { pill: true, variant: 'primary', children: [_jsx(BifIconLink, {}), " ", t('Connected_')] }));
};
export default ProviderItemBadge;
