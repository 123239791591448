import { combineReducers } from "redux";
import { addEntry, addId, deleteEntry, removeId, setEntries, setIds } from "../../../util/reducers";
import { ADD_RECORDING_NOTE_SUCCESS, CLEAR_RECORDING_NOTES, EXTRACT_NOTES, FETCH_RECORDING_NOTES_SUCCESS, REMOVE_RECORDING_NOTE_SUCCESS, UPDATE_RECORDING_NOTE_SUCCESS } from '../../actions/notes';
function byId(state = {}, action) {
    switch (action.type) {
        case CLEAR_RECORDING_NOTES:
            return {};
        case FETCH_RECORDING_NOTES_SUCCESS:
        case EXTRACT_NOTES:
            return setEntries(state, action);
        case UPDATE_RECORDING_NOTE_SUCCESS:
            return addEntry(state, action);
        case ADD_RECORDING_NOTE_SUCCESS:
            return addEntry(state, action);
        case REMOVE_RECORDING_NOTE_SUCCESS:
            return deleteEntry(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case CLEAR_RECORDING_NOTES:
            return [];
        case FETCH_RECORDING_NOTES_SUCCESS:
        case EXTRACT_NOTES:
            return setIds(state, action);
        case ADD_RECORDING_NOTE_SUCCESS:
            return addId(state, action);
        case REMOVE_RECORDING_NOTE_SUCCESS:
            return removeId(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
