import i18n from "../../../app/services/i18n";
export const ACCOUNT_USAGE_NAME_TRANSCRIPTS = 'transcripts';
export const ACCOUNT_USAGE_NAME_RECORDING_VISIBILITY = 'recordingsVisibility';
export const ACCOUNT_USAGE_NAMES_LIST = [
    ACCOUNT_USAGE_NAME_TRANSCRIPTS,
    ACCOUNT_USAGE_NAME_RECORDING_VISIBILITY
];
export const ACCOUNT_USAGE_NAMES_ENUM = [
    { value: ACCOUNT_USAGE_NAME_TRANSCRIPTS, get text() { return i18n.t('Transcripts_'); } },
    { value: ACCOUNT_USAGE_NAME_RECORDING_VISIBILITY, get text() { return i18n.t('Access.your.conversations_'); } },
];
export const ACCOUNT_USAGE_TYPE_UNLIMITED = 'unlimited';
export const ACCOUNT_USAGE_TYPE_TOTAL = 'total';
export const ACCOUNT_USAGE_TYPE_MONTH = 'monthly';
export const ACCOUNT_USAGE_TYPES_LIST = [
    ACCOUNT_USAGE_TYPE_UNLIMITED,
    ACCOUNT_USAGE_TYPE_TOTAL,
    ACCOUNT_USAGE_TYPE_MONTH
];
export const ACCOUNT_USAGE_TYPES_ENUM = [
    { value: ACCOUNT_USAGE_TYPE_UNLIMITED, get text() { return i18n.t('Unlimited_'); } },
    { value: ACCOUNT_USAGE_TYPE_TOTAL, get text() { return i18n.t('Limited_'); } },
    { value: ACCOUNT_USAGE_TYPE_MONTH, get text() { return i18n.t('per.month_'); } },
];
export const ACCOUNT_USAGE_VISIBLE_UNIT_MINS = 'mins';
export const ACCOUNT_USAGE_VISIBLE_UNIT_HOURS = 'hours';
export const ACCOUNT_USAGE_VISIBLE_UNITS_ENUM = [
    { value: ACCOUNT_USAGE_VISIBLE_UNIT_MINS, get text() { return i18n.t('mins_'); } },
    { value: ACCOUNT_USAGE_VISIBLE_UNIT_HOURS, get text() { return i18n.t('hours_'); } },
];
