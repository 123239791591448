import { isChrome, isEdge, isOpera } from "react-device-detect";
import { EXTENSION_CHROME_URL, EXTENSION_EDGE_URL, EXTENSION_OPERA_URL } from "src/features/auth/config/extension";
/**
 * @description returns extension download url for current browser
 */
const useGetExtensionUrl = () => {
    if (isChrome) {
        return EXTENSION_CHROME_URL;
    }
    if (isOpera) {
        return EXTENSION_OPERA_URL;
    }
    if (isEdge) {
        return EXTENSION_EDGE_URL;
    }
    return;
};
export default useGetExtensionUrl;
