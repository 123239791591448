import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BanafoInput, BanafoModal, Form, InputGroup, SpinnerBackdrop } from "banafo-design-system";
import { hideExistingContactModal, selectActiveCallId, selectExistingContactModalIsOpen, switchContactModals } from "src/features/conversations/conversationsSlice";
import { selectActiveContactsIntegrations, useGetIntegrationsQuery } from "src/features/integrations/integrationsSlice";
import validateSchema from "src/features/auth/util/validateSchema";
import Ajv from "ajv";
import { selectExisitngAllContacts, useLazyGetExisitngContactsQuery } from "src/features/contacts/apiContactsSlice";
import ExistingContactsTable from "src/features/conversations/ExistingContactsTable";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, useDefaults: 'empty' });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    required: ["provider"],
    "properties": {
        "search": {
            type: ['string'],
            "minLength": 3,
            "maxLength": 32
        },
        "provider": {
            type: ['string'],
            "minLength": 1,
        },
    },
    errorMessage: {
        properties: {
            search: 'errors:Invalid.search_',
            provider: 'errors:Invalid.provider_',
        }
    },
};
const ExistingContactModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialFormState = {
        search: '',
        provider: '',
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const { isSuccess: isSuccessIntegrations, isLoading: isLoadingIntegrations } = useGetIntegrationsQuery();
    const [loadContacts, { isLoading: isLoadingContacts, isFetching: isFetchingContacts }] = useLazyGetExisitngContactsQuery();
    const integrations = useSelector(selectActiveContactsIntegrations);
    const show = useSelector(selectExistingContactModalIsOpen);
    const callId = useSelector(selectActiveCallId);
    const contacts = useSelector(state => selectExisitngAllContacts(state, Object.assign({}, inputs)));
    useEffect(() => {
        if (!isSuccessIntegrations) {
            return;
        }
        if (integrations.length > 0) {
            setInputs((inputs) => (Object.assign(Object.assign({}, inputs), { ['provider']: integrations[0].providerName })));
        }
    }, [isSuccessIntegrations]);
    useEffect(() => {
        if (inputs.provider && inputs.search.length > 3) {
            handleSearchContact();
        }
    }, [inputs.search, inputs.provider]);
    const handleInputChange = (e) => {
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const handleHideModal = () => {
        setInputs(Object.assign(Object.assign({}, initialFormState), { provider: integrations[0].providerName }));
        setErrors({});
        dispatch(hideExistingContactModal());
    };
    const handleSwitchModals = (e) => {
        e.preventDefault();
        dispatch(switchContactModals());
    };
    const handleSearchContact = () => {
        setErrors({});
        const { hasErrors, errors } = validateSchema(ajv, schema, inputs, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        loadContacts(Object.assign({}, inputs));
    };
    const renderFormFilters = () => {
        return (_jsxs(Form, { className: 'mb-100', children: [_jsx(Form.Group, { controlId: "search", children: _jsxs(InputGroup, { className: 'mb-50', children: [_jsx(BanafoInput, { placeholder: t('Search.contacts_'), name: "search", onChange: handleInputChange, value: inputs.search, isInvalid: !!errors.search }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.search })] }) }), _jsxs(Form.Group, { controlId: "provider", children: [_jsx(Form.Select, { placeholder: t('Providers_'), name: "provider", onChange: handleInputChange, value: inputs.provider, isInvalid: !!errors.provider, children: integrations.map(({ providerName }, index) => {
                                return _jsx("option", { value: providerName, children: providerName }, index);
                            }) }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.provider })] })] }));
    };
    const renderContacts = () => {
        if (isLoadingContacts) {
            return _jsx(SpinnerBackdrop, { position: 'static' });
        }
        return _jsx(ExistingContactsTable, { callId: callId, contacts: contacts, handleHideModal: handleHideModal });
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t("Add.to.an.Existing.Contact_") }), _jsxs("div", { className: "mb-50", children: [t("Can/'t.find.the.contact.you.are.looking.for_"), "? ", " ", _jsx(Link, { onClick: handleSwitchModals, to: "", className: 'link-danger', children: t("Create.a.new.contact_") }), "."] }), renderFormFilters(), _jsxs("div", { className: 'position-relative', children: [isFetchingContacts && _jsx(SpinnerBackdrop, { position: 'absolute' }), renderContacts()] })] }));
};
export default ExistingContactModal;
