import { AUDIO_CURRENT_TIME_CHANGED, AUDIO_ERROR, AUDIO_FINISHED, AUDIO_PAUSED, AUDIO_PLAYING, AUDIO_READY, LOAD_AUDIO, PAUSE_AUDIO, PLAY_AUDIO, SEEK_AUDIO, SET_AUDIO_VOLUME, SET_PLAYBACK_RATE, SKIP_AUDIO, UNLOAD_AUDIO } from "../../../features/auth/store/actions/playback";
import WaveSurfer from "../../../../node_modules/wavesurfer.js/dist/wavesurfer";
import api from "../../../api/api";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
export default store => {
    let waveSurfer = null;
    return next => action => {
        switch (action.type) {
            case LOAD_AUDIO: {
                if (waveSurfer !== null) {
                    waveSurfer.destroy();
                }
                waveSurfer = WaveSurfer.create(action.options);
                waveSurfer.on('error', (error) => {
                    // document.getElementById("audio-player-container").style.display = 'none';
                    store.dispatch({ type: AUDIO_ERROR, error: { message: error } });
                });
                waveSurfer.on('ready', () => {
                    store.dispatch({
                        type: AUDIO_READY,
                        currentTime: waveSurfer.getCurrentTime(),
                        duration: waveSurfer.getDuration(),
                        format: fileFormat
                    });
                });
                waveSurfer.on('play', () => void store.dispatch({
                    type: AUDIO_PLAYING
                }));
                waveSurfer.on('pause', () => void store.dispatch({
                    type: AUDIO_PAUSED
                }));
                waveSurfer.on('finish', () => void store.dispatch({
                    type: AUDIO_FINISHED,
                    currentTime: 0
                }));
                waveSurfer.on('seek', () => void store.dispatch({
                    type: AUDIO_CURRENT_TIME_CHANGED,
                    currentTime: waveSurfer.getCurrentTime()
                }));
                waveSurfer.on('audioprocess', (time) => {
                    if (time > store.getState().app.playback.currentTime + 1) {
                        store.dispatch({
                            type: AUDIO_CURRENT_TIME_CHANGED,
                            currentTime: time
                        });
                    }
                });
                let fileFormat = store.getState().app.playback.format;
                Promise.all(action.fileIds.map(fileId => api.getPlaybackInfo(fileId)))
                    .then(files => {
                    waveSurfer.load(files.map(file => file.url), files.map(file => file.peaks), 'auto', files.reduce((duration, file) => {
                        return Math.max(duration, file.duration);
                    }, 0));
                    files.map(file => {
                        fileFormat = file.format;
                    });
                })
                    .catch(error => {
                    void store.dispatch({
                        type: AUDIO_ERROR,
                        error: error.response.body.name === FILE_AUTO_DELETED_ERROR ? new Error(error.response.body.name) : error
                    });
                });
                break;
            }
            case SET_AUDIO_VOLUME:
                if (action.source === undefined) {
                    waveSurfer.setVolume(action.volume);
                }
                else {
                    waveSurfer.setSourceVolume(action.source, action.volume);
                }
                break;
            case SET_PLAYBACK_RATE:
                waveSurfer.setPlaybackRate(action.rate);
                break;
            case PAUSE_AUDIO:
                waveSurfer.pause();
                break;
            case PLAY_AUDIO:
                waveSurfer.play();
                break;
            case SEEK_AUDIO:
                if (!waveSurfer)
                    break;
                if (!isNaN(action.progress)) {
                    waveSurfer.seekTo(action.progress);
                }
                break;
            case SKIP_AUDIO:
                waveSurfer.skip(action.seconds);
                break;
            case UNLOAD_AUDIO:
                if (waveSurfer !== null) {
                    waveSurfer.destroy();
                }
                waveSurfer = null;
                break;
        }
        return next(action);
    };
};
