import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import { Divider, Image, Text } from "@mantine/core";
import Title from "./Title";
import settings from "./images/3cx-settings.png";
import archive from "./images/3cx-archive.png";
import { useTranslation } from "react-i18next";
const ResellerCustomersSettingsGuide3cxPage = () => {
    const { t } = useTranslation();
    return (_jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "p-200 d-flex flex-column" },
        }, children: [_jsx(Title, {}), _jsx(Divider, { size: "md", my: "sm" }), _jsxs(Text, { size: "lg", mb: "lg", children: [_jsx(Text, { size: "xl", span: true, fw: "bold", children: "1." }), t('infos:Setup.3cx.settings_')] }), _jsx(Image, { src: settings, maw: "max-content" }), _jsxs(Text, { size: "lg", my: "lg", children: [_jsx(Text, { size: "xl", span: true, fw: "bold", children: "2." }), t('infos:Setup.3cx.archive_'), ":"] }), _jsx(Image, { src: archive, maw: "max-content" })] }));
};
export default ResellerCustomersSettingsGuide3cxPage;
