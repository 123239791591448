import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack } from "@mantine/core";
import ActivitiesList from "src/features/activityLog/ActivitiesList";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
const Activities = forwardRef(({ activities, isLoading, isFetching, hasNextPage, loadMoreActivities }, ref) => {
    const { t } = useTranslation();
    return (_jsxs(Stack, { flex: "1", align: "center", ref: ref, style: { scrollMargin: '2rem' }, children: [_jsx(ActivitiesList, { activities: activities }), hasNextPage && _jsx(Button, { onClick: loadMoreActivities, variant: "outline-filled", loading: isFetching, tt: "uppercase", children: t('Load.more_') })] }));
});
export default Activities;
