import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import Payments from "src/features/payments/payments/Payments";
const PaymentsHistorySection = () => {
    const { t } = useTranslation();
    const scrollRef = useRef(null);
    return (_jsxs("div", { className: "my-200 mx-50", ref: scrollRef, children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t("Payment.history_") }), _jsx(Payments, { scrollRef: scrollRef })] }));
};
export default PaymentsHistorySection;
