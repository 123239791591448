import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@mantine/hooks";
import { useRef } from "react";
import useIsTruncated from "src/hooks/useIsTruncated";
import { Anchor, Box, Text } from "@mantine/core";
const SummaryContent = ({ text, aiAnalysisEnabled, disableLineClamp }) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure(false);
    const ref = useRef(null);
    const isTruncated = useIsTruncated(ref, text);
    return (_jsxs(Box, { mb: "md", children: [_jsx(Text, { c: disableLineClamp ? 'dark.2' : undefined, lineClamp: disableLineClamp || opened ? 0 : 4, ref: ref, children: text }), (!disableLineClamp && (isTruncated || opened)) &&
                _jsx(Anchor, { href: undefined, onClick: (e) => toggle(), underline: "never", c: "teal", children: !opened ? t('View.more_') : t('View.less_') })] }));
};
export default SummaryContent;
