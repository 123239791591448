var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { formattedDateTime } from "src/features/auth/util/dateTime";
import { API_KEY_STATUS_ACTIVE } from "src/features/apiKeys/config";
export const apiKeysAdapter = createEntityAdapter();
const initialState = apiKeysAdapter.getInitialState({ query: {} });
export const localizedApiKeysSelectors = apiKeysAdapter.getSelectors();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getResellerCustomerFtpSettings: builder.query({
            query: (userId) => {
                return `/settings/ftp/reseller/${userId}`;
            },
        }),
        getResellerCustomerApiKeys: builder.query({
            query: (userId) => {
                return `/auth/reseller/api-keys/${userId}`;
            },
            transformResponse: ({ list: apiKeys = [] }) => {
                return apiKeysAdapter.setAll(Object.assign({}, initialState), apiKeys.map((_a) => {
                    var { _id: id } = _a, apiKeys = __rest(_a, ["_id"]);
                    return (Object.assign({ id }, apiKeys));
                }));
            },
            providesTags: ["ResellerApiKeys"]
        }),
        generateResellerCustomerFtpPass: builder.mutation({
            query(userId) {
                return {
                    url: `/settings/ftp/reseller/${userId}`,
                    method: "POST",
                };
            },
        }),
        generateResellerCustomerApiKey: builder.mutation({
            query(userId) {
                return {
                    url: `/auth/reseller/api-keys/${userId}`,
                    method: "POST",
                };
            },
            invalidatesTags: ["ResellerApiKeys"]
        }),
        revokeResellerCustomerApiKey: builder.mutation({
            query({ userId, apiKeyId }) {
                return {
                    url: `/auth/reseller/api-keys/${userId}/${apiKeyId}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["ResellerApiKeys"]
        }),
    })
});
export const { useGetResellerCustomerFtpSettingsQuery, useGetResellerCustomerApiKeysQuery, useGenerateResellerCustomerFtpPassMutation, useGenerateResellerCustomerApiKeyMutation, useRevokeResellerCustomerApiKeyMutation, } = extendedApi;
//SELECTORS
// ResellerCustomerApiKeysEntity
export const selectResellerCustomerApiKeysEntityResult = createSelector((args) => args, (args) => extendedApi.endpoints.getResellerCustomerApiKeys.select(args));
export const selectResellerCustomerApiKeysEntityData = createSelector((state, args) => selectResellerCustomerApiKeysEntityResult(args)(state), (resellerCustomerApiKeysEntityResult) => { var _a; return (_a = resellerCustomerApiKeysEntityResult === null || resellerCustomerApiKeysEntityResult === void 0 ? void 0 : resellerCustomerApiKeysEntityResult.data) !== null && _a !== void 0 ? _a : initialState; });
export const selectAllResellerCustomerApiKeysEntity = createSelector((state, args) => selectResellerCustomerApiKeysEntityData(state, args), (resellerCustomerApiKeysEntityData) => {
    return localizedApiKeysSelectors.selectAll(resellerCustomerApiKeysEntityData);
});
// ResellerCustomerApiKeys
export const selectAllResellerCustomerApiKeys = createSelector((state, args) => selectAllResellerCustomerApiKeysEntity(state, args), (allResellerCustomerApiKeysEntity) => {
    return allResellerCustomerApiKeysEntity
        .filter((item) => item.status === API_KEY_STATUS_ACTIVE)
        .map((_a) => {
        var _b;
        var { creationDate } = _a, resellerCustomerApiKey = __rest(_a, ["creationDate"]);
        return Object.assign(Object.assign({}, resellerCustomerApiKey), { creationDate: (_b = (creationDate && formattedDateTime(creationDate))) !== null && _b !== void 0 ? _b : creationDate });
    });
});
export const makeSelectAllResellerCustomerApiKeys = () => {
    return selectAllResellerCustomerApiKeys;
};
