import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { isChrome, isEdge, isOpera } from "react-device-detect";
import chromeColor from "images/browser/google-chrome-color.png";
import edgeColor from "images/browser/microsoft-edge-color.png";
import operaColor from "images/browser/opera-color.png";
import BrowserExtensions from "./BrowserExtensions";
import BrowserExtensionButton from "./BrowserExtensionButton";
import useGetExtensionUrl from "src/features/browserExtension/hook/useGetExtensionUrl";
function BrowserExtensionsSection() {
    const { t } = useTranslation();
    const extensionUrl = useGetExtensionUrl();
    const isSupportedBrowser = isChrome || isEdge || isOpera;
    const getDetectBrowser = () => {
        const url = extensionUrl;
        if (isChrome) {
            return { name: 'Chrome', url, img: chromeColor };
        }
        if (isOpera) {
            return { name: 'Opera', url, img: operaColor };
        }
        if (isEdge) {
            return { name: 'Edge', url, img: edgeColor };
        }
        return null;
    };
    return (_jsxs("div", { className: "browser-extensions mb-200", children: [_jsx("h5", { className: "font-weight-bold mb-100", children: t("Record.audio.in.your.browser_") }), _jsxs("p", { className: "mb-0", children: [t("Install.the.browser.extension.to.start.recording_"), " ", _jsx("br", {})] }), !isSupportedBrowser && _jsx(BrowserExtensions, {}), isSupportedBrowser && _jsx("div", { className: "button-container", children: _jsx(BrowserExtensionButton, Object.assign({}, getDetectBrowser())) })] }));
}
export default BrowserExtensionsSection;
