var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BifIconKey, Button, Col, Row, SpinnerBackdrop } from "banafo-design-system";
import { selectActiveApiKeys, useGetApiKeysQuery, useRevokeApiKeyMutation } from "./apiKeysSlice";
import ApiKeysTable from "./ApiKeysTable";
import AddApiKeyModal from "./modals/AddApiKeyModal";
import ApiKeysRow from "src/features/apiKeys/ApiKeysRow";
import DeleteApiKeyModal from "src/features/apiKeys/modals/DeleteApiKeyModal";
const ApiKeysList = () => {
    const { t } = useTranslation();
    const { isLoading } = useGetApiKeysQuery();
    const apiKeys = useSelector(selectActiveApiKeys);
    const [addApiKeyModalShow, setAddApiKeyModalShow] = useState(false);
    const [revokeApiKey] = useRevokeApiKeyMutation();
    const [deleteApiKeyModalShow, setDeleteApiKeyModalShow] = useState(false);
    const [deleteApiKeyId, setDeleteApiKeyId] = useState('');
    const handleDelete = (id) => () => {
        setDeleteApiKeyModalShow(true);
        setDeleteApiKeyId(id);
    };
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs("div", { className: "my-200 mx-50", children: [_jsx("h5", { className: "text-uppercase", children: t('Api.keys_') }), _jsx(Row, { className: "mb-150 g-0", children: _jsxs(Col, { className: "d-flex justify-content-between align-items-start", children: [_jsx("p", { className: "mb-150", children: t('infos:Api.key.info.text_') }), _jsxs(Button, { variant: 'dark', onClick: () => setAddApiKeyModalShow(true), className: "text-uppercase text-nowrap with-icon end", children: [t('Generate.new_'), " ", _jsx(BifIconKey, {})] })] }) }), _jsx(ApiKeysTable, { apiKeys: apiKeys, renderItem: (_a) => {
                    var { id } = _a, apiKey = __rest(_a, ["id"]);
                    return _jsx(ApiKeysRow, Object.assign({ id: id }, apiKey, { handleDelete: handleDelete(id) }), id);
                } }), _jsxs("div", { children: ["* ", t('infos:Api.keys.page.info.bullets.first_')] }), _jsxs("div", { children: ["* ", t('infos:Api.keys.page.info.bullets.second_')] }), _jsx(AddApiKeyModal, { show: addApiKeyModalShow, onHide: () => setAddApiKeyModalShow(false) }), _jsx(DeleteApiKeyModal, { show: deleteApiKeyModalShow, onHide: () => setDeleteApiKeyModalShow(false), deleteApiKey: () => revokeApiKey(deleteApiKeyId).unwrap() })] }));
};
export default ApiKeysList;
