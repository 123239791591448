import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import apiApp from "src/app/services/apiApp";
// @ts-ignore
const usagesAdapter = createEntityAdapter({
    selectId: (item) => item.name,
});
const usagesInitialState = usagesAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => {
        return ({
            getUsages: builder.query({
                query: (args) => "/limiter/usages",
                // @ts-ignore
                transformResponse: ({ list = [] }) => {
                    return usagesAdapter.setAll(usagesInitialState, list);
                }
            }),
            changePassword: builder.mutation({
                query(body) {
                    return {
                        url: `/profile/password`,
                        method: "POST",
                        body
                    };
                }
            }),
            updateProfileData: builder.mutation({
                query({ fullName, email }) {
                    const data = Object.assign(Object.assign({}, (fullName ? { fullName } : {})), (email ? { email } : {}));
                    return {
                        url: `/profile`,
                        method: "PUT",
                        body: data
                    };
                }
            }),
            uploadProfilePicture: builder.mutation({
                query({ userId, file }) {
                    const data = new FormData();
                    data.append("theFile", file);
                    return {
                        url: `/media/profile-photos/${userId}.jpg`,
                        method: "PUT",
                        body: data
                    };
                }
            })
        });
    }
});
export const { useGetUsagesQuery, useChangePasswordMutation, useUpdateProfileDataMutation, useUploadProfilePictureMutation } = extendedApi;
//SELECTORS
export const selectUsagesResult = (state, args) => extendedApi.endpoints.getUsages.select(args)(state);
export const selectUsagesData = createSelector(selectUsagesResult, usagesResult => usagesResult.data);
export const selectUsagesById = createSelector((state, args) => { var _a; return (_a = selectUsagesData(state, args)) !== null && _a !== void 0 ? _a : usagesInitialState; }, (state, args, id) => id, (data, id) => (usagesAdapter.getSelectors().selectById(data, id)));
export const { selectAll: selectAllUsages, selectEntities: selectEntitiesUsages, } = usagesAdapter.getSelectors(
// @ts-ignore
(state, args) => { var _a; return (_a = selectUsagesData(state, args)) !== null && _a !== void 0 ? _a : usagesInitialState; });
const sidebarInitialState = {
    expanded: true,
    hamburgerExpanded: false,
    profileModalOpen: false,
    changePasswordModalOpen: false
};
export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: sidebarInitialState,
    reducers: {
        toggleSidebar: state => {
            state.expanded = !state.expanded;
        },
        toggleHamburgerMenu: state => {
            state.hamburgerExpanded = !state.hamburgerExpanded;
        },
        showProfileModal: state => {
            state.profileModalOpen = true;
        },
        hideProfileModal: state => {
            state.profileModalOpen = false;
        },
        showChangePasswordModal: state => {
            state.changePasswordModalOpen = true;
        },
        hideChangePasswordModal: state => {
            state.changePasswordModalOpen = false;
        }
    }
});
const { name, actions, reducer } = sidebarSlice;
export const { toggleSidebar, toggleHamburgerMenu, showProfileModal, hideProfileModal, showChangePasswordModal, hideChangePasswordModal } = actions;
export { name, actions };
export default reducer;
export const selectSidebarIsExpanded = (state) => state[name].expanded;
export const selectHamburgerIsExpanded = (state) => state[name].hamburgerExpanded;
export const selectProfileModalIsOpen = (state) => state[name].profileModalOpen;
export const selectChangePasswordIsOpen = (state) => state[name].changePasswordModalOpen;
