import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from "react-redux";
import { Container, Navbar } from "banafo-design-system";
import { SCHEME, WEB_URL } from "src/app/config";
import logoDark from "images/logo/logo-dark.svg";
import { showOffcanvas } from "src/features/layout/pageLayout/layoutSlice";
const AuthHeader = () => {
    const dispatch = useDispatch();
    const handleShowOffcanvas = () => dispatch(showOffcanvas());
    return (_jsx(Navbar, { bg: "white", expand: "lg", className: "d-lg-none", children: _jsxs(Container, { fluid: true, children: [_jsx(Navbar.Brand, { href: `${SCHEME}://${WEB_URL}/`, children: _jsx("img", { src: logoDark, height: "40", className: "d-inline-block align-top", alt: "Banafo logo" }) }), _jsx(Navbar.Toggle, { onClick: handleShowOffcanvas })] }) }));
};
export default AuthHeader;
