import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { useClipboard } from "@mantine/hooks";
import { ActionIcon, Group, Title } from "@mantine/core";
import { MdOutlineCheck, MdOutlineContentCopy, MdOutlineEdit, MdOutlineMail } from "react-icons/md";
import { showSummaryModal } from "src/features/conversation/conversationSlice";
import mailTo from "src/common/utils/mailTo";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
const SummaryTitle = ({ text }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { email } = useAppSelector(selectAuthUser);
    const clipboard = useClipboard({ timeout: 500 });
    return (_jsxs(Group, { justify: "space-between", children: [_jsx(Title, { order: 6, fw: "bold", children: t('Summary_') }), _jsxs(Group, { gap: 0, children: [text && _jsx(ActionIcon, { variant: "transparent", "aria-label": "Edit", onClick: () => mailTo({ email, subject: t('Summary_'), body: text }), children: _jsx(MdOutlineMail, { size: 18 }) }), text && _jsx(ActionIcon, { color: clipboard.copied ? 'green' : 'primary', variant: "transparent", "aria-label": "Copy", onClick: () => clipboard.copy(text), children: clipboard.copied ? _jsx(MdOutlineCheck, { size: 18 }) : _jsx(MdOutlineContentCopy, { size: 18 }) }), _jsx(ActionIcon, { variant: "transparent", "aria-label": "Edit", onClick: () => dispatch(showSummaryModal()), children: _jsx(MdOutlineEdit, { size: 18 }) })] })] }));
};
export default SummaryTitle;
