var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { BanafoInput, BanafoModal, Form } from "banafo-design-system";
import SearchResultTable from "./SearchResultTable";
import useDebounce from "src/common/hooks/useDebounce";
import { startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { selectSearchTranscripts } from "src/features/auth/store/selectors/recordingTranscripts";
import { resetSearchRecordingTranscripts, searchTranscripts } from "src/features/auth/store/actions/transcripts";
function SearchModal(_a) {
    var { onHide, searchTranscripts, resetSearchRecordingTranscripts, searchTranscriptsList, startAppLoader, stopAppLoader } = _a, others = __rest(_a, ["onHide", "searchTranscripts", "resetSearchRecordingTranscripts", "searchTranscriptsList", "startAppLoader", "stopAppLoader"]);
    const { t } = useTranslation();
    const initialFormState = {
        search: "",
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const debouncedValue = useDebounce(inputs, 750);
    useEffect(() => {
        return () => {
            handleHideModal();
        };
    }, []);
    useEffect(() => {
        if (inputs.search.length > 3) {
            startAppLoader();
            searchTranscripts(inputs.search).finally(() => {
                stopAppLoader();
            });
        }
    }, [debouncedValue]);
    const handleInputChange = (e) => {
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const onShow = () => {
        setInputs(initialFormState);
    };
    const handleHideModal = () => {
        onHide();
        setInputs(initialFormState);
        resetSearchRecordingTranscripts();
    };
    const onEnterPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };
    return (_jsxs(BanafoModal, Object.assign({ onHide: handleHideModal, onShow: onShow, title: t("Search_"), size: "xl", "aria-labelledby": "contained-modal-title-vcenter" }, others, { children: [_jsx(Form, { children: _jsxs(Form.Group, { controlId: "search", children: [_jsx(BanafoInput, { placeholder: t('Search.in.transcripts_'), name: "search", onChange: handleInputChange, onKeyDown: onEnterPress, value: inputs.search, autoFocus: true, autoComplete: "off" }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.search })] }) }), searchTranscriptsList.length > 0 && _jsx(SearchResultTable, { searchTranscripts: searchTranscriptsList, targetValue: inputs.search, handleHideModal: handleHideModal })] })));
}
SearchModal.propTypes = {
    searchTranscripts: PropTypes.func,
    resetSearchRecordingTranscripts: PropTypes.func,
    onHide: PropTypes.func,
    searchTranscriptsList: PropTypes.array,
    startAppLoader: PropTypes.any,
    stopAppLoader: PropTypes.any,
};
function mapStateToProps(state) {
    return {
        searchTranscriptsList: selectSearchTranscripts(state),
    };
}
const mapDispatchToProps = {
    searchTranscripts,
    resetSearchRecordingTranscripts,
    startAppLoader,
    stopAppLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
