import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { Container, Row, Col, Button, Image, LineSeparator } from "banafo-design-system";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import card from "./images/payment_successful.png";
import { useFinishPaymentMutation } from "src/features/payments/paymentsSlice";
import { finishPaymentSuccess } from "src/features/auth/store/actions/payment";
import { HOME_ROUTE, WIZARD_ROUTE } from "src/app/router/config";
const BillingSuccessPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [counter, setCounter] = useState(30);
    const authUser = useSelector((state) => selectAuthUser(state));
    const [finishPayment] = useFinishPaymentMutation();
    useEffect(() => {
        finishPayment({})
            .unwrap()
            .then((response) => {
            dispatch(finishPaymentSuccess(response));
        })
            .catch((e) => Sentry.captureException(e));
        setTimeout(() => {
            finishPayment({})
                .unwrap()
                .then((response) => {
                if (authUser.showWizard) {
                    navigate(WIZARD_ROUTE);
                    return;
                }
                navigate(HOME_ROUTE);
            })
                .catch((e) => Sentry.captureException(e));
        }, 30000);
        counter > 0 && setInterval(() => {
            setCounter((time) => time - 1);
        }, 1000);
    }, []);
    const handleContinueClick = () => {
        finishPayment({})
            .unwrap()
            .then((response) => {
            dispatch(finishPaymentSuccess(response));
            if (authUser.showWizard) {
                navigate(WIZARD_ROUTE);
                return;
            }
            navigate(HOME_ROUTE);
        })
            .catch((e) => Sentry.captureException(e));
    };
    return (_jsx(Container, { children: _jsx(Row, { className: "justify-content-center full-width", children: _jsxs(Col, { style: { maxWidth: 800 }, children: [_jsxs("div", { className: "text-center py-200 py-lg-400 m-auto", children: [_jsx(Image, { fluid: true, src: card }), _jsxs("h1", { className: "text-uppercase fw-normal mb-300", children: ["- ", t('Thank.you.for.your.order_'), "! -"] }), _jsx("h6", { className: "text-uppercase fw-bold", children: t('Your.payment.has.been.successfully.processed_') })] }), _jsx(LineSeparator, { color: "secondary" }), _jsxs("div", { className: "py-200 px-400 py-lg-400 px-lg-800 text-center", children: [_jsx(Button, { variant: "dark", type: "submit", className: "text-uppercase mb-100 px-400", onClick: handleContinueClick, children: t('Continue_') }), _jsx("div", { children: t("Automatic.redirect.after_") }), _jsx("div", { className: 'mb-200 text-muted', children: counter })] })] }) }) }));
};
export default BillingSuccessPage;
