import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BifIconPencil, OverlayTrigger } from "banafo-design-system";
import cx from "classnames";
import { CHAT_USER_POSITION_RIGHT } from "src/features/transcripts/config";
import SpeakerNamePopover from "src/features/transcripts/SpeakerNamePopover";
const SpeakerName = ({ speakerId, speakerName, fileId, showSpeakerDetails, position }) => {
    if (speakerId) {
        return (_jsx(OverlayTrigger, { trigger: "click", placement: "top", rootClose: true, overlay: _jsx(SpeakerNamePopover, { speakerId: speakerId, speakerName: speakerName, fileId: fileId }), children: _jsxs("div", { className: cx("d-flex align-items-center", { "flex-row-reverse": position === CHAT_USER_POSITION_RIGHT }), children: [_jsx(BifIconPencil, { className: cx({ "invisible": !showSpeakerDetails }) }), " ", ' ', _jsx("span", { className: cx("name", { "invisible": !showSpeakerDetails }), children: speakerName })] }) }));
    }
    return (_jsx("span", { className: "fw-bold", children: speakerName }));
};
export default SpeakerName;
