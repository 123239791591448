var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { ACTIVITY_PAGINATION_LIMIT } from "src/features/activityLog/constants";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { lastPageReached } from "src/features/activityLog/activityLogSlice";
export const activitiesAdapter = createEntityAdapter();
const initialState = activitiesAdapter.getInitialState({ query: {} });
export const localizedActivitiesSelectors = activitiesAdapter.getSelectors();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getActivities: builder.query({
            query: ({ start }) => {
                const payload = {
                    start: start.toString(),
                    limit: ACTIVITY_PAGINATION_LIMIT.toString()
                };
                const params = new URLSearchParams(payload);
                return `/activity?${params}`;
            },
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { start } = queryArgs, otherArgs = __rest(queryArgs, ["start"]);
                return otherArgs;
            },
            merge: (currentCache, newItems) => {
                return activitiesAdapter.addMany(currentCache, localizedActivitiesSelectors.selectAll(newItems));
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            transformResponse: ({ list: activities = [], query }) => {
                return activitiesAdapter.setAll(Object.assign(Object.assign({}, initialState), { query }), activities.map((_a) => {
                    var { _id: id } = _a, activities = __rest(_a, ["_id"]);
                    return (Object.assign({ id }, activities));
                }));
            },
            onQueryStarted(args, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        if (localizedActivitiesSelectors.selectTotal(data) < ACTIVITY_PAGINATION_LIMIT) {
                            dispatch(lastPageReached());
                        }
                    }
                    catch (err) {
                    }
                });
            },
            keepUnusedDataFor: 0,
        }),
    })
});
export const { useGetActivitiesQuery, } = extendedApi;
//SELECTORS
export const selectActivitiesResult = createSelector((args) => args, (args) => extendedApi.endpoints.getActivities.select(args));
export const selectActivitiesData = createSelector((state, args) => selectActivitiesResult(args)(state), (ActivitiesResult) => { var _a; return (_a = ActivitiesResult === null || ActivitiesResult === void 0 ? void 0 : ActivitiesResult.data) !== null && _a !== void 0 ? _a : initialState; });
export const selectAllActivities = createSelector((state, args) => selectActivitiesData(state, args), (activitiesData) => {
    return localizedActivitiesSelectors.selectAll(activitiesData);
});
export const makeSelectAllActivities = () => {
    return selectAllActivities;
};
