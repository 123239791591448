var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { API_KEY_STATUS_ACTIVE } from "./config";
import { formattedDateTime } from "src/features/auth/util/dateTime";
const apiKeysAdapter = createEntityAdapter();
const initialState = apiKeysAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getApiKeys: builder.query({
            query: () => "/auth/api-keys",
            transformResponse: ({ list = [] }) => apiKeysAdapter.setAll(initialState, list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["ApiKeys"]
        }),
        getApiKeysSettings: builder.query({
            query: () => "/settings",
            providesTags: ["ApiSettings"]
        }),
        generateApiKey: builder.mutation({
            query() {
                return {
                    url: `/auth/api-keys`,
                    method: "POST"
                };
            },
            invalidatesTags: ["ApiKeys"]
        }),
        revokeApiKey: builder.mutation({
            query(id) {
                return {
                    url: `/auth/api-keys/${id}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: ["ApiKeys"]
        }),
        updateApiKeysSettings: builder.mutation({
            query: (body) => ({
                url: `/settings`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["ApiSettings"]
        }),
        generateFtpPass: builder.mutation({
            query() {
                return {
                    url: `/settings/ftp`,
                    method: "POST"
                };
            },
            invalidatesTags: ["ApiSettings"]
        }),
    })
});
export const { useGetApiKeysQuery, useGetApiKeysSettingsQuery, useUpdateApiKeysSettingsMutation, useGenerateApiKeyMutation, useRevokeApiKeyMutation, useGenerateFtpPassMutation, } = apiApp;
//SELECTORS
export const selectApiKeysResult = extendedApi.endpoints.getApiKeys.select();
export const selectApiKeysData = createSelector(selectApiKeysResult, apiKeysResult => apiKeysResult.data);
export const { selectAll: selectAllApiKeys, selectEntities: selectEntitiesApiKeys, selectById: selectApiKeyById } = apiKeysAdapter.getSelectors(state => { var _a; return (_a = selectApiKeysData(state)) !== null && _a !== void 0 ? _a : initialState; });
export const selectActiveApiKeys = createSelector(selectAllApiKeys, (items) => items === null || items === void 0 ? void 0 : items.filter((item) => item.status === API_KEY_STATUS_ACTIVE).map((_a) => {
    var _b;
    var { creationDate } = _a, apiKey = __rest(_a, ["creationDate"]);
    return Object.assign(Object.assign({}, apiKey), { creationDate: (_b = (creationDate && formattedDateTime(creationDate))) !== null && _b !== void 0 ? _b : creationDate });
}));
