import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Form } from "banafo-design-system";
import { setAudioVolume } from "src/features/auth/store/actions/playback";
import { useDispatch } from "react-redux";
const VolumeControl = ({ source }) => {
    const dispatch = useDispatch();
    const [volume, setVolume] = useState(1);
    const handleVolumeChange = source => e => {
        const volume = parseFloat(e.target.value);
        setVolume(volume);
        dispatch(setAudioVolume(volume, source));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Form.Label, { children: ["Stream ", source + 1] }), _jsx(Form.Range, { onChange: handleVolumeChange(source), min: "0", max: "1", step: "0.01", value: volume, className: "volume-control" })] }));
};
export default VolumeControl;
