import WebAudio from 'wavesurfer.js/src/webaudio';
import * as util from 'wavesurfer.js/src/util';
export default class MultiSourceBackend extends WebAudio {
    constructor(params) {
        super(params);
        /** @private */
        this.params = params;
        /** @private */
        this.media = [];
        /** @private */
        this.mediaType = params.mediaType.toLowerCase();
        /** @private */
        this.peaks = null;
        /** @private */
        this.playbackRate = 1;
        /** @private */
        this.volume = 1;
        /** @private */
        this.isMuted = false;
        /** @private */
        this.onPlayEnd = null;
        /** @private */
        this.mediaListeners = {};
    }
    init() {
        this.setPlaybackRate(this.params.audioRate);
        this.createTimer();
    }
    _setupMediaListeners() {
        this.mediaListeners.error = () => {
            this.fireEvent('error', 'Error loading media element');
        };
        this.mediaListeners.canplay = () => {
            if (this.media.every(mediaEl => {
                return mediaEl.readyState >= 2;
            })) {
                this.fireEvent('canplay');
            }
        };
        this.mediaListeners.ended = () => {
            if (this.media.every(mediaEl => {
                return mediaEl.ended;
            })) {
                this.fireEvent('finish');
            }
        };
        this.mediaListeners.play = () => {
            if (this.media.every(mediaEl => {
                return !mediaEl.paused;
            })) {
                this.fireEvent('play');
            }
        };
        this.mediaListeners.pause = () => {
            if (this.media.every(mediaEl => {
                return mediaEl.paused;
            })) {
                this.fireEvent('pause');
            }
        };
        this.mediaListeners.seeked = () => {
            this.fireEvent('seek');
        };
        // reset event listeners
        Object.keys(this.mediaListeners).forEach(id => {
            this.media.forEach(mediaEl => {
                mediaEl.removeEventListener(id, this.mediaListeners[id]);
                mediaEl.addEventListener(id, this.mediaListeners[id]);
            });
        });
    }
    createTimer() {
        const onAudioProcess = () => {
            if (this.isPaused()) {
                return;
            }
            this.fireEvent('audioprocess', this.getCurrentTime());
            // Call again in the next frame
            util.frame(onAudioProcess)();
        };
        this.on('play', onAudioProcess);
        // Update the progress one more time to prevent it from being stuck in
        // case of lower framerates
        this.on('pause', () => {
            this.fireEvent('audioprocess', this.getCurrentTime());
        });
    }
    load(urls, container, peaks, preload) {
        const prevMedia = container.querySelectorAll(this.mediaType);
        Array.prototype.forEach.call(prevMedia, prevMediaEl => {
            container.removeChild(prevMediaEl);
        });
        const media = urls.map(url => {
            const mediaEl = document.createElement(this.mediaType);
            mediaEl.controls = this.params.mediaControls;
            mediaEl.autoplay = this.params.autoplay || false;
            mediaEl.preload = preload == null ? 'auto' : preload;
            mediaEl.src = url;
            mediaEl.style.width = '100%';
            mediaEl.dataset.sourceVolume = '1';
            container.appendChild(mediaEl);
            return mediaEl;
        });
        this._load(media, peaks, preload);
    }
    _load(media, peaks, preload) {
        // load must be called manually on iOS, otherwise peaks won't draw
        // until a user interaction triggers load --> 'ready' event
        //
        // note that we avoid calling media.load here when given peaks and preload == 'none'
        // as this almost always triggers some browser fetch of the media.
        if (!(peaks && preload == 'none')) {
            media.forEach(mediaEl => {
                if (typeof mediaEl.load == 'function') {
                    // Resets the media element and restarts the media resource. Any
                    // pending events are discarded. How much media data is fetched is
                    // still affected by the preload attribute.
                    mediaEl.load();
                }
            });
        }
        this.media = media;
        this._setupMediaListeners();
        this.peaks = peaks;
        this.onPlayEnd = null;
        this.isMuted = false;
        this.setPlaybackRate(this.playbackRate);
        this.setVolume(this.volume);
    }
    isPaused() {
        return this.media.every(mediaEl => {
            return mediaEl.paused;
        });
    }
    getDuration() {
        if (this.explicitDuration) {
            return this.explicitDuration;
        }
        return this.media.reduce((accumulatedValue, mediaEl) => {
            let duration = mediaEl.duration;
            if (duration >= Infinity) {
                // streaming audio
                duration = mediaEl.seekable.end(0);
            }
            return Math.max(accumulatedValue, duration);
        }, 0);
    }
    getCurrentTime() {
        return this.media.reduce((accumulatedValue, mediaEl) => {
            return Math.max(accumulatedValue, mediaEl.currentTime);
        }, 0);
    }
    getPlayedPercents() {
        return this.getCurrentTime() / this.getDuration() || 0;
    }
    getPlaybackRate() {
        return this.playbackRate;
    }
    setPlaybackRate(value) {
        this.playbackRate = value || 1;
        this.media.forEach(mediaEl => {
            mediaEl.playbackRate = this.playbackRate;
        });
    }
    seekTo(start) {
        if (start != null) {
            this.media.forEach(mediaEl => {
                mediaEl.currentTime = start;
            });
        }
        this.clearPlayEnd();
    }
    play(start, end) {
        this.seekTo(start);
        const promise = Promise.all(this.media.map(mediaEl => {
            return mediaEl.play();
        }));
        end && this.setPlayEnd(end);
        return promise;
    }
    pause() {
        const promise = Promise.all(this.media.map(mediaEl => {
            return mediaEl.pause();
        }));
        this.clearPlayEnd();
        return promise;
    }
    setPlayEnd(end) {
        this.clearPlayEnd();
        this._onPlayEnd = time => {
            if (time >= end) {
                this.pause();
                this.seekTo(end);
            }
        };
        this.on('audioprocess', this._onPlayEnd);
    }
    /** @private */
    clearPlayEnd() {
        if (this._onPlayEnd) {
            this.un('audioprocess', this._onPlayEnd);
            this._onPlayEnd = null;
        }
    }
    getPeaks() {
        return this.peaks || [];
    }
    setSinkId(deviceId) {
        if (deviceId) {
            return Promise.all(this.media.map(mediaEl => {
                if (!mediaEl.setSinkId) {
                    return Promise.reject(new Error('setSinkId is not supported in your browser'));
                }
                return mediaEl.setSinkId(deviceId);
            }));
        }
        return Promise.reject(new Error('Invalid deviceId: ' + deviceId));
    }
    getSourceVolume(source) {
        return Number(this.media[source].dataset.sourceVolume);
    }
    setSourceVolume(source, value) {
        this.media[source].volume = value * this.volume;
        this.media[source].dataset.sourceVolume = value;
    }
    getVolume() {
        return this.volume;
    }
    setVolume(value) {
        this.volume = value;
        for (let source = 0; source < this.media.length; source++) {
            this.setSourceVolume(source, this.getSourceVolume(source));
        }
    }
    setMute(muted) {
        this.isMuted = muted;
        this.media.forEach(mediaEl => {
            mediaEl.muted = muted;
        });
    }
    destroy() {
        this.pause();
        this.unAll();
        this.destroyed = true;
        // cleanup media event listeners
        Object.keys(this.mediaListeners).forEach(id => {
            this.media.forEach(mediaEl => {
                mediaEl.removeEventListener(id, this.mediaListeners[id]);
            });
        });
        if (this.params.removeMediaElementOnDestroy) {
            this.media.forEach(mediaEl => {
                if (mediaEl.parentNode) {
                    mediaEl.parentNode.removeChild(mediaEl);
                }
            });
        }
        this.media = [];
    }
}
