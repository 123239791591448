import { RTK_REQUEST_STATUS_FULFILLED, RTK_REQUEST_STATUS_REJECTED } from "src/app/config";
import i18n from "src/app/services/i18n";
export const isFulfilled = (item) => item.action.meta.requestStatus === RTK_REQUEST_STATUS_FULFILLED;
export const isRejected = (item) => item.action.meta.requestStatus === RTK_REQUEST_STATUS_REJECTED;
export const generateMessage = (item) => {
    const { action: { payload, meta: { arg: { endpointName } } } } = item;
    let message;
    switch (endpointName) {
        case 'updateRecordingExtensionSettings':
        case 'updateApiKeysSettings':
            message = isFulfilled(item) ? i18n.t('Settings.updated.successfully_') : i18n.t('Settings.update.failed_');
            break;
        case 'analysesType':
        case 'analysesQuestion':
            message = isFulfilled(item) ? i18n.t('Your.question.was.answered.successfully_') : i18n.t('Your.question.answer.failed_');
            break;
        case 'generateApiKey':
        case 'generateResellerCustomerApiKey':
            message = isFulfilled(item) ? i18n.t('Api.key.generated.successfully_') : i18n.t('Api.key.generation.failed_');
            break;
        case 'revokeApiKey':
        case 'revokeResellerCustomerApiKey':
            message = isFulfilled(item) ? i18n.t('Api.key.revoked.successfully_') : i18n.t('Api.key.revoke.failed_');
            break;
        case 'uploadProfilePicture':
            message = isFulfilled(item) ? i18n.t('Profile.picture.updated.successfully_') : i18n.t('Profile.picture.update.failed_');
            break;
        case 'updateProfileData':
            message = isFulfilled(item) ? i18n.t('Profile.data.updated.successfully_') : i18n.t('Profile.data.update.failed_');
            break;
        case 'changePassword':
            message = isFulfilled(item) ? i18n.t('Password.changed.successfully_') : i18n.t('Password.change.failed_');
            break;
        case 'updateAccount':
            message = isFulfilled(item) ? i18n.t('infos:trial.successfully.started_') : i18n.t('infos:trial.failed_');
            break;
        case 'uploadFile': {
            if (isFulfilled(item)) {
                message = i18n.t('File.uploaded.successfully_');
                break;
            }
            if (isRejected(item)) {
                if ((payload === null || payload === void 0 ? void 0 : payload.data.name) && (payload === null || payload === void 0 ? void 0 : payload.data.name) === 'ValidationError') {
                    if (payload.data.detail[0].field === 'instance.isCompleted' && payload.data.detail[0].reason === 'isCompleted') {
                        message = i18n.t('File.already.exists_');
                        break;
                    }
                }
                if ((payload === null || payload === void 0 ? void 0 : payload.data.name) && (payload === null || payload === void 0 ? void 0 : payload.data.name) === 'LimitReachedError') {
                    message = i18n.t('Storage.limit.reached_');
                    break;
                }
            }
            message = i18n.t('File.upload.failed_');
            break;
        }
        case 'updateCall': {
            if (isFulfilled(item)) {
                message = i18n.t('Conversation.updated.successfully_');
            }
            break;
        }
        case 'updateCallUnmapContact': {
            if (isFulfilled(item)) {
                message = i18n.t('Contact.unmapped.successfully_');
            }
            break;
        }
        case 'updateCallMapContact': {
            if (isFulfilled(item)) {
                message = i18n.t('Contact.mapped.successfully_');
            }
            break;
        }
        case 'deleteCall': {
            if (isFulfilled(item)) {
                message = i18n.t('Conversation.deleted.successfully_');
            }
            break;
        }
        case 'addWhitelistWebsite': {
            if (isFulfilled(item)) {
                message = i18n.t('Site.added.successfully_');
            }
            else {
                message = i18n.t('errors:invalid.url.match.pattern_');
            }
            break;
        }
        case 'removeWhitelistWebsite': {
            if (isFulfilled(item)) {
                message = i18n.t('Site.deleted.successfully_');
            }
            break;
        }
        case 'oauthLogin': {
            if (isFulfilled(item)) {
                message = i18n.t('Device.has.been.successfully.activated_');
            }
            else {
                message = i18n.t('Device.activation.failed_');
            }
            break;
        }
        case 'addNote': {
            if (isFulfilled(item)) {
                if (item.action.payload.description === "") {
                    message = i18n.t('Point.of.interest.created.successfully_');
                }
                else {
                    message = i18n.t('Note.created.successfully_');
                }
            }
            break;
        }
        case 'editNote': {
            if (isFulfilled(item)) {
                message = i18n.t('Note.edited.successfully_');
            }
            break;
        }
        case 'deleteNote': {
            if (isFulfilled(item)) {
                message = i18n.t('Successfully.deleted_');
            }
            break;
        }
        case 'updateSpeakerName': {
            message = isFulfilled(item) ? i18n.t('Speaker.name.changed.successfully_') : i18n.t('Speaker.name.change.failed_');
            break;
        }
        case 'updateConversationSummary': {
            message = isFulfilled(item) ? i18n.t('Summary.changed.successfully_') : i18n.t('Summary.change.failed_');
            break;
        }
        case 'updateConversationActionPoints': {
            message = isFulfilled(item) ? i18n.t('Action.points.changed.successfully_') : i18n.t('Action.points.change.failed_');
            break;
        }
        case 'addIntegration': {
            message = isFulfilled(item) ? i18n.t('Integration.activated.successfully_') : i18n.t('Integration.activate.failed_');
            break;
        }
        case 'removeIntegration': {
            message = isFulfilled(item) ? i18n.t('Integration.removed.successfully_') : i18n.t('Integration.remove.failed_');
            break;
        }
        case 'inviteResellerCustomer': {
            message = isFulfilled(item) ? i18n.t('Invite.was.successfully.sent_') : i18n.t('Invite.send.failed_');
            break;
        }
        case 'acceptResellerInvitation': {
            message = isFulfilled(item) ? i18n.t('Invite.was.successfully.accepted_') : i18n.t('Invite.accept.failed_');
            break;
        }
        default:
            message = isFulfilled(item) ? i18n.t('Operation.successful_') : i18n.t('Operation.failed_');
    }
    return Object.assign(Object.assign({}, item), { message });
};
