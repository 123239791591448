import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { audioPlayerToggleNotes, audioPlayerTogglePois, selectAudioPlayer } from "src/features/conversations/conversationsSlice";
import { Dropdown, Form, Stack } from "banafo-design-system";
import SettingsCarousel from "src/common/components/ui/SettingsCarousel";
import { AUDIO_PLAYER_FAST_FORWARD_INTERVALS, AUDIO_PLAYER_PLAYBACK_SPEEDS } from "src/features/conversations/config";
import { setFastForwardInterval, setPlaybackRate } from "src/features/auth/store/actions/playback";
const AudioSettingsAction = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { showNotes, showPois } = useSelector(selectAudioPlayer);
    const fastForwardInterval = useSelector(state => state.app.playback.fastForwardInterval);
    const rate = useSelector(state => state.app.playback.rate);
    const handleNotesVisibility = () => {
        dispatch(audioPlayerToggleNotes());
    };
    const handlePoisVisibility = () => {
        dispatch(audioPlayerTogglePois());
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown.ItemText, { className: "px-50 pb-100", children: _jsxs(Stack, { direction: "vertical", children: [_jsx("div", { className: "text-center fw-bold pb-50", children: t("Playback.speed_") }), _jsx(SettingsCarousel, { items: AUDIO_PLAYER_PLAYBACK_SPEEDS, initialIndex: AUDIO_PLAYER_PLAYBACK_SPEEDS.findIndex(({ value }) => value === rate), onSelect: ({ value }) => dispatch(setPlaybackRate(value)) })] }) }, 0), _jsx(Dropdown.ItemText, { className: "px-50 pb-100", children: _jsxs(Stack, { direction: "vertical", children: [_jsx("div", { className: "text-center fw-bold pb-50", children: t("Fast.forward.interval_") }), _jsx(SettingsCarousel, { items: AUDIO_PLAYER_FAST_FORWARD_INTERVALS, initialIndex: AUDIO_PLAYER_FAST_FORWARD_INTERVALS.findIndex(({ value }) => value === fastForwardInterval), onSelect: ({ value }) => dispatch(setFastForwardInterval(value)) })] }) }, 1), _jsx(Dropdown.ItemText, { className: "px-150", children: _jsxs(Stack, { direction: "horizontal", className: "justify-content-between", children: [_jsx("span", { children: t("Notes_") }), _jsx(Form.Check, { type: "switch", reverse: true, 
                            /* label={showNotes ? t("Yes_") : t("No_")} */
                            id: "audio-player-show-notes", checked: showNotes, onChange: handleNotesVisibility })] }) }, 2), _jsx(Dropdown.ItemText, { className: "px-150", children: _jsxs(Stack, { direction: "horizontal", className: "justify-content-between", children: [_jsx("span", { children: t("Points.of.interest_") }), _jsx(Form.Check, { type: "switch", reverse: true, 
                            /* label={showPois ? t("Yes_") : t("No_")} */
                            id: "audio-player-show-notes", checked: showPois, onChange: handlePoisVisibility })] }) }, 3)] }));
};
export default AudioSettingsAction;
