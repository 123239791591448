import client, { apiClient } from "src/app/services/httpClient";
export const login = (data) => {
    return apiClient
        .post(`/auth/profile/verify`)
        .send(data);
};
export const interactionLogin = (uid, data) => {
    return apiClient
        .post(`/auth/interactions/${uid}/login`)
        .send(data);
};
export const interactionDetails = (uid) => {
    return apiClient
        .get(`/auth/interactions/${uid}/details`);
};
export const refreshToken = () => {
    return apiClient
        .post(`/auth/profile/verify`)
        .send({ grant_type: 'refresh_token' });
};
export const changePassword = (data) => {
    return apiClient
        .post(`/auth/recovery/password`)
        .send(data);
};
export const sendRecoveryMessage = (data) => {
    return apiClient
        .post(`/auth/recovery`)
        .send(data);
};
export const register = (data) => {
    return apiClient
        .post(`/auth/register`)
        .send(data);
};
export const sendVerification = (email) => {
    return apiClient
        .post(`/auth/register/resend`)
        .send({ email });
};
export const getOauthXsrfToken = () => {
    return client
        .get(`/oauth/device`);
};
export const oauthLogin = (xsrf, user_code) => {
    return client
        .post(`/oauth/device`)
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .send({ confirm: 'yes', xsrf, user_code });
};
export const getAvailablePlans = (query) => {
    return client
        .get(`/plans-available`)
        .query(query);
};
