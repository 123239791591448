import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Image } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import noMarkers from './images/noMarkers.jpg';
const EmptyNotes = () => {
    const { t } = useTranslation();
    const handleNewNote = () => {
        let element = document.getElementById("new-note-button");
        element.click();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-center my-200", children: _jsx(Image, { fluid: true, src: noMarkers, alt: "empty notes" }) }), _jsxs("div", { className: "text-center", children: [_jsx("h5", { className: "text-uppercase", children: t("It/'s.lonely.in.here_") }), t('infos:Empty.notes_'), _jsx(Button, { variant: "dark", onClick: handleNewNote, className: "d-block mx-auto mt-50", children: t('New.note_') })] })] }));
};
export default EmptyNotes;
