var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoImageModal, Button, Form } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Ajv from "ajv";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import validateSchema from "src/features/auth/util/validateSchema";
import { useDispatch, useSelector } from "react-redux";
import { recoverySendInputChanged, recoverySendMessage, recoverySendSuccessClear } from "src/features/auth/store/actions/auth/recovery";
import './ForgottenPassword.scss';
import { SITE_KEY } from "src/app/config";
import GeneralError from "src/features/auth/GeneralError";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, $data: true });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    required: ["email", "reCaptchaKey"],
    properties: {
        "email": {
            "type": "string",
            "format": "email",
            "minLength": 3,
            "maxLength": 32
        },
        "reCaptchaKey": {
            "type": "string",
            "pattern": '[0-9a-zA-Z_-]{39}',
            errorMessage: {
                "pattern": 'errors:ReCaptcha.error_',
            }
        }
    }
};
function ForgottenPasswordModal(_a) {
    var { show, onHide, showSuccess } = _a, others = __rest(_a, ["show", "onHide", "showSuccess"]);
    const initialFormState = {
        email: '',
        reCaptchaKey: ''
    };
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [input, setInput] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const recaptchaRef = useRef();
    const validationErrors = useSelector(state => state.app.auth.recoverySendValidationErrors);
    useEffect(() => {
        return () => {
            dispatch(recoverySendSuccessClear());
        };
    }, []);
    useLayoutEffect(() => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setInput(Object.assign(Object.assign({}, input), { ['reCaptchaKey']: '' }));
        }
    }, [validationErrors]);
    function handleChange(event) {
        if (typeof event === 'string') {
            setInput(Object.assign(Object.assign({}, input), { ['reCaptchaKey']: event }));
        }
        else {
            const { name, value } = event.target;
            setInput(Object.assign(Object.assign({}, input), { [name]: value }));
        }
        dispatch(recoverySendInputChanged(name));
    }
    const handleHideModal = () => {
        onHide();
        setInput(Object.assign({}, initialFormState));
        setErrors({});
    };
    const formSubmitted = e => {
        e.preventDefault();
        const { hasErrors, errors } = validateSchema(ajv, schema, input, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        dispatch(recoverySendMessage(input.email, input.reCaptchaKey))
            .then(() => {
            handleHideModal();
            showSuccess();
        });
    };
    return (_jsxs(BanafoImageModal, { show: show, onHide: handleHideModal, title: `${t("Forgot.your.password_")}?`, className: "forgotten-password-modal", children: [_jsx("p", { className: "mb-200", children: t('Enter.your.email.and.we.will.send.you.link.to.reset.your.password_') }), _jsx(GeneralError, { as: "h6" }), _jsxs(Form.Group, { controlId: "formEmail", children: [_jsx(Form.Control, { placeholder: t("Email_"), name: "email", type: 'text', onChange: e => handleChange(e), value: input.email, isInvalid: !!errors.email }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.email })] }), _jsxs(Form.Group, { controlId: "reCaptcha", className: 'mb-50', children: [_jsx(ReCAPTCHA, { className: "d-flex justify-content-center my-200", sitekey: SITE_KEY, ref: recaptchaRef, onChange: e => handleChange(e), onExpired: () => recaptchaRef.current.reset() }), _jsx(Form.Control.Feedback, { className: 'd-block', type: "invalid", children: errors.reCaptchaKey })] }), _jsx(Button, { variant: "dark", type: "submit", onClick: formSubmitted, children: t('Send_') })] }));
}
export default ForgottenPasswordModal;
