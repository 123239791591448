import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, SpinnerBackdrop } from "banafo-design-system";
import PaymentsTable from "src/features/payments/payments/PaymentsTable";
import { incrementPage, selectCombinedPayments, selectHasNextPage, useGetPaymentsQuery, selectPage, resetPage, useDownloadInvoiceMutation } from "src/features/payments/paymentsSlice";
import { PAYMENTS_PAGINATION_LIMIT } from "src/features/payments/config";
import { useDispatch, useSelector } from "react-redux";
import useScrollable from "src/features/auth/util/scrollable";
import apiApp from "src/app/services/apiApp";
const Payments = ({ scrollRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(selectPage);
    const perPage = PAYMENTS_PAGINATION_LIMIT;
    const start = page * perPage;
    const payments = useSelector(state => selectCombinedPayments(state, { start }));
    const { isLoading } = useGetPaymentsQuery({ start });
    const hasNextPage = useSelector(state => selectHasNextPage(state, { start }));
    const [undefined, { isLoading: isDownloadingInvoice }] = useDownloadInvoiceMutation({ fixedCacheKey: "download-invoice" });
    useScrollable(scrollRef, page <= 0, [payments.length]);
    useEffect(() => {
        return () => {
            dispatch(resetPage());
            dispatch(apiApp.util.invalidateTags(['Payments']));
        };
    }, []);
    const handleLoadMore = () => {
        dispatch(incrementPage());
    };
    // if(isLoading){
    //   return <SpinnerBackdrop />;
    // }
    return (_jsxs(_Fragment, { children: [isLoading || isDownloadingInvoice && _jsx(SpinnerBackdrop, {}), _jsx(PaymentsTable, { payments: payments }), hasNextPage &&
                _jsx(Button, { onClick: handleLoadMore, variant: "outline-dark", className: "d-block mx-auto mt-200 text-uppercase", children: t('Load.more_') })] }));
};
export default Payments;
