import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import ResellerCustomersContainerWrapper from "src/features/resellerCustomers/ResellerCustomersContainerWrapper";
import InviteCustomerModal from "src/features/resellerCustomers/modals/InviteCustomerModal";
import PurchaseModalsWrapper from "src/features/resellerCustomers/modals/PurchaseModalsWrapper";
const ResellerCustomersPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
                    contentProps: { className: "p-200 d-flex flex-column" },
                }, children: _jsx(ResellerCustomersContainerWrapper, {}) }), _jsx(InviteCustomerModal, {}), _jsx(PurchaseModalsWrapper, {})] }));
};
export default ResellerCustomersPage;
