import i18n from "i18next";
export const CONVERSATION_DIRECTION_INBOUND = 'inbound';
export const CONVERSATION_DIRECTION_OUTBOUND = 'outbound';
export const CONVERSATION_DIRECTION_LIST = [
    CONVERSATION_DIRECTION_INBOUND,
    CONVERSATION_DIRECTION_OUTBOUND
];
export const CONVERSATION_DIRECTIONS_ENUM = [
    { value: CONVERSATION_DIRECTION_INBOUND, get text() { return i18n.t('Inbound_'); } },
    { value: CONVERSATION_DIRECTION_OUTBOUND, get text() { return i18n.t('Outbound_'); } },
];
export const CONVERSATION_TYPE_OTHER = 'other';
export const CONVERSATION_TYPE_WEB_CONFERENCE = 'web-conference';
export const CONVERSATION_TYPE_PHONE_CALL = 'phone-call';
export const CONVERSATION_TYPES_ENUM = [
    { value: CONVERSATION_TYPE_OTHER, get text() { return i18n.t('Other_'); } },
    { value: CONVERSATION_TYPE_WEB_CONFERENCE, get text() { return i18n.t('Web.conference_'); } },
    { value: CONVERSATION_TYPE_PHONE_CALL, get text() { return i18n.t('Phone.call_'); } },
];
