import apiApp from "src/app/services/apiApp";
import { API_PREFIX } from "src/app/config";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getOauthXsrfToken: builder.query({
            query: () => `${API_PREFIX}/oauth/device`,
            providesTags: ["OauthXsrfToken"]
        }),
        oauthLogin: builder.mutation({
            query: ({ xsrf, code }) => ({
                url: `${API_PREFIX}/oauth/device`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ confirm: 'yes', xsrf, user_code: code })
            }),
            invalidatesTags: ["OauthXsrfToken"]
        }),
    })
});
export const { useGetOauthXsrfTokenQuery, useOauthLoginMutation } = extendedApi;
