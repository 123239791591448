import { apiClient } from "src/app/services/httpClient";
export const getCalls = (query) => {
    return apiClient
        .get(`/calls`)
        .query(query);
};
export const getCall = (callId) => {
    return apiClient
        .get(`/calls/${callId}`);
};
export const updateCall = (callId, data) => {
    return apiClient
        .put(`/calls/${callId}`)
        .send(data);
};
export const removeCall = (id) => {
    return apiClient
        .delete(`/calls/${id}`);
};
export const removeRecording = (id) => {
    return apiClient
        .delete(`/calls/recordings/${id}`);
};
export const shareCalls = (data) => {
    return apiClient
        .put(`/calls/share`)
        .send(data);
};
export const createRecordingPlaceholder = (data) => {
    return apiClient
        .post(`/media/recordings`)
        .send(data);
};
export const getMediaRecording = (id) => {
    return apiClient
        .get(`/media/recordings/${id}`)
        .responseType('blob');
};
export const getPlaybackInfo = (id) => {
    return apiClient
        .get(`/media/recordings/${id}/playback`);
};
export const resumableRecordingUpload = (fileId, file, xStartByte) => {
    const formData = new FormData();
    formData.append('theFile', file);
    return apiClient
        .set('Content-Type', null)
        .set('x-start-byte', xStartByte)
        .put(`/media/${fileId}`)
        .send(formData)
        .timeout({
        response: 60000,
        deadline: 90000, //1,5min
    });
};
