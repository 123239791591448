import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDownloadInvoiceMutation } from "src/features/payments/paymentsSlice";
const ResellerBillingsRow = ({ resellerBilling }) => {
    const { createdAt, forUserEmail, transactionNumber, paymentProvider, status, amount, purchase, invoiceNumber, invoiceEnLocation } = resellerBilling;
    const [downloadInvoice] = useDownloadInvoiceMutation();
    const handleDownload = () => {
        downloadInvoice({ url: invoiceEnLocation, name: invoiceNumber });
    };
    return (_jsxs("tr", { children: [_jsx("td", { children: createdAt }), _jsx("td", { children: forUserEmail }), _jsx("td", { children: transactionNumber }), _jsx("td", { children: paymentProvider }), _jsx("td", { children: status }), _jsx("td", { children: amount }), _jsx("td", { children: purchase.name }), _jsx("td", { children: invoiceNumber && _jsx("a", { href: "#", onClick: handleDownload, children: invoiceNumber }) })] }));
};
export default ResellerBillingsRow;
