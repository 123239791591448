import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Nav } from "banafo-design-system";
import logo from "images/logo/logo-dark.svg";
import LineSeparator from "src/common/components/ui-old/LineSeparator";
import { SIDEBAR_MENU_ITEMS } from "./config";
import { selectSidebarIsExpanded } from "./sidebarSlice";
import SidebarItem from "./SidebarItem";
import SearchBar from "src/features/transcriptsSearch/SearchBar";
import { HOME_ROUTE } from "src/app/router/config";
import UserDetails from "src/features/account/UserDetails";
import UpgradeButton from "src/features/sidebar/UpgradeButton";
import StartTrial from "src/features/sidebar/StartTrial";
import "./Sidebar.scss";
import { useAppSelector } from "src/app/store/hooks";
const Sidebar = ({ className }) => {
    const isExpanded = useAppSelector(selectSidebarIsExpanded);
    return (_jsxs("div", { className: cx("sidebar", className, { "sidebar-expanded": isExpanded, "sidebar-collapsed": !isExpanded }), children: [_jsx(Link, { className: "logo-container", to: HOME_ROUTE, children: _jsx("img", { src: logo, alt: "Banafo logo", className: "logo" }) }), _jsx(LineSeparator, { borderColor: "dark" }), _jsx(SearchBar, {}), _jsx(LineSeparator, { borderColor: "dark" }), _jsx(UserDetails, {}), _jsx(LineSeparator, { borderColor: "dark" }), _jsx(Nav, { defaultActiveKey: HOME_ROUTE, className: "flex-column mt-100", children: SIDEBAR_MENU_ITEMS.map(item => (_jsx(SidebarItem, { 
                    // @ts-ignore
                    item: item }, item.key))) }), _jsx(UpgradeButton, {}), _jsx(StartTrial, {})] }));
};
export default Sidebar;
