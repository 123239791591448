var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Col, Row } from "banafo-design-system";
import { PROVIDER_STATUS_COMING_SOON } from "src/features/integrations/config";
import { selectConnectingProvider } from "src/features/integrations/integrationsSlice";
import ProviderItem from "./ProviderItem";
import ProviderConnectModal from "./modals/ProviderConnectModal";
import ComingSoonModal from "src/features/integrations/modals/ComingSoonModal";
import { selectSidebarIsExpanded } from "src/features/sidebar/sidebarSlice";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
const ProvidersList = ({ providers }) => {
    const isSidebarExpanded = useSelector(selectSidebarIsExpanded);
    const authUser = useSelector(selectAuthUser);
    const { isFeatureEnabled } = useAccount();
    const { id, status } = useSelector((state) => selectConnectingProvider(state));
    return (_jsxs(_Fragment, { children: [_jsx(Row, { xs: 1, sm: 2, md: 3, lg: isSidebarExpanded ? 2 : 3, xl: 4, className: "g-200 py-200", children: providers.map((_a) => {
                    var { id } = _a, provider = __rest(_a, ["id"]);
                    return _jsx(Col, { children: _jsx(ProviderItem, { provider: Object.assign({ id }, provider) }) }, id);
                }) }), id && status !== PROVIDER_STATUS_COMING_SOON && isFeatureEnabled(FeatureTypes.IntegrationsCrm) &&
                _jsx(ProviderConnectModal, {}), id && status === PROVIDER_STATUS_COMING_SOON &&
                _jsx(ComingSoonModal, {})] }));
};
export default ProvidersList;
