import { jsx as _jsx } from "react/jsx-runtime";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import ResellerBillingsContainerWrapper from "src/features/resellerBillingHistory/ResellerBillingsContainerWrapper";
const ResellerBillingHistoryPage = () => {
    return (_jsx(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "p-200 d-flex flex-column" },
        }, children: _jsx(ResellerBillingsContainerWrapper, {}) }));
};
export default ResellerBillingHistoryPage;
