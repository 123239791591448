var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { BifIconShare, BifIconUser, OverlayTrigger, Stack, Tooltip } from "banafo-design-system";
import { useLazyGetContactUrlQuery } from "src/features/contacts/apiContactsSlice";
import { useUpdateCallMapContactMutation } from "src/features/conversations/conversationsSlice";
import { PROVIDER_NAME_BANAFO } from "src/features/integrations/config";
const ExistingContactRow = ({ contact, callId, handleHideModal }) => {
    const { t } = useTranslation();
    const [loadContactUrl] = useLazyGetContactUrlQuery();
    const [updateRecording] = useUpdateCallMapContactMutation({ fixedCacheKey: "update-recording" });
    const handleContactOpenClick = () => {
        loadContactUrl({ provider: contact.remote.provider || PROVIDER_NAME_BANAFO, contactId: contact.id, type: contact.type })
            .unwrap()
            .then(({ contactUrl }) => {
            window.open(contactUrl, '_blank');
        })
            .catch(e => null);
    };
    const handleContactAddClick = () => {
        const remote = contact.provider === PROVIDER_NAME_BANAFO ? {} : { provider: contact.provider, id: contact === null || contact === void 0 ? void 0 : contact.id };
        const _a = Object.assign({ _id: contact === null || contact === void 0 ? void 0 : contact.id }, contact), { id } = _a, contactRest = __rest(_a, ["id"]);
        const data = {
            contact: Object.assign({ remote, displayName: contact.fullName }, contactRest)
        };
        updateRecording({ id: callId, data })
            .unwrap()
            .then(handleHideModal)
            .catch(e => null);
    };
    return (_jsxs("tr", { children: [_jsx("td", { children: contact.displayName }), _jsx("td", { children: contact.company }), _jsx("td", { children: _jsxs(Stack, { direction: 'horizontal', className: 'justify-content-evenly', children: [contact.remote.provider &&
                            _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "open-note", children: t('Open.contact_') }), children: _jsx("span", { children: _jsx(BifIconShare, { className: 'cursor-pointer text-dark', onClick: handleContactOpenClick }) }) }), _jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: "open-note", children: t('Add.contact_') }), children: _jsx("span", { children: _jsx(BifIconUser, { className: 'cursor-pointer text-dark', onClick: handleContactAddClick }) }) })] }) })] }));
};
export default ExistingContactRow;
