import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Stack } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_FORMAT_LIST, FILE_UPLOAD_MAX_SIZE } from "src/features/conversations/config";
import ManualRecording from "src/features/account/ManualRecording";
import UploadRecording from "src/features/account/UploadRecording";
const RecordMeetingSection = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: "text-center text-white", children: [_jsxs("h4", { className: "font-weight-bold mb-200", children: [t("Welcome_"), "!"] }), _jsxs("div", { className: "col-8 d-flex flex-wrap mx-auto gap-100", children: [_jsx(ManualRecording, { style: { flex: "1 1 270px" } }), _jsx(UploadRecording, { style: { flex: "1 1 270px" } })] }), _jsxs(Stack, { direction: "horizontal", gap: 75, className: 'justify-content-center mt-200', children: [_jsxs("small", { className: "fw-bold", children: [FILE_UPLOAD_MAX_SIZE, " ", t('limit.for.uploading_')] }), _jsx("small", { className: "vr" }), _jsxs("small", { children: [t('Supported.formats_'), ": ", FILE_UPLOAD_FORMAT_LIST.join(', ')] })] })] }));
};
export default RecordMeetingSection;
