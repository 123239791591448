var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Group, Text, ThemeIcon } from "@mantine/core";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";
const AppCard = (_a) => {
    var { icon, name, moreInfoUrl } = _a, props = __rest(_a, ["icon", "name", "moreInfoUrl"]);
    return (_jsxs(Group, Object.assign({ gap: 'sm', align: "center", wrap: "nowrap" }, props, { children: [_jsx(ThemeIcon, { variant: "white", size: "lg", color: "gray.6", children: icon }), _jsxs(Box, { children: [_jsx(Text, { fw: "bold", children: name }), _jsx(Text, { children: _jsx(Trans, { i18nKey: "More.info.here_", components: [_jsx(Link, { to: moreInfoUrl, color: "green", target: "_blank" })] }) })] })] })));
};
export default AppCard;
