'use strict';
export function set(key, value) {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}
export function remove(key) {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
    }
}
export function get(key, defaultValue) {
    if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem(key);
        if (item) {
            return JSON.parse(item) || defaultValue;
        }
    }
}
export function patch(data) {
    if (typeof data === 'object' && data !== null && data.constructor === Object) {
        Object.keys(data).forEach(key => set(key, data[key]));
    }
}
