var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import classNames from "classnames";
const propTypes = {
    bsPrefix: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark']),
};
const defaultProps = {
    variant: null,
};
function Thead(_a) {
    var { bsPrefix, className, variant } = _a, props = __rest(_a, ["bsPrefix", "className", "variant"]);
    const prefix = bsPrefix || 'thead';
    const classes = classNames(className, variant && `${prefix}-${variant}`);
    return _jsx("thead", Object.assign({}, props, { className: classNames(classes) }));
}
Thead.propTypes = propTypes;
Thead.defaultProps = defaultProps;
export default Thead;
