import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, BanafoInput } from "banafo-design-system";
import DatePicker from "react-datepicker";
import DatePickerInput from "src/common/components/ui/DatePickerInput";
import { useTranslation } from "react-i18next";
import { changeFilter, selectFilters } from "src/features/conversations/conversationsSlice";
import { useDispatch, useSelector } from "react-redux";
const GeneralFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { search, startedAt, endedAt } = useSelector(selectFilters);
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        dispatch(changeFilter({ name, value }));
    };
    return (_jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xs: 12, xl: 8, children: _jsxs(Row, { className: "gx-25", children: [_jsx(Col, { xs: 12, lg: 7, className: "mb-50 mb-lg-0", children: _jsx(BanafoInput, { name: "search", mode: "dark", placeholder: t('Search.conversations_'), onChange: handleFilterChange }) }), _jsx(Col, { xs: 12, lg: true, className: "mb-50 mb-lg-0", children: _jsx(DatePicker, { customInput: _jsx(DatePickerInput, {}), selected: startedAt, onChange: date => handleFilterChange({ target: { name: 'startedAt', value: date } }), selectsStart: true, startDate: startedAt, endDate: endedAt, isClearable: true, placeholderText: `${t('From_')}`, dateFormat: "dd.MM.yyyy", wrapperClassName: "d-block", className: "form-control" }) }), _jsx(Col, { xs: 12, lg: true, className: "mb-50 mb-lg-0", children: _jsx(DatePicker, { customInput: _jsx(DatePickerInput, {}), selected: endedAt, onChange: date => handleFilterChange({ target: { name: 'endedAt', value: date } }), selectsEnd: true, startDate: startedAt, endDate: endedAt, minDate: startedAt, isClearable: true, placeholderText: `${t('To_')}`, dateFormat: "dd.MM.yyyy", wrapperClassName: "d-block", className: "form-control" }) })] }) }) }));
};
export default GeneralFilters;
