import { useEffect, useState } from "react";
import { useGetOauthXsrfTokenQuery, useOauthLoginMutation } from "src/features/deviceActivate/deviceActivateApi";
import useGetDeviceCode from "src/features/deviceActivate/hooks/useGetDeviceCode";
import useActivateDeviceForm from "src/features/deviceActivate/hooks/useActivateDeviceForm";
const useActivateDeviceCode = (options) => {
    const defaultOptions = {
        enableAutoSubmit: true,
        hideAutoSubmitProcess: false,
    };
    const { enableAutoSubmit, hideAutoSubmitProcess } = Object.assign(Object.assign({}, defaultOptions), options);
    const { data: { xsrf } = {} } = useGetOauthXsrfTokenQuery();
    const [oauthLogin] = useOauthLoginMutation({ fixedCacheKey: "activate-device-code" });
    const form = useActivateDeviceForm();
    const internalForm = useActivateDeviceForm();
    const _form = (enableAutoSubmit && hideAutoSubmitProcess) ? internalForm : form;
    const [startAutoSubmit, setStartAutoSubmit] = useState(false);
    const code = useGetDeviceCode();
    useEffect(() => {
        if (code == null)
            return;
        _form.setFieldValue('code', code);
        if (enableAutoSubmit)
            setStartAutoSubmit(true);
    }, [code]);
    useEffect(() => {
        if (!startAutoSubmit)
            return;
        if (!xsrf)
            return;
        _form.validate();
        if (!_form.isValid()) {
            setStartAutoSubmit(false);
            return;
        }
        oauthLogin({ xsrf, code: _form.values.code })
            .unwrap()
            .catch(() => null)
            .finally(() => {
            setStartAutoSubmit(false);
            _form.reset();
        });
    }, [startAutoSubmit, xsrf]);
    const triggerSubmit = () => {
        if (!xsrf)
            return;
        form.validate();
        if (!form.isValid())
            return;
        oauthLogin({ xsrf, code: form.values.code });
        form.reset();
    };
    const onSubmit = (e) => {
        e.preventDefault();
        triggerSubmit();
    };
    return { form, triggerSubmit, onSubmit };
};
export default useActivateDeviceCode;
