import { jsx as _jsx } from "react/jsx-runtime";
import { SpinnerBackdrop } from "banafo-design-system";
import { useChangePasswordMutation, useUpdateProfileDataMutation, useUploadProfilePictureMutation } from "src/features/sidebar/sidebarSlice";
import { useUpdateAccountMutation } from "src/features/account/accountApi";
import { useDeleteCallMutation, useUpdateCallMutation, useUploadFileMutation } from "src/features/conversations/conversationsSlice";
import { useInitPaymentMutation } from "src/features/payments/paymentsSlice";
import { useUpdateConversationActionPointsMutation, useUpdateConversationSummaryMutation } from "src/features/conversation/conversationApi";
const GeneralLoader = () => {
    const [, { isLoading: isChangingPassword }] = useChangePasswordMutation({ fixedCacheKey: "change-password" });
    const [, { isLoading: isUploadingProfilePicture }] = useUploadProfilePictureMutation({ fixedCacheKey: "upload-profile-picture" });
    const [, { isLoading: isUpdatingProfileData }] = useUpdateProfileDataMutation({ fixedCacheKey: "update-profile-data" });
    const [, { isLoading: isUpgradingAccount }] = useUpdateAccountMutation({ fixedCacheKey: "upgrade-account" });
    const [, { isLoading: isUploadingRecording }] = useUploadFileMutation({ fixedCacheKey: "upload-recording" });
    const [, { isLoading: isUpdatingSummary }] = useUpdateConversationSummaryMutation({ fixedCacheKey: "update-summary" });
    const [, { isLoading: isUpdationActionPoints }] = useUpdateConversationActionPointsMutation({ fixedCacheKey: "update-action-points" });
    const [, { isLoading: isDeletingRecording }] = useDeleteCallMutation({ fixedCacheKey: "delete-recording" });
    const [, { isLoading: isUpdatingRecording }] = useUpdateCallMutation({ fixedCacheKey: "update-recording" });
    const [, { isLoading: isInitializingPayment }] = useInitPaymentMutation({ fixedCacheKey: "init-payment" });
    if (!(isChangingPassword || isUploadingProfilePicture || isUpdatingProfileData || isUpgradingAccount ||
        isUploadingRecording || isDeletingRecording || isUpdatingRecording || isInitializingPayment || isUpdatingSummary ||
        isUpdationActionPoints)) {
        return null;
    }
    return _jsx(SpinnerBackdrop, {});
};
export default GeneralLoader;
