import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import classNames from "classnames";
import './LineSeparator.scss';
const propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    borderColor: PropTypes.oneOf([
        'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'notice', 'dark', 'light',
        '100', '200', '300', '400', '500', '600', '700', '800', '900',
    ]),
};
const LineSeparator = ({ text, borderColor = 'primary', className }) => {
    const classes = classNames(className, 'separator');
    const classesLine = classNames('separator-line', borderColor && `border-${borderColor}`);
    return (_jsxs("div", { className: classes, children: [_jsx("div", { className: classesLine }), text && _jsx("span", { className: "separator-text", children: text })] }));
};
LineSeparator.propTypes = propTypes;
export default LineSeparator;
