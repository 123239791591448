export const FETCH_CALLS_BEGIN = 'FETCH_CALLS_BEGIN';
export const FETCH_CALLS_SUCCESS = 'FETCH_CALLS_SUCCESS';
export const FETCH_CALLS_FAILURE = 'FETCH_CALLS_FAILURE';
export const CALLS_INITIALIZE = 'CALLS_INITIALIZE';
export const CALLS_DE_INITIALIZE = 'CALLS_DE_INITIALIZE';
export const CALLS_LOADING = 'CALLS_LOADING';
export const CALLS_LOADED = 'CALLS_LOADED';
export const CALLS_LIMIT_SAVE = 'CALLS_LIMIT_SAVE';
export const CALLS_PAGINATION_SAVE = 'CALLS_PAGINATION_SAVE';
export const CALLS_PAGINATION_RESET = 'CALLS_PAGINATION_RESET';
export const CALLS_ADD = 'CALLS_ADD';
export const CALLS_SET = 'CALLS_SET';
export const CALLS_RESET = 'CALLS_RESET';
export const CALL_DELETE = 'CALL_DELETE';
export const CALL_MODIFY = 'CALL_MODIFY';
