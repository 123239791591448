import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BifIconSoundwave, ListGroup, Overlay, Popover } from "banafo-design-system";
import { formattedDateTimeFromNow } from "src/features/auth/util/dateTime";
import getConfigEnumText from "src/common/utils/config/configEnumText";
import { ACTIVITY_CALL_CREATED, ACTIVITY_CONVERSATION_SUMMARIZED, ACTIVITY_CONVERSATION_TRANSCRIBED, ACTIVITY_INTEGRATION_ACTIVATED, ACTIVITY_INTEGRATION_DEACTIVATED, ACTIVITY_LOCAL_NOTE_CREATED, ACTIVITY_LOCAL_TASK_CREATED, ACTIVITY_TYPES_ENUM, ACTIVITY_USER_CREATED, ACTIVITY_USER_UPDATED } from "src/features/auth/config/activities";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import classNames from "classnames";
import configEnumProp from "src/common/utils/config/configEnumProp";
const ActivitiesItem = (activity) => {
    const { activityType, activityContent, creationDate } = activity;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const handleRowClick = () => {
        setShow(!show);
        if (activityType === ACTIVITY_CALL_CREATED) {
            navigate(`/conversations/${activityContent._id}`, { state: { activeTab: 0 } });
        }
        if ([ACTIVITY_CONVERSATION_TRANSCRIBED, ACTIVITY_CONVERSATION_SUMMARIZED].includes(activityType)) {
            if (activityContent.callId) {
                navigate(`/conversations/${activityContent.callId}`, { state: { activeTab: 0 } });
            }
        }
        if (activityType === ACTIVITY_LOCAL_NOTE_CREATED) {
            if (activityContent.parentType === 'call') {
                navigate(`/conversations/${activityContent.parentId}`, { state: { activeTab: 1, activityNoteId: activityContent._id } });
            }
        }
        if (activityType === ACTIVITY_LOCAL_TASK_CREATED) {
            if (activityContent.parentType === 'call') {
                navigate(`/conversations/${activityContent.parentId}`, { state: { activeTab: 1 } });
            }
        }
    };
    const renderOverlay = () => {
        if ([
            ACTIVITY_USER_UPDATED,
            ACTIVITY_USER_CREATED,
            ACTIVITY_INTEGRATION_ACTIVATED,
            ACTIVITY_INTEGRATION_DEACTIVATED,
            ACTIVITY_LOCAL_TASK_CREATED,
            ACTIVITY_LOCAL_NOTE_CREATED
        ].includes(activityType)) {
            if ([ACTIVITY_LOCAL_TASK_CREATED, ACTIVITY_LOCAL_NOTE_CREATED].includes(activityType)
                && activityContent.parentType === 'call') {
                return;
            }
            return (_jsx(Overlay, { containerPadding: 20, target: target.current, show: show, rootClose: true, onHide: () => setShow(false), placement: "top-start", flip: true, children: _jsx(Popover, { children: _jsxs(Popover.Body, { children: [activityType === ACTIVITY_LOCAL_NOTE_CREATED
                                && _jsxs(_Fragment, { children: [_jsx("h6", { children: t('Title_') }), _jsx("p", { children: activityContent.title }), _jsx("h6", { children: t('Description_') }), _jsx("p", { children: activityContent.description })] }), activityType === ACTIVITY_LOCAL_TASK_CREATED
                                && _jsxs(_Fragment, { children: [_jsx("h6", { children: t('Title_') }), _jsx("p", { children: activityContent.title }), _jsx("h6", { children: t('Description_') }), _jsx("p", { children: activityContent.description }), _jsx("h6", { children: t('Start.date_') }), _jsx("p", { children: activityContent.dueDate })] }), [ACTIVITY_INTEGRATION_ACTIVATED, ACTIVITY_INTEGRATION_DEACTIVATED].includes(activityType)
                                && _jsxs(_Fragment, { children: [_jsx("h6", { children: t('Provider_') }), _jsx("p", { children: activityContent.providerName })] }), [ACTIVITY_USER_UPDATED, ACTIVITY_USER_CREATED].includes(activityType)
                                && _jsxs(_Fragment, { children: [_jsx("h6", { children: t('Full.name_') }), _jsx("p", { children: activityContent.fullName }), _jsx("h6", { children: t('Email_') }), _jsx("p", { children: activityContent.email })] })] }) }) }));
        }
    };
    // @ts-ignore
    const Icon = configEnumProp(ACTIVITY_TYPES_ENUM, activityType, 'icon', BifIconSoundwave);
    return (_jsxs(_Fragment, { children: [_jsxs(ListGroup.Item, { action: true, as: "li", onClick: handleRowClick, children: [_jsx("div", { className: classNames({ [`text-${configEnumProp(ACTIVITY_TYPES_ENUM, activityType, 'color')}`]: configEnumProp(ACTIVITY_TYPES_ENUM, activityType, 'color') }), children: Icon({ 'className': "mx-150" }) }), _jsx("div", { ref: target, children: getConfigEnumText(ACTIVITY_TYPES_ENUM, activityType) }), _jsx("div", { className: "ms-auto", children: creationDate ? formattedDateTimeFromNow(creationDate) : '' })] }), renderOverlay()] }));
};
export default ActivitiesItem;
