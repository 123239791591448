import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAcceptResellerInvitationMutation } from "src/features/resellerCustomers/resellerCustomersApi";
const ResellerInvitation = () => {
    const { token } = useParams();
    const [acceptResellerInvitation] = useAcceptResellerInvitationMutation();
    useEffect(() => {
        acceptResellerInvitation({ token });
    }, []);
    return (_jsx(_Fragment, { children: "Reseller invitation" }));
};
export default ResellerInvitation;
