export function setEntries(state, action) {
    const { payload } = action;
    const { items } = payload;
    return Object.assign({}, ...items.map(item => ({ [item.id]: item })));
}
export function setEntry(state, action) {
    const { payload } = action;
    const { item } = payload;
    return {
        [item.id]: Object.assign({}, item)
    };
}
export function setIds(state, action) {
    const { payload } = action;
    const { items } = payload;
    return items.map(item => item.id);
}
export function setId(state, action) {
    const { payload } = action;
    const { item } = payload;
    return [item.id];
}
export function addEntries(entries, action) {
    const { payload } = action;
    const { items } = payload;
    return Object.assign({}, entries, ...items.map(item => ({ [item.id]: item })));
}
export function addEntry(entries, action) {
    const { payload } = action;
    const { item } = payload;
    return Object.assign(Object.assign({}, entries), { [item.id]: Object.assign({}, item) });
}
export function modifyEntry(entries, action) {
    const { payload } = action;
    const { item } = payload;
    return Object.assign(Object.assign({}, entries), { [item.id]: Object.assign(Object.assign({}, entries[item.id]), item) });
}
export function addId(ids, action) {
    const { payload } = action;
    const { item } = payload;
    return [...ids, item.id];
}
export function addIds(ids, action) {
    const { payload } = action;
    const { items } = payload;
    return [...ids, ...items.map(item => item.id)];
}
export function deleteEntry(state, action) {
    const { payload } = action;
    const { id } = payload;
    const entries = Object.assign({}, state);
    delete entries[id];
    return entries;
}
export function removeId(state, action) {
    const { payload } = action;
    const { id } = payload;
    return state.filter(item => item !== id);
}
