import { jsx as _jsx } from "react/jsx-runtime";
import { BanafoInput, Col, Row } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { changeFilter, selectResellerCustomersFilters } from "src/features/resellerCustomers/resellerCustomersSlice";
const GeneralFilters = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { search } = useAppSelector(selectResellerCustomersFilters);
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        dispatch(changeFilter({ name, value }));
    };
    return (_jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xs: 12, xl: 8, children: _jsx(Row, { children: _jsx(Col, { xs: 12, lg: 7, className: "mx-auto mb-50 mb-lg-0", children: _jsx(BanafoInput, { name: "search", value: search, mode: "dark", placeholder: t('Search.clients_'), onChange: handleFilterChange }) }) }) }) }));
};
export default GeneralFilters;
