import { combineReducers } from "redux";
import { FETCH_RECORDING_CALLS_SUCCESS, RECORDINGS_CALLS_ADD, RECORDINGS_CALLS_RESET } from '../../actions/recordingCalls';
import { addEntries, addIds, setEntries, setIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case RECORDINGS_CALLS_ADD:
            return addEntries(state, action);
        case RECORDINGS_CALLS_RESET:
            return {};
        case FETCH_RECORDING_CALLS_SUCCESS:
            return setEntries(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case RECORDINGS_CALLS_ADD:
            return addIds(state, action);
        case RECORDINGS_CALLS_RESET:
            return [];
        case FETCH_RECORDING_CALLS_SUCCESS:
            return setIds(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
