import { jsx as _jsx } from "react/jsx-runtime";
import { BanafoModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideInviteCustomerModal, selectInviteCustomerModalIsOpen } from "src/features/resellerCustomers/resellerCustomersSlice";
import { Button, TextInput } from "@mantine/core";
import { useFocusTrap } from "@mantine/hooks";
import { isEmail, useForm } from "@mantine/form";
import { useInviteResellerCustomerMutation } from "src/features/resellerCustomers/resellerCustomersApi";
const InviteCustomerModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const show = useAppSelector(selectInviteCustomerModalIsOpen);
    const [inviteResellerCustomer, { isLoading, reset }] = useInviteResellerCustomerMutation();
    const focusTrapRef = useFocusTrap(true);
    const form = useForm({
        initialValues: { email: '' },
        validate: {
            email: isEmail(t('errors:Invalid.email_')),
        },
    });
    const handleHideModal = () => {
        form.reset();
        dispatch(hideInviteCustomerModal());
        reset();
    };
    const handleSubmit = (values) => {
        inviteResellerCustomer(Object.assign({}, values))
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsx(BanafoModal, { show: show, onHide: handleHideModal, title: t("Invite.Customer_"), footer: _jsx(Button, { tt: "uppercase", fw: "bold", type: "submit", form: "inviteCustomer", color: "red", loading: isLoading, children: t("Invite_") }), "aria-labelledby": "contained-modal-title-vcenter", children: _jsx("form", { id: "inviteCustomer", onSubmit: form.onSubmit(handleSubmit), ref: focusTrapRef, children: _jsx(TextInput, Object.assign({ label: t('Email_'), placeholder: t('Email_'), withAsterisk: true, mt: "md" }, form.getInputProps('email'))) }) }));
};
export default InviteCustomerModal;
