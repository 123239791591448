import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";
import { SpinnerBackdrop } from "banafo-design-system";
import connectAndForwardRef from "src/features/auth/util/redux";
import { selectAppLoading } from "src/features/auth/store/selectors/common";
import Loadable from "src/features/auth/component-old/common/Loadable";
import { selectAudioPlayer } from "src/features/conversations/conversationsSlice";
import AudioPlayerMarkers from "src/features/conversations/audioPlayer/AudioPlayerMarkers";
const WaveSurferPlayer = ({ loadingApp, recordingId, playbackLoading, playbackReady }, ref) => {
    const { showNotes, showPois } = useSelector(selectAudioPlayer);
    return (_jsxs(_Fragment, { children: [_jsx(Loadable, { loading: playbackLoading && !loadingApp, loader: _jsx(SpinnerBackdrop, { position: "absolute" }) }), _jsx("div", { className: "RecordingPage-recording-main", children: _jsxs("div", { className: cx("RecordingPage-waveform-container"), children: [_jsx("div", { ref: ref, "aria-label": "wavesurfer" }), playbackReady && (showNotes || showPois) && _jsx(AudioPlayerMarkers, { showNotes: showNotes, showPoi: showPois, recordingId: recordingId })] }) })] }));
};
function mapStateToProps(state) {
    return {
        loadingApp: selectAppLoading(state),
        playbackLoading: state.app.playback.loading,
        playbackReady: state.app.playback.ready
    };
}
const mapDispatchToProps = {};
export default connectAndForwardRef(mapStateToProps, mapDispatchToProps)(WaveSurferPlayer);
WaveSurferPlayer.propTypes = {
    loadingApp: PropTypes.bool,
    playbackLoading: PropTypes.any,
    playbackReady: PropTypes.any,
    recordingId: PropTypes.any
};
