import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectAuth } from "src/features/auth/store/selectors/auth";
import { Navigate, Outlet } from "react-router-dom";
import { HOME_ROUTE, RESELLER_CUSTOMERS_ROUTE, WIZARD_ROUTE } from "src/app/router/config";
import useHasRole from "src/features/account/hooks/useHasRole";
import { RoleTypes } from "src/features/account/types";
function RedirectAuth() {
    const auth = useSelector(selectAuth);
    const isReseller = useHasRole(RoleTypes.Reseller);
    if (!auth.user) {
        return _jsx(Outlet, {});
    }
    else {
        if (isReseller) {
            return _jsx(Navigate, { to: RESELLER_CUSTOMERS_ROUTE });
        }
        if (auth.user.showPaymentRequired) {
            return _jsx(Navigate, { to: `/payment/${auth.user.registeredAs}` });
        }
        if (auth.user.showWizard) {
            return _jsx(Navigate, { to: WIZARD_ROUTE });
        }
        if (auth.redirectUrl) {
            const path = auth.redirectUrl;
            return _jsx(Navigate, { to: path });
        }
        return _jsx(Navigate, { to: HOME_ROUTE });
    }
}
export default RedirectAuth;
