import { extendedApi, makeSelectAllActivities, useGetActivitiesQuery } from "src/features/activityLog/activityLogApi";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { resetActivities, incrementPage, selectActivitiesApiArgs, selectIsLastPage } from "src/features/activityLog/activityLogSlice";
import { useLocation } from "react-router-dom";
const useGetActivities = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const resultsRef = useRef([]);
    const args = useAppSelector(selectActivitiesApiArgs);
    const result = useGetActivitiesQuery(args);
    const { isFetching } = result;
    const selectAllActivities = useMemo(makeSelectAllActivities, []);
    const activities = useAppSelector(state => selectAllActivities(state, args));
    const hasNextPage = !useAppSelector(selectIsLastPage);
    useEffect(() => {
        const result = dispatch(extendedApi.endpoints.getActivities.initiate(args));
        // @ts-ignore
        resultsRef.current.push(result);
    }, [args]);
    useEffect(() => {
        return () => {
            dispatch(resetActivities());
            for (let result of resultsRef.current) {
                // @ts-ignore
                result.unsubscribe();
            }
        };
    }, [location]);
    const loadMoreActivities = useCallback(() => {
        if (isFetching || !hasNextPage) {
            return;
        }
        dispatch(incrementPage());
    }, [isFetching, hasNextPage]);
    return Object.assign({ activities, hasNextPage, loadMoreActivities }, result);
};
export default useGetActivities;
