import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown, Form } from "banafo-design-system";
import CustomDropdownToggle from "src/common/components/ui/CustomDropdownToggle";
import { changeFilter, selectFilters, selectFiltersHamburgerIsExpanded, toggleFiltersHamburgerMenu } from "src/features/conversations/conversationsSlice";
import FilterMenu from "src/common/components/ui/FilterMenu";
const DropdownMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { directionInbound, directionOutbound, typeWebConference, typeOther } = useSelector(selectFilters);
    const isHamburgerMenuExpanded = useSelector(selectFiltersHamburgerIsExpanded);
    const hasActiveFilters = directionInbound || directionOutbound || typeWebConference || typeOther;
    const toggleMenu = () => dispatch(toggleFiltersHamburgerMenu());
    const handleMenuToggle = () => {
        toggleMenu();
    };
    const handleCheckboxFilterChange = (e) => {
        const { name, checked } = e.target;
        dispatch(changeFilter({ name, value: checked }));
        toggleMenu();
    };
    return (_jsxs(Dropdown, { onToggle: handleMenuToggle, autoClose: "outside", className: 'ms-100', children: [hasActiveFilters && _jsx("span", { className: "position-absolute top-0 start-100 translate-middle p-25 bg-danger border border-light rounded-circle" }), _jsx(Dropdown.Toggle, { as: CustomDropdownToggle, el: (props) => _jsx(FilterMenu, Object.assign({}, props)), isOpen: isHamburgerMenuExpanded, bsPrefix: " ", id: "sidebar-dropdown-menu" }), _jsxs(Dropdown.Menu, { children: [_jsx(Dropdown.Item, { as: "button", children: _jsx(Form.Check
                        // inline
                        , { 
                            // inline
                            label: t('Incoming_'), type: 'checkbox', id: 'incoming', 
                            // className='ms-auto'
                            checked: directionInbound, name: "directionInbound", onChange: handleCheckboxFilterChange }) }), _jsx(Dropdown.Item, { as: "button", children: _jsx(Form.Check, { inline: true, variant: "tertiary", label: t('Outgoing_'), type: 'checkbox', id: `outgoing`, checked: directionOutbound, name: "directionOutbound", onChange: handleCheckboxFilterChange }) }), _jsx(Dropdown.Item, { as: "button", children: _jsx(Form.Check, { inline: true, variant: "tertiary", label: t('Web.conference_'), type: 'checkbox', id: `web-conference`, checked: typeWebConference, name: "typeWebConference", onChange: handleCheckboxFilterChange }) }), _jsx(Dropdown.Item, { as: "button", children: _jsx(Form.Check, { inline: true, variant: "tertiary", label: t('Other_'), type: 'checkbox', id: `other`, checked: typeOther, name: "typeOther", onChange: handleCheckboxFilterChange }) })] })] }));
};
export default DropdownMenu;
