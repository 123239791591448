import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, BanafoInput } from "banafo-design-system";
import { INTEGRATION_STATUSES_ENUM, PROVIDER_TYPES_ENUM } from "./config";
import { selectIntegrationsFilters, setStatusFilter, setTextFilter, setTypeFilter } from "./integrationsSlice";
const FiltersForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { text, status, type } = useSelector(state => selectIntegrationsFilters(state));
    const handleTextFilterChange = (e) => {
        dispatch(setTextFilter(e.target.value));
    };
    const handleStatusFilterChange = (e) => {
        dispatch(setStatusFilter(e.target.value));
    };
    const handleTypeFilterChange = (e) => {
        dispatch(setTypeFilter(e.target.value));
    };
    return (_jsx(Form, { className: "my-200", children: _jsxs(Row, { children: [_jsx(Col, { xs: 12, lg: 6, className: "mb-50 mb-lg-100", children: _jsx(BanafoInput, { placeholder: t("Search.integration_"), value: text, onChange: handleTextFilterChange }) }), _jsx(Col, { xs: 12, lg: true, className: "mb-50 mb-lg-100", children: _jsxs(Form.Select, { value: status, onChange: handleStatusFilterChange, children: [_jsx("option", { value: '', children: t("All.integrations_") }), INTEGRATION_STATUSES_ENUM.map(({ value, text }, index) => _jsx("option", { value: value, children: text }, index))] }) }), _jsx(Col, { xs: 12, lg: true, className: "mb-50 mb-lg-100", children: _jsxs(Form.Select, { value: type, onChange: handleTypeFilterChange, children: [_jsx("option", { value: '', children: t("All.types_") }), PROVIDER_TYPES_ENUM.map(({ value, text }, index) => _jsx("option", { value: value, children: text }, index))] }) })] }) }));
};
export default FiltersForm;
