import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import useAccount from "src/features/account/hooks/useAccount";
import { Button } from "@mantine/core";
const UpdateButton = () => {
    const { t } = useTranslation();
    const { upgradeAccount } = useAccount();
    const handleUpgradeAccount = () => {
        upgradeAccount();
    };
    return (_jsx(Button, { onClick: handleUpgradeAccount, tt: "uppercase", color: "teal", children: t('Upgrade.to.PRO_') }));
};
export default UpdateButton;
