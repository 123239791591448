import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { setRedirectUrl } from "src/features/auth/store/actions/auth/login";
import { matchPath, matchRoutes, Navigate, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { AUTH_LOGIN_ROUTE, HOME_ROUTE, RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE, RESELLER_CUSTOMERS_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE, RESELLER_CUSTOMERS_SETTINGS_ROUTE, RESELLER_INVITE_ROUTE } from "src/app/router/config";
import useHasRole from "src/features/account/hooks/useHasRole";
import { RoleTypes } from "src/features/account/types";
const propTypes = {
    rememberPath: PropTypes.bool,
};
function RequireAuth({ rememberPath = true }) {
    const dispatch = useDispatch();
    const authUser = useSelector(selectAuthUser);
    const isReseller = useHasRole(RoleTypes.Reseller);
    const location = useLocation();
    if (authUser) {
        const resellerRoutes = [
            { path: RESELLER_CUSTOMERS_ROUTE },
            { path: RESELLER_CUSTOMERS_SETTINGS_ROUTE },
            { path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE },
            { path: RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE },
            { path: RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE },
        ];
        if (!isReseller && matchRoutes(resellerRoutes, location)) {
            return _jsx(Navigate, { to: HOME_ROUTE });
        }
        return _jsx(Outlet, {});
    }
    // match reseller invitation page
    const matchResellerInvitePage = matchPath({ path: RESELLER_INVITE_ROUTE }, location.pathname);
    if (matchResellerInvitePage) {
        localStorage.setItem('resellerInvitationToken', matchResellerInvitePage.params.token);
    }
    // remembers the path and redirects to it after successful login
    if (!matchResellerInvitePage && rememberPath) {
        dispatch(setRedirectUrl(location.pathname + location.search));
    }
    return _jsx(Navigate, { to: AUTH_LOGIN_ROUTE });
}
RequireAuth.propTypes = propTypes;
export default RequireAuth;
