import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideActionPointsModal, selectActionPointsIsOpen } from "src/features/conversation/conversationSlice";
import { useUpdateConversationActionPointsMutation } from "src/features/conversation/conversationApi";
import { Button, LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";
import { useGetCallQuery } from "src/features/conversations/conversationsSlice";
import { useEffect } from "react";
import { useFocusTrap } from "@mantine/hooks";
const EditActionPointsModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { callId: id } = useParams();
    const show = useAppSelector(selectActionPointsIsOpen);
    const [updateActionPoints] = useUpdateConversationActionPointsMutation({ fixedCacheKey: "update-action-points" });
    const { data: conversation = {}, isLoading } = useGetCallQuery(id);
    const focusTrapRef = useFocusTrap(true);
    const form = useForm({
        initialValues: { actionPoints: '' },
        validate: {},
    });
    useEffect(() => {
        if (isLoading || !show)
            return;
        const { actionPoints } = conversation;
        form.initialize({ actionPoints });
        form.setInitialValues({ actionPoints });
        form.setValues({ actionPoints });
    }, [isLoading, show]);
    const handleHideModal = () => {
        form.reset();
        dispatch(hideActionPointsModal());
    };
    const handleSubmit = (values) => {
        updateActionPoints(Object.assign({ id }, values))
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t("Edit.action.points_"), footer: _jsx(Button, { tt: "uppercase", type: "submit", form: "action-points", color: "red", children: t("Save_") }), size: "xl", "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LoadingOverlay, { visible: isLoading, zIndex: 1000, overlayProps: { radius: "xl", blur: 5 }, loaderProps: { color: 'teal' } }), _jsx("form", { id: "action-points", onSubmit: form.onSubmit(handleSubmit), ref: focusTrapRef, children: _jsx(Textarea, Object.assign({ placeholder: t('Action.points_'), withAsterisk: true, autosize: true, minRows: 4 }, form.getInputProps('actionPoints'))) })] }));
};
export default EditActionPointsModal;
