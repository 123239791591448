import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter, hideSearch, selectFilters, selectSearchIsOpen } from "src/features/transcripts/transcriptsSlice";
import { Alert, BanafoInput } from "banafo-design-system";
import "./SearchBox.scss";
const SearchBox = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const show = useSelector(selectSearchIsOpen);
    const { search } = useSelector(selectFilters);
    const handleTranscriptSearch = (e) => {
        const { value } = e.target;
        dispatch(changeFilter({ name: 'search', value }));
    };
    if (show) {
        return (_jsx(Alert, { variant: "white", className: "search-box text-dark text-center mt-50 shadow-md", onClose: () => dispatch(hideSearch()), dismissible: true, children: _jsx(BanafoInput, { xs: 12, lg: 6, placeholder: t('Search_'), onChange: handleTranscriptSearch, value: search, autoFocus: true }) }));
    }
};
export default SearchBox;
