export const WAVE_SURFER_FAST_FORWARD_INTERVAL_1 = 1;
export const WAVE_SURFER_FAST_FORWARD_INTERVAL_5 = 5;
export const WAVE_SURFER_FAST_FORWARD_INTERVAL_10 = 10;
export const WAVE_SURFER_FAST_FORWARD_INTERVAL_20 = 20;
export const WAVE_SURFER_FAST_FORWARD_INTERVAL_40 = 40;
export const WAVE_SURFER_FAST_FORWARD_INTERVALS = [
    { value: WAVE_SURFER_FAST_FORWARD_INTERVAL_1, text: '1s' },
    { value: WAVE_SURFER_FAST_FORWARD_INTERVAL_5, text: '5s' },
    { value: WAVE_SURFER_FAST_FORWARD_INTERVAL_10, text: '10s' },
    { value: WAVE_SURFER_FAST_FORWARD_INTERVAL_20, text: '20s' },
    { value: WAVE_SURFER_FAST_FORWARD_INTERVAL_40, text: '40s' },
];
