import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { BanafoModal, Button, LineSeparator } from "banafo-design-system";
import { selectActiveSubscriptionId, selectUnsubscribeModalIsOpen, hideUnsubscribeModal, useUnSubscribeMutation } from "src/features/account/accountSlice";
const ConfirmUnsubscribeModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const show = useSelector(selectUnsubscribeModalIsOpen);
    const subscriptionId = useSelector(selectActiveSubscriptionId);
    const [unSubscribe] = useUnSubscribeMutation({ fixedCacheKey: "un-subscribe" });
    const handleHideModal = () => {
        dispatch(hideUnsubscribeModal());
    };
    const handleUnsubscribeAccount = () => {
        unSubscribe(subscriptionId)
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t('Unsubscribe.account_'), className: 'text-center', "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-400', children: t('Are.you.sure.you.want.to.unsubscribe.this.account_') }), _jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(Button, { variant: 'outline-dark', className: 'text-uppercase mx-100', onClick: handleHideModal, children: t('Cancel_') }), _jsx(Button, { variant: 'danger', className: 'text-uppercase mx-100 with-icon end', onClick: handleUnsubscribeAccount, children: t('Unsubscribe_') })] })] }));
};
export default ConfirmUnsubscribeModal;
