var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";
import AcronymFirstOfWord from "src/features/transcripts/utils/acronym/AcronymFirstOfWord";
import MetaDataSequentially from "src/features/transcripts/utils/metaData/MetaDataSequentially";
import { CHAT_THEME_COLOR_LIST, CHAT_USER_POSITION_LIST } from "src/features/transcripts/config";
import AiAnalysisChatFactory from "src/features/transcripts/utils/factory/AiAnalysisChatFactory";
const analysesAdapter = createEntityAdapter();
const initialAnalysesState = analysesAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getTranscriptsAnalyses: builder.query({
            query: ({ fileIds }) => {
                const payload = { fileIds };
                const params = new URLSearchParams(payload);
                return `/transcripts/analyses?${params}`;
            },
            transformResponse: (analyses = []) => analysesAdapter.setAll(initialAnalysesState, analyses.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["TranscriptsAnalyses"]
        }),
        getTranscriptsAnalysesTypes: builder.query({
            query: () => "/transcripts/analyses/types",
        }),
        analysesType: builder.mutation({
            query({ fileIds, type }) {
                return {
                    url: `/transcripts/analyses`,
                    method: "POST",
                    body: { fileIds, analyze_types: [type] }
                };
            },
            invalidatesTags: ["TranscriptsAnalyses"]
        }),
        analysesQuestion: builder.mutation({
            query({ fileIds, question }) {
                return {
                    url: `/transcripts/analyses`,
                    method: "POST",
                    body: { fileIds, custom_prompt: question }
                };
            },
            invalidatesTags: ["TranscriptsAnalyses"]
        }),
    })
});
export const { useGetTranscriptsAnalysesQuery, useGetTranscriptsAnalysesTypesQuery, useAnalysesTypeMutation, useAnalysesQuestionMutation, } = extendedApi;
// @ts-ignore
export const selectAnalysesResult = (state, args) => extendedApi.endpoints.getTranscriptsAnalyses.select(args)(state);
export const selectAnalysesData = createSelector(selectAnalysesResult, analysesResult => analysesResult.data);
export const { selectAll: selectAllAnalyses, selectEntities: selectEntitiesAnalyses,
// @ts-ignore
 } = analysesAdapter.getSelectors((state, args) => { var _a; return (_a = selectAnalysesData(state, args)) !== null && _a !== void 0 ? _a : initialAnalysesState; });
export const selectAnalysesAsChat = createSelector(
// @ts-ignore
(state, args) => selectAllAnalyses(state, args), (analyses) => {
    const acronymGenerator = new AcronymFirstOfWord();
    const colorGenerator = new MetaDataSequentially(CHAT_THEME_COLOR_LIST);
    const positionGenerator = new MetaDataSequentially(CHAT_USER_POSITION_LIST);
    const analysisChatFactory = new AiAnalysisChatFactory(acronymGenerator, colorGenerator, positionGenerator);
    const analysesChat = analysisChatFactory.create(analyses);
    return analysesChat;
});
