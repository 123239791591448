var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Button, Modal } from "banafo-design-system";
import { FailureIcon } from "./Icon";
import { useTranslation } from "react-i18next";
function MessageModal(_a) {
    var { message, icon, onHide } = _a, others = __rest(_a, ["message", "icon", "onHide"]);
    const { t } = useTranslation();
    return (_jsxs(Modal, Object.assign({ autoFocus: true, backdrop: false, onHide: onHide }, others, { "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(Modal.Header, { closeButton: false, className: "border-0" }), _jsx(Modal.Body, { children: _jsxs("div", { className: 'text-center', children: [icon, _jsx("h5", { children: message })] }) }), _jsx(Modal.Footer, { className: "pt-0 border-0", children: _jsx(Button, { variant: "secondary", size: "sm", onClick: onHide, children: t('Close_') }) })] })));
}
export function ErrorMessageModal(props) {
    const { t, i18n } = useTranslation();
    return _jsx(MessageModal, Object.assign({ message: `${t('errors:general_')}.` }, props, { icon: _jsx(FailureIcon, {}) }));
}
export default MessageModal;
MessageModal.propTypes = {
    icon: PropTypes.any,
    message: PropTypes.any,
    onHide: PropTypes.any
};
