import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BanafoInput, Form } from "banafo-design-system";
import { selectAuth } from "src/features/auth/store/selectors/auth";
import SearchModal from "./SearchModal";
const SearchBar = () => {
    const { t } = useTranslation();
    const auth = useSelector((state) => selectAuth(state));
    const [searchModalShow, setSearchModalShow] = useState(false);
    const handleInputChange = (e) => {
        setSearchModalShow(true);
    };
    const handleOnHideSearchModal = () => {
        setSearchModalShow(false);
    };
    return ((!auth.user.showPaymentRequired && !auth.user.showWizard) && _jsxs(Form, { children: [_jsx(BanafoInput, { placeholder: t('Search.in.transcripts_'), readOnly: true, onClick: handleInputChange, className: "search-bar py-50" }), _jsx(SearchModal, { show: searchModalShow, onHide: handleOnHideSearchModal })] }));
};
export default SearchBar;
