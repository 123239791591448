/**
 * Get text from config enum
 *
 * @param enumList Array with enum config values
 * @param value Value to search for
 * @param defaultText Returned if no result found
 * @returns {string}
 */
const getConfigEnumText = (enumList, value, defaultText = '') => {
    const result = enumList.find(item => item.value === value);
    return (result === null || result === void 0 ? void 0 : result.text) || defaultText;
};
export default getConfigEnumText;
