import { transformValidationErrors } from "../../../util/validation";
import status from "http-status";
import { httpError } from "../errors";
import { loadingAppFinish, loadingAppStart } from "../loader";
import { selectAuthKey } from "../../selectors/auth";
import api from "../../../../../api/api";
import { setErrorMessage, setSuccessMessage } from "../app";
import i18n from "../../../../../app/services/i18n";
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_INPUT_CHANGED = 'REGISTER_INPUT_CHANGED';
export const REGISTER_VALIDATION_ERROR = 'REGISTER_VALIDATION_ERROR';
export const RESET_REGISTERED_USER = 'RESET_REGISTERED_USER';
export const registerSuccess = user => ({ type: REGISTER_SUCCESS, user });
export const registerInputChanged = name => ({ type: REGISTER_INPUT_CHANGED, name });
export const registerValidationErrors = errors => ({
    type: REGISTER_VALIDATION_ERROR,
    err: transformValidationErrors(errors)
});
export const resetRegisteredUser = () => ({
    type: RESET_REGISTERED_USER
});
export const register = (email, password, passConf, reCaptchaKey, registeredAs, discountCode) => (dispatch, getState) => {
    if (password !== passConf) {
        dispatch(registerValidationErrors([{
                field: 'instance.passConf',
                message: "Passwords don't match",
                reason: 'no_match'
            }]));
        return;
    }
    const keyLoading = selectAuthKey(getState());
    dispatch(loadingAppStart(keyLoading));
    api.register({ email, password, reCaptchaKey, registeredAs, discountCode })
        .then(res => {
        dispatch(registerSuccess(res.body.user));
        // dispatch(login(email, password));
        dispatch(setSuccessMessage(i18n.t('Account.created.successfully_')));
        localStorage.removeItem('registeredAs');
    })
        .catch(e => {
        if (e.status === status.BAD_REQUEST) {
            dispatch(registerValidationErrors(e.response.body.detail));
        }
        // if (err.status === status.FORBIDDEN) return dispatch(inactiveAccount(err));
        // dispatch(setErrorMessage(err.response.body.detail?.[0]?.message ?? err.response.body.message ?? i18n.t("errors:general_")));
        if (e.response.body.name && e.response.body.name === 'ValidationError') {
            if (e.response.body.detail[0].field === 'instance.email' && e.response.body.detail[0].reason === 'unique') {
                dispatch(setErrorMessage(i18n.t('This.e-mail.address.is.already.in.use_')));
            }
            if (e.response.body.detail[0].field === 'instance.password') {
                dispatch(setErrorMessage(i18n.t("ThePassword.does.not.match_")));
            }
        }
        dispatch(httpError(e));
    })
        .then(() => dispatch(loadingAppFinish(keyLoading)));
};
