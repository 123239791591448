/**
 * @example
 * range(1, 2).forEach(console.log) // Logs: 1, 2
 * range(2, 5, 2).map(v => v * 10) // Outputs: [20, 40]
 * for (let x of range(5, 7)) console.log(x) // Logs: 5, 6, 7
 * ;[...range(10, 30, 10)] // Outputs: [10, 20, 30]
 * 3.8 in range(1, 3) // Outputs: false
 *
 * @param start
 * @param end
 * @param step
 * @returns {{}|boolean|*}
 */
const range = (start, end, step = 1) => {
    if (step <= 0)
        throw RangeError('Step property must be positive');
    if (start > end)
        step = -step;
    const forLoop = fn => {
        if (step > 0)
            for (let x = start; x <= end; x += step)
                fn(x);
        else
            for (let x = start; x >= end; x += step)
                fn(x);
    };
    const between = (v, start, end) => v >= start && v <= end;
    const hasValue = v => between(v, start, end) || between(v, end, start);
    const iterate = function* (mapFn) {
        if (step > 0)
            for (let x = start; x <= end; x += step)
                yield mapFn ? mapFn(x) : x;
        else
            for (let x = start; x >= end; x += step)
                yield mapFn ? mapFn(x) : x;
    };
    const rangeObj = {};
    const createProp = v => ({ value: v });
    const map = createProp(mapFn => [...iterate(mapFn)]);
    const forEach = createProp(forLoop);
    const includes = createProp(v => {
        for (let x = start; x <= end; x += step) {
            if (v === x)
                return true;
        }
        return false;
    });
    const has = createProp(hasValue);
    Object.defineProperties(rangeObj, {
        map,
        forEach,
        includes,
        has,
    });
    rangeObj[Symbol.iterator] = iterate;
    const rangeProxy = new Proxy(rangeObj, {
        has(t, p) {
            if (p === Symbol.iterator)
                return true;
            return hasValue(parseFloat(p.toString(), 10));
        },
    });
    return rangeProxy;
};
export default range;
