import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { OverlayTrigger, Popover, BifIconInformationCircle } from "banafo-design-system";
import { LANGUAGE_EN_US } from "src/app/config";
const propTypes = {
    language: PropTypes.string.isRequired,
};
const LanguageQualityMessage = ({ language }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [rerender, setRerender] = useState(0);
    useEffect(() => {
        if (!language || language === LANGUAGE_EN_US) {
            setShowTooltip(false);
            return;
        }
        setShowTooltip(true);
        setRerender(prevState => prevState + 1);
    }, [language]);
    if (!showTooltip) {
        return null;
    }
    return (_jsx("span", { onClick: (e) => { e.stopPropagation(); }, className: "align-top me-25", children: _jsx(OverlayTrigger, { trigger: 'click', placement: "top", rootClose: true, delay: { show: 250, hide: 0 }, defaultShow: true, overlay: _jsx(Popover, { children: _jsx(Popover.Body, { children: _jsx("small", { className: "form-text", children: _jsxs("ul", { className: "language-quality-list mb-0", children: [_jsx("li", { className: "mb-2", children: _jsx(Trans, { i18nKey: "infos:Language.quality.info.text.first_" }) }), _jsx("li", { className: "mb-2", children: _jsx(Trans, { i18nKey: "infos:Language.quality.info.text.second_" }) }), _jsx("li", { className: "mb-2", children: _jsx(Trans, { i18nKey: "infos:Language.quality.info.text.third_", components: [_jsx("a", { href: `mailto:yello@banafo.com`, children: "Let us know" }, 0)] }) })] }) }) }) }), children: _jsx(BifIconInformationCircle, { className: "text-warning" }) }, rerender) }));
};
LanguageQualityMessage.propTypes = propTypes;
export default LanguageQualityMessage;
