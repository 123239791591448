var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Table } from "banafo-design-system";
import PaymentsRow from "src/features/payments/payments/PaymentsRow";
const PaymentsTable = ({ payments }) => {
    const { t } = useTranslation();
    return (_jsxs(Table, { responsive: true, hover: true, children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: t('Paid.At_') }), _jsx("th", { children: t('ID_') }), _jsx("th", { children: t('Transaction_') }), _jsx("th", { children: t('Paid.via_') }), _jsx("th", { children: t('Status_') }), _jsx("th", { children: t('Amount_') }), _jsx("th", { children: t('Invoice_') })] }) }), _jsx("tbody", { children: payments.map((_a) => {
                    var { id } = _a, payment = __rest(_a, ["id"]);
                    return _jsx(PaymentsRow, Object.assign({ id: id }, payment), id);
                }) })] }));
};
export default PaymentsTable;
