import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { Alert, BifIconChevronThinUp } from "banafo-design-system";
import { useSelector } from "react-redux";
import { USER_PLAN_FREE } from "src/features/auth/config/user";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import useAccount from "src/features/account/hooks/useAccount";
const UpgradeBanner = () => {
    const { t } = useTranslation();
    const { upgradeAccount } = useAccount();
    const authUser = useSelector(selectAuthUser);
    const handlePlanUpgrade = (e) => {
        e.preventDefault();
        upgradeAccount();
    };
    if (authUser.title !== USER_PLAN_FREE) {
        return;
    }
    return (_jsx(Alert, { variant: "dark", className: "border-0 mb-0 position-sticky bottom-0 mt-auto", show: true, children: _jsxs(Alert.Heading, { as: "p", className: "m-0 font-weight-normal text-center", children: [_jsx(Trans, { i18nKey: "infos:limits.visibility.reached_" }), " ", ' ', _jsxs("a", { href: "#", className: "link-danger fw-bold text-decoration-none", onClick: handlePlanUpgrade, children: [t("Upgrade.to.PRO_"), " ", _jsx(BifIconChevronThinUp, {})] })] }) }));
};
export default UpgradeBanner;
