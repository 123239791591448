import i18n from "src/app/services/i18n";
//STATUS
export const INTEGRATION_STATUS_NOT_ACTIVE = 'notActive';
export const INTEGRATION_STATUS_ACTIVE = 'active';
export const INTEGRATION_STATUSES_ENUM = [
    { value: INTEGRATION_STATUS_ACTIVE, get text() { return i18n.t('Active_'); } },
    { value: INTEGRATION_STATUS_NOT_ACTIVE, get text() { return i18n.t('Not.Active_'); } },
];
//STATUS
export const PROVIDER_STATUS_COMING_SOON = 'commingSoon';
export const PROVIDER_STATUS_ACTIVE = 'active';
//TYPE
export const PROVIDER_TYPE_CRM = 'crm';
export const PROVIDER_TYPE_SPEECH = 'speechToText';
export const PROVIDER_TYPE_BROWSER = 'browser';
export const PROVIDER_TYPE_WEB_CONFERENCE = 'webConferencingTool';
export const PROVIDER_TYPE_IDENTITY = 'identity';
export const PROVIDER_TYPES_LIST = [
    PROVIDER_TYPE_CRM,
    PROVIDER_TYPE_SPEECH,
    PROVIDER_TYPE_BROWSER,
    PROVIDER_TYPE_WEB_CONFERENCE,
    PROVIDER_TYPE_IDENTITY,
];
export const PROVIDER_TYPES_ENUM = [
    { value: PROVIDER_TYPE_CRM, get text() { return i18n.t('CRM_'); } },
    { value: PROVIDER_TYPE_SPEECH, get text() { return i18n.t('Speech.to.Text_'); } },
    { value: PROVIDER_TYPE_BROWSER, get text() { return i18n.t('Browser_'); } },
    { value: PROVIDER_TYPE_WEB_CONFERENCE, get text() { return i18n.t('Web.conferencing.tool_'); } },
];
export const PROVIDER_NAME_BANAFO = 'banafo';
//AUTH_STRATEGY
export const PROVIDER_AUTH_STRATEGY_API_KEY = 'apiKey';
export const PROVIDER_AUTH_STRATEGY_API_OAUTH2 = 'oauth2';
export const PROVIDER_AUTH_STRATEGY_API_SERVICE_ACCOUNT = 'serviceAccount';
export const PROVIDER_AUTH_STRATEGY_API_NONE = 'none';
export const PROVIDER_AUTH_STRATEGY_API_GOOGLE = 'google';
export const PROVIDER_AUTH_STRATEGY_LIST = [
    PROVIDER_AUTH_STRATEGY_API_KEY,
    PROVIDER_AUTH_STRATEGY_API_OAUTH2,
    PROVIDER_AUTH_STRATEGY_API_SERVICE_ACCOUNT,
    PROVIDER_AUTH_STRATEGY_API_NONE,
    PROVIDER_AUTH_STRATEGY_API_GOOGLE,
];
