import { useEffect } from "react";
const useScrollable = (ref, skipCondition, dependencies) => {
    useEffect(() => {
        if (skipCondition) {
            return;
        }
        const scrollToBottom = () => {
            ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
        };
        scrollToBottom();
    }, dependencies);
};
export default useScrollable;
