import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button } from "banafo-design-system";
import "./BrowserExtensionButton.scss";
function BrowserExtensionButton({ name, url, img, className, }) {
    const { t } = useTranslation();
    return (_jsxs(Button, { href: url, variant: 'warning', className: cx(className, "d-inline-flex align-items-center justify-content-center fw-bold text-uppercase"), target: "_blank", rel: "noreferrer", children: [_jsx("img", { src: img, alt: `${name}`, height: 36, className: "my-n100 me-50" }), " ", t("Add.to_"), " ", name] }));
}
BrowserExtensionButton.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    className: PropTypes.string,
};
export default BrowserExtensionButton;
