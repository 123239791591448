import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@mantine/core";
import { TbCircleFilled, TbMicrophone } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { BsSoundwave } from "react-icons/bs";
import moment from "moment/moment";
import { useInterval } from "@mantine/hooks";
import { useReactMediaRecorder } from "react-media-recorder";
import { CONVERSATION_TYPE_OTHER } from "src/features/auth/config/conversations";
import { useUploadFileMutation } from "src/features/conversations/conversationsSlice";
const ManualRecording = (props) => {
    const { t } = useTranslation();
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(0);
    const durationRef = useRef(0);
    const { start, stop, } = useInterval(() => setDuration(prev => prev + 1), 1000);
    const [uploadFile] = useUploadFileMutation();
    const { status, startRecording, stopRecording } = useReactMediaRecorder({
        audio: true,
        type: "audio/wav",
        onStop: (blobUrl, blob) => {
            const data = {
                file: blob,
                startedAt: moment(),
                duration: durationRef.current * 1000,
                direction: 'inbound',
                source: '',
                type: CONVERSATION_TYPE_OTHER
            };
            setLoading(true);
            uploadFile(data)
                .unwrap()
                .catch((err) => err)
                .finally(() => {
                setLoading(false);
            });
        }
    });
    useEffect(() => {
        if (duration > 3600) {
            stopRecording();
        }
    }, [duration]);
    const handleToggleRecording = () => {
        if (!recording) {
            startRecording();
            setRecording(true);
            start();
        }
        else {
            setRecording(false);
            stop();
            durationRef.current = duration;
            setDuration(0);
            stopRecording();
        }
    };
    return (_jsx(Button, Object.assign({ leftSection: recording ? _jsxs(_Fragment, { children: [_jsx(TbCircleFilled, { size: 16 }), " ", _jsx(BsSoundwave, { size: 16 })] }) : _jsx(TbMicrophone, { size: 16 }), variant: "filled", color: "red", tt: "uppercase", onClick: handleToggleRecording, fullWidth: true, loading: loading, radius: "md" }, props, { children: recording ? _jsxs(_Fragment, { children: [" ", t('Stop.recording_'), " ", moment.utc(duration * 1000).format('mm:ss'), " "] }) : t('Banafo.Voice.Memo_') })));
};
export default ManualRecording;
