import { DASHBOARD_AVAILABLE, EXTENSION_SETTINGS_CHANGED } from "../config";
const sendExtensionMessage = (type) => {
    window.postMessage({ type }, "*");
};
export const sendExtensionSettingsMessage = () => {
    sendExtensionMessage(EXTENSION_SETTINGS_CHANGED);
};
export const sendDashboardAvailableMessage = () => {
    sendExtensionMessage(DASHBOARD_AVAILABLE);
};
