import { combineReducers } from "redux";
import { UPDATE_PROFILE_PICTURE_BEGIN, UPDATE_PROFILE_PICTURE_SUCCESS, UPDATE_PROFILE_PICTURE_FAILURE, } from "../../actions/profile";
function loading(state = 0, action) {
    switch (action.type) {
        case UPDATE_PROFILE_PICTURE_BEGIN:
            return state + 1;
        case UPDATE_PROFILE_PICTURE_SUCCESS:
        case UPDATE_PROFILE_PICTURE_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
export default combineReducers({
    loading,
});
