import i18n from "src/app/services/i18n";
import { DEVICE_ACTIVATE_ROUTE, DEVICE_ROUTE, RESELLER_CUSTOMERS_BILLING_HISTORY_ROUTE, RESELLER_CUSTOMERS_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_3CX_ROUTE, RESELLER_CUSTOMERS_SETTINGS_GUIDE_ROUTE, RESELLER_CUSTOMERS_SETTINGS_ROUTE, RESELLER_INVITE_ROUTE, SETTINGS_ROUTE } from "src/app/router/config";
import DynamicResellerCustomerBreadcrumb from "src/features/breadcrumbs/DynamicResellerCustomerBreadcrumb";
export const pageRoutes = [
    { path: '/conversations/:id', get breadcrumb() { return i18n.t('Conversation.Details_'); } },
    { path: '/settings/teams', get breadcrumb() { return i18n.t('Teams.and.Members_'); } },
    { path: '/settings/members', get breadcrumb() { return i18n.t('Teams.and.Members_'); } },
    { path: '/settings/integrations', get breadcrumb() { return i18n.t('Integrations_'); } },
    { path: '/settings/general', get breadcrumb() { return i18n.t('General_'); } },
    { path: '/settings/recording-extension', get breadcrumb() { return i18n.t('Browser.Extension_'); } },
    { path: '/settings/api-keys', get breadcrumb() { return i18n.t('Speech.to.text.api_'); } },
    { path: SETTINGS_ROUTE, get breadcrumb() { return i18n.t('Settings_'); } },
    { path: '/contacts/crm', get breadcrumb() { return i18n.t('Crm_'); } },
    { path: DEVICE_ROUTE, match: { url: '/settings/general' }, get breadcrumb() { return i18n.t('Settings_'); } },
    { path: DEVICE_ACTIVATE_ROUTE, get breadcrumb() { return i18n.t('Apps_'); } },
    { path: "/reseller", get breadcrumb() { return i18n.t('Reseller.invite_'); } },
    { path: "/reseller/invite", breadcrumb: null },
    { path: RESELLER_INVITE_ROUTE, breadcrumb: null },
    {
        path: "reseller-customers", get breadcrumb() { return i18n.t('My.customers_'); },
        children: [
            { path: "/reseller-customers/", get breadcrumb() { return i18n.t('My.customers_'); } },
            { path: "billing-history", get breadcrumb() { return i18n.t('Billing.history_'); } },
            { path: ":resellerCustomerId", breadcrumb: DynamicResellerCustomerBreadcrumb },
            {
                path: "settings", get breadcrumb() { return i18n.t('Settings_'); },
                children: [
                    { path: "guide", get breadcrumb() { return i18n.t('Guide_'); } },
                    { path: "guide-3cx", get breadcrumb() { return i18n.t('Guide.-.3cx_'); } }
                ]
            }
        ],
    },
];
