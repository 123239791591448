import { combineReducers } from "redux";
import { ADD_RECORDING_DETAILS_SUCCESS, CLEAR_RECORDING_DETAILS, EXTRACT_RECORDING_DETAILS, FETCH_RECORDING_DETAILS_SUCCESS } from '../../actions/recordingDetails';
import { setEntries, setEntry, setId, setIds } from "../../../util/reducers";
function byId(state = {}, action) {
    switch (action.type) {
        case CLEAR_RECORDING_DETAILS:
            return {};
        case EXTRACT_RECORDING_DETAILS:
            return setEntries(state, action);
        case FETCH_RECORDING_DETAILS_SUCCESS:
        case ADD_RECORDING_DETAILS_SUCCESS:
            return setEntry(state, action);
        default:
            return state;
    }
}
function allIds(state = [], action) {
    switch (action.type) {
        case CLEAR_RECORDING_DETAILS:
            return [];
        case EXTRACT_RECORDING_DETAILS:
            return setIds(state, action);
        case FETCH_RECORDING_DETAILS_SUCCESS:
        case ADD_RECORDING_DETAILS_SUCCESS:
            return setId(state, action);
        default:
            return state;
    }
}
export default combineReducers({
    byId,
    allIds,
});
