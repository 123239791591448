import { apiClient } from "src/app/services/httpClient";
export const getTranscripts = (query) => {
    return apiClient
        .get(`/transcripts`)
        .query(query);
};
export const downloadTranscripts = (query) => {
    return apiClient
        .get(`/transcripts/download`)
        .query(query);
};
export const updateSpeakerName = (fileId, speakerId, speakerName) => {
    return apiClient
        .put(`/transcripts/speaker/${fileId}/${speakerId}`)
        .send({ speakerName });
};
