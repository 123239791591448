import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Group, Paper, Progress, rem, Text, ThemeIcon } from "@mantine/core";
import { FaFile } from "react-icons/fa";
import classes from './UploadCard.module.css';
import { useAppSelector } from "src/app/store/hooks";
import { selectUploadFileName, selectUploadProgress } from "src/features/conversations/conversationsSlice";
const UploadCard = () => {
    const progress = useAppSelector(selectUploadProgress);
    const name = useAppSelector(selectUploadFileName);
    return (_jsxs(Paper, { radius: "md", withBorder: true, className: classes.card, mt: 20, children: [_jsx(ThemeIcon, { className: classes.icon, size: 60, radius: 60, children: _jsx(FaFile, { style: { width: rem(32), height: rem(32) }, stroke: 1.5 }) }), _jsx(Text, { ta: "center", fw: 700, className: classes.title, children: name }), _jsxs(Group, { justify: "space-between", mt: "xs", children: [_jsx(Text, { fz: "sm", c: "dimmed", children: "Progress" }), _jsxs(Text, { fz: "sm", c: "dimmed", children: [Math.round(progress), "%"] })] }), _jsx(Progress, { "aria-label": "Uploading progress", value: progress, mt: 5 })] }));
};
export default UploadCard;
