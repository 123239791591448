import { useState } from "react";
const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggle() {
        setIsOpen(!isOpen);
    }
    function open() {
        setIsOpen(true);
    }
    function close() {
        setIsOpen(false);
    }
    return {
        isOpen,
        toggle,
        open,
        close
    };
};
export default useModal;
