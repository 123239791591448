import { jsx as _jsx } from "react/jsx-runtime";
import AppCard from "src/features/deviceActivate/AppCard/AppCard";
import { AiFillWindows } from "react-icons/ai";
import { SCHEME, WEB_URL } from "src/app/config";
import { useTranslation } from "react-i18next";
const Zoiper5Card = (props) => {
    const { t } = useTranslation();
    return _jsx(AppCard, Object.assign({ icon: _jsx(AiFillWindows, { size: "100%" }), name: t('Zoiper5_'), moreInfoUrl: `${SCHEME}://${WEB_URL}/en/application/zoiper` }, props));
};
export default Zoiper5Card;
