import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Button } from "banafo-design-system";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/**
 * @deprecated use SentryFallback instead
 *
 * @param error
 * @param componentStack
 * @param resetError
 * @param eventId
 * @returns {JSX.Element}
 * @constructor
 */
function FallbackComponent({ error, componentStack, resetError, eventId }) {
    const { t } = useTranslation();
    return (_jsx("div", { className: 'd-flex justify-content-center', children: _jsxs("div", { children: [_jsxs("h1", { children: [t("General.error_"), "!"] }), _jsxs("p", { children: [t("errors:general_"), "."] }), _jsxs("p", { children: ["Error ID ", eventId] }), _jsx("p", { children: _jsx(Button, { variant: "primary", onClick: () => { resetError(); }, as: Link, to: '/', children: t("Go.Home_") }) })] }) }));
}
export default FallbackComponent;
FallbackComponent.propTypes = {
    componentStack: PropTypes.any,
    error: PropTypes.any,
    resetError: PropTypes.any,
    eventId: PropTypes.any
};
