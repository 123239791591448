import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Stack } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import featuresImg from "./images/features.jpg";
import classNames from "classnames";
import { SETTINGS_ROUTE } from "src/app/router/config";
import useAccount from "src/features/account/hooks/useAccount";
const FeaturesCard = ({ className }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { upgradeAccount, subscriptionPlan: { isFree } } = useAccount();
    const handleEnableHere = (e) => {
        e.preventDefault();
        navigate(SETTINGS_ROUTE);
    };
    const handlePlanUpgrade = (e) => {
        e.preventDefault();
        upgradeAccount();
    };
    return (_jsxs(Card, { className: classNames(className, 'shadow'), children: [_jsxs(Card.Body, { className: "text-center pt-200", children: [_jsx("img", { src: featuresImg, alt: "pricing-pro" }), _jsx("h5", { className: "text-uppercase my-200", children: t('New.features_') }), _jsx("p", { children: "Automated summarization \u2013 auto generated summary of your recording transcript. PRO users get all the information condensed and promptly described in just a few sentences of an executive summary." })] }), _jsx(Card.Footer, { children: _jsx(Stack, { className: "col-10 mx-auto", children: isFree ?
                        _jsx(Button, { variant: "outline-dark text-uppercase fw-bold", className: 'text-uppercase', onClick: handlePlanUpgrade, children: t("Upgrade.to.PRO_") }) :
                        _jsx(Button, { variant: "outline-dark text-uppercase fw-bold", className: 'text-uppercase', onClick: handleEnableHere, children: t('Enable.here_') }) }) })] }));
};
export default FeaturesCard;
