import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, SpinnerBackdrop } from "banafo-design-system";
import { useGetApiKeysSettingsQuery, useUpdateApiKeysSettingsMutation } from "src/features/apiKeys/apiKeysSlice";
import { useTranslation } from "react-i18next";
const SettingsForm = () => {
    const { t } = useTranslation();
    const { data: { thirdPartyApi, transcripts } = { thirdPartyApi: {} }, isLoading } = useGetApiKeysSettingsQuery();
    const [updateSettings, { data: { thirdPartyApi: thirdPartyApiUpdated } = { thirdPartyApi }, isLoading: isUpdating }] = useUpdateApiKeysSettingsMutation();
    const handleAutomaticDeletionChange = (e) => {
        updateSettings({ thirdPartyApi: Object.assign(Object.assign({}, thirdPartyApi), { automaticDeletion: e.target.checked }) });
    };
    const handlePunctuationChange = (e) => {
        updateSettings({ thirdPartyApi: Object.assign(Object.assign({}, thirdPartyApi), { punctuation: e.target.checked }) });
    };
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs(_Fragment, { children: [isUpdating && _jsx(SpinnerBackdrop, {}), _jsx(Form, { className: "mx-50 my-200", children: _jsxs(Form.Group, { children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t('Api.keys.settings_') }), _jsxs("div", { className: "mb-150 d-flex justify-content-between", children: [t('Delete.audio.files.automatically.after.transcript.is.ready_'), _jsx(Form.Check, { type: "switch", inline: true, reverse: true, label: thirdPartyApi.automaticDeletion ? t('Yes_') : t('No_'), id: "show-auto-deletion", checked: thirdPartyApi.automaticDeletion, onChange: handleAutomaticDeletionChange })] }), _jsxs("div", { className: "mb-150 d-flex justify-content-between", children: [t('Add.punctuation_'), _jsx(Form.Check, { type: "switch", inline: true, reverse: true, label: thirdPartyApi.punctuation ? t('Yes_') : t('No_'), id: "show-auto-punctuation", checked: thirdPartyApi.punctuation, onChange: handlePunctuationChange })] })] }) })] }));
};
export default SettingsForm;
