import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Ajv from "ajv";
import { Button, Modal, Form } from "banafo-design-system";
import validateSchema from "src/features/auth/util/validateSchema";
import { hideChangePasswordModal, selectChangePasswordIsOpen, useChangePasswordMutation } from "src/features/sidebar/sidebarSlice";
const ajv = new Ajv({ allErrors: true, jsonPointers: true, $data: true });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    required: ["old_password", "new_password", "conf_password"],
    properties: {
        "old_password": {
            "type": "string",
            "minLength": 8,
            "maxLength": 64
        },
        "new_password": {
            "type": "string",
            "minLength": 8,
            "maxLength": 64,
            "pattern": '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,64}$',
            errorMessage: {
                minLength: 'errors:Use.at.least.{{limit}}.characters_',
                maxLength: 'errors:Use.maximum.{{limit}}.characters_',
                pattern: 'errors:ThePassword.does.not.match_'
            }
        },
        "conf_password": {
            const: { $data: '1/new_password' }
        },
    },
    errorMessage: {
        properties: {
            old_password: 'errors:Invalid.password_',
        }
    },
};
const ChangePasswordModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [changePassword, { isLoading: isChangingPassword }] = useChangePasswordMutation({ fixedCacheKey: 'change-password' });
    const handleCloseModal = () => {
        dispatch(hideChangePasswordModal());
        setInputs(initialFormState);
        setErrors({});
    };
    const isOpen = useSelector(selectChangePasswordIsOpen);
    const initialFormState = {
        old_password: '',
        new_password: '',
        conf_password: '',
    };
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const handleChangePassword = () => {
        setErrors({});
        const { hasErrors, errors } = validateSchema(ajv, schema, inputs, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        changePassword({ newPass: inputs.new_password, oldPass: inputs.old_password })
            .unwrap()
            .then(fulfilled => handleCloseModal())
            .catch(e => null);
    };
    return (_jsxs(Modal, { show: isOpen, onHide: handleCloseModal, "aria-labelledby": "profile-modal", centered: true, children: [_jsx(Modal.Header, { closeButton: true, className: "border-0" }), _jsxs(Modal.Body, { className: 'basic text-center', children: [_jsx("h4", { children: t('Change.password_') }), _jsxs(Form, { className: "text-start", children: [_jsxs(Form.Group, { controlId: "formOldPassword", className: 'mb-100', children: [_jsx(Form.Label, { children: t('Old.password_') }), _jsx(Form.Control, { placeholder: t('Old.password_'), name: "old_password", type: 'password', onChange: handleInputChange, value: inputs.old_password, isInvalid: !!errors.old_password }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.old_password })] }), _jsxs(Form.Group, { controlId: "formNewPassword", className: 'mb-100', children: [_jsx(Form.Label, { children: t('New.password_') }), _jsx(Form.Control, { placeholder: t('New.password_'), name: "new_password", type: 'password', onChange: handleInputChange, value: inputs.new_password, isInvalid: !!errors.new_password }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.new_password })] }), _jsxs(Form.Group, { controlId: "formRePassword", children: [_jsx(Form.Label, { children: t('Re-enter.password_') }), _jsx(Form.Control, { placeholder: t('Re-enter.password_'), name: "conf_password", type: 'password', onChange: handleInputChange, value: inputs.conf_password, isInvalid: !!errors.conf_password }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.conf_password })] })] })] }), _jsx(Modal.Footer, { className: "pt-0 border-0", children: _jsx(Button, { variant: "primary", onClick: handleChangePassword, children: t('Save_') }) })] }));
};
export default ChangePasswordModal;
