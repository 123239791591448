var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BifIconEnvelop, Button, Card, Col, Container, Form, GoogleSignInButton, Image, MicrosoftSignInButton, Row, SpinnerBackdrop, Stack } from "banafo-design-system";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { loginInputChanged, loginPassword } from "src/features/auth/store/actions/auth/login";
import { clearError, clearErrorMessage } from "src/features/auth/store/actions/app";
import pending from "images/other/pending.png";
import { SuccessIcon } from "src/features/auth/component-old/common/Icon";
import { selectAuth, selectAuthKey } from "src/features/auth/store/selectors/auth";
import { selectErrorName as selectErrorNameSelector } from "src/features/auth/store/selectors/common";
import useModal from "src/common/hooks/useModal";
import ForgottenPasswordModal from "src/features/auth/login/ForgottenPasswordModal";
import ForgottenPasswordSuccessModal from "src/features/auth/login/ForgottenPasswordSuccessModal";
import { getUser } from "src/app/services/authService";
import { googleUrl, microsoftUrl } from "src/features/auth/config";
import { AUTH_REGISTER_ROUTE } from "src/app/router/config";
import GeneralError from "src/features/auth/GeneralError";
import "./LoginPage.scss";
import status from "http-status";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "src/app/config";
function LoginPage() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.app.loader.indexOf(selectAuthKey(state)) > -1);
    const { showWrongCredentials, tooManyRequests } = useSelector((state) => selectAuth(state));
    const selectErrorName = useSelector((state) => selectErrorNameSelector(state));
    const sendDataToGTM = useGTMDispatch();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [reCaptchaKey, setReCaptchaKey] = useState('');
    const [reCaptchaKeyError, setReCaptchaKeyError] = useState('');
    const [pendingApproval, setPendingApproval] = useState(selectErrorName);
    const { isOpen, open, close } = useModal();
    const [forgottenPassSuccessModalShow, setForgottenPassSuccessModalShow] = useState(false);
    useEffect(() => {
        setPendingApproval(selectErrorName === 'AccountPendingApprovalError');
    }, [selectErrorName]);
    const handleSubmit = (e) => __awaiter(this, void 0, void 0, function* () {
        e.preventDefault();
        sendDataToGTM({ event: 'loginButtonClicked', value: username });
        const user = yield getUser();
        if (user) {
            window.location = '';
            return;
        }
        if (tooManyRequests && !reCaptchaKey) {
            setReCaptchaKeyError(t('errors:ReCaptcha.error_'));
            return;
        }
        dispatch(loginPassword(username, password, reCaptchaKey))
            .catch((err) => null);
        setReCaptchaKeyError('');
    });
    const renderPendingApprovalScreen = () => {
        return (_jsxs(_Fragment, { children: [_jsx(Col, { md: "6", className: "Auth-info order-last order-md-first my-100 my-md-300 px-100 px-md-300", children: _jsx("div", { className: "text-center", children: _jsx(Image, { fluid: true, src: pending }) }) }), _jsx(Col, { md: "6", className: `Auth-main order-first order-md-last my-100 my-md-300 px-100 px-md-300`, children: _jsxs("div", { children: [_jsx("div", { className: 'mt-200 mb-200', children: _jsx(SuccessIcon, {}) }), _jsx("h3", { className: 'mb-100 text-notice', children: t("Yello_") }), _jsx("p", { className: 'mb-300', children: t('infos:Pending.approval.message_') }), _jsx(Button, { variant: "notice", type: "submit", onClick: () => dispatch(clearError()), children: t("Login_") })] }) })] }));
    };
    /* if (pendingApproval) {
        return renderPendingApprovalScreen();
    } */
    const handleOpenForgottenPassword = () => {
        dispatch(clearErrorMessage());
        open();
    };
    return (_jsxs("section", { className: "login", children: [_jsx(Container, { fluid: 'xl', className: 'pt-300 pb-800', children: _jsx(Row, { children: _jsx(Col, { xs: 12, md: { span: 6, offset: 3 }, lg: { span: 6, offset: 6 }, xl: { span: 4, offset: 8 }, xxl: { span: 3, offset: 9 }, children: _jsx(Card, { className: "px-lg-300 py-lg-400 border-0", children: _jsxs(Card.Body, { children: [isLoading ? _jsx(SpinnerBackdrop, { position: 'absolute' }) : null, _jsxs("form", { onSubmit: handleSubmit, children: [_jsx("h2", { className: "text-center mb-200", children: t("Login_") }), _jsx(GeneralError, { as: "h6" }), _jsx("div", { className: "form-group mb-50", children: _jsx("input", { type: "text", name: "username", value: username, onChange: e => {
                                                        setUsername(e.target.value);
                                                        dispatch(loginInputChanged());
                                                    }, placeholder: t("Email_"), className: "form-control" }) }), _jsxs("div", { className: "form-group mb-0", children: [_jsx("input", { type: "password", name: "password", value: password, onChange: e => {
                                                            setPassword(e.target.value);
                                                            dispatch(loginInputChanged());
                                                        }, placeholder: t("Password_"), className: `form-control${showWrongCredentials ? ' is-invalid' : ''}` }), showWrongCredentials ? (_jsx("div", { className: "invalid-feedback", children: t("Wrong.username.or.password_") })) : ''] }), tooManyRequests &&
                                                _jsxs("div", { className: "form-group mb-0", children: [_jsx(ReCAPTCHA, { className: "d-flex justify-content-center", sitekey: SITE_KEY, onChange: value => setReCaptchaKey(value), onExpired: () => setReCaptchaKey('') }), _jsx(Form.Control.Feedback, { className: "d-block", type: "invalid", children: reCaptchaKeyError })] }), _jsx("p", { className: "text-end my-0", children: _jsxs(Link, { className: 'text-dark text-decoration-none', onClick: handleOpenForgottenPassword, to: '#', children: [t("Forgot.your.password_"), "?"] }) }), _jsx(Button, { variant: "dark", type: "submit", className: "px-300 mt-250 mx-auto d-block text-uppercase", children: t("Login_") }), _jsx(Row, { className: "mt-100", children: _jsx(Col, { xs: 9, className: "mx-auto my-200", children: _jsxs(Stack, { gap: 100, className: "col mx-auto", children: [_jsx("div", { className: "google-login-wrapper", children: _jsxs(Button, { className: "google-login-btn light", as: Link, to: AUTH_REGISTER_ROUTE, children: [_jsx("div", { className: "google-login-icon-wrapper", children: _jsx(BifIconEnvelop, { className: "align-middle" }) }), _jsx("span", { className: "google-login-text", children: t('Register.with.email_') })] }) }), _jsx(GoogleSignInButton, { url: googleUrl, children: t('Sign.in.with.Google_') }), _jsx(MicrosoftSignInButton, { url: microsoftUrl })] }) }) })] })] }) }) }) }) }), _jsx(ForgottenPasswordModal, { show: isOpen, onHide: close, showSuccess: () => setForgottenPassSuccessModalShow(true) }), _jsx(ForgottenPasswordSuccessModal, { show: forgottenPassSuccessModalShow, onHide: () => setForgottenPassSuccessModalShow(false) })] }));
}
export default LoginPage;
