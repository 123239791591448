import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from "./Header";
import { Layout } from "banafo-design-system";
import ExistingResellerCustomer from "src/features/resellerCustomers/ExistingResellerCustomer";
import { Divider } from "@mantine/core";
import Title from "src/pages/resellerCustomersSettings/Title";
import SettingsForm from "src/features/resellerCustomerSettings/SettingsForm";
const ResellerCustomersSettingsPage = () => {
    return (_jsx(ExistingResellerCustomer, { children: _jsxs(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
                contentProps: { className: "p-200 d-flex flex-column" },
            }, children: [_jsx(Title, {}), _jsx(Divider, { size: "md", my: "sm" }), _jsx(SettingsForm, {})] }) }));
};
export default ResellerCustomersSettingsPage;
