import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Header from "src/pages/home/Header";
import FeedsSection from "src/features/account/FeedsSection";
import Wizard from "src/features/wizard/Wizard";
import RecentActivitySection from "src/pages/home/RecentActivitySection";
const WizardPage = () => {
    return (_jsx(_Fragment, { children: _jsxs(Layout, { header: _jsx(Header, {}), options: {
                contentProps: { className: 'px-200' },
            }, children: [_jsx(Wizard, {}), _jsx(RecentActivitySection, {}), _jsx(FeedsSection, { className: "d-lg-none mb-200" })] }) }));
};
export default WizardPage;
