import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { formattedDuration } from "src/features/auth/util/dateTime";
const AudioDuration = () => {
    const playbackReady = useSelector(state => state.app.playback.ready);
    const playbackDuration = useSelector(state => state.app.playback.duration);
    const playbackCurrentTime = useSelector(state => state.app.playback.currentTime);
    if (!playbackReady) {
        return;
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "fw-bold", children: formattedDuration(playbackCurrentTime * 1000, 'HH:mm:ss') }), " ", " / ", " ", formattedDuration(playbackDuration * 1000, 'HH:mm:ss')] }));
};
export default AudioDuration;
