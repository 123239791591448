import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendDashboardAvailableMessage } from "src/features/browserExtension/utils/extensionMessages";
import { browserExtensionNotAvailable } from "src/features/browserExtension/browserExtensionSlice";
const useForceBrowserExtensionCheck = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(browserExtensionNotAvailable());
        sendDashboardAvailableMessage();
    }, [dispatch]);
};
export default useForceBrowserExtensionCheck;
