import React from "react";
import * as Sentry from "@sentry/react";
import { SENTRY_ENV_NAME, SENTRY_FRONTEND_DSN } from "src/app/config";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: SENTRY_FRONTEND_DSN,
        environment: SENTRY_ENV_NAME,
        debug: false,
        normalizeDepth: 10,
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                // maskAllText: true,
                // blockAllMedia: true,
                networkDetailAllowUrls: [window.location.origin],
            })
        ],
        // sampleRate: 1,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [window.location.origin],
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
