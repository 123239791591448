var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request from "superagent";
import noCache from 'superagent-no-cache';
import prefix from 'superagent-prefix';
import status from 'http-status';
import { store } from "src/app/store/store";
import { setError } from "src/features/auth/store/actions/app";
import { logoutUser } from "src/features/auth/store/actions/auth/login";
import { API_PREFIX, API_VERSION_PREFIX } from "../config";
import { getUser, oidcLogout } from "./authService";
const errorHandler = (req) => {
    req.on('response', function (res) {
        if (res.error) {
            if (res.status === status.UNAUTHORIZED && (res.body.name === "NoTokenError" || res.body.name === "BadTokenError")) {
                store.dispatch(logoutUser());
            }
            if (res.status === status.FORBIDDEN) {
                store.dispatch(setError(res === null || res === void 0 ? void 0 : res.body));
            }
            if (res.status === status.INTERNAL_SERVER_ERROR) {
                store.dispatch(setError(res));
            }
        }
    });
};
export function withBodyParser(agent) {
    return (...params) => agent(...params).then(response => response.body);
}
export function withAuthHeader(agent) {
    return (...params) => __awaiter(this, void 0, void 0, function* () {
        const user = yield getUser();
        const { expired = null } = user || {};
        if (expired) {
            return oidcLogout();
        }
        const token = user ? user.access_token : null;
        return agent(...params).set('Authorization', `Bearer ${token}`);
    });
}
const agent = request.agent()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .timeout({
    response: 5000 * 2,
    deadline: 60000 * 2, //1min
})
    .use(noCache)
    .use(errorHandler);
agent.use(prefix(API_PREFIX));
const apiClient = request.agent()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .timeout({
    response: 5000,
    deadline: 60000, //1min
})
    .use(noCache)
    .use(errorHandler);
apiClient.use(prefix(API_PREFIX + API_VERSION_PREFIX));
export default agent;
export { apiClient };
