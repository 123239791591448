import i18n from "i18next";
export const EXTENSION_SETTINGS_CHANGED = 'banafo-settings_changed';
export const EXTENSION_AVAILABLE = 'banafo-extension_available';
export const DASHBOARD_AVAILABLE = 'banafo-dashboard_available';
export const EXTENSION_CHROME_URL = 'https://chrome.google.com/webstore/detail/fimdehpmamnjanklbfejacbkomabdhdh';
export const EXTENSION_EDGE_URL = 'https://microsoftedge.microsoft.com/addons/detail/banafo-transcribe-rec/fkgojjjehknjckmjfepghlgjofepjncj';
export const EXTENSION_OPERA_URL = 'https://chrome.google.com/webstore/detail/fimdehpmamnjanklbfejacbkomabdhdh';
export const CONTROLS_POSITION_LEFT = 'left';
export const CONTROLS_POSITION_RIGHT = 'right';
export const CONTROLS_POSITION_ENUM = [
    { value: CONTROLS_POSITION_LEFT, get text() { return i18n.t('Left_'); } },
    { value: CONTROLS_POSITION_RIGHT, get text() { return i18n.t('Right_'); } },
];
