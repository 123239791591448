import { generatePath, useNavigate } from "react-router-dom";
import { UPGRADE_PLAN } from "src/features/account/config";
import { BILLING_UPGRADE_ROUTE } from "src/app/router/config";
import { AccountUpgradeModalTypes } from "src/features/account/types";
import { showAccountUpgradeModal } from "src/features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
const useUpgradeAccount = (isFree) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { reseller } = useAppSelector(selectAuthUser);
    return (infoModal) => {
        if (reseller) {
            dispatch(showAccountUpgradeModal(AccountUpgradeModalTypes.Reseller));
            return;
        }
        if (isFree && infoModal) {
            dispatch(showAccountUpgradeModal(infoModal));
        }
        else {
            navigate(generatePath(BILLING_UPGRADE_ROUTE, {
                plan: UPGRADE_PLAN,
                upgrade: 'upgrade'
            }));
        }
    };
};
export default useUpgradeAccount;
