var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BanafoModal, BifIconTrash, Button, LineSeparator } from "banafo-design-system";
import { useRemoveWhitelistWebsiteMutation } from "src/features/browserExtension/browserExtensionSlice";
const propTypes = {
    id: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
const DeleteWhitelistWebsitesModal = (_a) => {
    var { id, show, onHide } = _a, others = __rest(_a, ["id", "show", "onHide"]);
    const { t } = useTranslation();
    const [removeWhitelistWebsite] = useRemoveWhitelistWebsiteMutation({ fixedCacheKey: 'remove-whitelist-website' });
    const handleHideModal = () => {
        onHide();
    };
    const handleDeleteWhitelistWebsite = () => {
        removeWhitelistWebsite(id);
    };
    return (_jsxs(BanafoModal, { show: show, title: t('Remove.website_'), onHide: handleHideModal, className: 'text-center', children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-400', children: t('Are.you.sure.you.want.to.remove.this.website_') }), _jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(Button, { variant: 'outline-dark', className: 'text-uppercase mx-100', onClick: handleHideModal, children: t('Cancel_') }), _jsxs(Button, { variant: 'danger', className: 'text-uppercase mx-100 with-icon end', onClick: handleDeleteWhitelistWebsite, children: [t('Remove_'), " ", _jsx(BifIconTrash, {})] })] })] }));
};
DeleteWhitelistWebsitesModal.propTypes = propTypes;
export default DeleteWhitelistWebsitesModal;
