var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from "react";
import { useUpdateSpeakerNameMutation } from "src/features/transcripts/transcriptsSlice";
import { Form, Popover } from "banafo-design-system";
const SpeakerNamePopover = forwardRef((_a, ref) => {
    var { speakerId, speakerName, fileId } = _a, props = __rest(_a, ["speakerId", "speakerName", "fileId"]);
    const [name, setName] = useState(speakerName);
    const [updateSpeakerName] = useUpdateSpeakerNameMutation();
    const handleChangeName = (e) => {
        const { value } = e.target;
        setName(value);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (name <= 0) {
                return;
            }
            updateSpeakerName({ fileId, speakerId, speakerName: name });
        }
    };
    return (_jsx(Popover, Object.assign({ ref: ref }, props, { children: _jsx(Popover.Body, { children: _jsx(Form.Control, { type: "text", placeholder: "Speaker name", value: name, onChange: handleChangeName, onKeyDown: handleKeyDown, autoFocus: true }) }) })));
});
export default SpeakerNamePopover;
