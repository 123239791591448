import { isRejectedWithValue } from "@reduxjs/toolkit";
import httpStatus from "http-status";
import { logoutUser } from "src/features/auth/store/actions/auth/login";
export const rtkErrorHandler = (store) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const { payload: { status, data: { name } } } = action;
        if (status === httpStatus.UNAUTHORIZED && (name === "NoTokenError" || name === "BadTokenError")) {
            store.dispatch(logoutUser());
        }
    }
    return next(action);
};
export default rtkErrorHandler;
