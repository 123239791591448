import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "banafo-design-system";
import Thead from "src/common/components/ui/Thead";
import ExistingContactsRow from "src/features/conversations/ExistingContactsRow";
const ExistingContactsTable = ({ contacts, callId, handleHideModal }) => {
    const { t } = useTranslation();
    return (_jsxs(Table, { hover: true, size: "sm", children: [_jsx(Thead, { variant: 'light', children: _jsxs("tr", { className: "d-none d-md-table-row", children: [_jsx("th", { children: t('Name_') }), _jsx("th", { children: t('Company_') }), _jsx("th", { children: t('Actions_') })] }) }), _jsx("tbody", { children: contacts.map((contact, index) => _jsx(ExistingContactsRow, { contact: contact, callId: callId, handleHideModal: handleHideModal }, index)) })] }));
};
export default ExistingContactsTable;
