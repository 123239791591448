import { combineReducers } from "redux";
import { FETCH_COUNTRIES_BEGIN, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILURE, FETCH_VAT_RATE_BEGIN, FETCH_VAT_RATE_SUCCESS, FETCH_VAT_RATE_FAILURE } from "../../actions/payment";
function initialize(state = false, action) {
    switch (action.type) {
        case FETCH_COUNTRIES_SUCCESS:
        case FETCH_COUNTRIES_FAILURE:
        case FETCH_VAT_RATE_SUCCESS:
        case FETCH_VAT_RATE_FAILURE:
            return true;
        default:
            return state;
    }
}
function loading(state = 0, action) {
    switch (action.type) {
        case FETCH_COUNTRIES_BEGIN:
        case FETCH_VAT_RATE_BEGIN:
            return state + 1;
        case FETCH_COUNTRIES_SUCCESS:
        case FETCH_VAT_RATE_SUCCESS:
            return state - 1;
        case FETCH_COUNTRIES_FAILURE:
        case FETCH_VAT_RATE_FAILURE:
            return state - 1;
        default:
            return state;
    }
}
function vatRate(state = {}, action) {
    switch (action.type) {
        case FETCH_VAT_RATE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
export default combineReducers({
    initialize,
    loading,
    vatRate
});
