var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { RESELLER_CUSTOMERS_PAGINATION_LIMIT } from "src/features/resellerCustomers/constants";
import { resellerCustomersLastPageReached } from "src/features/resellerCustomers/resellerCustomersSlice";
import { formattedDateTime } from "src/features/auth/util/dateTime";
import capitalize from "src/helpers/capitalize";
export const resellerCustomersAdapter = createEntityAdapter();
const initialState = resellerCustomersAdapter.getInitialState({ query: {} });
export const localizedResellerCustomersSelectors = resellerCustomersAdapter.getSelectors();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getResellerCustomers: builder.query({
            query: ({ start, filters }) => {
                const payload = Object.assign({ start: start.toString(), limit: RESELLER_CUSTOMERS_PAGINATION_LIMIT.toString() }, filters);
                const params = new URLSearchParams(payload);
                return `/reseller/customers?${params}`;
            },
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { start } = queryArgs, otherArgs = __rest(queryArgs, ["start"]);
                return otherArgs;
            },
            merge: (currentCache, newItems) => {
                return resellerCustomersAdapter.addMany(currentCache, localizedResellerCustomersSelectors.selectAll(newItems));
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            transformResponse: ({ list: resellerCustomers = [], query }) => {
                return resellerCustomersAdapter.setAll(Object.assign(Object.assign({}, initialState), { query }), resellerCustomers.map((_a) => {
                    var { _id: id } = _a, resellerCustomers = __rest(_a, ["_id"]);
                    return (Object.assign({ id }, resellerCustomers));
                }));
            },
            onQueryStarted(args, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        if (localizedResellerCustomersSelectors.selectTotal(data) < RESELLER_CUSTOMERS_PAGINATION_LIMIT) {
                            dispatch(resellerCustomersLastPageReached());
                        }
                    }
                    catch (err) {
                    }
                });
            },
            providesTags: ["ResellerCustomers"],
            keepUnusedDataFor: 0,
        }),
        inviteResellerCustomer: builder.mutation({
            query({ email }) {
                return {
                    url: `/reseller/invite`,
                    method: "POST",
                    body: { email }
                };
            },
            invalidatesTags: ["ResellerCustomers"]
        }),
        acceptResellerInvitation: builder.mutation({
            query({ token }) {
                return {
                    url: `/reseller/invite/${token}`,
                    method: "POST",
                };
            },
        }),
        initResellerPayment: builder.mutation({
            query({ email, full_name, customerType, vat_number, company_name, company_address, countryIso, paymentMethod, totalAmount, vatRate, userPlan, resellerCustomerId }) {
                const purchase = {
                    paymentDetails: {
                        type: "resellerPurchase",
                        vatRate: vatRate,
                        userId: resellerCustomerId,
                        currency: "usd",
                        amount: totalAmount,
                        purchase: {
                            plan: userPlan
                        },
                        paymentMethodDetails: {
                            type: "card",
                            brand: paymentMethod
                        }
                    },
                    customerDetails: {
                        type: customerType,
                        name: full_name,
                        email: email,
                        companyVat: vat_number,
                        companyAddress: company_address,
                        companyName: company_name,
                        country: countryIso
                    }
                };
                return {
                    url: `/limiter/payment-sessions`,
                    method: "POST",
                    body: purchase,
                };
            },
        }),
    })
});
export const { useGetResellerCustomersQuery, useInviteResellerCustomerMutation, useAcceptResellerInvitationMutation, useInitResellerPaymentMutation, } = extendedApi;
//SELECTORS
// ResellerCustomersEntity
export const selectResellerCustomersEntityResult = createSelector((args) => args, (args) => extendedApi.endpoints.getResellerCustomers.select(args));
export const selectResellerCustomersEntityData = createSelector((state, args) => selectResellerCustomersEntityResult(args)(state), (resellerCustomersEntityResult) => { var _a; return (_a = resellerCustomersEntityResult === null || resellerCustomersEntityResult === void 0 ? void 0 : resellerCustomersEntityResult.data) !== null && _a !== void 0 ? _a : initialState; });
export const selectAllResellerCustomersEntity = createSelector((state, args) => selectResellerCustomersEntityData(state, args), (resellerCustomersEntityData) => {
    return localizedResellerCustomersSelectors.selectAll(resellerCustomersEntityData);
});
export const selectTotalResellerCustomers = createSelector((state, args) => selectResellerCustomersEntityData(state, args), (resellerEntityCustomersData) => {
    return localizedResellerCustomersSelectors.selectTotal(resellerEntityCustomersData);
});
export const selectResellerCustomerEntity = createSelector((state, args) => selectResellerCustomersEntityData(state, args), (state, args, id) => id, (resellerEntityCustomersData, id) => {
    return localizedResellerCustomersSelectors.selectById(resellerEntityCustomersData, id);
});
// ResellerCustomers
export const selectAllResellerCustomers = createSelector((state, args) => selectAllResellerCustomersEntity(state, args), (allResellerCustomersEntity) => {
    return allResellerCustomersEntity.map((_a) => {
        var _b, _c;
        var { startedAt, name } = _a, resellerCustomer = __rest(_a, ["startedAt", "name"]);
        return Object.assign(Object.assign({}, resellerCustomer), { name: (_b = (name && capitalize(name))) !== null && _b !== void 0 ? _b : name, startedAt: (_c = (startedAt && formattedDateTime(startedAt))) !== null && _c !== void 0 ? _c : startedAt });
    });
});
export const makeSelectAllResellerCustomers = () => {
    return selectAllResellerCustomers;
};
