import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { Avatar } from "banafo-design-system";
import { setActiveTranscriptId, useUpdateSpeakerNameMutation } from "src/features/transcripts/transcriptsSlice";
import SpeakerName from "src/features/transcripts/SpeakerName";
import { seekAudio } from "src/features/auth/store/actions/playback";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
import { hideSearch, selectIsSearchViewTriggered } from "src/features/transcripts/transcriptsSlice";
const TranscriptsChatItem = ({ transcript: { id, speakerId, speakerName, showSpeakerDetails, fileId, acronym, startedAt, timestamp, text, acronymColor, position, matched, isHighlighted }, enableFaded }) => {
    const dispatch = useDispatch();
    const isSearchViewTriggered = useSelector(selectIsSearchViewTriggered);
    const { duration: playbackDuration, ready: playbackReady, error: playbackError } = useSelector(state => state.app.playback);
    const handleClick = (e) => {
        // Select text without seeking when holding Alt
        if (e.altKey) {
            return;
        }
        // click only on matched elements or оn ungrouped transcripts (then matched is undefined)
        if (matched === false) {
            return;
        }
        if (playbackError !== FILE_AUTO_DELETED_ERROR) {
            if (!playbackReady)
                return;
            const duration = playbackDuration * 1000;
            const position = startedAt / duration > 1 ? 1 : startedAt / duration;
            dispatch(seekAudio(position));
            if (isSearchViewTriggered) {
                dispatch(hideSearch());
            }
        }
        else {
            dispatch(setActiveTranscriptId(id));
        }
    };
    return (_jsxs("div", { className: cx(`transcripts-chat-row transcripts-chat-${position}`, { "highlighted": isHighlighted, "faded": !matched && enableFaded }), onClick: handleClick, children: [_jsx("div", { className: cx("speaker", { "invisible": !showSpeakerDetails }), children: _jsx(Avatar, { size: 50, color: acronymColor, className: "fw-bold text-white", children: acronym }) }), _jsxs("div", { className: "content", children: [_jsxs("div", { className: "title", onClick: e => e.stopPropagation(), children: [_jsx(SpeakerName, { position: position, speakerId: speakerId, speakerName: speakerName, fileId: fileId, showSpeakerDetails: showSpeakerDetails }), _jsx("span", { className: "small text-muted", children: timestamp })] }), _jsx("div", { className: "transcript", children: text })] })] }));
};
export default TranscriptsChatItem;
