import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { playPauseAudio, skipAudio } from "src/features/auth/store/actions/playback";
import { BifIconPlayerJumpBackwards, BifIconPlayerJumpForwards, BifIconPlayerPause, BifIconPlayerPlay, Button, OverlayTrigger, Tooltip } from "banafo-design-system";
const AudioControls = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fastForwardInterval = useSelector(state => state.app.playback.fastForwardInterval);
    const playbackPlaying = useSelector(state => state.app.playback.playing);
    const handleSkipBackward = () => dispatch(skipAudio(-fastForwardInterval));
    const handleSkipForward = () => dispatch(skipAudio(fastForwardInterval));
    const handlePlayPauseAudio = () => dispatch(playPauseAudio());
    return (_jsxs(_Fragment, { children: [_jsx(OverlayTrigger, { placement: "top", overlay: _jsxs(Tooltip, { id: "open-note", children: [t("Backward_"), " ", fastForwardInterval, "s"] }), children: _jsx(Button, { variant: "outline-white", size: "sm", className: "btn-icon me-100", onClick: handleSkipBackward, children: _jsx(BifIconPlayerJumpBackwards, {}) }) }), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "open-note", children: playbackPlaying ? t("Pause_") : t("Play_") }), children: _jsx(Button, { variant: "outline-white", size: "lg", className: "btn-icon", onClick: handlePlayPauseAudio, children: playbackPlaying ? _jsx(BifIconPlayerPause, {}) : _jsx(BifIconPlayerPlay, {}) }) }), _jsx(OverlayTrigger, { placement: "top", overlay: _jsxs(Tooltip, { id: "open-note", children: [t("Forward_"), " ", fastForwardInterval, "s"] }), children: _jsx(Button, { variant: "outline-white", size: "sm", className: "btn-icon ms-100", onClick: handleSkipForward, children: _jsx(BifIconPlayerJumpForwards, {}) }) })] }));
};
export default AudioControls;
