var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Image } from "banafo-design-system";
function FallbackImage(_a) {
    var { src, srcFallback, isProfilePictureLoading } = _a, props = __rest(_a, ["src", "srcFallback", "isProfilePictureLoading"]);
    const handleError = (e) => {
        e.target.src = srcFallback;
    };
    return (_jsx(Image, Object.assign({}, props, { src: isProfilePictureLoading ? srcFallback : src, onError: handleError })));
}
FallbackImage.defaultProps = {
    isProfilePictureLoading: false
};
FallbackImage.propTypes = {
    isProfilePictureLoading: PropTypes.bool.isRequired,
    src: PropTypes.any,
    srcFallback: PropTypes.string.isRequired
};
export default FallbackImage;
