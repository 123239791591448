import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form } from "banafo-design-system";
import { UPGRADE_PLAN, SUBSCRIPTION_PLAN_PREMIUM_YEARLY, SUBSCRIPTION_PLAN_FREE, SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY } from "src/features/account/config";
import { getCookie } from "src/features/auth/util/cookies";
import PlanItem from "src/features/account/pricingPlans/PlanItem";
import FreePlanFeatures from "src/features/account/pricingPlans/features/FreePlanFeatures";
import ProPlanFeatures from "src/features/account/pricingPlans/features/ProPlanFeatures";
const PlansList = ({ handleOnChange, plan }) => {
    const { t } = useTranslation();
    const [yearly, setYearly] = useState(getCookie('monthly') ? false : true);
    const [free, setFree] = useState(getCookie('free') ? getCookie('free') : null);
    useEffect(() => {
        handleOnChange({
            target: {
                name: "registeredAs",
                value: !yearly ? UPGRADE_PLAN : SUBSCRIPTION_PLAN_PREMIUM_YEARLY
            }
        });
    }, [yearly]);
    useEffect(() => {
        if (free === SUBSCRIPTION_PLAN_FREE) {
            handleOnChange({
                target: {
                    name: "registeredAs",
                    value: SUBSCRIPTION_PLAN_FREE
                }
            });
        }
    }, [free]);
    const handleSetYearly = (e) => {
        setYearly(e.target.checked);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ml-sm-100 text-center my-200", children: [_jsx("label", { className: "me-50", role: "button", htmlFor: "switch-month-anual-plan", children: t("Monthly_") }), _jsx(Form.Check, { type: "switch", inline: true, id: "switch-month-anual-plan", label: t("Yearly.(save.20%)_"), checked: (plan === UPGRADE_PLAN || plan === SUBSCRIPTION_PLAN_PREMIUM_YEARLY) ? yearly : false, onChange: handleSetYearly, className: "fw-bold", disabled: !(plan === UPGRADE_PLAN || plan === SUBSCRIPTION_PLAN_PREMIUM_YEARLY) })] }), _jsxs(Col, { xs: 12, lg: 6, className: "mb-200 mb-lg-0", children: [_jsx(PlanItem, { plan: SUBSCRIPTION_PLAN_FREE, active: plan === SUBSCRIPTION_PLAN_FREE, handleOnChange: handleOnChange }), _jsx(FreePlanFeatures, {})] }), _jsxs(Col, { xs: 12, lg: 6, className: "mb-200 mb-lg-0", children: [_jsx(PlanItem, { plan: !yearly ? UPGRADE_PLAN : SUBSCRIPTION_PLAN_PREMIUM_YEARLY, active: plan === UPGRADE_PLAN || plan === SUBSCRIPTION_PLAN_PREMIUM_YEARLY, subscriptionPeriod: yearly ? SUBSCRIPTION_PERIOD_YEARLY : SUBSCRIPTION_PERIOD_MONTHLY, handleOnChange: handleOnChange }), _jsx(ProPlanFeatures, {})] })] }));
};
export default PlansList;
