import { jsx as _jsx } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import Header from "src/pages/resellerInvitation/Header";
import ResellerInvitation from "src/features/resellerInvitation/ResellerInvitation";
const ResellerInvitationPage = () => {
    return (_jsx(Layout, { header: _jsx(Header, {}), scrollBar: 1, options: {
            contentProps: { className: "px-200" }
        }, children: _jsx(ResellerInvitation, {}) }));
};
export default ResellerInvitationPage;
