import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import PlanFeatures from "src/features/account/pricingPlans/features/PlanFeatures";
import React from "react";
const ProPlanFeatures = ({ className }) => {
    const { t } = useTranslation();
    const PRO_FEATURES = [
        { text: t("infos:pro.plan.1_"), tooltip: undefined },
        { text: t("infos:pro.plan.2_"), tooltip: t("infos:pro.plan.2.tooltip_") },
        { text: t("infos:pro.plan.3_"), tooltip: t("infos:pro.plan.3.tooltip_") },
        { text: t("infos:pro.plan.4_"), tooltip: undefined },
        { text: t("infos:pro.plan.5_"), tooltip: undefined },
        { text: t("infos:pro.plan.6_"), tooltip: undefined },
        { text: t("infos:pro.plan.7_"), tooltip: undefined },
        { text: t("infos:pro.plan.8_"), tooltip: undefined },
        { text: t("infos:pro.plan.9_"), tooltip: undefined }
    ];
    return _jsx(PlanFeatures, { title: t("Everything.in.FREE.and_"), features: PRO_FEATURES, className: className });
};
export default ProPlanFeatures;
