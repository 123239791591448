import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY, SUBSCRIPTION_PLAN_FREE } from "src/features/account/config";
import cx from "classnames";
import React from "react";
const PlanPrice = ({ plan, subscriptionPeriod }) => {
    const { t } = useTranslation();
    const price = () => {
        if (plan === SUBSCRIPTION_PLAN_FREE) {
            return '$0';
        }
        if (plan !== SUBSCRIPTION_PLAN_FREE) {
            if (subscriptionPeriod === SUBSCRIPTION_PERIOD_MONTHLY) {
                return '$10';
            }
            if (subscriptionPeriod === SUBSCRIPTION_PERIOD_YEARLY) {
                return '$8';
            }
        }
    };
    return (_jsxs("div", { className: "pricing", children: [_jsxs("div", { className: "price", children: [_jsx("div", { className: cx("h1 m-0", { "fw-bold": plan !== SUBSCRIPTION_PLAN_FREE }), children: price() }), plan !== SUBSCRIPTION_PLAN_FREE &&
                        _jsx("small", { className: "text-nowrap", children: subscriptionPeriod === SUBSCRIPTION_PERIOD_YEARLY ? t("Billed.Annually_") : t("Billed.Monthly_") })] }), plan !== SUBSCRIPTION_PLAN_FREE &&
                _jsxs("div", { className: "details", children: [_jsx("div", { className: "d-block", children: "USD" }), subscriptionPeriod === SUBSCRIPTION_PERIOD_YEARLY && _jsx("small", { className: "text-nowrap", children: t("per.month_") })] })] }));
};
export default PlanPrice;
