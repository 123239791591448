import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGenerateFtpPassMutation } from "../apiKeysSlice";
import { BanafoModal, BifIconCopy, BifIconInformationCircle, BifIconKey, Button, Col, Form, LineSeparator, Row } from "banafo-design-system";
const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};
const FtpPasswordModal = ({ show, onHide, }) => {
    const { t, i18n } = useTranslation();
    const [generateFtpPass, { data, isSuccess }] = useGenerateFtpPassMutation();
    const [ftpPasswordInput, setFtpPasswordInput] = useState("");
    const [copyInfoText, setCopyInfoText] = useState('');
    const ftpPassRef = useRef(null);
    useEffect(() => {
        if (!isSuccess) {
            return;
        }
        setFtpPasswordInput(data === null || data === void 0 ? void 0 : data.password);
        setCopyInfoText(`* ${i18n.t('infos:Copy.FTP.password.to.clipboard_')}`);
    }, [isSuccess]);
    useEffect(() => {
        return () => {
            setFtpPasswordInput("");
            setCopyInfoText("");
        };
    }, []);
    const handleHideModal = () => {
        onHide();
        setFtpPasswordInput("");
        setCopyInfoText("");
    };
    const handleCreateFtpPassword = () => {
        generateFtpPass();
    };
    const handleCopyFtpPassword = (e) => {
        try {
            ftpPassRef.current.focus();
            ftpPassRef.current.select();
            document.execCommand('copy');
            setCopyInfoText(`${i18n.t('Copied.to.clipboard_')}`);
        }
        catch (err) {
            setCopyInfoText(err.message);
        }
    };
    return (_jsxs(BanafoModal, { size: ftpPasswordInput && 'xl', show: show, title: t('FTP.password_'), onHide: handleHideModal, className: 'text-center', children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Your.generated.FTP.password_') }), _jsx("p", { className: 'fw-bold mb-400', children: t('infos:Lost.your.FTP.password_') }), !ftpPasswordInput &&
                _jsxs(Button, { variant: "dark", disabled: ftpPasswordInput !== "", onClick: handleCreateFtpPassword, className: "mb-150 with-icon end", children: [t("Generate_"), " ", _jsx(BifIconKey, {})] }), ftpPasswordInput && (_jsx(Row, { className: "justify-content-center", children: _jsxs(Col, { xs: 10, className: "d-flex justify-content-center align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, ref: ftpPassRef, value: ftpPasswordInput, className: "d-inline-block" }), _jsx(Form.Text, { className: "d-block text-dark", children: copyInfoText })] }), _jsxs(Button, { variant: "dark", onClick: handleCopyFtpPassword, className: "text-uppercase text-nowrap with-icon end", children: [t('Copy_'), " ", _jsx(BifIconCopy, {})] })] }) }))] }));
};
FtpPasswordModal.propTypes = propTypes;
export default FtpPasswordModal;
