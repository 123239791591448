var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";
import { ErrorMessageModal } from "src/features/auth/component-old/common/MessageModal";
import { selectErrorId, selectErrorText, selectHasError } from "src/features/auth/store/selectors/common";
import { clearError } from "src/features/auth/store/actions/app";
import FallbackComponent from "src/common/components/errorBoundary/FallbackComponent";
import "./PublicLayout.scss";
function PaymentPrivateLayout(_a) {
    var { component: Component, hasError, clearError, errorText, errorId } = _a, rest = __rest(_a, ["component", "hasError", "clearError", "errorText", "errorId"]);
    return (_jsxs("div", { className: "public-layout", children: [_jsx("main", { role: "main", className: "pt-3 container-fluid", children: _jsx(Sentry.ErrorBoundary, { fallback: (props) => _jsx(FallbackComponent, Object.assign({}, props)), children: _jsx(Outlet, {}) }) }), _jsx(ErrorMessageModal, { message: `${errorText}. Error ID ${errorId}`, show: hasError, onHide: () => clearError() })] }));
}
PaymentPrivateLayout.propTypes = {
    clearError: PropTypes.func,
    component: PropTypes.any,
    errorText: PropTypes.any,
    errorId: PropTypes.any,
    hasError: PropTypes.bool,
};
function mapStateToProps(state) {
    return {
        hasError: selectHasError(state),
        errorText: selectErrorText(state),
        errorId: selectErrorId(state),
    };
}
const mapDispatchToProps = {
    clearError,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPrivateLayout);
