import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RESELLER_BILLINGS_PAGINATION_LIMIT } from "src/features/resellerBillingHistory/constants";
const resellerBillingHistoryInitialState = {
    page: 0,
    isLastPage: false,
};
const resellerBillingHistorySlice = createSlice({
    name: 'resellerBillingHistory',
    initialState: resellerBillingHistoryInitialState,
    reducers: {
        resetResellerBillingHistory: () => resellerBillingHistoryInitialState,
        incrementBillingHistoryPage: (state) => {
            state.page += 1;
        },
        resellerBillingHistoryLastPageReached: (state) => {
            state.isLastPage = true;
        },
    }
});
const { name, actions, reducer } = resellerBillingHistorySlice;
export const { resetResellerBillingHistory, incrementBillingHistoryPage, resellerBillingHistoryLastPageReached, } = actions;
export { name, actions };
export default reducer;
export const selectResellerBillingHistoryPage = (state) => state[name].page;
export const selectResellerBillingHistoryIsLastPage = (state) => state[name].isLastPage;
export const selectResellerCustomersApiArgs = createSelector(selectResellerBillingHistoryPage, (page) => {
    const args = { start: page * RESELLER_BILLINGS_PAGINATION_LIMIT };
    return args;
});
