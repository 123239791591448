import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Container, SpinnerBackdrop } from "banafo-design-system";
import { useNewsletterSubscribeMutation } from "src/features/account/accountApi";
import useForceBrowserExtensionCheck from "src/features/browserExtension/hook/useForceBrowserExtensionCheck";
import { selectIntegratedProviders, useAddIntegrationMutation, useGetIntegrationsQuery, useGetProvidersQuery, useRemoveIntegrationMutation } from "./integrationsSlice";
import ProvidersList from "./ProvidersList";
const Integrations = () => {
    useForceBrowserExtensionCheck();
    const { isLoading: isLoadingProviders } = useGetProvidersQuery();
    const { isLoading: isLoadingIntegrations } = useGetIntegrationsQuery();
    const [, { isLoading: isLoadingAddIntegration }] = useAddIntegrationMutation({ fixedCacheKey: "add-integration" });
    const [, { isLoading: isLoadingRemoveIntegration }] = useRemoveIntegrationMutation({ fixedCacheKey: "remove-integration" });
    const [, { isLoading: isLoadingNewsletterSubscribe }] = useNewsletterSubscribeMutation({ fixedCacheKey: "newsletter-subscribe" });
    const integratedProviders = useSelector(selectIntegratedProviders);
    const isLoading = isLoadingProviders || isLoadingIntegrations;
    const isUpdating = isLoadingAddIntegration || isLoadingRemoveIntegration || isLoadingNewsletterSubscribe;
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs(_Fragment, { children: [isUpdating && _jsx(SpinnerBackdrop, {}), _jsx(Container, { fluid: true, className: "g-0", children: _jsx(ProvidersList, { providers: integratedProviders }) })] }));
};
export default Integrations;
