var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "banafo-design-system";
import WhitelistWebsitesRow from "src/features/browserExtension/WhitelistWebsitesRow";
const WhitelistWebsitesTable = ({ whitelistWebsites }) => {
    return (_jsxs(Table, { responsive: true, hover: true, className: "mt-200", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { className: "p-0 m-0" }), _jsx("th", { className: "p-0 m-0" }), _jsx("th", { className: "p-0 m-0" })] }) }), _jsx("tbody", { children: whitelistWebsites.map((_a) => {
                    var { id } = _a, whitelistWebsite = __rest(_a, ["id"]);
                    return _jsx(WhitelistWebsitesRow, Object.assign({ id: id }, whitelistWebsite), id);
                }) })] }));
};
export default WhitelistWebsitesTable;
