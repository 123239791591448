var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Avatar } from "banafo-design-system";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import convertTranscriptsUsages from "src/features/sidebar/convertTranscriptsUsages";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { selectUsagesById, useGetUsagesQuery, useUploadProfilePictureMutation } from "src/features/sidebar/sidebarSlice";
import { ACCOUNT_USAGE_NAME_TRANSCRIPTS } from "src/features/auth/config/accountUsages";
import moment from "moment/moment";
import ProfileDropdown from "src/features/sidebar/ProfileDropdown";
import useAccount from "src/features/account/hooks/useAccount";
import { useAppSelector } from "src/app/store/hooks";
import useHasRole from "src/features/account/hooks/useHasRole";
import { RoleTypes } from "src/features/account/types";
const UserDetails = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { subscriptionPlan: { displayName } } = useAccount();
    const _a = useAppSelector(selectAuthUser), { reseller } = _a, authUser = __rest(_a, ["reseller"]);
    const isReseller = useHasRole(RoleTypes.Reseller);
    const { isFetching, isLoading: isLoadingUsages, isSuccess: isSuccessUsages } = useGetUsagesQuery(location.pathname, { refetchOnMountOrArgChange: true });
    const accountUsagesTranscripts = useSelector(state => selectUsagesById(state, location.pathname, ACCOUNT_USAGE_NAME_TRANSCRIPTS));
    const [, { isSuccess, requestId }] = useUploadProfilePictureMutation({ fixedCacheKey: "upload-profile-picture" });
    return (_jsxs("div", { className: "avatar-container d-flex flex-column", children: [_jsx(ProfileDropdown, {}), _jsxs("div", { className: "text-center order-1", children: [_jsx(Avatar, { src: `/api/v1/media/profile-photos/${authUser._id}.jpg`, alt: 'profile-picture', border: 2 }, isSuccess ? requestId : authUser._id), _jsx("h6", { className: "collapsed-hidden mt-75 mb-0", children: authUser.fullName }), !isReseller && _jsxs("p", { className: "m-0", children: [t('Current.plan_'), ": ", _jsx("span", { className: "fw-bold text-uppercase", children: displayName })] }), reseller && _jsxs("p", { className: "m-0", children: [t('Current.reseller_'), ": ", _jsx("span", { className: "fw-bold text-uppercase", children: reseller.email })] }), authUser.trialEndDate && _jsxs("p", { className: "m-0 text-danger", children: [t('Trial.ends.on_'), ": ", _jsx("span", { className: "fw-bold text-uppercase", children: moment(authUser.trialEndDate).format('MMMM D, YYYY') })] }), !isReseller && _jsxs("p", { className: "m-0", children: [t('Transcripts_'), ": ", ' ', _jsx("span", { className: "fw-bold white-space-pre", children: !isFetching && isSuccessUsages ? convertTranscriptsUsages(accountUsagesTranscripts) + ' ' + t("left_") : '-' })] })] })] }));
};
export default UserDetails;
