import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectConversationFileIds } from "src/features/conversations/conversationsSlice";
import { selectAllTranscripts } from "src/features/transcripts/transcriptsSlice";
import GoogleDriveOpenButton from "src/features/googleDrive/GoogleDriveOpenButton";
import GoogleDriveUploadButton from "src/features/googleDrive/GoogleDriveUploadButton";
const GoogleDriveTab = () => {
    var _a, _b, _c;
    const { callId } = useParams();
    // @ts-ignore
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    // @ts-ignore
    const transcripts = useSelector((state) => selectAllTranscripts(state, { fileId: fileIds }));
    if (!((_a = transcripts[0]) === null || _a === void 0 ? void 0 : _a.transcript.length)) {
        return;
    }
    if (((_b = transcripts[0]) === null || _b === void 0 ? void 0 : _b.googleDriveFileId) && ((_c = transcripts[0]) === null || _c === void 0 ? void 0 : _c.googleDriveWebLink)) {
        return _jsx(GoogleDriveOpenButton, { googleDriveUrl: transcripts[0].googleDriveWebLink });
    }
    else {
        return _jsx(GoogleDriveUploadButton, {});
    }
};
export default GoogleDriveTab;
