import { sendDashboardAvailableMessage } from "src/features/browserExtension/utils/extensionMessages";
export const BROWSER_EXTENSION_AVAILABLE = 'BROWSER_EXTENSION_AVAILABLE';
export const BROWSER_EXTENSION_NOT_AVAILABLE = 'BROWSER_EXTENSION_NOT_AVAILABLE';
export const browserExtensionIsAvailable = () => ({
    type: BROWSER_EXTENSION_AVAILABLE
});
export const browserExtensionIsNotAvailable = () => ({
    type: BROWSER_EXTENSION_NOT_AVAILABLE
});
export const forceBrowserExtensionCheck = () => {
    return (dispatch, getState) => {
        dispatch(browserExtensionIsNotAvailable());
        sendDashboardAvailableMessage();
    };
};
