import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loginAuthorizationCode } from "src/features/auth/store/actions/auth/login";
import { selectHasErrorMessage } from "src/features/auth/store/selectors/common";
import { Card, Col, Container, Row } from "banafo-design-system";
import { AUTH_LOGIN_ROUTE } from "src/app/router/config";
function VerificationPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useParams();
    const hasErrorMessage = useSelector((state) => selectHasErrorMessage(state));
    useEffect(() => {
        dispatch(loginAuthorizationCode(code));
    }, []);
    if (!code || hasErrorMessage) {
        return _jsx(Navigate, { to: AUTH_LOGIN_ROUTE, replace: true });
    }
    return (_jsx("section", { className: "login-verify", children: _jsx(Container, { fluid: 'xl', className: 'pt-300 pb-800', children: _jsx(Row, { children: _jsx(Col, { xs: 12, children: _jsx(Card, { className: "px-lg-300 py-lg-400 border-0 shadow-md", children: _jsx(Card.Body, { children: _jsxs(Card.Text, { children: [t('Verifying.your.email.address_'), "..."] }) }) }) }) }) }) }));
}
export default VerificationPage;
