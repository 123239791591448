var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import TranscriptsChatItem from "src/features/transcripts/TranscriptsChatItem";
import cx from "classnames";
import "./TranscriptsChatList.scss";
const TranscriptsChatList = ({ transcripts, className, enableFaded = false }) => {
    return (_jsx("div", { className: cx("transcripts-chat", className), children: transcripts.map((_a, index) => {
            var { id } = _a, transcript = __rest(_a, ["id"]);
            return _jsx(TranscriptsChatItem, { transcript: Object.assign({ id }, transcript), enableFaded: enableFaded }, index);
        }) }));
};
export default TranscriptsChatList;
