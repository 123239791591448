var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { formattedDateTime } from "src/features/auth/util/dateTime";
import capitalize from "src/helpers/capitalize";
import { RESELLER_BILLINGS_PAGINATION_LIMIT } from "src/features/resellerBillingHistory/constants";
import { resellerBillingHistoryLastPageReached } from "src/features/resellerBillingHistory/resellerBillingHistorySlice";
export const resellerBillingsAdapter = createEntityAdapter();
const initialState = resellerBillingsAdapter.getInitialState({ query: {} });
export const localizedResellerBillingsSelectors = resellerBillingsAdapter.getSelectors();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getResellerBillings: builder.query({
            query: ({ start }) => {
                const payload = {
                    start: start.toString(),
                    limit: RESELLER_BILLINGS_PAGINATION_LIMIT.toString(),
                };
                const params = new URLSearchParams(payload);
                return `/limiter/payments?${params}`;
            },
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { start } = queryArgs, otherArgs = __rest(queryArgs, ["start"]);
                return otherArgs;
            },
            merge: (currentCache, newItems) => {
                return resellerBillingsAdapter.addMany(currentCache, localizedResellerBillingsSelectors.selectAll(newItems));
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            transformResponse: ({ list: resellerBillings = [], query }) => {
                return resellerBillingsAdapter.setAll(Object.assign(Object.assign({}, initialState), { query }), resellerBillings.map((_a) => {
                    var { _id: id } = _a, resellerBillings = __rest(_a, ["_id"]);
                    return (Object.assign({ id }, resellerBillings));
                }));
            },
            onQueryStarted(args, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        if (localizedResellerBillingsSelectors.selectTotal(data) < RESELLER_BILLINGS_PAGINATION_LIMIT) {
                            dispatch(resellerBillingHistoryLastPageReached());
                        }
                    }
                    catch (err) {
                    }
                });
            },
            keepUnusedDataFor: 0,
        }),
    })
});
export const { useGetResellerBillingsQuery, } = extendedApi;
//SELECTORS
// ResellerBillingsEntity
export const selectResellerBillingsEntityResult = createSelector((args) => args, (args) => extendedApi.endpoints.getResellerBillings.select(args));
export const selectResellerBillingsEntityData = createSelector((state, args) => selectResellerBillingsEntityResult(args)(state), (resellerBillingsEntityResult) => { var _a; return (_a = resellerBillingsEntityResult === null || resellerBillingsEntityResult === void 0 ? void 0 : resellerBillingsEntityResult.data) !== null && _a !== void 0 ? _a : initialState; });
export const selectAllResellerBillingsEntity = createSelector((state, args) => selectResellerBillingsEntityData(state, args), (resellerBillingsEntityData) => {
    return localizedResellerBillingsSelectors.selectAll(resellerBillingsEntityData);
});
export const selectTotalResellerBillings = createSelector((state, args) => selectResellerBillingsEntityData(state, args), (resellerBillingsEntityData) => {
    return localizedResellerBillingsSelectors.selectTotal(resellerBillingsEntityData);
});
// ResellerBillings
export const selectAllResellerBillings = createSelector((state, args) => selectAllResellerBillingsEntity(state, args), (allResellerBillingsEntity) => {
    return allResellerBillingsEntity.map((_a) => {
        var _b, _c;
        var { status, createdAt } = _a, resellerBilling = __rest(_a, ["status", "createdAt"]);
        return Object.assign(Object.assign({}, resellerBilling), { status: (_b = (status && capitalize(status))) !== null && _b !== void 0 ? _b : status, createdAt: (_c = (createdAt && formattedDateTime(createdAt))) !== null && _c !== void 0 ? _c : createdAt });
    });
});
export const makeSelectAllResellerBillings = () => {
    return selectAllResellerBillings;
};
