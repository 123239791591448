var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { createEntityAdapter, createSelector, createSlice, createAction, isAllOf } from "@reduxjs/toolkit";
import { CONTACTS_PAGINATION_LIMIT } from "src/features/contacts/config";
import range from "src/common/utils/range";
const contactsAdapter = createEntityAdapter();
const initialState = contactsAdapter.getInitialState({
    query: {},
    pagination: {}
});
const contactsRemoved = createAction('contacts/contactsRemoved');
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getContacts: builder.query({
            query: ({ searchFilters, start }) => {
                const data = Object.assign(Object.assign({}, searchFilters), { limit: CONTACTS_PAGINATION_LIMIT, start });
                const params = new URLSearchParams(data);
                return `/contacts?${params}`;
            },
            transformResponse: ({ list = [], pagination = {}, query = {} }) => contactsAdapter.setAll(Object.assign(Object.assign({}, initialState), { pagination, query }), list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            onCacheEntryAdded(arg, { cacheEntryRemoved, dispatch }) {
                return __awaiter(this, void 0, void 0, function* () {
                    yield cacheEntryRemoved;
                    if (arg === 0) {
                        dispatch(contactsRemoved(arg));
                    }
                });
            },
            providesTags: (result) => result
                ?
                    [
                        ...contactsAdapter.getSelectors().selectAll(result).map(({ id }) => ({ type: 'Contacts', id })),
                        { type: 'Contacts', id: 'LIST' },
                    ]
                :
                    [{ type: 'Contacts', id: 'LIST' }],
        }),
        getExisitngContacts: builder.query({
            query: ({ provider, search, pagination }) => {
                const payload = { provider, search, limit: CONTACTS_PAGINATION_LIMIT };
                const params = new URLSearchParams(payload);
                return `/contacts?${params}`;
            },
            transformResponse: ({ list = [] }) => contactsAdapter.setAll(initialState, list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            providesTags: ["Contacts"]
        }),
        getContactUrl: builder.query({
            query: ({ provider, contactId, type }) => {
                const payload = { provider, contactId, type };
                const params = new URLSearchParams(payload);
                return `/contacts/contact-url?${params}`;
            },
        }),
    })
});
export const { useGetContactsQuery, useLazyGetContactsQuery, useLazyGetExisitngContactsQuery, useLazyGetContactUrlQuery, } = apiApp;
//SELECTORS
export const selectExisitngContactsResult = (state, args) => extendedApi.endpoints.getExisitngContacts.select(args)(state);
export const selectExisitngContactsData = createSelector(selectExisitngContactsResult, contactsResult => contactsResult.data);
export const { selectAll: selectExisitngAllContacts, selectEntities: selectExisitngEntitiesContacts, } = contactsAdapter.getSelectors((state, args) => { var _a; return (_a = selectExisitngContactsData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
export const selectContactsResult = (state, args) => extendedApi.endpoints.getContacts.select(args)(state);
export const selectContactsData = createSelector(selectContactsResult, contactsResult => contactsResult.data);
export const selectContactsById = createSelector((state, args) => { var _a; return (_a = selectContactsData(state, args)) !== null && _a !== void 0 ? _a : initialState; }, (state, args, id) => id, (data, id) => (contactsAdapter.getSelectors().selectById(data, id) || {}));
export const { selectAll: selectAllContacts, selectEntities: selectEntitiesContacts, } = contactsAdapter.getSelectors((state, args) => { var _a; return (_a = selectContactsData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
export const selectHasNextPage = createSelector(selectContactsData, (data = initialState) => {
    const { ids, pagination, query: { total, start, limit } } = data;
    const hasNextPage = pagination.next_page;
    return hasNextPage;
});
export const selectCombinedContacts = (state, args) => {
    const { start } = args;
    const perPage = CONTACTS_PAGINATION_LIMIT;
    let contacts = [];
    for (let x of range(0, start, perPage)) {
        contacts = [...contacts, ...selectAllContacts(state, Object.assign(Object.assign({}, args), { start: x }))];
    }
    return contacts;
};
export const selectCombinedContactsResult = (state, args) => {
    const hasNextPage = selectHasNextPage(state, args);
    const { isError, isLoading, isSuccess, isUninitialized } = selectContactsResult(state, args);
    const contacts = selectCombinedContacts(state, args);
    return {
        data: {
            contacts,
            total: contacts.length,
            hasNextPage
        },
        isError,
        isLoading,
        isSuccess,
        isUninitialized
    };
};
// SLICE
const matchContactsRemoved = isAllOf(contactsRemoved);
const initFilters = {
    provider: '',
    search: '',
    types: []
};
const initialSliceState = {
    page: 0,
    filters: initFilters,
    filtersHamburgerExpanded: false,
};
export const contactsSlice = createSlice({
    name: 'contacts',
    initialState: initialSliceState,
    reducers: {
        reset: () => initialSliceState,
        resetPage: state => {
            state.page = 0;
        },
        incrementPage: state => {
            state.page++;
        },
        changeFilter: (state, action) => {
            const { name, value } = action.payload;
            state.filters[name] = value;
            state.page = 0;
        },
        toggleFiltersHamburgerMenu: state => {
            state.filtersHamburgerExpanded = !state.filtersHamburgerExpanded;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(matchContactsRemoved, (state, action) => {
            return initialSliceState;
        });
    },
});
export const { name, actions } = contactsSlice;
export const { reset, resetPage, incrementPage, changeFilter, toggleFiltersHamburgerMenu } = contactsSlice.actions;
export default contactsSlice.reducer;
export const selectPage = state => state[name].page;
export const selectFilters = state => state[name].filters;
export const selectFiltersHamburgerIsExpanded = state => state[name].filtersHamburgerExpanded;
