import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button } from "banafo-design-system";
import useAccount from "src/features/account/hooks/useAccount";
const UpgradeButton = () => {
    const { t } = useTranslation();
    const { subscriptionPlan: { isGroupFree: show }, upgradeAccount } = useAccount();
    const handlePlanUpgrade = (e) => {
        e.preventDefault();
        upgradeAccount();
    };
    return (_jsx(_Fragment, { children: show && _jsx(Button, { onClick: handlePlanUpgrade, className: "text-uppercase mb-50", variant: "dark", children: _jsx("span", { className: "collapsed-hidden", children: t("Upgrade.to.PRO_") }) }) }));
};
export default UpgradeButton;
