import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider } from "@mantine/core";
import ResellerCustomersTableFilters from "src/features/resellerCustomers/table/ResellerCustomersTableFilters";
import ResellerCustomersTable from "src/features/resellerCustomers/table/ResellerCustomersTable";
const ResellerCustomers = forwardRef(({ resellerCustomers, totalResellerCustomers, loadMoreResellerCustomers, isLoading, isFetching, hasNextPage, }, ref) => {
    const { t } = useTranslation();
    return (_jsxs(Box, { flex: "1", ta: "center", ref: ref, style: { scrollMargin: '2rem' }, children: [_jsx(ResellerCustomersTableFilters, { total: totalResellerCustomers }), _jsx(Divider, { size: "md", my: "sm" }), _jsx(ResellerCustomersTable, { resellerCustomers: resellerCustomers }), hasNextPage && _jsx(Button, { onClick: loadMoreResellerCustomers, variant: "outline-filled", loading: isFetching, tt: "uppercase", mt: "md", children: t('Load.more_') })] }));
});
export default ResellerCustomers;
